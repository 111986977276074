import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "../../components/accordion";
import Button from "../../components/button";
import Card from "../../components/card";
import Footer from "../../components/footer";
import Giftcard from "../../components/giftcard";
import Header from "../../components/header";
import SubHeadBox from "../../components/subHeaderBox";
import TabHeader from "../../components/tabHeader";
import Utils from "../../library/Utils";
import { useGetPlanListQuery } from "../../services/customerApi";
import { useGetUseCasesQuery } from "../../services/useCaseSetupApi";
import {
  BannerImgWrap,
  BannerSection,
  BannerTextSection,
  FeaturesCardOverall,
  GiftcardOverall,
  GreyBgSection,
  Homewrap,
  PricingFooter,
  PurpleBox,
  UsecaseCardOverall,
  WhiteBgSection1,
} from "./homepage.styles";

const env = window._jsenv || process.env
const imageBaseUrl = env.REACT_APP_GCP_BASE_URL;


const Home = () => {

    const St = `${imageBaseUrl}assets/png/landinggrad.webp`
    const RectangleBlur = `${imageBaseUrl}assets/png/Rectangle.webp`
    const virtual = `${imageBaseUrl}assets/svg/virtual.svg`
    const support = `${imageBaseUrl}assets/svg/support.svg`
    const skillset = `${imageBaseUrl}assets/svg/skillset.svg`
    const shield = `${imageBaseUrl}assets/svg/shield-security.svg`
    const paystack = `${imageBaseUrl}assets/svg/paystack.svg`
    const knowledge = `${imageBaseUrl}assets/svg/knowledge-base.svg`
    const general = `${imageBaseUrl}assets/svg/general.svg`
    const doc = `${imageBaseUrl}assets/svg/doc-import.svg`
    const customize = `${imageBaseUrl}assets/svg/customization.svg`
    const chat = `${imageBaseUrl}assets/svg/chat-history.svg`
    const assessment = `${imageBaseUrl}assets/svg/assesment.svg`
    const android = `${imageBaseUrl}assets/svg/android.svg`
    const howItWorks = `${imageBaseUrl}assets/png/how-it-works.png`
    const brain = `${imageBaseUrl}assets/png/brain.png`
    const banner = `${imageBaseUrl}assets/png/banner.png`

  const [clicked, setClicked] = useState("0");
  const [planType, setPLanType] = useState("monthly");
  const [monthlyPlans, setMonthlyPlans] = useState<any>([]);
  const [yearlyPlans, setYearlyPlans] = useState<any>([]);
  const [casesReceived, setCasesReceived] = useState<any>([]);
  const [bannerCache,setBannerCache] = useState<any>('')
  const navigate = useNavigate();
  const featureRef: any = useRef(null);
  const pricingRef: any = useRef(null);
  const contactRef: any = useRef(null);
  const [scrollInto, setScrollInto] = useState<HTMLElement | null>(null);

  const { data, isLoading, isSuccess, refetch } = useGetPlanListQuery();
  const {
    data: usecaseData,
    isLoading: usecaseLoading,
    isSuccess: usecaseSuccess,
    refetch: refetchUsecase,
    isError: isUscaseError,
  } = useGetUseCasesQuery();

  const location = useLocation();

  useEffect(()=>{
    if(!Utils.isFieldEmpty(localStorage.getItem('bannerCache'))){
      const imgCache = localStorage.getItem("bannerCache")
      setBannerCache(imgCache)
    }
    else{
      localStorage.setItem("bannerCache",banner)
    }

  },[banner])



  useEffect(() => {
    const sectionRef = location.hash; // Extracting section reference from hash
    if (sectionRef && sectionRef !== "/") {
      const sectionElement = document.getElementById(sectionRef);
      if (sectionElement) {
        setScrollInto(sectionElement);
        //sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useLayoutEffect(() => {
    //  document.documentElement.style.fontSize = "16px";
  }, []);

  useEffect(() => {
    if (scrollInto !== null) {
      scrollInto.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollInto]);

  // USEEFFECT TO GET THE PLANS
  useEffect(() => {
    refetch();
    refetchUsecase();
  }, [data, usecaseData]);

  useEffect(() => {
    if (isSuccess) {
      setMonthlyPlans(data?.monthly);
      setYearlyPlans(data?.yearly);
    }

    if (usecaseSuccess) {
      setCasesReceived(usecaseData);
    }
  }, [data, isSuccess, usecaseData, usecaseSuccess]);

  const List1 = [
    {
      header: "Select your use case",
      content:
        "Choose the one that aligns with your bot's objectives and services to achieve a seamless fit with a distinctive character and purpose.",
    },
    {
      header: "Create knowledge base",
      content:
        "Create a knowledge base for your data. Prolific will use it to respond to user input with customized datasets.",
    },
    {
      header: "Customize your AI",
      content: "Choose a name and an avatar for your AI.",
    },
    {
      header: "Start your conversation",
      content: "Start exploring with your AI",
    },
  ];

  const FaqList = [
    {
      header: "What is this service for?",
      content:
        "Prolific is a platform that allows you to create and interact with chatbots, access a variety of tools and services, and also gives you access to pre-built apps that you can use to perform a wide range of tasks.",
    },
    {
      header: "How do I use Prolific?",
      content:
        "You can use Prolific by typing you questions or statements into the chat window. Prolific will then generate a response based on the information it has been programmed to understand.",
    },
    {
      header: "Do you offer free trial?",
      content:
        "Yes, we offer you free access to experience Prolific one (1) chatbot and fifty (50) messages",
    },
    {
      header: "Is there a cost to use Prolific",
      content:
        "Yes. Our monthly membership includes access to any chatbot and all of its features",
    },
  ];

  const featureList = [
    {
      img: chat,
      title: "Chat history",
      content:
        "Easily review and reference previous conversations with your bots, ensuring that it has all the information it needs.",
    },
    {
      img: knowledge,
      title: "Knowledge base",
      content:
        "Manage and organize the data you want Prolific to use to respond to user input with bespoke datasets.",
    },
    {
      img: skillset,
      title: "Custom Skillsets",
      content:
        "Special abilities of Prolific to fetch information from web pages in seconds",
    },
    {
      img: doc,
      title: "Document Importing",
      content:
        "Import PDF, DOCX, CSV and other popular file formats directly into your datasets.",
    },
    {
      img: customize,
      title: "Extreme Customization",
      content:
        "Customize Prolific's responses and behavior based on variety of use cases",
    },
    {
      img: android,
      title: "Personalization",
      content:
        "Choose from our presets of avatars and names or  customize Prolific to suit your brand",
    },
  ];

  const useCaseList = [
    {
      img: assessment,
      title: "Knowledge Assessment",
      content:
        "Improve your knowledge by generating quiz based tests from the data you provdded",
    },

    {
      img: virtual,
      title: "Virtual Assistant",
      content:
        "Improve your knowledge by generating quiz based tests from the data you provdded",
    },

    {
      img: support,
      title: "Customer Support",
      content:
        "Improve your knowledge by generating quiz based tests from the data you provdded",
    },

    {
      img: general,
      title: "General",
      content:
        "Improve your knowledge by generating quiz based tests from the data you provdded",
    },
  ];

  const handleToggle = (index: string) => {
    if (clicked === String(index)) {
      return setClicked("");
    }
    setClicked(String(index));
  };

  const handleScroll = (ref: any) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header
        featureRef={featureRef}
        pricingRef={pricingRef}
        handleScroll={handleScroll}
        contactRef={contactRef}
      />

      <Homewrap
        style={{
          height: "710px",
          backgroundImage: `url(${St})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          // backgroundPosition: "center",
          position: "relative",
        }}
      >
        <BannerSection>
          <BannerTextSection>
            <h2>
              Transform your data into intelligent conversations with{" "}
              <span> Prolific </span>
            </h2>

            <p>
              Prolific creates conversational AI chatbots with your own data to
              communicate naturally.
            </p>

            <Button
              text={"Get started for free"}
              btnType={"free-cta"}
              onClick={() => navigate("/signup")}
            />
          </BannerTextSection>
          <BannerImgWrap>
            <img src={Utils.isFieldEmpty(bannerCache)?banner:bannerCache}  loading="lazy" />
          </BannerImgWrap>
        </BannerSection>

        {/* HOW IT WORKS */}
        <WhiteBgSection1>
          <div className="inner-wrapper">
            <h2>
              <span>Prolific</span> provides functionality for every step of a
              modern conversational workflow
            </h2>

            <div className="how-it-works-flex">
              <div className="left-text-side">
                <SubHeadBox title={"HOW IT WORKS"} />
                <div>
                  {List1?.map((item: any, id: any) => {
                    return (
                      <Accordion
                        key={id}
                        num={Number(id) + 1}
                        list={item}
                        onToggle={() => handleToggle(id)}
                        active={clicked === String(id)}
                      />
                    );
                  })}
                </div>

                {/* <Button 
                                btnType={'cta'}
                                text={'Try now'}
                                onClick={() => navigate("/choose-plan")}
                            /> */}
              </div>

              <div className="right-img-side">
                <img src={howItWorks} />
              </div>
            </div>
          </div>
        </WhiteBgSection1>

        <WhiteBgSection1 id={"#feature"} ref={featureRef}>
          <div className="inner-wrapper">
            <div className="features-header">
              <SubHeadBox title={"FEATURES"} />

              <h2>
                <span>Prolific </span>
                creates conversational AI with your own data!
              </h2>

              <p>
                A better and easy way for your customers to interact with your
                business on the web.
              </p>
            </div>

            <FeaturesCardOverall>
              {featureList?.map((item: any, id: number) => {
                return (
                  <Card
                    key={id}
                    icon={item.img}
                    title={item.title}
                    content={item.content}
                  />
                );
              })}
            </FeaturesCardOverall>
          </div>
        </WhiteBgSection1>

        <WhiteBgSection1>
          <div className="inner-wrapper">
            <div className="features-header use-case">
              <h2>Explore different use cases to model your AI.</h2>

              <p>
                Choose the one that aligns with your bot's objectives and
                services to achieve a seamless fit with a distinctive character
                and purpose.
              </p>
            </div>

            <UsecaseCardOverall>
              {casesReceived?.map((item: any, id: number) => {
                return (
                  <Card
                    key={id}
                    icon={item.icon}
                    title={item.name}
                    content={item.description}
                    cardType={"useCase"}
                    onCardClicked={() => {
                      return;
                    }}
                  />
                );
              })}
            </UsecaseCardOverall>
          </div>
        </WhiteBgSection1>

        <GreyBgSection id={"#pricing"} ref={pricingRef}>
          <div className="inner-wrapper">
            <div className="features-header">
              <SubHeadBox title={"PRICING"} />

              <h2>
                Try <span>Prolific </span> before you buy!
              </h2>

              <p>
                Experience Prolific with free access to 1 chatbot and 50
                messages when you get started. No credit card required.
              </p>

              <TabHeader planType={planType} setPLanType={setPLanType} />

              <GiftcardOverall>
                {planType === "monthly"
                  ? monthlyPlans?.map((item: any, index: any) => {
                      return (
                        <Giftcard
                          key={index}
                          accessType={item?.name}
                          queryNum={item?.totalMessages}
                          chatbotNum={item?.totalChatbots}
                          price={item?.price}
                          onClick={() => navigate("/signup")}
                          time={item?.time}
                          btnText={"Get Started"}
                        />
                      );
                    })
                  : yearlyPlans?.map((item: any, index: any) => {
                      return (
                        <Giftcard
                          key={index}
                          accessType={item?.name}
                          queryNum={item?.totalMessages}
                          chatbotNum={item?.totalChatbots}
                          price={item?.price}
                          onClick={() => navigate("/signup")}
                          time={item?.time}
                          btnText={"Get Started"}
                        />
                      );
                    })}
              </GiftcardOverall>

              <PricingFooter>
                <img src={shield} />
                <p className="pricing-footer-text">Payment secured by</p>
                <img src={paystack} />
              </PricingFooter>
            </div>
          </div>
        </GreyBgSection>

        <WhiteBgSection1 id={"#contact"} ref={contactRef}>
          <div className="inner-wrapper">
            <div className="how-it-works-flex faq-flex">
              <div className="left-text-side faq-txt">
                <h4 className="gradient-header">Frequently asked questions</h4>

                <p className="content-text">
                  Answers to common questions our users are asking about
                  Prolific. If you can't find what you're looking for, you can
                  always send us an email with your enquiry
                </p>

                <a href={`mailto:sales@periculum.io`}>Contact Sales</a>
              </div>

              <div className="right-text-side">
                {FaqList?.map((item: any, id: any) => {
                  return (
                    <Accordion
                      key={id}
                      num={id + 1}
                      list={item}
                      onToggle={() => handleToggle(id)}
                      active={clicked === String(id)}
                    />
                  );
                })}
              </div>
            </div>

            <PurpleBox>
              <div className="inner-purple-box">
                <div className="purple-text-content">
                  <h4 className="header-text">
                    AI has come to stay with
                    <span> Prolific</span>
                  </h4>

                  <div className="content-text">
                    Experience the power of real-time communication and take
                    your data analysis to the next level with Prolific AI.
                  </div>

                  <Button
                    btnType={"cta"}
                    text={"Try now for free"}
                    onClick={() => navigate("/signup")}
                  />
                </div>

                <div className="img-wrap">
                  <img src={brain} />
                </div>
              </div>
            </PurpleBox>
          </div>
        </WhiteBgSection1>

        <Footer
          featureRef={featureRef}
          pricingRef={pricingRef}
          handleScroll={handleScroll}
          contactRef={contactRef}
        />
      </Homewrap>
    </>
  );
};

export default Home;
