import styled, { css } from "styled-components";

export const GiftcardWrap = styled.div<{ accessType: string }>`
  background-color: #fff;
  border: solid 1px #f5f5f5;
  border-radius: 10px;

  .inner-gift-wrap {
    height: 100%;
    background-color: transparent;
    padding: 25px;

    .top-card-section {
      border-radius: 10px;
      border: solid 1px #e5e5e5;
      background-color: #fafafa;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 15px;
      padding: 15px;
      min-height: 100px;

      .card-type {
        color: #737373;
        text-transform: uppercase;

        ${(props) =>
          props.accessType === "growth" &&
          css`
            color: #16a34a;
          `}

        ${(props) =>
          props.accessType === "standard" &&
          css`
            color: #502a8d;
          `}
        
                ${(props) =>
          props.accessType === "enterprise" &&
          css`
            color: #171717;
          `}
      }

      .card-price {
        font-size: 1.3rem;
        font-weight: 700;
      }

      .card-info {
        p {
          margin: 0;
          margin-bottom: 10px;
          text-align: center;
          width: unset;
          font-size: 0.9rem;
        }
      }

      .currency-control {
        display: flex;
        align-items: center;
        column-gap: 8px;
      }
    }

    .access-type-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 130px;

      .content-header {
        color: #062149;
        font-size: 0.9rem;
        text-align: center;
        font-weight: 500;
      }
    }
  }
`;

export const AccessListWrap = styled.div`
  margin-top: 0px;

  ul {
    padding-left: 20px;
    margin-bottom: 25px;

    li {
      color: #062149;
      margin-top: 3px;
      font-size: 0.7rem;
    }
  }
`;

export const AccessContentWrap = styled.div<{ accessType: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 100%;

  .access-type-box {
    padding: 7px 15px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: #737373;
    font-weight: 600;
    background-color: #f5f5f5;
    text-transform: uppercase;

    ${(props) =>
      props.accessType === "growth" &&
      css`
        background-color: #f0fdf4;
        color: #16a34a;
      `}

    ${(props) =>
      props.accessType === "standard" &&
      css`
        color: #502a8d;
        background-color: #faf6ff;
      `}

        ${(props) =>
      props.accessType === "enterprise" &&
      css`
        background-color: #898989;
        color: #171717;
      `}
  }

  .plus-wrap {
    color: #737373;
    font-size: 0.8rem;
    font-weight: 600;
  }

  .text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 5px;

    p {
      color: #062149;
      font-weight: 700;
      font-size: 0.7rem;
      width: unset !important;
      margin: 0;
    }
  }
`;

export const GiftcardBtn = styled.div<{ accessType: string }>`
  outline: none;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  color: #fff;
  font-size: 1rem;
  padding: 15px;
  background-color: #737373;
  cursor: pointer;

  ${(props) =>
    props.accessType === "growth" &&
    css`
      background-color: #15803d;
    `}

  ${(props) =>
    props.accessType === "standard" &&
    css`
      background-color: #502a8d;
    `}

    ${(props) =>
    props.accessType === "enterprise" &&
    css`
      background-color: #171717;
    `}
`;

export const FreeAccesText = styled.p`
  font-size: 0.7rem;
  text-align: center;
  color: #525252;
  font-weight: 500;
  width: 100% !important;
`;
