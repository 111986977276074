import { useState } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { AuthWrap } from "./auth.styles";

import { useNavigate } from "react-router-dom";
import logo from "../../assets/svg/logo-white.svg";
import AuthCard from "../../components/authCard";
import Button from "../../components/button";
import FeedbackBox from "../../components/feedbackBox";

const Auth = () => {
  const navigate = useNavigate();
  const [authPage, setAuthPage] = useState("login");

  const handleAuthPage = (value: string) => {
    setAuthPage(value);
  };

  return (
    <>
      <AuthWrap>
        <div className="inner-auth-wrap">
          <div className="auth-header">
            <img src={logo} alt="" />
          </div>

          <div className="auth-flex-wrap">
            <div className="left-auth-subwrap">
              <div className="btn-wrap">
                <Button
                  btnType={"backWhite"}
                  text={"Go home"}
                  onClick={() => navigate("/")}
                />
              </div>

              <div className="auth-text-wrap">
                <div className="large-text">
                  <h5>
                    Get started with <span> Prolific </span> for free.
                  </h5>

                  <p>
                    Prolific create conversational AI chatbots with your own
                    data to communicate naturally.
                  </p>
                </div>
              </div>

              <div className="swiper-wrap">
                <Swiper
                  spaceBetween={20}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  direction="horizontal"
                  modules={[Autoplay]}
                >
                  <SwiperSlide>
                    <FeedbackBox />
                  </SwiperSlide>

                  <SwiperSlide>
                    <FeedbackBox />
                  </SwiperSlide>

                  <SwiperSlide>
                    <FeedbackBox />
                  </SwiperSlide>

                  <SwiperSlide>
                    <FeedbackBox />
                  </SwiperSlide>
                </Swiper>
              </div>

              <div className="auth-footer">
                <p>
                  © Copyright 2023 - Prolific Technologies Limited || All Rights
                  Reserved
                </p>
              </div>
            </div>

            <div className="right-auth-subwrap">
              <AuthCard authType={authPage} handleAuthPage={handleAuthPage} />
            </div>
          </div>
        </div>
      </AuthWrap>
    </>
  );
};

export default Auth;
