import React, { useEffect, useRef, useState } from 'react';
import AppWrapper from './wrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import store, { RootState, useAppSelector } from './store';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Utils from './library/Utils';
import { FetchDataStatus } from './library/Variables';
import { setToken } from './slices/keyToken';
import { useGetUserQuery } from './services/onboardingApi';
import { useGetProjectCountQuery } from './services/customerApi';
import { clearAuth } from './slices/auth';



function App() { 
  const expires_in = useAppSelector<any>((store:RootState) => store?.auth?.auth?.token?.expires_in)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenCheckerInterval:any = useRef(null);
  const [timeLeft, setTimeLeft] = useState(expires_in);


  // const {data, isLoading, isSuccess, refetch, isError} = useGetUserQuery()

  useEffect(() => {
    if(expires_in) {
      let loggedInDate = localStorage.getItem('prolificLoggedInTime');
      let expiresDate = DateTime.fromISO(loggedInDate).plus({ seconds: expires_in });
      setTimeLeft(timeLeft - 1)


      if(tokenCheckerInterval.current === null) {
        tokenCheckerInterval.current = setInterval(() => {
          let currentDateTime = DateTime.now();
          if(Utils.isTokenExpired(expiresDate.toMillis(), currentDateTime.toMillis())) {
            localStorage.removeItem('prolificLoggedInTime');
            localStorage.removeItem("prolificLastVisited")
            toast.info ('Session expired. Kindly log-in again', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
          })
            dispatch(clearAuth());
            navigate('/login');
          } 
        }, 25000)
      }
    }
    else {
      clearInterval(tokenCheckerInterval.current);
      tokenCheckerInterval.current = null;
      if(['/login','/confirmPassword','/signup','/password-forgot','/','/ambassador','/contact-us'].includes(window.location.pathname)){
      }
      else{
        navigate("/login")
      }
      
    }

  }, [expires_in, tokenCheckerInterval])

  return (
    <div className="App">
      <AppWrapper />
    </div>
  );
}

export default App;
