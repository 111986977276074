import React, { useState, useEffect } from 'react'
import { ShareWhiteBox, UserHeaderWrap, UserMobileHeader } from './userheader.styles'
import Button from '../button'
import Modal from 'react-modal';
import { useNavigate,  useParams, createSearchParams  } from 'react-router-dom'
import Input from '../input';
import { Overlay } from '../header/header.styles';
import { SidebarAccordion, SidebarAccordionList, ProjectList, SiteLinkList } from '../userSidebar/usersidebar.styles';

import logo from "../../assets/svg/prolific-logo.svg"
import share from "../../assets/svg/share.svg"
import copy from "../../assets/svg/copy.svg"
import cancel from "../../assets/svg/dark-cancel.svg"
import link from "../../assets/svg/link.svg"
import code from "../../assets/svg/code.svg"
import hamburger from "../../assets/svg/hamburger.svg"
import closeSquareBlue from "../../assets/svg/close-square-blue.svg"
import trash from "../../assets/svg/trash.svg"
import message from "../../assets/svg/message-question.svg"
import settings from "../../assets/svg/setting-2.svg"
import logout from "../../assets/svg/logout.svg"
import closeSquare from "../../assets/svg/close-square.svg"
import closeCircle from "../../assets/svg/close-circle-2.svg"
import edit from "../../assets/svg/edit-2.svg"
import cross from "../../assets/svg/blue-cross.svg"
import file from "../../assets/svg/blue-file.svg"
import InfoBar from './infoBar';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from '../../store'
import { useGetConversationListQuery, useGetCurrentPlanQuery, useDeleteConversationMutation, useUpdateConversationTitleMutation } from '../../services/customerApi'
import { clearAuth } from '../../slices/auth'
import DeleteConvo from '../deletePrompt';
import EditConvoTitle from '../editPrompt';




const UserHeader = ({projectList, handleProjectChoice, projectId, conversationId}:any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const path = window?.location?.pathname
  const [linkModal, setLinkModal] = useState(false)
  const [modalActive, setModalActive] = useState(false)
  const [conversationList, setConversationList] = useState<any>([])
  const [activePlan, setActivePlan] = useState<any>([])
  const [triggerFetch,setTriggerFetch] = useState(false)
  const [showDelete,setShowDelete] = useState(false)
  const [selectedConvo,setSelectedConvo] = useState<any>('')
  const [showEditPop,setShowEditPop] = useState(false)


  const count = useAppSelector<any>((store:RootState) => store?.auth?.auth?.count)

  const {data, isLoading, isSuccess, isError, error, refetch} = useGetConversationListQuery()

  const {data: currentPlanData, refetch: refetchCurrentPlan, isSuccess: currentPlanSuccess} = useGetCurrentPlanQuery()

  const [deleteConversation, {data: deleteConversationData, isLoading: deleteConversationLoading, isSuccess: deleteConversationSuccess, isError: isdeleteConversationError, error: deleteConversationError}] = useDeleteConversationMutation() ;
  const [updateConversationTitle, {data: updateConversationTitleData, isLoading: updateConversationTitleLoading, isSuccess: updateConversationTitleSuccess, isError: isupdateConversationTitleError, error: updateConversationTitleError}] = useUpdateConversationTitleMutation()


  const customStyles = {
    content: {
    transition: '0.125s ease-in-out',
    transform: 'translate(-50%, -50%)',
    height: '33%',
    width: '33%',
    top: '50%',
    left: '50%',
    padding: '0 0 0 0',
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignCenter: 'center',
    border: 'none',
    },
    overlay: {
    zIndex: 3000,
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.7)'
    }
  };


    // USEEFFECT TO GET CONVERSATION LIST
    useEffect(() => {
        refetch()
        refetchCurrentPlan()
    }, [data, currentPlanData,triggerFetch])

    useEffect(() => {
        if(isSuccess) {
            setConversationList(data?.conversations)
        }

        if(currentPlanSuccess) {
            setActivePlan(currentPlanData)
        }
    }, [isSuccess, data, isLoading, isError, error, currentPlanSuccess])

    useEffect(()=>{
      if(deleteConversationSuccess || updateConversationTitleSuccess){
          
          setTriggerFetch(!triggerFetch)
      }
  },[deleteConversationSuccess,updateConversationTitleSuccess])


  useEffect(()=>{
      setShowEditPop(false)
      setShowDelete(false)
  },[data])

  const handleGotoConvo = (item:any) => {
    setModalActive(false)
    const params:any = {chatId: `${item?.conversationUuid}`, projectId: `${item?.projectUuid}`
                        , language:`${item?.language}` }

    navigate({
        pathname: "/chatroom",
        search: `?${createSearchParams(params).toString()}`
    })
}

  const handleDeleteConversation = (id:any) => {
    const params = id

    deleteConversation(params)
}

const handleEditConversation = (val:any) => {
  const params = val

  updateConversationTitle(params)
}


const handleDeletePop = (item:any)=>{
  setSelectedConvo(item)
  setShowDelete(true)
}

  const handleLogout = () => {
      navigate("/login")
      localStorage.removeItem('prolificLoggedInTime');
      localStorage.removeItem("prolificLastVisited")
      dispatch(clearAuth())
  }


  const handleEditPop = (item:any)=>{
    setSelectedConvo(item)
    setShowEditPop(true)
}

  return (
    <>
        <Modal className={"react-modal-class"} isOpen={linkModal} style={customStyles} onRequestClose={() => setLinkModal(false)}>
          <ShareWhiteBox>
            <div className="header-section">
              <h3>Create shareable link</h3>

              <img src={cancel} alt="" onClick={() => setLinkModal(false)} />
            </div>

            <div className="form-wrap">
              <p>Send to email address</p>

              <form action="">
                <Input holder={"Email, comma seperated"} />
                <Button text={"Send"} />
              </form>
            </div>

            <div className="flexd-wrap">
              <Button icon={link} text={"Copy link"} btnType={"invert"}/>
              <Button icon={code} text={"Get embedded code"} btnType={"invert"} />
            </div>
          </ShareWhiteBox>
        </Modal>
        <Overlay activeOverlay={modalActive} onClick={() => setModalActive(false)} />

        <UserHeaderWrap>
          <div className="access-info-bar">
            <InfoBar/>
          </div>
          <nav className="inner-user-header">
              <div className="left-side">
                  <img src={logo} alt="" onClick={() => navigate("/")} />
              </div>

              <div className="right-side">
                  {/* <Button text={"Copy API endpoint"} btnType={"copy"} icon={copy} />
                  <Button text={"Share link"} btnType={"share"} icon={share} onClick={() => setLinkModal(true)} /> */}

                  <div className="toggle-wrap">
                    <img src={hamburger} alt="" onClick={() => setModalActive(true)} />
                  </div>
              </div>
          </nav>
        </UserHeaderWrap>

        <UserMobileHeader active={modalActive}>
        {showDelete && <DeleteConvo setShowDelete={setShowDelete} selectedConvo={selectedConvo} handleDeleteConversation={handleDeleteConversation} deleteConversationSuccess={deleteConversationSuccess} deleteConversationLoading={deleteConversationLoading} isLoading={isLoading} isSuccess={isSuccess}/>}
        {showEditPop && <EditConvoTitle setShowEditPop ={setShowEditPop} selectedConvo={selectedConvo} handleEditConversation={handleEditConversation} updateConversationTitleSuccess={updateConversationTitleSuccess} updateConversationTitleLoading={updateConversationTitleLoading} isLoading={isLoading} isSuccess={isSuccess} />}  
          
          <div className="inner-user-mobile-wrap">

            <div className="header-flex">
              <h3 className="text">Menu</h3>

              <img src={closeSquareBlue} alt="" onClick={() => setModalActive(false)}/>
            </div>

            <div className="nav-wrap">
              <SidebarAccordion>
                  <div className="accordion-side-header">
                      <div className="left-side">
                          Chat history
                      </div>
                  </div>

                  <SidebarAccordionList>
                      <li onClick={() => {navigate("/new-chat");
                          setModalActive(false)
                        }
                      } 
                      className={path?.includes("new-chat") ? "active" : ""}>+ New chat</li>
                      {
                          conversationList?.map((item:any, index:any) => {
                              return (
                                  <li key={index} className={item?.conversationUuid === conversationId ? "active" : ""} >
                                      <p onClick={() => handleGotoConvo(item)}>{item?.conversationName}</p>
                                      <div onClick={()=> handleEditPop(item)} className="img-wraps">
                                          <img src={edit} alt="" />
                                      </div>

                                      <div onClick={()=> handleDeletePop(item)} className="img-wraps">
                                          <img src={closeCircle} alt="" />
                                      </div>
                                  </li>
                              )
                          })
                      }
                  </SidebarAccordionList>
              </SidebarAccordion>

              <SidebarAccordion>
                  <div className="accordion-side-header">
                      <div className="left-side">
                          Created Projects
                      </div>

                      {
                        count < activePlan?.totalChatbots &&
                        <Button btnType={"copy-project"} text={"New project"} icon={cross} onClick={() => navigate("/usecase-setup")} />
                      }
                  </div>

                  <ProjectList>
                      {
                          projectList?.map((item:any, index:any) => {
                              return (
                                  <li key={index} className={projectId === item?.key ? "active" : "" } onClick={() => {handleProjectChoice(item?.key);
                                    setModalActive(false)
                                  }}>
                                      <div className="left-text">
                                          <img src={file} alt="" />
                                          <p>{item?.name}</p>
                                      </div>

                                      <div className="img-wraps">

                                          <img src={closeSquare} alt="" />
                                      </div>
                                  </li>
                              )
                          })
                      }
                  </ProjectList>
              </SidebarAccordion>

              <SiteLinkList>
                  {/* <li> <img src={trash} alt="" /> Clear conversation</li> */}
                  <li onClick={() => {navigate("/user-settings");
                    setModalActive(false)
                  }} className={path?.includes("user-settings") ? "active" : ""}> <img src={settings} alt="" /> Settings</li>
                  {/* <li> <img src={message} alt="" /> Get help</li> */}
                  <li onClick={handleLogout}> <img src={logout} alt="" /> Logout</li>
              </SiteLinkList>
            </div>
          </div>
        </UserMobileHeader>
    </>

  )
}

export default UserHeader