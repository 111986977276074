import React from 'react'
import { AddfileWrap } from './addfile.styles'
import { RootState } from '../../store'
import fileIcon from "../../assets/svg/upload-icon.svg"
import { useSelector } from 'react-redux'

const AddFile = ({}:any) => {
  const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
  return (
    <AddfileWrap colorState={backgroundMode.color}>
        <div className="inner-file-wrap">
            <img src={fileIcon} alt="" />

            <h5 className="head-text">
                Click here to upload file
            </h5>

            <p className="direction-text">
                or drag and drop it here
            </p>
        </div>
    </AddfileWrap>
  )
}

export default AddFile