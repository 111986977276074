import styled from "styled-components"

export const ChatMessageWrap = styled.div `
    height: 300px;
    width: 94%;
    display: flex;
    border-radius: inherit;
    scroll-snap-type: y proximity;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    row-gap: 1em;
    padding: 30px 20px;

    &::-webkit-scrollbar {
        width: 10px;
        background: #F3F8FF;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(17, 98, 220, 1);
        border-radius: 10px;
    }

    .empty-box {
        padding: 2px;
    }
`

export const MessageSentWrap = styled.div `
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5em;
    width: calc(50% - 2em);
    margin-left: 53%;

    .chat-text-wrap {
        border-radius: 4px;
        border: 0.5px solid var(--secondary-200, #DFCCFF);
        background-color: var(--secondary-50, #FAF6FF);
        padding: 13px;

        p {
            margin: 0;
            color: #062149;
            font-size: 0.8rem;
            font-weight: 500;
        }
    }


    @media only screen and (max-width: 650px) {
        width: calc(70% - 2em);
        margin-left: 38%;
    }

    @media only screen and (max-width: 340px) {
        width: calc(90% - 2em);
        margin-left: 21%;
    }
`

export const MessageReceivedWrap = styled(MessageSentWrap) `
    margin-left: 0;
    margin-right: 60%;
    justify-content: flex-start;

    .chat-text-wrap {
        border-radius: 4px;
        border: 0.5px solid var(--primary-100, #B0CBF3);
        background-color: var(--primary-50, #E1EEFF);
        position:relative;
        padding:10px 40px 40px 10px;

        span{
            position:absolute;
            bottom:5px;
            right:10px;
            padding:5px;
            border: 1px solid grey;
            display:flex;
            align-items:center;
            justify-content:center;
            font-size:11px;
            border-radius:3px;
            cursor:pointer;
            color:grey;
        }

        span:hover{
            background-color:#B6D4F9;
            transition: background-color 0.5s;
            color:grey;
        }

        p.typing {
            font-style: italic;
            font-weight: 300;
        }
    }
`