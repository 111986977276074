import styled from "styled-components"

export const AddfileWrap = styled.div`
    background-color:"#F2F8FF";
    transition: background-color 0.5s ease;
    border: solid 1px #B0CBF3;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;

    .inner-file-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 3em 0;

        img {

        }

        .head-text {
            color:"#1162DC";
            transition: color 0.5s ease;
            margin: 0;
            font-weight: 700;
            font-size: 1rem;
            margin-top: 15px;
        }

        .direction-text {
            color:"#062149";
            transition: color 0.5s ease;
            font-weight: 400;
            margin: 0;
            font-size: 0.6rem;
        }
    }
`