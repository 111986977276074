import React from 'react'
import { useNavigate,createSearchParams } from 'react-router-dom'


interface ConvoMigrationItem {
    conversationUuid: string;
    projectUuid: string;
    language: string;
  }


const HandleConvoMigration = (item:any,navigate:any) => {

    const params = {
        chatId: item?.conversationUuid,
        projectId: item?.projectUuid,
        language: item?.language,
      };
    
    navigate({
        pathname: "/individual-chatroom",
        search: `?${createSearchParams(params).toString()}`
    })
}

export default HandleConvoMigration