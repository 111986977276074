import { useEffect, useRef, useState } from 'react'
import { ChatQuestionBox, ChatroomFooter, ChatroomSection } from '../chatroom.styles'
import send from "../../../assets/svg/send-2.svg"
import chatHolder from "../../../assets/png/chat-holder.png"
import { ChatMessageWrap, MessageReceivedWrap, MessageSentWrap } from './chat.styles'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Spinner from '../../../spinnersvg/Spinner'
import chatResponseIcon from '../../../assets/png/clarity_language-line.png'
import dropdownIcon from '../../../assets/png/dropdown.png'
import { useStoreLanguageMutation,
         useGetChatContextQuery,
         useValidateChatMutation} from '../../../services/customerApi'

import convoIcon from '../../../assets/png/convoIcon.png'
import sendIcon from '../../../assets/png/sendColoured.png'
import arrowBlackBg from '../../../assets/png/arrowBlackBg.png'
import ContextPage from '../ContextHolder/ContextPage'
import ContextMiniPage from '../ContextHolder/ContextMiniPage'
import docIcon from '../../../assets/png/chatDocsIcon.png'
import tooltipW from '../../../assets/png/tooltipWbg.png'
import tooltipB from '../../../assets/png/tooltipBlackBg.png'
import backBlackIcon from '../../../assets/png/backBlackBg.png'
import backWhiteIcon from '../../../assets/png/backWhiteBg.png'



declare global {
    interface Window { 
        _jsenv: any;
    }
  }

const env = window._jsenv || process.env
const baseUrl = env.REACT_APP_FLASK_API_BASE_URL;

const Chat = ({chatMessages,
               setChatMessages,
               selectedLanguage,
               setSelectedLanguage,
               queryParams,
               chunks,
               setChunks,
               chatQuery,
               resetChat,
               setResetChat,
               setChatQuery, 
               handleSendMessage, 
               getMessagesLoading, 
               getMessageFetching,
               dummyQuery, 
               setDummyQuery, 
               sendMessageSuccess, 
               convoId, 
               projectId, 
               sendMessageLoading}:any) => {

    const bottomRef:any = useRef(null);
    const inputRef:any = useRef(null);
    const endRef:any = useRef(null)
    const [MssgLoop,setMssgLoop] = useState<any>(null)
    const [copied,setCopied] = useState(false)
    const textareaRef = useRef<any>(null);
    const sectionWrapperRef = useRef<any>(null)
    const [showLanguage,setShowLanguage] = useState(false)
    const [contextData,setContextData] = useState<any>('loading')
    const [initialHeight, setInitialHeight] = useState(0);
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const [showDocContext,setShowDocContext] = useState(false)
    const [storeDesiredLanguage, 
          {data: storeLangResponse, 
           isSuccess: storeLangResponseSuccess, 
           isLoading: storeLangResponseLoading, 
           isError: storeLangResponseError, 
           error: storeLangResponseErrorMessage}] = useStoreLanguageMutation()

    const [validateChat, 
          {data: storeValidateResponse, 
           isSuccess: storeValidateSuccess, 
           isLoading: ValidateLoading, 
           isError: ValidateError, 
           error: ValidateErrorMessage}] = useValidateChatMutation()


    
    
    
    let authDATAA = useSelector((state: RootState) => state.auth?.auth?.token?.access_token)

    //const token = (getState() as RootState)?.auth?.auth?.token?.access_token;
    let chunkData:any;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
  
    const languages = ['English','Yoruba', 'Igbo', 'Hausa', 'Fulah', 'Fulfulde', 'French', 'Portuguese',
    'Arabic', 'Amharic', 'Somali', 'Swahili', 'Oromo', 'Zulu', 'Shona', 'Afrikaans',
    'Xhosa', 'Kinyarwanda']

     //fetch chat context

     const {data: chatContext, 
        refetch: refetchChatContext, 
        isSuccess: chatContextSuccess,
        isLoading:chatContextLoading,
        isError:ischatContextError, 
        isFetching:chatContextFetching,
        error:chatContextError} = useGetChatContextQuery(projectId)



//https://chatbot.dev.prolific.periculum-models.link/stream_chat
//https://api.dev.prolific.periculum-models.link/chat/event-stream
//https://chatbot.dev.prolific.periculum-models.app/stream_chat
    const fetchStream = (raw:any)=>{
        setResetChat(false)
        fetch(
            `${baseUrl}/stream_chat`, 
            {
                method: "POST",
                headers: { "Content-Type": "application/json",'Authorization': `Bearer ${authDATAA}` },
                body: raw
            }).then(response => {
                // Get the readable stream from the response body
                setChunks('')
                const decoder = new TextDecoderStream();
                let stream
                if(response.body){

                    stream = response.body.pipeThrough(decoder);
                }
                // Get the reader from the stream
                let reader:any;
                if(stream){
                    reader = stream.getReader();
                }
                let chunks = '';
                
                const delay = 30;

                // Define a function to process each piece with a delay
            const processPieces = (pieces:any, index:any) => {
                if (index >= pieces.length) {
                    setResetChat(true);
                    return;
                }

                let piece = pieces[index];

                setChunks((prevChunks:any) => prevChunks + piece + ' ');
                setMssgLoop(true)

                // Schedule the next piece to be processed after the delay
                setTimeout(() => processPieces(pieces, index + 1), delay);
            };
                // Define a function to read each chunk
                const readChunk = () => {
                    // Read a chunk from the reader
                    reader.read()
                        .then(({
                            value,
                            done
                        }:any) => {
                            // Check if the stream is done
                             
                            if (done) {
                                // Log a message
                                setResetChat(true)
                                return;
                            }
                            // Convert the chunk value to a string
                            
                            let chunkString = value

                            chunkString = chunkString.replace(/\*\*([^*]+?)\*\*/g, (_:any, match:any) => {
                                return match ? `<strong>${match}</strong>` : '**';
                            });
        
                            // Replace ### with <h3>
                            chunkString = chunkString.replace(/###\s*(.*?)(\n|$)/g, '<h3>$1</h3>');
        
                            // Replace single new lines with <br/>
                            chunkString = chunkString.replace(/\n/g, '<br/>');
        
                            // Split the processed chunkString by spaces and new lines
                            const pieces = chunkString.split(/([\s\n]+)/);


                            // Start processing the pieces
                            processPieces(pieces, 0);
                            
                           
                            
          
                        })
                        .catch((error:any) => {
                            // Log the error
                            setResetChat(false);
                            console.error(error);
                        });
                };
                // Start reading the first chunk
                readChunk();
            })
            .catch(error => {
                // Log the error
                setResetChat(false);
                console.error(error);
            });
        
    }


    const createMarkup = (htmlString:any) => {
        return { __html: htmlString };
      };




    const handleKeyDown = (e:any) => {
        if(e.key === "Enter"){
            //sendStreamMessage(queryParams)
            let newParam = {...queryParams,language:selectedLanguage?selectedLanguage:'English'}
            const dat = JSON.stringify(newParam)
            setDummyQuery(chatQuery)
            setChatQuery('') 
            validateChat(newParam)
            setMssgLoop(false)
            
            //fetchStream(dat)

        }
    }

    //useEffect for context Data
    useEffect(()=>{
        if(ischatContextError){
            setContextData('error')
        }

        else{

            if(chatContextFetching){
                setContextData('loading')
            }
    
            else{
                setContextData('success')
            }
        }
        

    },[chatContextSuccess,chatContextFetching,ischatContextError])



    //auto-focus input field on every mount
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [chatMessages]); 

    useEffect(()=>{
        inputRef.current?.scrollIntoView({behavior: "smooth"})
        endRef.current?.scrollIntoView({behavior: 'smooth'})
    },[])



    //if(e.key === "Enter") handleSendMessage()
    
    useEffect(() => {
        if(dummyQuery) {
            setChatQuery("")
        }

        if (sendMessageLoading) {
            bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        }

        if(sendMessageSuccess) {
            endRef.current?.scrollIntoView({behavior: 'smooth'});
        }

        if(chunks || dummyQuery){
            endRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [dummyQuery, sendMessageLoading, sendMessageSuccess,chunks,dummyQuery])

    useEffect(() => {
        inputRef.current?.scrollIntoView({behavior: "smooth"})
        endRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [convoId,chatMessages.length, projectId])


    useEffect(()=>{
        setMssgLoop(null)
    },[chatMessages.length])

    useEffect(()=>{
        if(storeValidateSuccess){
            let rawData = JSON.stringify(storeValidateResponse)
            fetchStream(rawData)
        }

    },[storeValidateSuccess])

  const fetchStreamOnClick = (data:any)=>{
    let newParam = {...data,language:selectedLanguage}
    setDummyQuery(chatQuery)
    setChatQuery('')
    setMssgLoop(false)
    validateChat(newParam)

    //fetchStream(mainData)
  }


  const selectNewLanguage = (lang:any)=>{
    let languageStore = {language:lang,conversationUuid:convoId}
    setSelectedLanguage(lang)
    storeDesiredLanguage(languageStore)
    setShowLanguage(false)
  }

  async function copyTextToClipboard(jsonString:any) {
    try {
      if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(jsonString);
        setCopied(true)
      }
    } catch (err) {
      console.error(err);
    }
  }


  const clipped = (data:any)=>{
    const formattedText = cleanText(data);

    copyTextToClipboard(formattedText)

    if(copied){

      toast.success('Copied to clipboard.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setCopied(false)
    }
  }

  const changeInput = (e:any)=>{
    setChatQuery(e)
    const textarea = textareaRef.current;
    const sectionarea = sectionWrapperRef.current;
    const scrollHeight = textarea.scrollHeight;
    
    if (scrollHeight > sectionarea.offsetHeight && scrollHeight>textarea.clientHeight && scrollHeight<=70) {
        sectionarea.style.height = scrollHeight + 'px';
    } 

    if(scrollHeight > sectionarea.offsetHeight && scrollHeight>textarea.clientHeight && scrollHeight>70){
        sectionarea.style.height = '70px';
        sectionarea.style.overflow = 'auto';
        sectionarea.style.maxHeight = '70px'
    }
    if(scrollHeight<sectionarea.offsetHeight){
        sectionarea.style.height = scrollHeight + 'px';
    }
    else if(scrollHeight === sectionarea.offsetHeight){
        textarea.style.height = 'fit-content'
        const newHeight = textarea.scrollHeight;
        sectionarea.style.height = newHeight + 'px';
        textarea.style.height = '100%'

    }
    else{
        textarea.style.height = 'fit-content'
        const newHeight = textarea.scrollHeight;
        sectionarea.style.height = newHeight + 'px';
        textarea.style.height = '100%'
    }
  }

  const handleTextAreaLoad = () => {
    const textarea = textareaRef.current;
    setInitialHeight(textarea.offsetHeight);
    
  };

const textAreaStyling = {
    border: 'none',
    width: '100%',
    resize: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    
    color: '#062149',
    fontSize: '0.8rem',
    fontWeight: '400',
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
}

function cleanText(html:any) {
    // Create a temporary element to hold the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
  
    // Get the text content, which will strip out the HTML tags
    let text = tempElement.textContent || tempElement.innerText || '';
  
    // Revert markdown syntax to plain text
    text = text.replace(/<br\s*\/?>/g, '\n');
    text = text.replace(/\*\*(.*?)\*\*\s*\:/g, '\n\n $1\n');
    text = text.replace(/###\s*(.*?)(<br\s*\/?>|$)/g, '\n\n\n $1\n');
    text = text.replace(/##\s*(.*?)(<br\s*\/?>|$)/g, '\n\n\n $1\n');
    text = text.replace(/#\s*(.*?)(<br\s*\/?>|$)/g, '\n\n\n $1\n');
    return text;
  }



  return (
    <ChatroomSection colorState={backgroundMode.color}>
        <div className="inner-chatroom-wrap">
            <div className="chatbox-wrapper">
                <div className="chatbox-header">

                        <div className="left-side">
                            <h3 className="head">Chatroom</h3>
                            <section style={{display:'flex',columnGap:'5px',justifyContent:"center",alignItems:'center'}}>
                                <div className='dropDownHolder'>
                                    <section onClick={()=>setShowLanguage(!showLanguage)} className='selectSection'>
                                        <span className='imageHold' ><img src={chatResponseIcon}/></span>
                                        <span>{selectedLanguage?selectedLanguage:'English'}</span>
                                        <span className='imageHold' ><img src={backgroundMode.color==='white'
                                                                        ?dropdownIcon
                                                                        :arrowBlackBg}/></span>
                                    </section>

                                    <section className={showLanguage?'langArray':'langArrayHide'}>
                                        {languages.map((lang,index)=><span onClick={()=>selectNewLanguage(lang)} 
                                        className='langText' key={languages.indexOf(lang)}>{lang}</span>)}
                                    </section>
                                </div>
                                <section className='tooltipHold'>
                                    <img src={backgroundMode.color==='white'?tooltipW:tooltipB}/>

                                    <div className='tooltiptext'>
                                        <p className='headerTool'>switch Language</p>
                                        <p className='context'>
                                            Select a language below to switch interface language
                                        </p>
                                    </div>
                                </section>
                            </section>
                        </div>
                        <section onClick={()=>setShowDocContext(!showDocContext)} className='header'>
                            <span style={{display:"flex",
                                          alignItems:"center",
                                          justifyContent:"center"}}>
                                <img src={backgroundMode.color==='white'?backWhiteIcon:backBlackIcon}/>
                            </span>

                            <div  className='headerFirst'>
                                <span className='imgHold'><img src={docIcon}/></span>
                                <span className='docHold'>{showDocContext?'Chat':'Document'}</span>
                            </div>  

                        </section>
                    

                    {/* {
                        chatMessages?.length > 0 && 

                        <div className="right-side">
                            <Button 
                                text={"Regenerate response"} 
                                onClick={() => {return}}
                                btnType={"chatroom"}
                                icon={refresh}
                            />
                            <Button 
                                text={"Restart chat"}
                                onClick={() => {return}}
                                btnType={"chatroom"}
                                icon={refresh}
                            />
                        </div>
                    } */}
                </div>
                {showDocContext?
               
                <ContextMiniPage contextData={contextData}
                    chatContext={chatContext}
                    projectUuid={projectId}/>
                :
                <div className="mainchatboxbody">
                    {
                        (!dummyQuery && chatMessages?.length === 0) && 
                        <div className="center-box-wrap">
                            <img src={backgroundMode.color==='white'?chatHolder:convoIcon} alt="" />

                            <div className="text">
                                <h3>Welcome</h3>
                                <p>Ask your AI a question and watch the magic</p>
                            </div>
                        </div>
                    }

                    {
                        (dummyQuery || chatMessages?.length > 0) && 
                        <ChatMessageWrap colorState={backgroundMode.color}>
                            {getMessageFetching?<Spinner/>:
                                <>{chatMessages?.map((item:any, index:any) => {
                                    return (
                                        <div key={index} style={{display:"flex",flexDirection:"column",rowGap:'12px',margin:'20px 0px',}}>
                                            <MessageSentWrap colorState={backgroundMode.color}>
                                                <div className="chat-text-wrap">
                                                    <p>
                                                        {item?.query}
                                                    </p>
                                                </div>
                                            </MessageSentWrap>

                                            {
                                                item?.response &&
                                                <MessageReceivedWrap colorState={backgroundMode.color}>
                                                    <div className="chat-text-wrap">
                                                    <p style={{lineHeight: "1.5"}} dangerouslySetInnerHTML ={{ __html: item?.response.replace(/\n/g, '<br />')
                                                                                                            .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
                                                                                                            .replace(/###\s*(.*?)(<br\s*\/?>|$)/g, '<h3>$1</h3>$2')
                                                                                                            .replace(/##\s*(.*?)(<br\s*\/?>|$)/g, '<h2>$1</h2>$2')
                                                                                                            .replace(/#\s*(.*?)(<br\s*\/?>|$)/g, '<h1>$1</h1>$2')}}>
                                                            
                                                        </p>
                                                        <span onClick={()=>clipped(item?.response)}>copy!</span>
                                                    </div> 
                                                </MessageReceivedWrap>
                                            }

                                        </div>
                                    )
                                })
                            }

                            {(MssgLoop===true) &&
                                <>
                                    <MessageSentWrap colorState={backgroundMode.color}>
                                        <div className="chat-text-wrap">
                                            <p>
                                                {dummyQuery}
                                            </p>
                                        </div>
                                    </MessageSentWrap>

                                    <MessageReceivedWrap colorState={backgroundMode.color}>
                                        <div className="chat-text-wrap">
                                            <p dangerouslySetInnerHTML={createMarkup(chunks)}>
                                                
                                            </p>
                                         
                                        </div> 
                                    </MessageReceivedWrap>
                                </>
                            }

                            
                            {
                                (MssgLoop===false && dummyQuery) && 
                                <MessageSentWrap colorState={backgroundMode.color}>
                                    <div className="chat-text-wrap">
                                        <p>
                                            {dummyQuery}
                                        </p>
                                    </div>
                                </MessageSentWrap>
                            }

                            {
                                (MssgLoop===false && dummyQuery) &&

                                <MessageReceivedWrap ref={bottomRef} colorState={backgroundMode.color}>
                                    <div className="chat-text-wrap">
                                        <p className='typing'>
                                            Typing...
                                        </p>
                                    </div> 
                                </MessageReceivedWrap>
                            }

                            <div className="empty-box" ref={endRef}></div>

                            </>}
                        </ChatMessageWrap>

                       
                        }

                </div>}

                {
                    (convoId && !showDocContext) &&
                    <ChatQuestionBox ref={inputRef} colorState={backgroundMode.color}>
                        <section ref={sectionWrapperRef} style={{height:'30px',width:'100%'}}>
                            <textarea ref={textareaRef} 
                                      wrap='soft' 
                                      placeholder='Ask your question...' 
                                      value={chatQuery} 
                                      onChange={(e:any) => changeInput(e.target.value)} 
                                      onKeyDown={(e:any) => handleKeyDown(e)} />
                        </section>
                            
                        <div className="send-btn">
                            <img src={backgroundMode.color==='white'?send:sendIcon} alt="" onClick={()=>fetchStreamOnClick(queryParams)} />
                        </div>
                    </ChatQuestionBox>
                }
            </div>
            <ContextPage contextData={contextData}
                         chatContext={chatContext}
                         projectUuid={projectId}/>
            

           
        </div>
        <ChatroomFooter colorState={backgroundMode.color}>
                <p>{`© Copyright ${currentYear} - Prolific Technologies`}</p>
        </ChatroomFooter>

        
    </ChatroomSection>
  )
}

export default Chat



/**
 * 
 *  // Replace **text** with <strong>text</strong>
                            chunkString = chunkString.replace(/\*\*([^*]+?)\*\g, (_:any, match:any) => {
                                return match ? `<strong>${match}</strong>` : '**';
                            });

                            // Replace ### with <h3>
                            chunkString = chunkString.replace(/###\s*(.*?)(\n|$)/g, '<h3>$1</h3>');

                            // Replace single new lines with <br/> and preserve multiple new lines
                            chunkString = chunkString.replace(/\n/g, '<br/>');
                     
                           
                            setChunks((prevChunks:any) => prevChunks + chunkString);
                            setMssgLoop(true)
                            setTimeout(readChunk, delay);
 */