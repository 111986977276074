import styled from"styled-components"


export const FileUp = styled.div <{colorState?: string}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #F4F6F9;
    padding: 8px 15px;
    column-gap: 20px;

    .left-side {
        display: flex;
        align-items: center;
        column-gap: 10px;

        img {

        }

        p {
            margin: 0;
            font-size: 0.7rem;
            font-weight: 600;
        }

        p.doc-name {
            color: #737373;
        }



        &.link {
            p {
                color: #062149;
            }
        }
    }

    .cancel-wrap {
        img {
            cursor: pointer;
        }
    }
`