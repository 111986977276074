import styled, { css } from "styled-components";

export const ButtonWrap = styled.button<{ btnType?: string; tag?: string }>`
  border-radius: 6px;
  padding: 0.9rem;
  display: flex;
  align-items: center;
  column-gap: 15px;
  justify-content: center;
  background-color: #1162dc;
  color: #fff;
  font-size: 1rem;

  ${(props) =>
    props.tag === "ambassador" &&
    css`
      padding: 1.2rem 2rem 1.2rem 2rem;
      font-size: 1.6rem;
      line-height: 2.4rem;
    `}
  outline: none;
  border: none;
  cursor: pointer;

  img {
    width: 1.2rem;
    ${(props) =>
      props.tag === "ambassador" &&
      css`
        width: 2rem;
      `}
  }

  ${(props) =>
    props.btnType === "link" &&
    css`
      background-color: transparent;
      color: #062149;
      padding: 0.9em 0.5em;
      font-weight: 600;
    `};

  ${(props) =>
    props.btnType === "faqBtn" &&
    css`
      background-color: #e1eeff;
      color: #1162dc;
      padding: 0.9em 0.8em;
      font-weight: 600;
    `};

  ${(props) =>
    props.btnType === "back-btn" &&
    css`
      background-color: #f5f5f5;
      color: #062149;
      padding: 0.8em 1.3em;
      font-weight: 600;
      border-radius: 30px;
      column-gap: 10px;
    `};

  ${(props) =>
    props.btnType === "backtransparent" &&
    css`
      background-color: transparent;
      color: #062149;
      padding: 0.8em 0.4em;
      font-weight: 600;
      column-gap: 7px;
    `};

  ${(props) =>
    props.btnType === "backWhite" &&
    css`
      background-color: rgba(255, 255, 255, 0.2);
      color: #fff;
      padding: 0.8em 1.3em;
      font-weight: 600;
      border-radius: 30px;
      column-gap: 10px;

      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg)
          brightness(102%) contrast(102%);
      }
    `};

  ${(props) =>
    props.btnType === "checkout" &&
    css`
      background-color: #754abb;
      margin-top: 10px;
    `};

  ${(props) =>
    props.btnType === "cancel" &&
    css`
      background-color: #fff;
      border: solid 1px #1162dc;
      justify-content: center;
      column-gap: 5px;
      color: #1162dc;
    `};

  ${(props) =>
    props.btnType?.includes("copy") &&
    css`
      background-color: #e1eeff;
      justify-content: center;
      column-gap: 5px;
      color: #1162dc;
      font-weight: 600;
    `};

  ${(props) =>
    props.btnType?.includes("project") &&
    css`
      padding: 0.6em 0.8em;
      font-size: 0.8rem;

      img {
        width: 15px;
      }
    `};

  ${(props) =>
    props.btnType?.includes("add") &&
    css`
      border-radius: 44px;
      border: 1px solid var(--neutral-200, #e5e5e5);
      background: #f4f6f9;
      padding: 0.6em 0.7em;
      color: #1162dc;
      font-size: 0.7rem;
      justify-content: center;
      column-gap: 5px;

      img {
        width: 12px;
      }
    `};

  ${(props) =>
    props.btnType?.includes("invert") &&
    css`
      border-radius: 4px;
      background: #fafafa;
      padding: 0.6em 0.7em;
      color: #1162dc;
      font-size: 0.7rem;
      justify-content: center;
      column-gap: 5px;

      img {
        width: 12px;
      }
    `};

  ${(props) =>
    props.btnType?.includes("cta-inactive") &&
    css`
      cursor: unset;
      background-color: #b0cbf3;
    `}

   ${(props) =>
    props.btnType?.includes("log-inactive") &&
    css`
      cursor: unset;
      background-color: #b0cbf3;
    `}

  ${(props) =>
    props.btnType?.includes("free-cta") &&
    css`
      background-color: #eef2ff;
      color: #502a8d;
      font-weight: 600;

      img {
        filter: brightness(0) saturate(100%) invert(16%) sepia(49%)
          saturate(4314%) hue-rotate(255deg) brightness(82%) contrast(89%);
      }
    `}

    ${(props) =>
    props.btnType === "chatroom" &&
    css`
      background-color: transparent;
      color: #062149;
      padding: 0.9em 0.5em;
      font-weight: 600;
      border-radius: 4px;
      font-size: 0.7rem;
      border: 0.5px solid var(--neutral-200, #e5e5e5);
      background: var(--neutral-100, #f5f5f5);
      column-gap: 8px;
    `};

  ${(props) =>
    props.btnType?.includes("inline") &&
    css`
      padding: 0.7em 1.8em;
      font-size: 0.6rem;
      border-radius: 4px;
      font-weight: 400;
    `};

  ${(props) =>
    props.btnType?.includes("inline-inactive") &&
    css`
      cursor: unset;
      background-color: #b0cbf3;
      padding: 0.7em 1.8em;
      font-size: 0.6rem;
      border-radius: 4px;
      font-weight: 400;
    `}


    ${props =>
      props.btnType === "blue-back" &&
      css`
        background-color: #1162DC;
        color: white ;
        padding: 0.8em 1.3em;
        font-weight: 600;
        border-radius: 30px;
        column-gap: 10px;
      `
    };

    ${props =>
      props.btnType === "new-chat" &&
      css`
        background-color: #1162DC;
        color: white ;
        padding: 0.8em 1.3em;
        font-weight: 600;
        border-radius: 7px;
        display:flex;
        align-items:center;
        justify-content:center;
      `
    };
`;
