import styled, { css } from "styled-components";

export const FooterWrap = styled.footer<{ tag?: string }>`
  background: var(--secondary-900, #2b0963);

  .inner-footer-wrap {
    padding: 2rem 3rem;

    .footer-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
      border-bottom: solid 1px #502a8d;

      ul {
        list-style: none;
        display: flex;
        align-iitems: center;
        column-gap: 2rem;
        padding: 0;
        paadding-bottom: 3rem;

        li {
          a {
            color: #d4d4d4;
            font-size: 16px;
            font-weight: 405;
            text-decoration: none;
          }
        }
      }
    }

    .footer-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1.2rem;
      margin-top: 16px;

      p {
        font-size: 1rem;
        font-weight: 400;
        ${(props) =>
          props.tag === "ambassador" &&
          css`
            font-size: 1.2rem;
            line-height: 1.452rem;
          `}
        color: #a3a3a3;
        margin: 0;
        text-align: center;
      }

      .bottom-txt {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.5rem;
        color: #a3a3a3;

        img {
          width: 100px;
        }
      }
    }
  }
`;
