import React from 'react'
import { ContactFlier } from './contactcard.styles'
import Utils from '../../../../library/Utils'

function ContactCards({title,info,titleTop,infoTop,index}:any) {
  return (
    <ContactFlier key={index}>
        {!Utils.isFieldEmpty(titleTop) && <section style={{margin:'20px 0px'}}>
            <p style={{color:'#062149',fontWeight:"600",fontSize:"18px"}}>{titleTop}</p>
            <p style={{color:"#1162DC",fontSize:"16px",fontWeight:"400"}}>{infoTop}</p>
        </section>}

        <section style={{display:"flex",flexDirection:"column",rowGap:"10px"}}>
            <span style={{color:'#062149',fontWeight:"600",fontSize:"18px"}}>{title}</span>
            <span style={{color:"#062149",fontSize:"16px",fontWeight:"400"}}>{info}</span>
        </section>
    </ContactFlier>
  )
}

export default ContactCards