import React,{useState,useRef,useEffect} from 'react'
import { ErrorAbsolute,InputWrap } from './textinput.styles';
import { useFormContext } from "react-hook-form";
import { FormProvider, useForm } from "react-hook-form";
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import { useGetUserDataQuery } from '../../../../services/customerApi';
import Utils from '../../../../library/Utils';


const FormInput = ({styleType, setEmail, 
                    forgotPass,holder, type, 
                    img, inputerror, inputValue, 
                    fields,inputtype,setValue,
                    errors,register,disable,disableFields}: any) => {

    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update state
        const handleResize = () => {
          setWidth(window.innerWidth);
          
        };
    
        // Add event listener for resize events
        window.addEventListener('resize', handleResize);
    
    
        // Cleanup function to remove event listener
        return () => window.removeEventListener('resize', handleResize);
      }, []); 

  



   
  
  return (
    <div style={{display:"flex",flexDirection:'column',alignItems:"start",justifyContent:"center",width:width>992?'45%':'100%'}}>
        <span style={{fontWeight:"500",color:backgroundMode.color==='white'?'black':'white'}}>{fields.label}</span>
        <div style={{marginTop:'10px',width:"100%"}}>
            <InputWrap styleType={styleType} 
                       inputtype={inputtype} 
                       colorState= {backgroundMode.color}
                       error={errors[fields.register] ? true : false} >
                
                {disableFields?
                    <div className="inner-input-wrap">
                
                    <input type={type} placeholder={holder} {...register(fields.register, fields.validators)} disabled/>
            
                </div>:

                disable?

                <div className="inner-input-wrap">
                
                    <input type={type} placeholder={holder} {...register(fields.register, fields.validators)} disabled/>
            
                </div>:
                
                <div className="inner-input-wrap">
                        {
                            type === "select" ? 
                            <select>
                                <option value="">Nigeria</option>
                            </select>
                            : type === "email" && forgotPass?<input  type={type} placeholder={holder} {...register(fields.register, fields.validators)} onChange={(e:any)=>setEmail(e.target.value)} />
                            : 
                            <input type={type} placeholder={holder} {...register(fields.register, fields.validators)} />
                        }
        
                    
                </div>

                
                
                }

                {
                    errors[fields.register] && 
                    <ErrorAbsolute>
                        {/* @ts-ignore */}
                        {errors[fields?.register]?.message}
                    </ErrorAbsolute>
                }
            </InputWrap>
        </div>
    </div>
  )
}

export default FormInput