import React from 'react'
import { FeedbackOverall } from './feedbackbox.styles'

import quote from "../../assets/svg/quote-mark.svg"

const FeedbackBox = ({text, author}:any) => {
  return (
    <>
        <FeedbackOverall>
            <div className="inner-feedback-wrap">
                <img src={quote} alt="" />

                <div className="feedback-content">
                  {text}
                </div>

                <div className="user-name-wrap">
                - {author}
                </div>
            </div>
        </FeedbackOverall>
    </>
  )
}

export default FeedbackBox