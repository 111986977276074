import { useEffect, useState } from "react";
import Modal from "react-modal";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  SidebarAccordion,
  SidebarAccordionList,
  SiteLinkList,
} from "../IndividualSideBar/usersidebar.styles";
import Button from "../button";
import { Overlay } from "../header/header.styles";
import Input from "../input";
import {
  ShareWhiteBox,
  UserHeaderWrap,
  UserMobileHeader,
} from "./userheader.styles";

import { useDispatch } from "react-redux";
import closeCircle from "../../assets/svg/close-circle-2.svg";
import closeSquareBlue from "../../assets/svg/close-square-blue.svg";
import code from "../../assets/svg/code.svg";
import cancel from "../../assets/svg/dark-cancel.svg";
import edit from "../../assets/svg/edit-2.svg";
import hamburger from "../../assets/svg/hamburger.svg";
import link from "../../assets/svg/link.svg";
import logout from "../../assets/svg/logout.svg";
import logo from "../../assets/svg/prolific-logo.svg";
import logoWhiteBg from '../../assets/png/prolificLogoWhitebg.png'
import settings from "../../assets/svg/setting-2.svg";
import {
  useDeleteConversationMutation,
  useGetConversationListQuery,
  useGetCurrentPlanQuery,
  useUpdateConversationTitleMutation,
  useDeleteProjectMutation
} from "../../services/customerApi";
import { clearAuth } from "../../slices/auth";
import { RootState, useAppSelector } from "../../store";
import LightButton from "../LightButton/LightButton";
import DeleteConvo from "../deletePrompt";
import EditConvoTitle from "../editPrompt";
import ChatCreationModal from "../modals/IndividualChatCreation/ChatCreationModal";
import InfoBar from "./infoBar";
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { setBackBlack,setBackWhite } from '../../slices/colourMode'
import moonIcon from '../../assets/png/moonIcon.png'
import sunIcon from '../../assets/png/sunIcon.png'
import menuIconWhiteBg from '../../assets/png/menuIconWhiteBg.png'
import { useUpdateLightModeMutation } from "../../services/customerApi";
import UpdateChatNameModal from "../modals/UpdateChatName/UpdateChatNameModal";
import BlackBgLogOut from '../../assets/png/blackBgLogOut.png' 
import Utils from "../../library/Utils";
import settingsDark from '../../assets/png/settingDarkMode.png'
import logoutDarkMode from '../../assets/png/logoutDarkMode.png'

const IndividualUserHeader = ({
  projectList,
  handleProjectChoice,
  openHeaderModal,
  setOpenHeaderModal,
  conversationId,
  recallChats,
  setRecallChats,
  projectId
}: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = window?.location?.pathname;
  const [linkModal, setLinkModal] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [conversationList, setConversationList] = useState<any>([]);
  const [activePlan, setActivePlan] = useState<any>([]);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedConvo, setSelectedConvo] = useState<any>("");
  const [showEditPop, setShowEditPop] = useState(false);
  const [createChat, setCreateChat] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const [projectName, setProjectName] = useState("");
  const [linksUploaded, setLinksUploaded] = useState<any>([]);
  const [filesUploaded, setFilesUploaded] = useState<any>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [nameEditIsOpen, setNameEdit] = useState(false);
  const backgroundMode = useSelector((state: RootState) => state.backgroundMode)

  //update light mode mutation
  const [updateLightMode, {data: updateLightModeData, 
    isLoading: updateLightModeLoading, 
    isSuccess: updateLightModeSuccess, 
    isError: isupdateLightModeError, 
    error: deleteupdateLightMode}] = useUpdateLightModeMutation() ;

  const count = useAppSelector<any>(
    (store: RootState) => store?.auth?.auth?.count
  );

  const { data, isLoading, isSuccess, isError, error, refetch } =
    useGetConversationListQuery();

  const {
    data: currentPlanData,
    refetch: refetchCurrentPlan,
    isSuccess: currentPlanSuccess,
  } = useGetCurrentPlanQuery();

  const [
    deleteConversation,
    {
      data: deleteConversationData,
      isLoading: deleteConversationLoading,
      isSuccess: deleteConversationSuccess,
      isError: isdeleteConversationError,
      error: deleteConversationError,
    },
  ] = useDeleteConversationMutation();

  const [deleteProject, {data: deleteProjectData, 
    isLoading: deleteProjectLoading, 
    isSuccess: deleteProjectSuccess, 
    isError: isdeleteProjectError, 
    error: deleteProjectError}] = useDeleteProjectMutation() ;



  const [
    updateConversationTitle,
    {
      data: updateConversationTitleData,
      isLoading: updateConversationTitleLoading,
      isSuccess: updateConversationTitleSuccess,
      isError: isupdateConversationTitleError,
      error: updateConversationTitleError,
    },
  ] = useUpdateConversationTitleMutation();

  const customStyles = {
    content: {
      transition: "0.125s ease-in-out",
      transform: "translate(-50%, -50%)",
      height: "33%",
      width: "33%",
      top: "50%",
      left: "50%",
      padding: "0 0 0 0",
      borderRadius: "16px",
      display: "flex",
      justifyContent: "center",
      alignCenter: "center",
      border: "none",
    },
    overlay: {
      zIndex: 3000,
      position: "fixed",
      backgroundColor: "rgba(0,0,0,0.7)",
    },
  };

  const mobileStyle = {
    content: {
      transition: "0.125s ease-in-out",
      height: "100%",
      width: "100%",
      top: "0%",
      left: "0%",
      padding: "0 0 0 0",
      display: "flex",
      justifyContent: "center",
      alignCenter: "center",
      border: "none",
      backgroundColor:backgroundMode.color==='white'?'#FFFFFF': '#1B1C1C',
    },
    overlay: {
      zIndex: 3000,
      position: "fixed",
      backgroundColor: "rgba(0,0,0,0.3)",
    },
  };


  const chatNameStyles = {
    content: {
      transition: '0.125s ease-in-out',
      transform: 'scale(1)',
      height: '300px',
      width: '70%',
      top: 'calc((100% - 300px) / 2)',
      left: 'calc((100% - 70%) / 2)',
      right: 'calc((100% - 70%) / 2)',
      padding:'15px',
      backgroundColor:backgroundMode.color==='white'?'#FFFFFF': '#1B1C1C',
      overflow:'hidden',
    },
    overlay: {
      zIndex: 10000000,
      position: 'absolute',
      backgroundColor: "rgba(0,0,0,0.3)",
    }
  };

  useEffect(() => {
    if (openHeaderModal) {
      setModalActive(true);
      setIsOpen(true);
    }
  }, [openHeaderModal]);

  // USEEFFECT TO GET CONVERSATION LIST
  useEffect(() => {
    refetch();
    refetchCurrentPlan();
  }, [data, currentPlanData, triggerFetch]);

  useEffect(() => {
    if (recallChats) {
      refetch();
    }
  }, [recallChats]);

  useEffect(() => {
    if (isSuccess) {
      setConversationList(data?.conversations);
      setRecallChats(false);
    }

    if (currentPlanSuccess) {
      setActivePlan(currentPlanData);
    }
  }, [isSuccess, data, isLoading, isError, error, currentPlanSuccess]);





  useEffect(() => {
    if(Utils.isFieldEmpty(data?.conversations)){
      navigate('/individual-chat')
  }

    if (deleteProjectSuccess || updateConversationTitleSuccess) {
      setTriggerFetch(!triggerFetch);
    }
  }, [deleteProjectSuccess,data, updateConversationTitleSuccess]);

  useEffect(() => {
    setShowEditPop(false);
    setShowDelete(false);
  }, [data]);





  useEffect(() => {
    // Function to update state
    const handleResize = () => {
      setWidth(window.innerWidth);
      
    };

    // Add event listener for resize events
    window.addEventListener('resize', handleResize);


    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); 



  

  const handleGotoConvo = (item: any) => {
    setModalActive(false);
    const params: any = {
      chatId: `${item?.conversationUuid}`,
      projectId: `${item?.projectUuid}`,
      language: `${item?.language}`,
    };

    navigate({
      pathname: "/individual-chatroom",
      search: `?${createSearchParams(params).toString()}`,
    });
  };

  const handleDeleteConversation = (id: any) => {
    const params = id;

    deleteConversation(params);
  };

  const handleEditConversation = (val: any) => {
    const params = val;

    updateConversationTitle(params);
  };

  const handleDeletePop = (item: any) => {
    setSelectedConvo(item);
    setShowDelete(true);
  };

  const handleLogout = () => {
    navigate("/login");
    localStorage.removeItem("prolificLoggedInTime");
    localStorage.removeItem("prolificLastVisited");
    dispatch(clearAuth());
  };

  const handleEditPop = (item: any) => {
    setSelectedConvo(item);
    setNameEdit(true);
  };

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    //dispatch(resetDownload());
  }

  function closeChatNameEdit(){
    setNameEdit(false)
  }

  return (
    <>
      <Modal
        className={"react-modal-class"}
        isOpen={linkModal}
        style={customStyles}
        onRequestClose={() => setLinkModal(false)}
      >
        <ShareWhiteBox>
          <div className="header-section">
            <h3>Create shareable link</h3>

            <img src={cancel} alt="" onClick={() => setLinkModal(false)} />
          </div>

          <div className="form-wrap">
            <p>Send to email address</p>

            <form action="">
              <Input holder={"Email, comma seperated"} />
              <Button text={"Send"} />
            </form>
          </div>

          <div className="flexd-wrap">
            <Button icon={link} text={"Copy link"} btnType={"invert"} />
            <Button icon={code} text={"Get embedded code"} btnType={"invert"} />
          </div>
        </ShareWhiteBox>
      </Modal>
      <Overlay
        activeOverlay={modalActive}
        onClick={() => setModalActive(false)}
      />

      <UserHeaderWrap colorState={backgroundMode.color}>
        <div className="access-info-bar">
          <InfoBar />
        </div>
        <nav className="inner-user-header">
          <div className="left-side">
          
            <img src={
                backgroundMode.color==='white' 
                ?logo
                :logoWhiteBg} alt="" onClick={() => navigate("/")} />
          </div>

          <div className="right-side">
            {/* <Button text={"Copy API endpoint"} btnType={"copy"} icon={copy} />
                  <Button text={"Share link"} btnType={"share"} icon={share} onClick={() => setLinkModal(true)} /> */}

            <div className="toggle-wrap">
              <img
                src={
                  backgroundMode.color==='white'
                  ?hamburger
                  :menuIconWhiteBg
                }
                alt=""
                onClick={() => setModalActive(true)}
              />
            </div>
          </div>
        </nav>
      </UserHeaderWrap>

      <UserMobileHeader active={modalActive} colorState={backgroundMode.color}>
        {modalIsOpen ? (
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={mobileStyle}
            contentLabel="Chat Creation"
          >
            <ChatCreationModal
              projectName={projectName}
              setProjectName={setProjectName}
              linksUploaded={linksUploaded}
              setLinksUploaded={setLinksUploaded}
              filesUploaded={filesUploaded}
              setFilesUploaded={setFilesUploaded}
              closeModal={closeModal}
              setIsOpen={setIsOpen}
              setRecallChats={setRecallChats}
              setOpenHeaderModal={setOpenHeaderModal}
            />
          </Modal>
        ) : (
          <div style={{ height: "auto",
                        backgroundColor: backgroundMode.color === 'white' 
                        ? "white" 
                        : "#131415" ,
                        transition: 'all 0.5s ease',
                        position:'relative',
                      }}>
            {showDelete && (
              <DeleteConvo
                setShowDelete={setShowDelete}
                selectedConvo={selectedConvo}
                handleDeleteConversation={handleDeleteConversation}
                deleteConversationSuccess={deleteConversationSuccess}
                deleteConversationLoading={deleteConversationLoading}
                isLoading={isLoading}
                isSuccess={isSuccess}
                projectId={projectId}
                deleteProject={deleteProject}
              />
            )}
            {showEditPop && (
              <EditConvoTitle
                setShowEditPop={setShowEditPop}
                selectedConvo={selectedConvo}
                handleEditConversation={handleEditConversation}
                updateConversationTitleSuccess={updateConversationTitleSuccess}
                updateConversationTitleLoading={updateConversationTitleLoading}
                isLoading={isLoading}
                isSuccess={isSuccess}
              />
            )}

            <Modal isOpen={nameEditIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeChatNameEdit} style={chatNameStyles}
                contentLabel="Chat Edit" >

                <UpdateChatNameModal selectedConvo={selectedConvo}
                                 closeModal={closeChatNameEdit}
                                 handleEditConversation={handleEditConversation}
                                 updateConversationTitleLoading={updateConversationTitleLoading}
                                 updateConversationTitleSuccess={updateConversationTitleSuccess}
                                 isLoading={isLoading}/>
            </Modal>

            <div className="inner-user-mobile-wrap">
              <div className="header-flex">
                <div className="logoHold">
                  <img src={
                        backgroundMode.color==='white' 
                        ?logo
                        :logoWhiteBg} alt="" onClick={() => navigate("/")} />
                </div>

                <img
                  src={closeSquareBlue}
                  alt=""
                  onClick={() => setModalActive(false)}
                />
              </div>

              <div className="nav-wrap">
                <SidebarAccordion colorState={backgroundMode.color}>
                  <section className='topSection'>
                      <div className="accordion-side-header">
                        <div className="left-side">Chat history</div>
                      </div>

                      <div className="creationHold">
                        <LightButton
                          text={"New Chat"}
                          onClick={() => setIsOpen(true)}
                          bigSize={true}
                        />
                        <LightButton text={"New Folder"} bigSize={true} />
                      </div>

                      <section className="chatPartition"></section>
                  </section>

                  <SidebarAccordionList colorState={backgroundMode.color}>
                    <div className="holder">
                        {conversationList?.map((item: any, index: any) => {
                          return (
                            <li
                              key={index}
                              className={
                                item?.conversationUuid === conversationId
                                  ? "active"
                                  : ""
                              }
                              
                            >
                              <p onClick={() => handleGotoConvo(item)} >
                                {item?.conversationName}
                              </p>
                              <div
                                onClick={() => handleEditPop(item)}
                                className="img-wraps"
                              >
                                <img src={edit} alt="" />
                              </div>

                              <div
                                onClick={() => handleDeletePop(item)}
                                className="img-wraps"
                              >
                                <img src={closeCircle} alt="" />
                              </div>
                            </li>
                          );
                        })}
                    </div>
                  </SidebarAccordionList>
                </SidebarAccordion>

                <SiteLinkList colorState={backgroundMode.color}>
                  {/* <li> <img src={trash} alt="" /> Clear conversation</li> */}
                  <div className='lightHold'>
                    <span onClick={()=>{dispatch(setBackBlack());updateLightMode({"screenMode":"DARK"})}} 
                        style={{
                                backgroundColor:backgroundMode.color==='black'? "white":'',
                                borderRadius:"50%",
                                cursor:"pointer",
                                padding:"5px"}}>
                        <img src={moonIcon}/>
                    </span>
                    <span onClick={()=>{dispatch(setBackWhite());updateLightMode({"screenMode":"LIGHT"})}} 
                        style={{
                                borderRadius:"50%",
                                cursor:"pointer",
                                padding:"5px",
                                backgroundColor:backgroundMode.color==='white'? "white":'',}}>
                        <img src={sunIcon}/>
                    </span>
                </div>

                <li
                    onClick={() => {
                      navigate("/individual-settings");
                      setModalActive(false);
                    }}
                    className={path?.includes("user-settings") ? "active" : ""}
                  >
                    {" "}
                    <img src={backgroundMode.color==='white'?settings
                              :settingsDark
                            } alt="" /> Settings
                </li>
                  {/* <li> <img src={message} alt="" /> Get help</li> */}
                  <li onClick={handleLogout}>
                    {" "}
                    <img src={backgroundMode.color==='white'?backgroundMode.color==='white'?logout
                              :logoutDarkMode
                              :BlackBgLogOut} alt="" /> Logout
                  </li>
                </SiteLinkList>
              </div>
            </div>
          </div>
        )}
      </UserMobileHeader>
    </>
  );
};

export default IndividualUserHeader;

/**
 * 
 * <SidebarAccordion>
                  <div className="accordion-side-header">
                      <div className="left-side">
                          Created Projects
                      </div>

                      {
                        count < activePlan?.totalChatbots &&
                        <Button btnType={"copy-project"} text={"New project"} icon={cross} onClick={() => navigate("/usecase-setup")} />
                      }
                  </div>

                  <ProjectList>
                      {
                          projectList?.map((item:any, index:any) => {
                              return (
                                  <li key={index} className={projectId === item?.key ? "active" : "" } onClick={() => {handleProjectChoice(item?.key);
                                    setModalActive(false)
                                  }}>
                                      <div className="left-text">
                                          <img src={file} alt="" />
                                          <p>{item?.name}</p>
                                      </div>

                                      <div className="img-wraps">

                                          <img src={closeSquare} alt="" />
                                      </div>
                                  </li>
                              )
                          })
                      }
                  </ProjectList>
              </SidebarAccordion>

 */

/**
 *
 */
