import styled from "styled-components"

export const PageBanner = styled.section `
    min-height: 100vh;
    width: 100%;
    background: rgb(203,71,197);
    background: linear-gradient(326deg, rgba(203,71,197,1) 1%, rgba(56,131,237,1) 11%, rgba(15,45,42,1) 59%);
    backdrop-filter: blur(10px);


    .inner-section {
        padding: 3em 5em;

        .header-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                cursor: pointer;
            }
        }

        .body-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 40px;

            .big-text-wrap {
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 30px;
                color: #fff;
                font-weight: 700;
                font-size: 2.5em;
            }

            .plans-wrap {
                display: grid;
                grid-template-columns: auto auto auto auto;
                align-content: center;
                margin-top: 2em;
                width: 100%;
                // grid-template-rows: 200px;
                column-gap: 40px;
            
                @media only screen and (max-width: 1002px) {
                    justify-content: center;
                    grid-template-columns: 280px 280px;
                    row-gap: 30px;
                }
            
                @media only screen and (max-width: 679px) {
                    grid-template-columns: 1fr 1fr;
                }
            
                @media only screen and (max-width: 549px) {
                    grid-template-columns: 280px;
                }
            }
        }
    }
`