import styled from "styled-components"

export const AvatarTabSubwrap = styled.div `
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    justify-content: flex-start;
    margin-bottom: 30px;

    p.header {
        font-size: 0.8rem;
        color: #062149;
        margin: 0;
        font-weight: 600;

        span {
            font-weight: 300;
        }
    }

    .flex-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 15px;

        & .gShxHa  {
            flex: 0.2;
        }
    }

    textarea {
        border-radius: 4px;
        border: solid 1px #A3A3A3;
        padding: 1em;

        &::placeholder {
            font-size: 14px;
            font-weight: 300;
        }
    }
`