import React from 'react'
import { LoginCard, SignUpCard } from './authcard.styles'
import Input from '../input'
import Button from '../button'
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";

import google from "../../assets/svg/google.svg"
import mail from "../../assets/svg/mail-icon.svg"
import eyeSlash from "../../assets/svg/eye-slash.svg"
import { useNavigate } from 'react-router-dom'
import { ErrorAbsolute, InputWrap } from '../input/input.styles'
import HookInput from '../hookInput';

const AuthCard = ({authType, onClick}:any) => {
    const navigate = useNavigate()
    const methods = useForm({
        mode: "onBlur"
    });
    const watchFields = methods.watch(["password"]);

    // const onSubmit = (formData) => {
    //     if(isDomainValid) {
    //       props.parentCallback(formData);
    //       methods.reset();
    //     } else {
    //       toast.info("Please check domain availability before submitting the form", {
    //         position: "bottom-right",
    //         autoClose: 5000,
    //         hideProgressBar: true,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "dark",
    //       });
    //     }
    // }

  return (
    <>
        {
            authType === "signup" ? 
            <SignUpCard>
                <div className="inner-authcard-wrap">
                    <div className="form-head-wrap">
                        <h3>Sign up for free.</h3>

                        <p>Create your personal account and start exploring Prolific.</p>
                    </div>

                    <FormProvider {...methods}>
                        <form action="" onSubmit={onClick}>
                            {/* FIRSTNAME AND LASTNAME */}
                            <div className="form-row">
                                <HookInput fields={{ label: "First Name", register: "firstName", validators: { 
                                required: "First name is required" } }} 
                                styleType={"normal-flex"} type={"text"} holder={"Enter your first name"}
                                />

                                <HookInput fields={{ label: "Last Name", register: "lastName", validators: { 
                                required: "Last name is required" } }} 
                                styleType={"normal-flex"} type={"text"} holder={"Enter your last name"}
                                />
                            </div>

                            <HookInput fields={{ label: "Work email", register: "email", validators: { 
                            required: "Email is required",
                            pattern: { value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Invalid email" }} }}
                            type={'email'} holder={'Enter email address'} img={mail} styleType={"normal-flex"} />

                            <HookInput  fields={{ label: "Password", register: "password",validators: { 
                            required: "Password is required", minLength: { value: 8, message: "Password must be at least 8 characters" }} }}
                            type={'password'} holder={'Create password'} img={eyeSlash} styleType={"normal-flex"} />

                            <HookInput fields={{ label: "Confirm Password", register: "cPassword", validators: { 
                                required: "Please confirm your password", minLength: { value: 8, message: "Password must be at least 8 characters" },
                                validate: (value:any) => value === watchFields[0] || "The passwords do not match" 
                            } }}
                            type={'password'} holder={'Confirm password'} img={eyeSlash} styleType={"normal-flex"} />

                            <Button text={'Create account'} />
                        </form>
                    </FormProvider>

                    <div className="form-extras">
                        <div className="login-redirect">
                            Already have an account? <span> Log in </span>
                        </div>

                        <div className="or-box">
                            <div className="horizontal-line"></div>
                            <p>OR</p>
                            <div className='horizontal-line'></div>
                        </div>

                        <div className="continue-with-google">
                            <img src={google} alt="" />

                            <p>Continue with Google</p>
                        </div>
                    </div>
                </div>
            </SignUpCard> :

            <LoginCard>
                <div className="inner-authcard-wrap">
                    <div className="form-head-wrap">
                        <h3>Welcome back!</h3>

                        <p className='login-subtext'>Kindly log in to your account</p>
                    </div>

                    <form action="">
                        <Input type={'email'} holder={'Enter email address'} img={mail} styleType={"normal-flex"} />

                        <Input type={'password'} holder={'Enter password'} img={eyeSlash} styleType={"normal-flex"} />

                        <Button text={'Log in'} onClick={() => navigate("/usecase-setup")} />
                    </form>

                    <div className="form-extras">
                        <div className="login-redirect">
                            Already have an account? <span> Sign up </span>
                        </div>

                        <div className="or-box">
                            <div className="horizontal-line"></div>
                            <p>OR</p>
                            <div className='horizontal-line'></div>
                        </div>

                        <div className="continue-with-google">
                            <img src={google} alt="" />

                            <p>Continue with Google</p>
                        </div>
                    </div>
                </div>
            </LoginCard>
        }
    </>
  )
}

export default AuthCard