import styled from "styled-components"
export const SettingsMobileContent = styled.div<{colorState?: string}>`
    width: calc(100% );
    height: calc(100vh-80px);
    position: relative;
    background-color:${(props:any) => props?.colorState === 'white' ? "#F3F8FF" : "black"} ;
    transition: background-color 0.5s ease;
    display:flex;
    justify-content:space-between;
    flex-direction:column;
    align-items:top;
    margin-top:80px;
    padding-top:20px;
    flex-direction:column;

    .containerOne{
        height:45%;
        width:20%;
        padding:5px 10px;
        background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#1B1C1C"} ;
        box-sizing:border-box;

        .optionHold{
            
        }
    }

    .containerTwo{
        height:70%;
        width:70%;
    }

    `


    export const CancelButton = styled.section<{colorState?: string}>`
        display:flex;
        cursor:pointer;
        align-items:center;
        justify-content:center;
        background-color:transparent;
        border: ${(props:any) => props?.colorState === 'white' ? "1px solid #1162DC" : "1px solid #DEDEDE"};
        color: ${(props:any) => props?.colorState === 'white' ? "#1162DC" : "#DEDEDE"};
        width:120px;
        padding:15px 0px;
        cursor:pointer;
        border-radius:10px;
    `

    export const UpdateButton = styled.section<{colorState?: string,editUserLoading?:boolean}>`
        display:flex;
        cursor:pointer;
        align-items:center;
        justify-content:center;
        background-color:${(props:any) => props?.editUserLoading ? "#639aee" : "#1162DC"};
        color: white;
        width:120px;
        padding:15px 0px;
        cursor:pointer;
        border-radius:10px
    `