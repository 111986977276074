import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router";
import { Notification } from "../../../components/Notification";
import Button from "../../../components/button";
import HookInput from "../../../components/hookInput";
import PhoneNumber, {
  CountrySelectInput,
} from "../../../components/phoneNumber";

function IndividualSignUp({
  methods,
  onSubmit,
  setPasswordShow,
  passwordShow,
  eyeSlash,
  watchFields,
  eye,
  mail,
  setSelectedCountry,
  selectedCountry,
  userDigit,
  setUserDigit,
  isSuccess,
  isLoading,
  country,
  setCountry,
  confirmPasswordShow,
  setCPasswordShow
}: any) {
  const navigate = useNavigate();
  return (
    <div >
      <div className="form-head-wrap">
        <h3>Sign up for free.</h3>

        <p>Create your personal account and start exploring Prolific.</p>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* FIRSTNAME AND LASTNAME */}
          <div className="form-row">
            <HookInput
              fields={{
                label: "First Name",
                register: "firstName",
                validators: {
                  required: "First name is required",
                },
              }}
              styleType={"normal-flex"}
              type={"text"}
              holder={"First name"}
            />

            <HookInput
              fields={{
                label: "Last Name",
                register: "lastName",
                validators: {
                  required: "Last name is required",
                },
              }}
              styleType={"normal-flex"}
              type={"text"}
              holder={"Last name"}
            />
          </div>

          <div className="form-row">
            <CountrySelectInput
              country={selectedCountry}
              setCountry={setSelectedCountry}
              styleType={"normal-flex"}
              showLabel={true}
            />
            <PhoneNumber
              // country={country}
              // setCountry={selectedCountry}
              selectedCountry={selectedCountry}
              fields={{
                label: "Phone Number",
                register: "phone",
                validators: { required: "Phone number is required" },
              }}
              setSelectedCountry={setSelectedCountry}
              styleType={"normal-flex"}
              setUserDigit={setUserDigit}
            />

            {/* <InputWrap styleType={""} inputtype={"unset"} error={false}>
              <div className="inner-input-wrap">
                <input
                  placeholder="COUNTRY"
                  autoComplete="off"
                  type="TEXT"
                  value={selectedCountry}
                />
              </div>
            </InputWrap> */}
          </div>

          <HookInput
            fields={{
              label: "Work email",
              register: "email",
              validators: {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$$/,
                  message: "Invalid email",
                },
              },
            }}
            type={"email"}
            holder={"Email"}
            img={mail}
            styleType={"normal-flex"}
          />

          <HookInput
            fields={{
              label: "Password",
              register: "password",
              validators: {
                required: "Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
              },
            }}
            type={passwordShow}
            holder={"Create password"}
            img={passwordShow === "password" ? eyeSlash : eye}
            styleType={"normal-flex"}
            passwordShow={passwordShow}
            setPasswordShow={setPasswordShow}
            inputtype={"password"}
          />

          <HookInput
            fields={{
              label: "Confirm Password",
              register: "cPassword",
              validators: {
                required: "Please confirm your password",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters",
                },
                validate: (value: any) =>
                  value === watchFields[0] || "The passwords do not match",
              },
            }}
            
            holder={"Confirm password"}
            img={confirmPasswordShow === "password" ? eyeSlash : eye}
            styleType={"normal-flex"}
            passwordShow={confirmPasswordShow}
            setPasswordShow={setCPasswordShow}
            inputtype={"password"}
          />

          {isLoading?
          <Button text={"Creating account..."} btnType={"log-inactive"} />
          :<Button text={"Create account"} type={"submit"} />}
        </form>
      </FormProvider>

      {isSuccess && <Notification message="Account created successfully." />}

      <div className="form-extras">
        <div className="login-redirect">
          Already have an account?{" "}
          <span onClick={() => navigate("/login")}> Log in </span>
        </div>

        {/* <div className="or-box">
                        <div className="horizontal-line"></div>
                        <p>OR</p>
                        <div className='horizontal-line'></div>
                    </div>

                    <div className="continue-with-google">
                        <img src={google} alt="" />

                        <p>Continue with Google</p>
                    </div> */}
      </div>
    </div>
  );
}

export default IndividualSignUp;
