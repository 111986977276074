import styled, {css} from "styled-components"

export const CardWrap = styled.div <{cardType: string}> `
    border: solid 1px red;
    background-color: #FAFCFF;
    border: solid 1px #E1EEFF;

    .inner-card-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
        align-items: flex-start;
        padding: 15px 20px;

        img {
            width: 25px;
        }

        h4 {
            font-size: 1rem;
            color: #0B4193;
            margin: 0;
            font-weight: 600;
        }

        p {
            font-size: 0.8rem;
            color: #062149;
            font-weight: 400;
        }
    }

    ${props => props.cardType === "useCase" && css`
        background-color: #fff;
        border: solid 1px #E5E5E5;
        cursor: pointer;

        .inner-card-wrapper {
            h4 {
                color: #062149;
            }

            p {
                color: #062149;
            }
        }
    `}

    // ${props => props.cardType === "useCase-active" && css`
    //     background-color: #fff;
    //     border: solid 1px #E5E5E5;
    //     cursor: pointer;

    //     .inner-card-wrapper {
    //         h4 {
    //             color: #062149;
    //         }

    //         p {
    //             color: #062149;
    //         }
    //     }
    // `}
`