type NotificationProps = {
  message: string;
  success?: boolean;
};

export const Notification = ({
  message,
  success = true,
}: NotificationProps) => {
  return (
    <div
      style={{
        padding: "10px",
        width: "100%",
        border: `1.5px solid  ${success ? "#2EB886" : "red"} `,
        textAlign: "center",
        color: success ? "#2EB886" : "red",
        fontSize: "16px",
        borderRadius: "5px",
        marginTop: "10px",
      }}
    >
      {message}
    </div>
  );
};
