import React from 'react'
import addIcon from '../../assets/png/newAdd.png'
import { ButtonHold } from './lightbutton.styles'
import { RootState } from '../../store'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import addIconBlackBg from '../../assets/png/addIconBlackBg.png'

function LightButton({text,onClick,bigSize}:any) {
  const backgroundMode = useSelector((state: RootState) => state.backgroundMode)

  return (
    <ButtonHold onClick={onClick} bigSize={bigSize} colorState={backgroundMode.color}>
        <span>
            <img src={backgroundMode.color==='white'
            ? addIcon
            :addIconBlackBg}/>
        </span>
        <span>
            {text}
        </span>
    </ButtonHold>
  )
}

export default LightButton