import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import config from './config';
import Keycloak from 'keycloak-js';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist'
import store from './store'
import { BrowserRouter as Router } from 'react-router-dom';


// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
 let persistor = persistStore(store)


  // root.render(
  //   <React.StrictMode>
  //       <Provider store={store}>
  //         <PersistGate persistor={persistor} >
  //           <Router>
  //             <App />
  //           </Router>
  //         </PersistGate>
  //       </Provider>
  //   </React.StrictMode>
  // );

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor} >
          <Router>
            <App />
          </Router>
        </PersistGate>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
  );