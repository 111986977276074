import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { AuthWrap } from "../Auth/auth.styles";

import { DateTime } from "luxon";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoginCard } from "../../components/authCard/authcard.styles";
import Button from "../../components/button";
import FeedbackBox from "../../components/feedbackBox";
import HookInput from "../../components/hookInput";
import { useLoginMutation } from "../../services/onboardingApi";
import { setAuth } from "../../slices/auth";
import Spinner from "../../spinnersvg/Spinner";
import { setBackBlack,setBackWhite } from "../../slices/colourMode";
import store, { RootState, useAppSelector } from '../../store';
import Utils from "../../library/Utils";
import { clearAuth } from "../../slices/auth";
import PublicLayout from "../../components/publicLayout";
const env = window._jsenv || process.env
const imageBaseUrl = env.REACT_APP_GCP_BASE_URL;

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //set a re-direct to last visited
    const expires_in = useAppSelector<any>((store:RootState) => store?.auth?.auth?.token?.expires_in)

    useEffect(()=>{
      if(expires_in){
        let loggedInDate = localStorage.getItem('prolificLoggedInTime');
        let expiresDate = DateTime.fromISO(loggedInDate).plus({ seconds: expires_in });
        let currentDateTime = DateTime.now();
        if(Utils.isTokenExpired(expiresDate.toMillis(), currentDateTime.toMillis())) {
            dispatch(clearAuth())
            localStorage.removeItem('prolificLoggedInTime');
            localStorage.removeItem("prolificLastVisited");
        }
        else{
          const lastVisit = localStorage.getItem("prolificLastVisited")
          navigate(`${lastVisit}`)
        }
      }
    },[expires_in])

    
    //image base Url
    

    const eyeSlash = `${imageBaseUrl}assets/svg/eye-slash.svg`
    const eye = `${imageBaseUrl}assets/svg/eye.svg`
    const mail = `${imageBaseUrl}assets/svg/mail-icon.svg`
    const logo = `${imageBaseUrl}assets/svg/logo-white.svg`


    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    const methods = useForm({
      mode: "onBlur",
    });

    const [passwordShow, setPasswordShow] = useState("password");
    //localStorage.setItem("prolificLastVisited", `/login`);

    // const keycloakContext:any = React.useContext(KeycloakContext);

    // const access_token = useAppSelector<any>((store:RootState) => store?.token.token)
    // const user = useAppSelector<any>((store:RootState) => store?.auth?.auth)

    const [login, { data, isSuccess, isLoading, isError, error }] =
      useLoginMutation();



    // USEFFECT FOR LOGIN
    useEffect(() => {
      if (isSuccess && !data.isEmailVerified) {
        navigate("/verify", { state: { data: data?.email } });
      }

      if (isSuccess && data.isEmailVerified) {
        dispatch(setAuth(data));
        if(data.screenMode === 'DARK'){
          dispatch(setBackBlack())
        }
        else{
          dispatch(setBackWhite())
        }
        
        localStorage.setItem("prolificLoggedInTime", DateTime.now());
        if (data.userType.toLowerCase() === "individual") {
          navigate("/individual-chat");
        } else {
          if (data?.count > 0) {
            navigate("/new-chat");
          } else {
            navigate("/usecase-setup");
          }
        }
      }

      if (isError && "status" in error!) {
        notifyError(error?.data?.message);
      }
    }, [isSuccess, isError, error, data]);

  // const {data: getUserData, isLoading: getUserLoading, isSuccess: getUserSuccess, isError: getUserIsError, error: getUserError, refetch} = useGetUserQuery()

  // useEffect(() => {
  //     if(keycloakContext.token !== null && keycloakContext.token !== undefined) {
  //         dispatch(setToken(keycloakContext.token))

  //         if(access_token && !getUserData?.data) {
  //             refetch()
  //         }

  //         if(getUserSuccess && Utils?.isFieldEmpty(user)) {
  //             dispatch(setAuth(getUserData))
  //         }
  //     }
  // }, [keycloakContext, getUserSuccess, access_token, getUserData, user])

  const notifyError = (text: string) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const onSubmit = (formData: any) => {
    login(formData);
  };

  return (
    <>
      <ToastContainer />
      <AuthWrap
      // style={{
      //   backgroundImage: `url(${St})`,
      //   backgroundRepeat: " no-repeat",
      //   backgroundSize: "cover",
      //   backgroundColor: "rgb(203, 71, 197)",
      //   backgroundPosition: "center",
      //   position: "relative",
      // }}
      >
        <PublicLayout>
          <div className="inner-auth-wrap">
            <div className="auth-header">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className="auth-flex-wrap">
              <div className="left-auth-subwrap">
                <div className="btn-wrap">
                  <Button
                    btnType={"backWhite"}
                    text={"Go home"}
                    onClick={() => navigate("/")}
                  />
                </div>

                <div className="auth-text-wrap">
                  <div className="large-text">
                    <h5>
                      Get started with <span> Prolific.</span>
                    </h5>

                    <p>
                      Prolific creates conversational AI chatbots with your data
                      to communicate naturally.
                    </p>
                  </div>
                </div>

                <div className="swiper-wrap">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    direction="horizontal"
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Our customer service has taken a giant leap with this chatbot. It streamlines processes, resolves issues promptly, and even assists in driving sales through personalized recommendations. A game-changer for our business!"
                        }
                        author={"Alex Brabra"}
                      />
                    </SwiperSlide>

                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Uploading my business’ data was easy and extremely fast, Prolific has helped in pleasantly engaging customers, and contributed to some sales. A practical asset for my business"
                        }
                        author={"Chris Dicaprio"}
                      />
                    </SwiperSlide>

                    {/* <SwiperSlide>
                                <FeedbackBox />
                            </SwiperSlide>

                            <SwiperSlide>
                                <FeedbackBox />
                            </SwiperSlide> */}
                  </Swiper>
                </div>

                <div className="auth-footer">
                  <p>{`© Copyright ${currentYear} - Prolific Technologies || All
                    Rights Reserved`}
                  </p>
                </div>
              </div>

              <div className="right-auth-subwrap">
                <LoginCard>
                  <div className="inner-authcard-wrap">
                    <div className="form-head-wrap">
                      <h3>Welcome back!</h3>

                      <p className="login-subtext">
                        Kindly log in to your account
                      </p>
                    </div>

                    <FormProvider {...methods}>
                      <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <HookInput
                          fields={{
                            label: "Work email",
                            register: "email",
                            validators: {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$$/,
                                message: "Invalid email",
                              },
                            },
                          }}
                          type={"email"}
                          holder={"Email"}
                          img={mail}
                          styleType={"normal-flex"}
                        />

                        <HookInput
                          fields={{
                            label: "Password",
                            register: "password",
                            validators: {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters",
                              },
                            },
                          }}
                          type={passwordShow}
                          holder={"Password"}
                          img={passwordShow === "password" ? eyeSlash : eye}
                          styleType={"normal-flex"}
                          passwordShow={passwordShow}
                          setPasswordShow={setPasswordShow}
                          inputtype={"password"}
                        />

                      {isLoading?
                      <Button text={"Logging in..."} btnType={'log-inactive'} />
                      :<Button text={"Log in"} type="submit" />}  
                      </form>
                    </FormProvider>

                    <div
                      onClick={() => navigate("/password-forgot")}
                      style={{
                        width: "100%",
                        boxSizing: "border-box",
                        textAlign: "left",
                        margin: "15px auto",
                        color: "red",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                    >
                      Forgot Password?
                    </div>
                    <div className="form-extras">
                      <div className="login-redirect">
                        Don't have an account?{" "}
                        <span onClick={() => navigate("/signup")}>
                          {" "}
                          Sign up{" "}
                        </span>
                      </div>

                      {/* <div className="or-box">
                                        <div className="horizontal-line"></div>
                                        <p>OR</p>
                                        <div className='horizontal-line'></div>
                                    </div>

                                    <div className="continue-with-google">
                                        <img src={google} alt="" />

                                        <p>Continue with Google</p>
                                    </div> */}
                    </div>
                  </div>
                </LoginCard>
              </div>
            </div>
          </div>
        </PublicLayout>
        {/* <figure
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            padding: 0,
            margin: 0,
            zIndex: 1,
          }}
        >
          <img
            src={St1}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="si"
          />
        </figure> */}
      </AuthWrap>
    </>
  );
};

export default Login;
