import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { Action, combineReducers } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist"
import thunkMiddleware from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { onboardingApi } from './services/onboardingApi';
import authReducer from './slices/auth';
import colorModeReducer from './slices/colourMode';
import identityStroreReducer from './slices/identityStore'
import tokenReducer from './slices/keyToken';
import { customerApi } from './services/customerApi';
import { useCaseSetupApi } from './services/useCaseSetupApi';

const reducers = combineReducers({
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [useCaseSetupApi.reducerPath]: useCaseSetupApi.reducer,

    auth: authReducer,
    token: tokenReducer,
    backgroundMode:colorModeReducer,
    dataRecords:identityStroreReducer
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [

    ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    // @ts-ignore
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
              },
        }).concat(
            thunkMiddleware,
            customerApi.middleware,
            onboardingApi.middleware,
            useCaseSetupApi.middleware,
        ),
    // getDefaultMiddleware().concat(authApi.middleware) 
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector:  TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
setupListeners(store.dispatch);

export default store;