import styled from "styled-components";

export const FormHolder = styled.div`
  display: flex;
  row-gap: 3px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  input {
    width: 24px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin: 3px;
  }

  @media only screen and (max-width: 480px) {
    input {
      width: 17px;
      padding: 5px;
      text-align: center;
      border-radius: 3px;
      margin: 1.5px;
    }
  }
`;

export const ForgotSection = styled.div`
  height: 80vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items:center;

 

  .contentHolder {
    width: 80%;
    height: 317px;
    background-color: white;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border:1px solid black;
  }

  @media only screen and (max-width: 800px) {
    .contentHolder {
      width: 100%;
      height: 350px;

    }

  @media only screen and (max-width: 450px) {
    .contentHolder {
      width: 100%;
      height: 350px;

      background-color: white;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const InnerRightAuthSubWrap = styled.div`
  height: 60%;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0px auto;

  section {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    color: #062149;
  }

  .upperP {
    font-size: 15px;
    width: 75%;
    margin: 10px auto;
    text-align: center;
    color: #062149;
    font-weight: 400;
  }

  .lowerP {
    font-size: 12px;
    width: 50%;
    margin: 10px auto;
    text-align: center;
    color: #062149;
    font-weight: 400;
  }

  @media only screen and (max-width: 390px) {
    section {
      font-size: 24px;
      text-align: center;
      font-weight: 700;
      color: #062149;
    }

    .upperP {
      font-size: 13px;
      width: 75%;
      margin: 10px auto;
      text-align: center;
      color: #062149;
      font-weight: 400;
    }

    .lowerP {
      font-size: 11px;
      width: 50%;
      margin: 10px auto;
      text-align: center;
      color: #062149;
      font-weight: 400;
    }
  }
`;
