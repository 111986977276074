import React, { useEffect, useState } from 'react'
import { TabContentWrap } from '../usersettings.styled'
import { ModifyAvatarOverall } from './usecasesettings.styles'

import support from "../../../assets/svg/support.svg"
import virtual from "../../../assets/svg/virtual.svg"
import assessment from "../../../assets/svg/assesment.svg"
import general from "../../../assets/svg/general.svg"
import cancel from "../../../assets/svg/cancel-round.svg"
import Card from '../../../components/card'
import Input from '../../../components/input'
import { useGetUseCasesQuery } from '../../../services/useCaseSetupApi'

const UsecaseSettings = ({chosenProject, usecaseSelected, setUsecaseSelected, companyName, customerNum, setCompanyName, setCustomerNum}: any) => {
    const [useCaseList, setUseCaseList] = useState([])

    const {data, isLoading, isSuccess, refetch, isError} = useGetUseCasesQuery()

    useEffect(() => {
        refetch()
    }, [data])

    useEffect(() => {
        if(isSuccess) {
            setUseCaseList(data)
        }
    }, [data, isLoading, isSuccess])


  return (
    <TabContentWrap>
        <h4>Edit use case settings.</h4>

        <ModifyAvatarOverall>
            <p className='head-text'>Modify Avatar</p>

            <div className="usecase-wrapper">
                {useCaseList?.map((item:any, id:number) => {
                    return (
                        <Card 
                            key={id}
                            icon={item.icon}
                            title={item.name}
                            content={item.description}
                            cardType={(usecaseSelected === item?.name || chosenProject?.usecaseName?.includes(item?.name)) ? 'useCase-active' : 'useCase'}
                            onCardClicked={() => {setUsecaseSelected(item?.name)}}
                        />
                    )
                })}
            </div>

            {
                usecaseSelected?.includes("Customer Support") &&

                <div className="customer-extra-info">
                    <Input holder={"Company name"} value={companyName} handleInputChange={setCompanyName} />
                    <Input holder={"Customer contact"} value={customerNum} handleInputChange={setCustomerNum} />
                </div>
            }

        </ModifyAvatarOverall>
    </TabContentWrap>
  )
}

export default UsecaseSettings