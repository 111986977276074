import {createSlice} from '@reduxjs/toolkit';

interface BackgroundState {
    color: string;
  }

const initialState:BackgroundState = {
  color: "white" ,
};


const backgroundSlice = createSlice({
  name: 'backgroundMode',
  initialState,
  reducers: {
    setBackBlack: (state) => {
      state.color = 'black'},
    setBackWhite: (state) => {
        state.color =  'white'
      }
  },
});

const {reducer, actions} = backgroundSlice;
export const {
    setBackBlack,
    setBackWhite,
} = actions;
export default reducer;