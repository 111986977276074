import styled from "styled-components";

export const SignUpCard = styled.div<{ navigationState?: string }>`
  background-color: #fff;
  height: 400px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  width: 100%;

  

  .inner-authcard-wrap {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 1em 4em;
    width: 100%;

    .stepper-container {
      margin-bottom: 1rem;

      div {
        cursor: pointer;
      }

      a {
        cursor: pointer;
      }
    }

    .user-selector {
      width: 80%;
      display: flex;
      margin: 0px auto;
      height: 50px;

      .unselected {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border: 0.1px solid rgba(17, 98, 220, 1);
        font-size: 400;
        color: rgba(14, 1, 29, 1);
        cursor: pointer;
      }

      .selected {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border: 0.1px solid rgba(17, 98, 220, 1);
        font-size: 400;
        color: rgba(14, 1, 29, 1);
        background-color: rgba(225, 238, 255, 1);
        cursor: pointer;
      }
    }

    .naviagtion-option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      color: rgba(255, 255, 255, 1);
      font-size: 17px;
      background-color: rgba(17, 98, 220, 1);
      margin: 0px auto;
      height: 50px;
      margin-top: 20px;
      border-radius: 8px;
      cursor: pointer;
    }
    .form-head-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      gap: 20px;

      h3 {
        color: #062149;
        font-weight: 700;
        font-size: 23.27px;
        line-height: 29.92px;
        text-align: center;
        margin: 0;

        @media only screen and (max-width: 768px) {
          font-size: 1.5rem;
        }
      }

      p {
        color: #062149;
        font-size: 13.3px;
        line-height: 19.95px;
        font-weight: 400;
        text-align: center;
        margin: 0;
        // width: 80%;
      }

      span {
        color: #062149;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin: 0;
        width: 100%;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      .form-row {
        display: flex;
        column-gap: 15px;
      }
    }

    .form-extras {
      margin-top: 30px;

      .login-redirect {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        color: #062149;
        column-gap: 6px;

        span {
          color: #1162dc;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .or-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
        padding: 0 50px;
        column-gap: 20px;

        .horizontal-line {
          border: solid 1px #d4d4d4;
          width: 100%;
        }

        p {
          font-size: 1rem;
          font-weight: 500;
          margin: 0;
          color: #737373;
        }
      }

      .continue-with-google {
        cursor: pointer;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #d4d4d4;
        border-radius: 4px;
        padding: 1em;
        column-gap: 10px;

        img {
        }

        p {
          margin: 0;
          font-size: 1rem;
          color: #000;
          font-weight: 600;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .inner-authcard-wrap {
      padding: 3em 4em;
    }
  }

  @media only screen and (max-width: 768px) {
    .inner-authcard-wrap {
      padding: 3em 1.5em;
    }
  }
`;

export const ForgotPass = styled(SignUpCard)`
  .login-subtext {
    width: 100%;
  }
`;

export const ForgotPassWrap = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background: linear-gradient(to right, #0f2d2a, #0f2d2a);


  .inner-auth-wrap {
    display: block;
    position: relative;
    height: 100%;
    z-index: 10;
    padding: 2em 3em;
    width:100%;

    @media screen and (max-width: 768px) {
      padding: 2em;
    }

   }

   .forgot-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 100%;
    z-index:10;
   }

   
  @media screen and (max-width: 768px) {
    .forgot-wrapper {
     flex-direction: column;
      position: relative;
     }

  }
   .wrapper-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    @media screen and (max-width: 768px){
      width:100%;
      display:block !important;
    }



    .auth-footer {
        padding-bottom:100px;
        @media screen and (max-width: 768px){
          padding-bottom:0;
          display:none;
        }
        p {
          margin: 0;
          color: #fff;
          font-size: 1rem;
          font-weight: 300;
          color: #fff;
          white-space: nowrap;
        }
      }
   }


   .wrapper-right {
    width:70%;
    @media screen and (max-width: 768px){ 
      width:100%;
      margin-inline:auto;
      position:absolute;
      top:40%;
      left:50%;
      height:100%;
      transform: translate(-40%, -40%);
    }
   }
    
    .auth-header {
      display: flex;
      justify-content: flex-start;
    }
    .auth-header-bottom {
        margin-bottom: 40px;
    }

    .auth-flex-wrap {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      padding-right: 5em;
      height:70vh;
      align-items:center;

      .left-auth-subwrap {
        flex: 0.4;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height:100%;
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        


        .auth-text-wrap {
          margin-top: 10px;

          .large-text {
            h5 {
              font-weight: 800;
              font-size: 3.5rem;
              color: #fff;
              margin: 0;

              span {
                background: -webkit-linear-gradient(45deg, #407bff, #c4a2fc);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }

            p {
              font-size: 1.2rem;
              font-weight: 300;
              color: #fff;
              margin-top: 30px;
            }
          }
        }

        .swiper-wrap {
          margin-top: 1.5em;

          .swiper {
            width: 400px !important;
            margin-left: 0px !important;
            margin-right: 0px !important;
          }

          // .swiper-slide {
          //     width: 380px !important;
          // }
        }

        .auth-footer {
          margin-top: 3rem;
          p {
            margin: 0;
            color: #fff;
            font-size: 1rem;
            font-weight: 300;
            color: #fff;
            white-space: nowrap;
          }
        }
      }

      .right-auth-subwrap {
        flex: 0.45;
        @media screen and (max-width: 768px){ 
          flex: 1;
        }
      }
    }

    .auth-flex-wrap-forgot {
        display: block;
        width:100%;
        padding-right:0;
        margin-top:0;


        p {
            font-size: 1.2rem;
            font-weight: 300;
            color: #fff;
            margin-top: 30px;
          }
    }
  }

  @media only screen and (max-width: 1410px) {
    .inner-auth-wrap {
      .auth-flex-wrap {
        .right-auth-subwrap {
          flex: 0.5;
        }
      }
    }
  }

  @media only screen and (max-width: 1355px) {
    .inner-auth-wrap {
      .auth-flex-wrap {
        padding-right: 2em;
        .right-auth-subwrap {
          flex: 0.5;
        }
      }
    }
  }

  @media only screen and (max-width: 1140px) {
    .inner-auth-wrap {
      .auth-flex-wrap {
        padding-right: 0em;

        .right-auth-subwrap {
          flex: 0.55;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .inner-auth-wrap {
      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
    

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          height: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    
   
    .inner-auth-wrap {
      .auth-flex-wrap {
        justify-content: center;
        align-items: center;

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          flex: 0.8;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {

    .inner-auth-wrap {
      padding: 10px 10px;
      
      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          flex: 1;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .inner-auth-wrap {
      padding: 5px 5px;
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      .auth-header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }

      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
        padding-top: 3em;

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          flex: 1;
          width: 85%;
        }
      }
    }
  }

  @media only screen and (max-width: 440px) {
    .inner-auth-wrap {
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:10px;

      .auth-header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }

      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
        padding-top: 3em;
        width: 100%;

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          width: 85%;
        }
      }
    }
  }
`;

