import { useEffect, useLayoutEffect } from "react";
import useMediaQuery from "./useMediaQuery";

export const useFontHandler = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  useLayoutEffect(() => {
    const fontSizeHandler = () => {
      const fontSizeByViewport = isMobile
        ? (window.innerWidth / 375) * 10
        : (window.innerWidth / 1440) * 10;

      document.documentElement.style.fontSize = `${fontSizeByViewport}px`;
    };

    fontSizeHandler();

    window.addEventListener("resize", fontSizeHandler);

    return () => {
      window.removeEventListener("resize", fontSizeHandler);
      //  document.documentElement.style.fontSize = "16px";
    };
  }, [isMobile]);

  useEffect(() => {
    return () => {
      document.documentElement.style.fontSize = "16px";
    };
  }, []);
};
