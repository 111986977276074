import styled from "styled-components";
// import fg from '../../assets/png/landinggrad.webp';

export const Homewrap = styled.div`
  // height: 750px;

  .hero-figure {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    @media only screen and (max-width: 1300px) {
      height: 680px;
    }

    @media only screen and (max-width: 930px) {
      height: 630px;
    }

    @media only screen and (max-width: 830px) {
      height: 580px;
    }

    @media only screen and (max-width: 670px) {
      height: 550px;
    }
  }
`;

export const BannerSection = styled.section`
  height: 710px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: relative;
  background:linear-gradient(to right, #37508A 0%, #37508A 10%, #784EAD 50%, #784EAD 75%, #252E3B 100%);
  scale:.8;
  opacity:0;
  animation: FAQfade linear forwards;
  animation-timeline:view();
  animation-range:300px 600px;



  @keyframes FAQfade {
    to {
      scale:1;opacity:1;
    }
  }

  @media only screen and (max-width: 1300px) {
    height: 680px;
  }

  @media only screen and (max-width: 930px) {
    height: 630px;
  }

  @media only screen and (max-width: 830px) {
    height: 580px;
  }

  @media only screen and (max-width: 670px) {
    height: 550px;
  }
`;

export const BannerTextSection = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 4em 0;
  gap: 25px;

  h2 {
    font-weight: 800;
    color: #fff;
    text-align: center;
    font-size: 2.8rem;
    margin: 0;

    span {
      background: linear-gradient(97.89deg, #eecda3 54.28%, #ef629f 107.97%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 800;
    }
  }

  p {
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    font-weight: 400;
    width: 50%;
    margin: 0;
  }

  @media only screen and (max-width: 965px) {
    width: 85%;
    p {
      width: 70%;
    }
  }

  @media only screen and (max-width: 698px) {
    width: 95%;

    p {
      width: 70%;
    }
  }

  @media only screen and (max-width: 540px) {
    width: 90%;

    p {
      width: 100%;
    }
  }
`;

export const BannerImgWrap = styled.div`
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%, 0%);

  img {
    // width: 100%;
  }

  @media only screen and (max-width: 1200px) {
    img {
      width: unset;
    }
  }

  @media only screen and (max-width: 950px) {
    img {
      width: 700px;
    }
  }

  @media only screen and (max-width: 827px) {
    img {
      width: 600px;
    }
  }

  @media only screen and (max-width: 673px) {
    img {
      width: 500px;
    }
  }

  @media only screen and (max-width: 547px) {
    img {
      width: 400px;
    }
  }

  @media only screen and (max-width: 435px) {
    img {
      width: 300px;
    }
  }

  @media only screen and (max-width: 320px) {
    img {
      width: 100%;
    }
  }
`;

export const WhiteBgSection1 = styled.section`
  background-color: #fff;
  scale:.8;
  opacity:0;
  animation: FAQfade linear forwards;
  animation-timeline:view();
  animation-range:300px 600px;



  @keyframes FAQfade {
    to {
      scale:1;opacity:1;
    }
  }

  .inner-wrapper {
    padding: 8rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 437px) {
      padding: 8em 3em;
    }

    h2 {
      font-weight: 800;
      color: #062149;
      text-align: center;
      font-size: 2.5rem;
      margin: 0;
      width: 75%;

      span {
        background: linear-gradient(98.92deg, #407bff 8.16%, #c4a2fc 26.38%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 800;
      }

      @media only screen and (max-width: 706px) {
        width: unset;
      }
    }

    .how-it-works-flex {
      margin-top: 3.5em;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: 992px) {
        flex-direction: column-reverse;
        row-gap: 50px;
      }

      &.faq-flex {
        justify-content: center;
        column-gap: 4rem;

        @media only screen and (max-width: 992px) {
          flex-direction: column;
          row-gap: 50px;
        }
      }

      .left-text-side {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
        flex: 0.4;

        &.faq-txt {
          flex: 0.3;

          a {
            border: none;
            text-decoration: none;
            background-color: #e1eeff;
            color: #1162dc;
            padding: 0.9em 0.8em;
            font-weight: 600;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            border-radius: 6px;
          }
        }

        .gradient-header {
          font-size: 2rem;
          font-weight: 800;
          background: -webkit-linear-gradient(45deg, #407bff, #c4a2fc);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin: 0;
          margin-top: 0.9rem;
        }

        .content-text {
          color: #737373;
          font-weight: 400;
          font-size: 1rem;
        }
      }

      .right-img-side {
        flex: 0.45;

        img {
          height: 100%;
          width: 100%;
        }

        @media only screen and (max-width: 1250px) {
          flex: 0.55;
        }
      }

      .right-text-side {
        flex: 0.45;
      }
    }

    .features-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1rem;
      width: 50%;

      h2 {
        font-size: 1.8rem;
      }

      p {
        text-align: center;
      }

      @media only screen and (max-width: 1131px) {
        width: 80%;
      }

      @media only screen and (max-width: 700px) {
        width: 100%;
      }
    }

    .features-header.use-case {
      width: 100%;

      p {
        width: 40%;
      }

      @media only screen and (max-width: 768px) {
        p {
          width: 80%;
        }
      }

      @media only screen and (max-width: 551px) {
        p {
          width: 100%;
        }
      }
    }
  }
`;

export const FeaturesCardOverall = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-content: center;
  margin-top: 2em;
  width: 80%;
  grid-template-rows: 200px;
  gap: 40px 90px;

  @media only screen and (max-width: 1050px) {
    gap: 40px;
    grid-template-rows: 170px;
  }

  @media only screen and (max-width: 930px) {
    grid-template-columns: 250px 250px;
    justify-content: center;
  }

  @media only screen and (max-width: 670px) {
    grid-template-columns: 200px 200px;
  }

  @media only screen and (max-width: 500px) {
    grid-template-columns: 180px 180px;
  }

  @media only screen and (max-width: 464px) {
    grid-template-columns: 1fr;
  }
`;

export const UsecaseCardOverall = styled(FeaturesCardOverall)`
  width: 70%;
  grid-template-columns: auto auto auto;
  grid-template-rows: 180px;
  gap: 40px;

  @media only screen and (max-width: 930px) {
    grid-template-columns: 200px 200px;
  }

  @media only screen and (max-width: 501px) {
    grid-template-columns: 180px 180px;
  }

  @media only screen and (max-width: 435px) {
    grid-template-columns: 200px;
  }
`;

export const GreyBgSection = styled(WhiteBgSection1)`
  background-color: #fafafa;

  .inner-wrapper {
    padding: 6rem 5rem 2rem 5rem;

    .features-header {
      width: 100%;

      p {
        width: 70%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .inner-wrapper {
      .features-header {
        p {
          width: 80%;
        }
      }
    }
  }

  @media only screen and (max-width: 551px) {
    .inner-wrapper {
      .features-header {
        p {
          width: 100%;
        }
      }
    }
  }
`;

export const GiftcardOverall = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-content: center;
  margin-top: 2em;
  width: 100%;
  // grid-template-rows: 200px;
  column-gap: 40px;

  @media only screen and (max-width: 1002px) {
    justify-content: center;
    grid-template-columns: 280px 280px;
    row-gap: 30px;
  }

  @media only screen and (max-width: 679px) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (max-width: 549px) {
    grid-template-columns: 280px;
  }
`;

export const PricingFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-top: 40px;

  .pricing-footer-text {
    font-size: 0.8rem;
    font-weight: 500;
    color: #737373;
  }

  img {
  }
`;

export const PurpleBox = styled.div`
  background-color: #2b0963;
  width: 90%;
  border-radius: 8px;
  margin-top: 7rem;

  .inner-purple-box {
    display: flex;
    padding: 3rem 2.5rem;
    justify-content: center;
    column-gap: 9rem;
    align-items: center;

    .purple-text-content {
      display: flex;
      flex: 0.55;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 2rem;

      .header-text {
        color: #fff;
        font-weight: 700;
        font-size: 3rem;
        margin: 0;

        span {
          background: -webkit-linear-gradient(45deg, #407bff, #c4a2fc);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 800;
        }
      }

      .content-text {
        color: #eef2ff;
        font-weight: 400;
        margin: 0;
        font-size: 1rem;
      }
    }

    .img-wrap {
      flex: 0.3;

      img {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 902px) {
    width: 100%;

    .inner-purple-box {
      .purple-text-content {
        flex: 0.7%;
      }

      .img-wrap {
        display: block;

        img {
          width: 250px;
        }
      }
    }
  }

  @media only screen and (max-width: 705px) {
    .inner-purple-box {
      .img-wrap {
        display: none;
      }
    }
  }
`;
