import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

declare global {
    interface Window { 
        _jsenv: any;
    }
  }

const env = window._jsenv || process.env
const baseUrl = env.REACT_APP_API_BASE_URL;

export const customerApi = createApi({
    reducerPath: 'customerApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token?.access_token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}` );
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    endpoints: builder => ({
        getUserProject: builder.query<any, void>({
            query: () => {
                return {
                    url: '/project',
                };
            }
        }),
        getCurrentPlan: builder.query<any, void>({
            query: () => {
                return {
                    url: '/user/activatedplan',
                };
            }
        }),
        getProjectCount: builder.query<any, void>({
            query: () => {
                return {
                    url: '/project/count',
                };
            }
        }),
        getMessages: builder.query<any, void>({
            query: (conversationUuid) => {
                return {
                    url: `/chat/getmessages/${conversationUuid}`,
                };
            }
        }),
        getPlanList: builder.query<any, void>({
            query: () => {
                return {
                    url: `/plans`,
                };
            }
        }),
        getConversationList: builder.query<any, void>({
            query: () => {
                return {
                    url: `/chat/getconversations`,
                };
            }
        }),
        deleteConversation: builder.mutation<any,void>({
            query: (conversationUuid) =>{
                return{
                    url: `/chat/deleteconversation/${conversationUuid}`
                }
            }
        }),

        deleteProject: builder.mutation<any,void>({
            query: (projectUuid) =>{
                return{
                    url: `/project/deleteproject/${projectUuid}`
                }
            }
        }),


        deleteProjectUrl: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/project/deleteurl',
                    method: 'post',
                    body: value
                };
            },
        }),
        deleteProjectFile: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/project/deletefile',
                    method: 'post',
                    body: value
                };
            },
        }),
        updateConversationTitle: builder.mutation<any, void>({
            query: (body:any) => {
                return {
                    url: `/chat/editconversationname/${body.Uuid}/${body.name}`,
                    method: 'get'
                };
            },
        }),
        updatePdf: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/project/updatepdf',
                    method: 'post',
                    body: value
                };
            },
        }),
        updateChatbotInfo: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/project/updatechatbot',
                    method: 'post',
                    body: value
                };
            },
        }),
        updateUsecase: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/project/updateusecase',
                    method: 'post',
                    body: value
                };
            },
        }),
        updateUrl: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/project/addurl',
                    method: 'post',
                    body: value
                };
            },
        }),
        createChat: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/chat/conversation',
                    method: 'post',
                    body: value
                };
            },
        }),
        storeLanguage: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/chat/set-language',
                    method: 'post',
                    body: value
                };
            },
        }),
        sendChatMessage: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/chat/query',
                    method: 'post',
                    body: value
                };
            },
        }),

        getChatContext: builder.query<any, void>({
            query: (projectUuid) => {
                return {
                    url: `/project/source-data/${projectUuid}`
                };
            },
        }),

        fetchChatFile: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/project/download/',
                    method: 'post',
                    body: value
                };
            },
        }),

 

        updateLightMode: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/user/screen-mode',
                    method: 'post',
                    body: value
                };
            },
        }),

        getUseCase: builder.query<any, void>({
            query: () => {
                return {
                    url: '/usecase'
                };
            },
        }),

        getDocumentCount: builder.query<any, any>({
            query: (projectUuid) => {
                return {
                    url: `/project/documentcount/${projectUuid}`
                };
            },
        }),

      


        getUserData: builder.query<any, void>({
            query: () => {
                return {
                    url: '/user',
                };
            }
        }),

        editUser: builder.mutation<any,any>({
            query: (value) => {
                return {
                    url: '/user/edit',
                    method: 'post',
                    body: value
                };
            },
        }),

        validateChat: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/chat/validate-chat/',
                    method: 'post',
                    body: value
                };
            },
        }),

        storeChat: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/chat/store-response/',
                    method: 'post',
                    body: value
                };
            },
        }),

        sendStreamMessage : builder.mutation<any,void>({
            query: (value) =>{
                
                return {
                    url: '/chat/event-stream',
                    method: 'post',
                    body: value,
                }
            },
            transformResponse: (response:any) => {
    
                let chunks = '';
                const reader = response.body.getReader();
        
                const readChunk = () => {
                  reader.read().then(({ value, done }:any) => {
                    if (done) {
                    
                      return;
                    }
                    const chunkString = new TextDecoder().decode(value);
                    chunks += chunkString;
                    // Here, you can dispatch an action with the received data
                    // For example: dispatch(someAction(chunks));
                    readChunk();
                  }).catch((error:any) => {
                    console.error(error);
                  });
                };
        
                readChunk();
              },
        })
    }),
});

export const {
    useGetUserProjectQuery,
    useGetProjectCountQuery,
    useGetCurrentPlanQuery,
    useGetMessagesQuery,
    useGetConversationListQuery,
    useDeleteConversationMutation,
    useGetPlanListQuery,
    useDeleteProjectUrlMutation,
    useDeleteProjectFileMutation,
    useUpdateConversationTitleMutation,
    useUpdatePdfMutation,
    useUpdateChatbotInfoMutation,
    useUpdateUsecaseMutation,
    useUpdateUrlMutation,
    useCreateChatMutation,
    useSendChatMessageMutation,
    useSendStreamMessageMutation,
    useStoreLanguageMutation,
    useGetChatContextQuery,
    useFetchChatFileMutation,
    useUpdateLightModeMutation,
    useGetUseCaseQuery,
    useGetUserDataQuery,
    useDeleteProjectMutation,
    useEditUserMutation,
    useGetDocumentCountQuery,
    useValidateChatMutation,
    useStoreChatMutation
    

} = customerApi;