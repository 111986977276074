import styled, { css } from "styled-components";

export const Headerwrap = styled.header`
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
`;

export const Headernav = styled.nav`
  // padding:
  width: 100%;
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    padding: 0 60px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0 30px;
  }

  .logo-wrap {
    img {
      cursor: pointer;
    }
  }

  .cta-section {
    display: flex;
    align-items: center;
    column-gap: 15px;

    @media only screen and (max-width: 992px) {
      display: none;
    }
  }

  .hamburger {
    display: none;

    @media only screen and (max-width: 992px) {
      display: block;
      cursor: pointer;
    }
  }
`;

export const HeaderList = styled.ul<{ tag?: string }>`
  padding: 0;
  display: flex;
  align-items: center;
  column-gap: 20px;
  list-style-type: none;
  animation: headerAnimation 1s ease forwards;

  @media only screen and (max-width: 992px) {
    display: none;
  }

  @keyframes headerAnimation {
    from {
      opacity:0;
      transform:translateX(-100%);
    }
    to {
      opacity:1;
      transform:translateX(0);
    }
  }
`;



export const HeaderSublist = styled.li<{ tag?: string }>`
  
  a {
    text-decoration: none;
    color: #062149;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }

  @media only screen and (max-width: 992px) {
    display: none;
  }
`;

export const Overlay = styled.div<{ activeOverlay?: boolean }>`
  position: fixed;
  z-index: ${(props: any) => (props.activeOverlay === true ? "400" : "-300")};
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  opacity: ${(props: any) => (props.activeOverlay === true ? "1" : "0")};
  transition: all 0.5s ease;
  display: none;

  @media only screen and (max-width: 992px) {
    display: block;
  }
`;

export const MobileMenu = styled.div<{ mobileActive?: boolean }>`
  position: fixed;
  min-height: 100vh;
  top: 0;
  right: ${(props: any) => (props.mobileActive ? "0" : "-900px")};
  width: 80%;
  background-color: #fff;
  z-index: 500;
  transition: all 0.5s ease;
  display: none;

  @media only screen and (max-width: 992px) {
    display: block;
    transition: all 0.5s ease;
    width: 40%;
  }

  @media only screen and (max-width: 768px) {
    display: block;
    transition: all 0.5s ease;
    width: 60%;
  }

  @media only screen and (max-width: 576px) {
    display: block;
    transition: all 0.5s ease;
    width: 80%;
  }

  .inner-mobile-wrap {
    padding: 3em 2.5em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .close-menu-box {
      img {
        cursor: pointer;
      }
    }

    .mobile-list {
      padding: 0;
      list-style: none;
      margin: 0;
      margin-top: 20px;
    

      li {
        margin-top: 20px;

        a {
          text-decoration: none;
          color: #062149;
          font-size: 1.1rem;
          font-weight: 600;
        }
      }
    }

    .mobile-cta-wrap {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
      margin-top: 15px;

      & button {
        font-size: 1.1rem;
      }

      & > button:first-child {
        justify-content: flex-start;
        // padding: 0.9em 0;
      }
    }
  }
`;
