import React, {useEffect,useState} from 'react'
import Spinner from '../../spinnersvg/Spinner'
import { OverWrap,PromptWrap, CancelWrap, EditWrap, SpanWrap } from './editPrompt.styles'

function EditConvoTitle({setShowEditPop ,selectedConvo,handleEditConversation ,updateConversationTitleSuccess, updateConversationTitleLoading, isLoading ,isSuccess}:any) {
  
  const [newTitle,setNewTitle] = useState(selectedConvo?.conversationName)

  const cancelEditPop = ()=>{
        
    setShowEditPop(false)
}

const [showLoad,setShowLoad] = useState(false)

useEffect(()=>{
if(isLoading||updateConversationTitleLoading){
    setShowLoad(true)
}
},[updateConversationTitleLoading,isLoading])


const EditTitle = (id:any,title:any)=>{
const value = {Uuid:id,name:title}

handleEditConversation(value)
}



  return (
    <OverWrap >
    <PromptWrap>{showLoad?<Spinner></Spinner>:
    <>
       <SpanWrap>Edit Conversation Title</SpanWrap>
        <input onChange={(e:any)=>setNewTitle(e.target.value)} value={newTitle} style={{width:"80%",height:"35px",backgroundColor:"white",outline:"none",border:'0.2px solid rgb(195, 194, 194)',borderRadius:'4px',paddingLeft:'15px',color:"black"}}/>
        <section style={{display:"flex",justifyContent:"space-around",width:"80%",margin:"15px auto"}}>
            <CancelWrap onClick={()=>cancelEditPop()}>Cancel</CancelWrap>
            <EditWrap onClick={()=>EditTitle(selectedConvo?.conversationUuid,newTitle)}>Save</EditWrap>    
        </section>
        </>}
    </PromptWrap>

    </OverWrap>
  )
}

export default EditConvoTitle