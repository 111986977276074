import { ButtonWrap } from "./button.styles";

import arrowLeft from "../../assets/svg/arrow-left.svg";
import arrow from "../../assets/svg/arrow-right.svg";

const Button = ({ btnType, text, onClick, icon, tag }: any) => {
  return (
    <ButtonWrap
      tag={tag}
      btnType={btnType}
      onClick={onClick}
      disabled={btnType?.includes("inactive") ? true : false}
    >
      {btnType?.includes("back") && <img src={icon || arrowLeft} />}
      {(btnType?.includes("cancel") ||
        btnType?.includes("share") ||
        btnType?.includes("copy") ||
        btnType?.includes("add") ||
        btnType?.includes("invert") ||
        btnType?.includes("chatroom")) && <img src={icon} />}
      {text}
      {btnType?.includes("cta") && <img src={arrow} alt="" />}
    </ButtonWrap>
  );
};

export default Button;
