import React, { useEffect, useState } from 'react'
import { ProjectList, SidebarAccordion, SidebarAccordionList, SidebarWrap, SiteLinkList, PromptWrap, SpanWrap } from './usersidebar.styles'
import Button from '../button'
import { createSearchParams, useNavigate } from 'react-router-dom'
import LightButton from '../LightButton/LightButton'
import cross from "../../assets/svg/blue-cross.svg"
import file from "../../assets/svg/blue-file.svg"
import trash from "../../assets/svg/trash.svg"
import message from "../../assets/svg/message-question.svg"
import settings from "../../assets/svg/setting-2.svg"
import logout from "../../assets/svg/logout.svg"
import closeSquare from "../../assets/svg/close-square.svg"
import closeCircle from "../../assets/svg/close-circle-2.svg"
import edit from "../../assets/svg/edit-2.svg"
import { useGetConversationListQuery, 
         useGetCurrentPlanQuery, 
         useDeleteConversationMutation, 
         useUpdateConversationTitleMutation,
         useUpdateLightModeMutation,
         useDeleteProjectMutation
          } 
         from '../../services/customerApi'
import { useDispatch } from 'react-redux'
import { clearAuth } from '../../slices/auth'
import { RootState, useAppSelector } from '../../store'
import DeleteConvo from '../deletePrompt'
import EditConvoTitle from '../editPrompt'
import moonIcon from '../../assets/png/moonIcon.png'
import sunIcon from '../../assets/png/sunIcon.png'
import { setBackBlack,setBackWhite } from '../../slices/colourMode'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import UpdateChatNameModal from '../modals/UpdateChatName/UpdateChatNameModal'
import Modal from "react-modal";
import Utils from '../../library/Utils'
import settingsDark from '../../assets/png/settingDarkMode.png'
import logoutDarkMode from '../../assets/png/logoutDarkMode.png'
import BlackBgLogout from '../../assets/png/blackBgLogOut.png'
import HandleConvoMigration from '../extraFunctions/HandleConvoMigration'
import { updateProjectId,updateConvoId } from '../../slices/identityStore'



const IndividualSideBar = ({projectList, handleProjectChoice, projectId, conversationId,setIsOpen,recallChats,setRecallChats}:any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const path = window?.location?.pathname
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)

    const [conversationList, setConversationList] = useState<any>([])
    const [activePlan, setActivePlan] = useState<any>([])
    const [triggerFetch,setTriggerFetch] = useState(false)
    const [showDelete,setShowDelete] = useState(false)
    const [selectedConvo,setSelectedConvo] = useState<any>('')
    const [showEditPop,setShowEditPop] = useState(false)
    const [modalIsOpen, setChatUpdateOpen] = useState(false);

    const count = useAppSelector<any>((store:RootState) => store?.auth?.auth?.count)

    const {data, isLoading, isSuccess, isError, error, refetch} = useGetConversationListQuery()

    const {data: currentPlanData, refetch: refetchCurrentPlan, isSuccess: currentPlanSuccess} = useGetCurrentPlanQuery()
    const [deleteConversation, {data: deleteConversationData, 
                                isLoading: deleteConversationLoading, 
                                isSuccess: deleteConversationSuccess, 
                                isError: isdeleteConversationError, 
                                error: deleteConversationError}] = useDeleteConversationMutation() ;

    const [deleteProject, {data: deleteProjectData, 
                            isLoading: deleteProjectLoading, 
                            isSuccess: deleteProjectSuccess, 
                            isError: isdeleteProjectError, 
                            error: deleteProjectError}] = useDeleteProjectMutation() ;

    const [updateLightMode, {data: updateLightModeData, 
                                    isLoading: updateLightModeLoading, 
                                    isSuccess: updateLightModeSuccess, 
                                    isError: isupdateLightModeError, 
                                    error: deleteupdateLightMode}] = useUpdateLightModeMutation() ;
                                
    const [updateConversationTitle, {data: updateConversationTitleData, 
                                    isLoading: updateConversationTitleLoading, 
                                    isSuccess: updateConversationTitleSuccess, 
                                    isError: isupdateConversationTitleError, 
                                    error: updateConversationTitleError}] = useUpdateConversationTitleMutation()

    // USEEFFECT TO GET CONVERSATION LIST
    useEffect(() => {
        refetch()
        refetchCurrentPlan()
    }, [data, currentPlanData,triggerFetch])

    useEffect(()=>{
        if(recallChats){
            refetch()
        }
    },[recallChats])


    useEffect(() => {
        if(isSuccess) {
            setConversationList(data?.conversations)
            setRecallChats(false)
        }

        if(currentPlanSuccess) {
            setActivePlan(currentPlanData)
        }
    }, [isSuccess, data, isLoading, isError, error, currentPlanSuccess])


    useEffect(()=>{
        if(Utils.isFieldEmpty(data?.conversations)){
            navigate('/individual-chat')
        }
        if(deleteProjectSuccess || updateConversationTitleSuccess){
            
            setTriggerFetch(!triggerFetch)
        }
    },[deleteProjectSuccess,data,updateConversationTitleSuccess])


    useEffect(()=>{
        setShowDelete(false)
        setShowEditPop(false)
    },[data])

    const handleEditConversation = (val:any) => {
        const params = val

        updateConversationTitle(params)
    }


    const  handleDeleteConversation = (id:any) => {
        const params = id

        deleteConversation(params)
    }

    const handleLogout = () => {
        navigate("/login")
        localStorage.removeItem('prolificLoggedInTime');
        localStorage.removeItem("prolificLastVisited")
        dispatch(clearAuth())
    }

    const handleDeletePop = (item:any)=>{
            setSelectedConvo(item)
            setShowDelete(true)
    }


    
    const handleEditPop = (item:any)=>{
        setSelectedConvo(item)
        setChatUpdateOpen(true)
}

function afterOpenModal() { }

    function closeModal() {
        setChatUpdateOpen(false);
        //dispatch(resetDownload());
      }

    const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '300px',
          width: '50%',
          top: 'calc((100% - 300px) / 2)',
          left: 'calc((100% - 50%) / 2)',
          padding:'15px',
          backgroundColor:backgroundMode.color==='white'?'#FFFFFF': '#1B1C1C',
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute',
          backgroundColor:'rgba(0, 0, 0, 0.3)',
        }
      };

    

  return (
    <SidebarWrap colorState={backgroundMode.color}>
        {showDelete && <DeleteConvo setShowDelete={setShowDelete} 
                                    selectedConvo={selectedConvo} 
                                    handleDeleteConversation={handleDeleteConversation} 
                                    deleteConversationSuccess={deleteConversationSuccess} 
                                    deleteProject={deleteProject}
                                    deleteConversationLoading={deleteConversationLoading} 
                                    isLoading={isLoading} 
                                    projectId={projectId}
                                    isSuccess={isSuccess}/>}

        {showEditPop && <EditConvoTitle setShowEditPop ={setShowEditPop} 
                                        selectedConvo={selectedConvo} 
                                        handleEditConversation={handleEditConversation} 
                                        updateConversationTitleSuccess={updateConversationTitleSuccess} 
                                        updateConversationTitleLoading={updateConversationTitleLoading} 
                                        isLoading={isLoading} isSuccess={isSuccess} />}


        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
          contentLabel="Chat Creation" >

            <UpdateChatNameModal selectedConvo={selectedConvo}
                                 closeModal={closeModal}
                                 handleEditConversation={handleEditConversation}
                                 updateConversationTitleLoading={updateConversationTitleLoading}
                                 updateConversationTitleSuccess={updateConversationTitleSuccess}
                                 isLoading={isLoading}/>
          </Modal>
        <div className="inner-sidebar-wrap">
            <SidebarAccordion colorState={backgroundMode.color}>
                <section className='topSection'>
                    <div className="accordion-side-header">
                        <div className="left-side">
                            Chat history
                        </div>
                    </div>

                    <section className='folderChatHold'>
                        <LightButton text={'New Chat'} onClick={() => setIsOpen(true)}/>
                        <LightButton text={'New Folder'} />
                    </section>

                    <section className='chatPartition'></section>
                </section>

                <SidebarAccordionList colorState={backgroundMode.color}>
                    
                    <div className='holder'>
                        {
                            conversationList?.map((item:any, index:any) => {
                                return (
                                    <li key={index} 
                                        className=
                                            {
                                                item?.conversationUuid === conversationId 
                                                ? "active" 
                                                : ""
                                            }
                                        onClick={() => {HandleConvoMigration(item,navigate);
                                                        dispatch(updateConvoId(item.conversationUuid));
                                                        dispatch(updateProjectId(item.projectUuid))               
                                                    }}>
                                        <p >{item?.conversationName}</p>

                                        <div onClick={()=> handleEditPop(item)} className="img-wraps">
                                            
                                            <img src={edit} alt="" />
                                        </div>

                                        <div onClick={()=> handleDeletePop(item)} className="img-wraps">
                                            
                                            <img src={closeCircle} alt="" />
                                        </div>
                                    </li>
                                )
                            })
                        }
                    </div>
                </SidebarAccordionList>
            </SidebarAccordion>

         

            <SiteLinkList colorState={backgroundMode.color}>
                {/* <li> <img src={trash} alt="" /> Clear conversation</li> */}
                <div className='lightHold'>
                    <span onClick={()=>{dispatch(setBackBlack());updateLightMode({"screenMode":"DARK"})}} 
                        style={{
                                backgroundColor:backgroundMode.color==='black'? "white":'',
                                borderRadius:"50%",
                                cursor:"pointer",
                                padding:"5px"}}>
                        <img src={moonIcon}/>
                    </span>
                    <span onClick={()=>{dispatch(setBackWhite());
                                        updateLightMode({"screenMode":"LIGHT"})}} 
                        style={{
                                borderRadius:"50%",
                                cursor:"pointer",
                                padding:"5px",
                                backgroundColor:backgroundMode.color==='white'? "white":'',}}>
                        <img src={sunIcon}/>
                    </span>
                </div>
                {/* <li> <img src={message} alt="" /> Get help</li> */}
                <li onClick={() => navigate("/individual-settings")} className={path?.includes("user-settings") ? "active" : ""}> 
                    <img src={backgroundMode.color==='white'?settings
                              :settingsDark} alt="" /> Settings
                </li>
                <li onClick={handleLogout} > 
                    <img src={backgroundMode.color==='white'?backgroundMode.color==='white'?logout
                              :logoutDarkMode:BlackBgLogout} alt="" /> Logout
                </li>
            </SiteLinkList>
        </div>
    </SidebarWrap>
  )
}

export default IndividualSideBar
