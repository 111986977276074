import React from 'react'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import { OptionsHold,OptionsHoldBg } from '../individualsettings.styles'


function OptionButton({text,brightIcon,deepIcon,field,selectedOption,clickOpt}:any) {
  const backgroundMode = useSelector((state: RootState) => state.backgroundMode)

  return (
    <>
      {backgroundMode.color==='white'?
          <OptionsHold colorState={backgroundMode.color} 
            fieldState={field}
            selectedState={selectedOption}
            onClick={()=>clickOpt()}>
            <span style={{display:'flex',justifyContent:"center",alignItems:"Center"}}>
              <img src={backgroundMode.color==='white'?brightIcon:deepIcon}/>
            </span>
            <span>
              {text}
            </span>
          </OptionsHold>

      :
      <OptionsHoldBg colorState={backgroundMode.color} 
                 fieldState={field}
                 selectedState={selectedOption}
                 onClick={()=>clickOpt()}>
        <span style={{display:'flex',justifyContent:"center",alignItems:"Center"}}>
            <img src={backgroundMode.color==='white'?brightIcon:deepIcon}/>
        </span>
        <span>
            {text}
        </span>
      </OptionsHoldBg>}
    </>
  )
}

export default OptionButton