
import { useState,useEffect } from 'react'
import IndividualSideBar from '../../components/IndividualSideBar'
import IndividualUserHeader from '../../components/IndividualUserHeader'
import { useNavigate,useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { SettingsWrapper } from './individualsettings.styles'
import { SettingsContent } from './individualsettings.styles'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import SettingsContentComponent from './ContentSection/SettingsContent'
import { useGetUserDataQuery,useGetDocumentCountQuery } from '../../services/customerApi'
import { useGetAllCountsQuery } from '../../services/onboardingApi'
import Spinner from '../../spinnersvg/Spinner'
import SettingsMobile from './ContentSection/SettingsMobile/SettingsMobileComponent'
import Modal from "react-modal";
import ChatCreationModal from '../../components/modals/IndividualChatCreation/ChatCreationModal'


function IndividualUserSettings() {
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth);
  const [projectList, setProjectList] = useState<any>([])
  const [searchParams] = useSearchParams()
  const convoId:any = searchParams.get('chatId')
  const projectId:any = searchParams.get('projectId')
  const [recallChats,setRecallChats] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false);
  const [openHeaderModal,setOpenHeaderModal] = useState(false)
  const dataIdentification = useSelector((state: RootState) => state.dataRecords)
  const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
  const [projectName, setProjectName] = useState("")
  const [linksUploaded, setLinksUploaded] = useState<any>([])
  const [filesUploaded, setFilesUploaded] = useState<any>([])
  function afterOpenModal() { }

  function closeModal() {
      setIsOpen(false);
      //dispatch(resetDownload());
    }

    const customStyles = {
      content: {
        transition: '0.125s ease-in-out',
        transform: 'scale(1)',
        height: '700px',
        width: '50%',
        top: 'calc((100% - 700px) / 2)',
        bottom : 'calc((100% - 700px) / 2)',
        left: '25%',
        backgroundColor:backgroundMode.color==='white'?'#FFFFFF': '#1B1C1C',
        borderRadius:'15px',
        padding:'30px 40px 0px',
        boxSizing:'border-box'
      },
      overlay: {
        zIndex: 1000,
        position: 'absolute'
      }
    };


  const {data:userData, 
    isLoading:userDataLoading, 
    isFetching:userDataFetching,
    isSuccess:userDataSuccess, 
    refetch:userDataRefetch, 
    isError:userDataisError} = useGetUserDataQuery()

    const {data:userDocCount, 
      isLoading:userDocCountLoading, 
      isFetching:userDocCountFetching,
      isSuccess:userDocCountSuccess, 
      refetch:userDocFountRefetch, 
      isError:userDocCountisError} = useGetAllCountsQuery()


      

  useEffect(()=>{
    userDataRefetch()
  },[])

  useEffect(() => {
    // Function to update state
    const handleResize = () => {
      setWidth(window.innerWidth);
      
    };

    // Add event listener for resize events
    window.addEventListener('resize', handleResize);


    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); 




  return (
    <SettingsWrapper >
       <ToastContainer />

      <IndividualUserHeader 
          projectList={projectList}
          handleProjectChoice={() => navigate("/individual-settings")}
          conversationId={dataIdentification.convoId}
          recallChats={recallChats}
          setRecallChats={setRecallChats}
          setOpenHeaderModal={setOpenHeaderModal}
      />
      <IndividualSideBar 
          projectList={projectList}
          handleProjectChoice={() => navigate("/individual-settings")}
          conversationId={dataIdentification.convoId}
          setIsOpen={setIsOpen}
          recallChats={recallChats}
          setRecallChats={setRecallChats}
          setOpenHeaderModal={setOpenHeaderModal}
      />
      {userDataSuccess || !userDataFetching?
        (width>992?
        <SettingsContentComponent userData={userData} 
                                  userDataFetching={userDataFetching} 
                                  userDataRefetch={userDataRefetch}
                                  userDocCount={userDocCount}/>

      :<SettingsMobile userData={userData} 
                       userDataFetching={userDataFetching} 
                       userDataRefetch={userDataRefetch}
                       userDocCount={userDocCount}/>)
      : <Spinner/>}

      <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
          contentLabel="Chat Creation" >
          <ChatCreationModal  projectName={projectName} setProjectName ={setProjectName} 
                              linksUploaded={linksUploaded} setLinksUploaded={setLinksUploaded} 
                              filesUploaded={filesUploaded} setFilesUploaded={setFilesUploaded}
                              closeModal={closeModal} setIsOpen={setIsOpen} setRecallChats={setRecallChats}
                              setOpenHeaderModal={setOpenHeaderModal}
                                />
        </Modal>

    </SettingsWrapper>
  )
}

export default IndividualUserSettings