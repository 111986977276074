import styled from "styled-components";

export const Ambassadorwrap = styled.section`
  display: flex;
  position: relative;
  width: 122.555rem;
  margin-inline: auto;
  max-height: 76.7rem;
  margin-bottom: 10rem;
  animation: growTitle 1.5s ease forwards;


  @keyframes growTitle {
    from {
      opacity:0;
    }

    to {
      opacity:1;
    }
  }


  .hero-hero-banner-container {
    flex-basis: 50%;
  }

  .hero-banner {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .hero-banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-banner-container {
    flex-basis: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AmbassadorLhs = styled.section`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 3.2rem;
  flex-basis: 50%;
  justify-content: center;

  .header {
    font-size: 60px;
    color: #1f2228;
    font-weight: 800;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    .header {
      font-size: 4rem;
    }
  }

  @media only screen and (max-width: 768px) {
    .header {
      line-height: 4.5rem;
    }
  }
  @media only screen and (max-width: 530px) {
    .header {
      line-height: 3.2rem;
    }
  }

  @media screen and (max-width: 460px) {
    width: 100%;
    .header {
      width: 90%;
      font-size: 4rem;
      line-height: 4.8rem;
      letter-spacing: -2%;
      font-weight: 800;
    }
  }

  .details {
    color: #7d7d7d;
    font-size: 2.4rem;
    margin-top: 15px;
    line-height: 3.6rem;
    font-weight: 500;
    max-width: 54.3rem;
  }
  @media screen and (max-width: 1024px) {
    .details {
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 820px) {
    .details {
      font-size: 2.4rem;
      line-height: 3.6rem;
    }
  }

  @media screen and (max-width: 768px) {
    .details {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
    }
  }
`;

export const BestFriend = styled.section`
  height: 32.7rem;
  width: 100%;
  scale:.8;
  opacity:0;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(to right, #0f2d2a, #0f2d2a);
  animation: divFadeIn linear forwards;
  animation-timeline:view();
  animation-range:100px 500px;



  @keyframes divFadeIn {
    to {
      scale:1;opacity:1
    }
  }

  .header {
    color: white;
    font-size: 4rem;
    font-weight: 800;
    line-height: 7.2rem;
    letter-spacing: -2%;
    z-index: 9999;
  }

  @media screen and (max-width: 768px) {
    height: 22.6rem;

    .header {
      font-size: 1.8rem;
      font-weight: 800;
      line-height: 2.4rem;
    }

    .details {
      font-size: 1.2rem;
      line-height: 2.2rem;
      margin-top: 0;
    }
  }

  .details {
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    line-height: 3.6rem;
    width: 65%;
    z-index: 9999;
    margin-top: -4.8rem;

    @media screen and (max-width: 1024px) {
      width: 90%;
      line-height: 5rem;
      font-size: 3rem;
    }
    @media screen and (max-width: 930px) {
      width: 85%;
      line-height: 5rem;
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 768px) {
    .details {
      font-size: 1.2rem;
      line-height: 2.2rem;
      margin-top: 0;
      text-align: center;
      width: 85%;
    }
  }
`;

export const AboutProlific = styled.section`
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 700px;
  width: 122.555rem;
  margin-inline: auto;
  scale:.8;
  opacity:0;
  margin-bottom: 12rem;
  animation: AboutFadeIn linear forwards;
  animation-timeline:view();
  animation-range:300px 700px;



  @keyframes AboutFadeIn {
    to {
      scale:1;opacity:1
    }
  }

  @media screen and (max-width: 930px) {
    padding: 0;
    flex-direction: column;
    width: 90%;
    margin-bottom: 4rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    width: 80%;
    margin-bottom: 4rem;
  }
`;

export const AboutProlificLhs = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 51.2rem;
  flex-direction: column;
  row-gap: 25px;

  .header {
    font-size: 32px;
    color: #1f2228;
    font-weight: 600;
  }

  .details {
    color: #6a717d;
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: 500;
    margin-top: 15px;
  }

  .about-list {
    width: 48rem;
    margin-bottom: 3.2rem;
  }

  .bulletDetails {
    color: #6a717d;
    font-size: 24px;
    line-height: 3.6rem;
    font-weight: 500;
  }

  @media screen and (max-width: 930px) {
    order: 1;
    width: 100%;

    .about-list {
      width: 100%;
    }

    .details {
      font-size: 20px;
    }
    .bulletDetails {
      font-size: 3rem;
      line-height: 2.4rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    order: 1;
    row-gap: 10px;

    .about-image {
      width: 100%;
      order: 10;
    }

    .header {
      font-size: 2.4rem;
      color: #1f2228;
      font-weight: 600;
    }
    .details {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    .bulletDetails {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
    .about-list {
      width: unset;
      margin-bottom: 5rem;

      svg {
        width: 1.6rem;
      }
    }
  }
`;

export const YourGain = styled.section`
  max-height: 66.7rem;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  background: linear-gradient(to right, #0f2d2a, #0f2d2a);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  scale:.8;
  opacity:0;
  margin-bottom: 12rem;
  animation: YourGainadeIn linear forwards;
  animation-timeline:view();
  animation-range:150px 500px;



  @keyframes YourGainadeIn {
    to {
      scale:1;opacity:1;
    }
  }

  .header {
    color: white;
    font-size: 4rem;
    font-weight: 800;
    z-index: 9999;
    line-height: 7.2rem;
    letter-spacing: -2%;
  }

  .details {
    color: white;
    font-size: 2.4rem;
    font-weight: 500;
    text-align: center;
    line-height: 3.6rem;
    width: 105.8rem;
    margin-inline: auto;
    margin-bottom: 6rem;
    z-index: 9999;
  }

  @media screen and (max-width: 768px) {
    height: 46.7rem;
    .header {
      font-size: 1.8rem;
      font-weight: 800;
      line-height: 2.4rem;
      letter-spacing: -2%;
      margin-bottom: 10px;
    }
    .details {
      font-size: 1.2rem;
      line-height: 2.2rem;
      width: 100%;
      text-align: center;
      z-index: 9999;
      font-weight: 500;
      margin-bottom: 3rem;
    }
  }

  // @media screen and (max-width: 900px) {
  //   .details {
  //     font-size: 18px;
  //   }
  // }

  .gridLay {
    width: 116.2rem;
    margin-inline: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: self-start;
    gap: 10px;
    row-gap: 5rem;
    z-index: 9999;
    margin-bottom: 5rem;

    .bulletDetails {
      color: white;
      font-size: 2.4rem;
      line-height: 3.2rem;
      margin: 0px auto;
      text-align: center;
      z-index: 9999;
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      width: 90%;
      align-items: self-start;
      row-gap: 3rem;
      column-gap: 2rem;
      img {
        width: 2.4rem;
      }

      .bulletDetails {
        font-size: 1.2rem;
        line-height: 1.7rem;
      }
    }
  }
`;

export const AmbassadorRequirements = styled.section`
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 700px;
  width: 128.1rem;
  margin-inline: auto;
  scale:.8;
  opacity:0;
  animation: YourGainadeIn linear forwards;
  animation-timeline:view();
  animation-range:150px 500px;



  @keyframes YourGainadeIn {
    to {
      scale:1;opacity:1;
    }
  }

  .require-img-box {
    flex-basis: 40%;
  }
  @media screen and (max-width: 930px) {
    width: 90%;
    padding: 0;
    min-height: 500px;
    flex-direction: row;
    .require-img-box {
      width: 100%;
      order: 0;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 90%;
    padding: 0;
    margin-bottom: 4rem;
    gap: 30px;
    .require-img-box {
      width: 100%;
      order: 0;
    }
  }
`;

export const AmbassadorRequirementsLhs = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-basis: 50%;
  flex-direction: column;
  // row-gap: 25px;
  row-gap: 3.2rem;

  .header {
    font-size: 3.2rem;
    color: #1f2228;
    font-weight: 600;
    line-height: 4.4rem;
  }

  .bulletDetails {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.6rem;
    color: #6a717d;
  }
  .details {
    color: #6a717d;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 3.6rem;
  }

  @media screen and (max-width: 1024px) {
    .header {
      font-size: 1.8rem;
      font-family: Inter;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 1024px) {
    .header {
      font-size: 3.2rem;
      line-height: 4.4rem;
    }
    .bulletDetails {
      font-size: 3rem;
    }

    .details {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    order: 1;
    .header {
      font-size: 1.8rem;
      font-family: Inter;
      font-weight: 600;
      line-height: 4.4rem;
    }
    svg {
      width: 1.6rem;
    }
  }

  @media screen and (max-width: 768px) {
    .details {
      font-size: 1.6rem;
    }

    .bulletDetails {
      font-size: 1.6rem;
    }
  }
`;

export const FAQs = styled.section`
  width: 100%;
  padding: 25px;
  padding-bottom: 6rem;
  background: linear-gradient(to right, #0f2d2a, #0f2d2a);
  display: flex;
  flex-direction: column;
  align-items: center;
  scale:.8;
  opacity:0;
  animation: FAQfade linear forwards;
  animation-timeline:view();
  animation-range:300px 600px;



  @keyframes FAQfade {
    to {
      scale:1;opacity:1;
    }
  }

  .faq-wrapper {
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    max-width: 85.4rem;
  }

  .header {
    color: white;
    font-size: 40px;
    font-weight: 800;
    line-height: 7.2rem;
    letter-spacing: -2%;
  }

  .details {
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    width: 60%;
  }

  .FaqList {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    color: white;
    width: 100%;

    .question-wrapper {
      padding-block: 2rem;
    }
    @media screen and (max-width: 768px) {
      .question-wrapper {
        padding-block: 1rem;
      }
    }

    .question {
      font-size: 3.2rem;
      font-weight: 700;
      line-height: 3.5rem;
      font-family: "DM Sans";
      flex-basis: 90%;

      @media screen and (max-width: 768px) {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    .reply {
      font-size: 2.4rem;
      font-weight: 400;
      line-height: 3.6rem;
      display: none;
      font-family: "DM Sans";
    }

    .reply-block {
      font-size: 24px;
      font-weight: 400;
      width: 100%;
      padding-block: 0rem;
      font-family: "DM Sans";
      max-height: 0px;
      overflow:hidden;
      transition: max-height 0.5s ease, padding-block 0.5s ease;
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        line-height: 1.8rem;
        font-family: "DM Sans";
        padding-block: 0rem;
      }
    }
  }

  .reply-block.isOpened {
    font-size: 24px;
    font-weight: 400;
    width: 100%;
    padding-block: 2rem;
    max-height: 400px;
    display:block;
    font-family: "DM Sans";
    @media screen and (max-width: 768px) {
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-family: "DM Sans";
      padding-block: 1rem;
    }
  }
}

  @media screen and (max-width: 768px) {
    .header {
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .details {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      width: 90%;
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    .header {
      font-size: 18px;
      font-weight: 800;
      line-height: 24px;
    }
  }
`;

export const ReadySection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 3.2rem;
  padding: 5rem;
  min-height:40rem;

  .header {
    font-size: 60px;
    color: #1f2228;
    font-weight: 800;
    margin-inline: auto;
    text-align: center;
    width: 75%
    margin-inline: auto;
  }

  @media screen and (max-width: 1100px) { 
    .header {
      font-size: 22px;
      font-weight: 800;
      line-height: 32px;
      letter-spacing: -2%;
    }
  }

  @media screen and (max-width: 1024px) {
    .header {
      font-size: 24px;
      font-weight: 800;
      line-height: 32px;
      letter-spacing: -2%;
    }
  }

  @media screen and (max-width: 768px){ 
    padding: unset;
    min-height:30rem;

  }

  @media screen and (max-width: 530px){ 
    padding: unset;
    min-height:30rem;
  }
`;
