import React, {useCallback, useState} from 'react'
import { StepsWrapper } from '../step1/firststep.styles'
import Button from '../../../components/button'
import { EmptyBox, UploadSection, UploadedFilesField, WrapInputField } from './secondstep.styles'
import AddFile from '../../../components/addFile'
import AddFileBusiness from '../../../components/addFileBusiness'
import Input from '../../../components/input'
import FileUpBox from '../../../components/fileup'
import {useDropzone} from 'react-dropzone'

import backBlue from "../../../assets/svg/back-blue.svg"
import addCircle from "../../../assets/svg/add-circle.svg"
import { ErrorAbsolute } from '../../../components/input/input.styles'


const SecondStep = ({ projectName, setProjectName, linksUploaded, setLinksUploaded, filesUploaded, setFilesUploaded,setStepShown}:any) => {
    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    const [filesSelected, setFilesSelected] = useState<any>([]);
    const [filesRejected, setFilesRejected] = useState<any>([]);
    const [link, setLink] = useState("")
    const [isInputError, setIsInputError] = useState(false)

    const onDrop = useCallback((acceptedFiles:any) => {
        setFilesUploaded([...filesUploaded, ...acceptedFiles]);
        setFilesRejected([]);
    }, [filesUploaded])


    const {
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 
            'application/pdf': ['.pdf',],
            'text/html': ['.html', '.htm'],
            'text/csv' : ['.csv'],
            'text/plain' : ['.txt'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        },
        maxSize: 100000000,
        // filesizeBase: 1000,
        multiple: true,
        onDrop,
        // onDropRejected
    });

    const onRemoveFile = (file:any) => {
        let newFilesArray = [...filesUploaded];
        newFilesArray.splice(newFilesArray.indexOf(file), 1);
        setFilesUploaded(newFilesArray)
    }


    const handleAddUrl = () => {
        const isValidURL = urlRegex.test(link);
        if (link) {
            if(isValidURL) {
                setLinksUploaded([...linksUploaded, link])
                setLink("")
            }else {
                setIsInputError(true)

                setTimeout(() => {
                    setIsInputError(false)
                }, 4000)
            }
        }
    }

    const onRemoveUrl = (link:any) => {
        let newLinkArray = [...linksUploaded];
        newLinkArray.splice(newLinkArray.indexOf(link), 1);
        setLinksUploaded(newLinkArray)
    }

    const handleOnKeyDown = (e:any) => {
        if(link) {
            if(e.key === "Enter") handleAddUrl()
        }else {
            return
        }
    }


  return (
    <StepsWrapper>
        <div className="text-wrap">
            <p className="steps-header">
                STEP 2
            </p>

            <h3 className="topic-header">
                Add knowledge base
            </h3>

            <p className="instruction-content">
            Select from a range of use cases that will infuse your bot with a distinctive purpose. Choose one that works best with your objectives.
            </p>
        </div>

        <UploadSection>
            <div {...getRootProps()} onClick={open}>
                <input {...getInputProps()} />
                <AddFileBusiness />
            </div>

            <WrapInputField>
                <label htmlFor="">Or upload from a URL</label>
                <div className={isInputError ? "url-input-wrap input-error" : "url-input-wrap"}>
                    <input type="text" className="url-input" placeholder='https://www.example.com' onChange={(e:any) => setLink(e.target.value)} onKeyDown={(e:any) => handleOnKeyDown(e)} value={link} />
                    {link ?  <button className="url-btn" onClick={handleAddUrl}>Add url</button> : <div className={"empty-box"}></div>}

                    {
                        isInputError && 
                        <ErrorAbsolute>Please type in a valid url</ErrorAbsolute>
                    }
                </div>

                <div className="flex-wrap">
                    {
                        linksUploaded?.map((item:any, id:any) => {
                            return (
                                <FileUpBox 
                                    key={id}
                                    type={"url"}
                                    onRemove={() => onRemoveUrl(item)}
                                    urlName={item}
                                />
                            )
                        })
                    }
                </div>
            </WrapInputField>


            {
                filesUploaded?.length > 0 ?
                <UploadedFilesField>
                    <p>Uploaded files</p>

                    <div className="files-flex-wrap">
                        {
                            filesUploaded?.map((item:any, index:any) => {
                                return (
                                    <FileUpBox 
                                        key={index}
                                        onRemove={() => onRemoveFile(item)}
                                        fileName={item?.name}
                                    />
                                )
                            })
                        }
                    </div>
                </UploadedFilesField> :
                <EmptyBox />
            }

            <WrapInputField>
                <label htmlFor="">Give this knowledge base a name <span>{'(data uploaded belongs here)'}</span> </label>
                <Input holder="My Business Development project" handleInputChange={setProjectName} inputValue={projectName} />
            </WrapInputField>
        </UploadSection>



        <div className="steps-footer-box">
            <Button 
                text={'Back'}
                btnType={"cancel"}
                icon={backBlue}
                onClick={() => setStepShown("first")}
            />

            <Button 
                text={"Next"}
                btnType={(projectName && (filesUploaded?.length !== 0 || linksUploaded?.length) ) ? "cta" : "cta-inactive"}
                onClick={() => setStepShown("third")}
                
            />
        </div>
    </StepsWrapper>
  )
}

export default SecondStep