import styled from "styled-components"

export const UploadSection = styled.div `
    margin: 30px 0;
`

export const WrapInputField = styled.div `
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    label {
        font-weight: 600;
        font-size: 0.8rem;

        span {
            font-weight: 300;
        }
    }

    .add-site-btn-wrap {
        display: flex;
        justify-content: flex-start;
        margin: 10px 0;

        .add-site-btn {
            display: flex;
            align-items: center;
            column-gap: 7px;
            cursor: pointer;

            img {

            }

            p {
                margin: 0;
                font-size: 0.7rem;
                color: #737373;
                font-weight: 500;
            }
        }
    }

    .flex-wrap {
        display: flex;
        align-items: center;
        column-gap: 15px;
        row-gap: 10px;
        flex-wrap: wrap;
    }

    .url-input-wrap {
        border: solid 1px #D4D4D4;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position-relative;
        padding: 0.4em;
        column-gap: 10px;

        &:has(> input:focus) {
            border: solid 1px #062149;

            &.input-error {
                border: solid 1px rgba(242, 114, 114, 1);
            }
        }

        &.input-error {
            border: solid 1px rgba(242, 114, 114, 1);
        }

        input.url-input {
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            font-size: 0.8rem;
            padding-left: 10px;


            &::placeholder {
                font-size: 0.8rem;
                color: #A3A3A3;
            }
        }

        button.url-btn {
            border: none;
            cursor: pointer;
            outline: none;
            color: #fff;
            font-weight: 500;
            font-size: 0.8rem;
            padding: 0.8em 1.5em;
            border-radius: 4px;
            background-color: #1162DC;
            white-space: nowrap;
        }

        .empty-box {
            padding: 1.1em 1.5em;
        }
    }
`

export const EmptyBox = styled.div `
    width: 100%;
    height: 30px;
`

export const UploadedFilesField = styled.div `
    p {
        font-size: 0.7rem;
        font-weight: 600;
        color: #062149;
    }

    .files-flex-wrap {
        display: flex;
        align-items: center;
        column-gap: 10px;
        row-gap: 6px;
        margin-top: 5px;
        flex-wrap: wrap;
    }
`