import React from 'react'
import { AccordionCard, AccordionContent } from './accordion.styles'

import plus from "../../assets/svg/add.svg"
import cancel from "../../assets/svg/cancel.svg"

const Accordion = ({list, onToggle, active, num} :any) => {

  return (
    <AccordionCard active={active ? true : false}>
        <div className="accordion-header" onClick={onToggle}>
            <div className="left-side">
                <p>{num}.</p>

                <p>{list.header}</p>
            </div>

            {
                active ?
                <img src={cancel} /> :
                <img src={plus}/>
            }
        </div>

        <AccordionContent active={active}>
            <div className="content-text">
                {list.content}
            </div>
        </AccordionContent>
    </AccordionCard>
  )
}

export default Accordion