import {createSlice} from '@reduxjs/toolkit';



const initialState = {
  token: "" ,
};


const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken: (state, action) => {
      return {...state, token: action.payload};
    },
    clearToken: () => {
      return {token: ""};
    },
  },
});

const {reducer, actions} = tokenSlice;
export const {
  setToken,
  clearToken,
} = actions;
export default reducer;