import React,{useState} from 'react'
import { SettingsContent } from '../individualsettings.styles'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import userProfileIcon from '../../../assets/png/userProfileIcon.svg'
import userProfileBlackBg from '../../../assets/png/profileUpdateBlackBg.png'
import changePassBlackBgIcon from '../../../assets/png/passwordIconBlackBg.png'
import changePassIcon from '../../../assets/png/changePasswordIcon.png'
import billingIcon from '../../../assets/png/billingIcon.png'
import billingBlackBgIcon from '../../../assets/png/billingBlackBg.png'
import copyIcon from '../../../assets/png/copyWhiteBg.png'
import copyBlackBgIcon from '../../../assets/png/copyBlackBg.png'
import OptionButton from './OptionButton'
import SettingsFieldBox from './SettingsField'
import profileDelRed from '../../../assets/svg/profile-delete-red.svg'
import { OptionsHold } from '../individualsettings.styles'


function SettingsContentComponent({userData,userDataFetching,userDataRefetch,userDocCount}:any) {
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const [optionSelected,setOptionSelect] = useState('profile')

    return (
        <SettingsContent colorState={backgroundMode.color}>
            <section className='containerOne'>
                <OptionButton text={'User Profile'} 
                              brightIcon={userProfileIcon}
                              deepIcon={userProfileBlackBg}
                              field={'profile'}
                              selectedOption={optionSelected}
                              clickOpt={()=>setOptionSelect('profile')}
                />

                <OptionButton text={'Change Password'} 
                              brightIcon={changePassIcon}
                              deepIcon={changePassBlackBgIcon}
                              field={'password'}
                              selectedOption={optionSelected}
                              clickOpt={()=>setOptionSelect('password')}
                />

                <OptionButton text={'Billing'} 
                              brightIcon={billingIcon}
                              deepIcon={billingBlackBgIcon}
                              field={'billing'}
                              selectedOption={optionSelected}
                              clickOpt={()=>setOptionSelect('billing')}
                />

                <OptionsHold colorState={backgroundMode.color} 
                    fieldState='delete'
                    onClick={()=>setOptionSelect('delete')}>
                    <span style={{display:'flex',justifyContent:"center",alignItems:"Center"}}>
                    <img src={profileDelRed}/>
                    </span>
                    <span style={{color:'#DF0B0B'}}>
                        Delete Account
                    </span>
                </OptionsHold>

               
            </section>

            <SettingsFieldBox userData={userData} 
                              userDataFetching={userDataFetching} 
                              userDataRefetch={userDataRefetch}
                              userDocCount={userDocCount}/>
        </SettingsContent>
    )
    }

export default SettingsContentComponent