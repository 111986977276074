import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number; 
}

declare global {
    interface Window { 
        _jsenv: any;
    }
}

const env = window._jsenv || process.env
const baseUrl = env.REACT_APP_API_BASE_URL;

export const onboardingApi = createApi({
    reducerPath: 'onboardingApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token?.access_token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}` );
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    endpoints: builder => ({
        login: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/user/login',
                    method: 'post',
                    body: value
                };
            },
        }),
        userSignup: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/user/signup',
                    method: 'post',
                    body: value
                };
            },
        }),
        userVerify : builder.mutation<any,any>({
            query: (value) =>{
                return {
                    url: '/user/verify',
                    method: 'post',
                    body: value
                }
            }
        }),
        resendOtp: builder.query<void,any>({
            query: (email) =>{
                return {
                    url: `user/send/${email}`
                }
            }
        }),
        createUser: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/user/create',
                    method: 'post',
                    body: value
                };
            },
        }),
        getUser: builder.query<any, void>({
            query: () => {
                return {
                    url: '/user',
                };
            }
        }),
        forgotPassword: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/user/forgot-password',
                    method: 'post',
                    body: value
                };
            },
        }),
        getPlanPaid: builder.query({
            query: ({reference, key}) => {
                return {
                    url: `/user/payment/process/${reference}/${key}`,
                };
            }
        }),

        confirmResetCode: builder.query<any, any>({
            query: (resetCode) => {
                return {
                    url: `/user/validate-password-reset-code/${resetCode}`,
                };
            }
        }),

        contactUs: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/contact',
                    method: 'post',
                    body: value
                };
            },
        }),


        getAllCounts: builder.query<any, void>({
            query: () => {
                return {
                    url: '/user/count/'
                };
            },
        }),
        

        updatePassWord: builder.mutation<any, any>({
            query: (value) => {
                return {
                    url: '/user/change-password',
                    method: 'post',
                    body: value
                };
            },
        }),
    }),
});

export const {
    useLoginMutation,
    useCreateUserMutation,
    useUserSignupMutation,
    useGetUserQuery,
    useGetPlanPaidQuery,
    useUserVerifyMutation,
    useResendOtpQuery,
    useForgotPasswordMutation,
    useConfirmResetCodeQuery,
    useUpdatePassWordMutation,
    useContactUsMutation,
    useGetAllCountsQuery
} = onboardingApi;