import React, { useEffect, useRef, useState } from 'react'
import { ChatQuestionBox, ChatroomFooter, ChatroomSection } from '../chatroom.styles'

import send from "../../../assets/svg/send-2.svg"
import chatHolder from "../../../assets/png/chat-holder.png"
import refresh from "../../../assets/svg/refresh-square-2.svg"
import { ChatMessageWrap, MessageReceivedWrap, MessageSentWrap } from './chat.styles'
import Button from '../../../components/button'
import { useSendStreamMessageMutation } from '../../../services/customerApi'
import copyText from '../../../assets/svg/copyText.svg'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Spinner from '../../../spinnersvg/Spinner'
import chatResponseIcon from '../../../assets/png/clarity_language-line.png'
import dropdownIcon from '../../../assets/png/dropdown.png'
import { useStoreLanguageMutation } from '../../../services/customerApi'
import tooltipWhite from '../../../assets/png/tooltipWbg.png'
import tooltipBlack from '../../../assets/png/tooltipBlackBg.png'


declare global {
    interface Window { 
        _jsenv: any;
    }
  }

const env = window._jsenv || process.env
const baseUrl = env.REACT_APP_API_BASE_URL;

const Chat = ({chatMessages,setChatMessages,selectedLanguage,setSelectedLanguage,queryParams,chunks,setChunks,chatQuery,resetChat,setResetChat,setChatQuery, handleSendMessage, getMessagesLoading, dummyQuery, setDummyQuery, sendMessageSuccess, convoId, projectId, sendMessageLoading}:any) => {
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const bottomRef:any = useRef(null);
    const inputRef:any = useRef(null);
    const endRef:any = useRef(null)
    const [MssgLoop,setMssgLoop] = useState<any>(null)
    const [copied,setCopied] = useState(false)
    const textareaRef = useRef<any>(null);
    const sectionWrapperRef = useRef<any>(null)
    const [showLanguage,setShowLanguage] = useState(false)
    const [initialHeight, setInitialHeight] = useState(0);
    const [storeDesiredLanguage, {data: storeLangResponse, isSuccess: storeLangResponseSuccess, isLoading: storeLangResponseLoading, isError: storeLangResponseError, error: storeLangResponseErrorMessage}] = useStoreLanguageMutation()
    let authDATAA = useSelector((state: RootState) => state.auth?.auth?.token?.access_token)
    //const token = (getState() as RootState)?.auth?.auth?.token?.access_token;
    let chunkData:any;
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
  
    const languages = ['English','Yoruba', 'Igbo', 'Hausa', 'Fulah', 'Fulfulde', 'French', 'Portuguese',
    'Arabic', 'Amharic', 'Somali', 'Swahili', 'Oromo', 'Zulu', 'Shona', 'Afrikaans',
    'Xhosa', 'Kinyarwanda']
//https://chatbot.dev.prolific.periculum-models.link/stream_chat
//https://api.dev.prolific.periculum-models.link/chat/event-stream
    const fetchStream = (raw:any)=>{
        setDummyQuery(chatQuery)
        setChatQuery('')
        setMssgLoop(false)
        fetch(
            `${baseUrl}/chat/event-stream`, 
            {
                method: "POST",
                headers: { "Content-Type": "application/json",'Authorization': `Bearer ${authDATAA}` },
                body: raw
            }).then(response => {
                // Get the readable stream from the response body
                setChunks('')
                setDummyQuery(chatQuery)
                const stream = response.body;
                // Get the reader from the stream
                let reader:any;
                if(stream){
                    reader = stream.getReader();
                }
                let chunks = '';
                // Define a function to read each chunk
                const readChunk = () => {
                    // Read a chunk from the reader
                    reader.read()
                        .then(({
                            value,
                            done
                        }:any) => {
                            // Check if the stream is done
                             
                            if (done) {
                                // Log a message
                                setResetChat(!resetChat)
                                return;
                            }
                            // Convert the chunk value to a string
                            
                            let chunkString = new TextDecoder().decode(value);
                            
                            chunkString = chunkString.replace(/\*\*([^*]+?)\*\*/g, (_, match) => {
                                // Use the first defined match
                                const matchedText = match
                                return matchedText ? `<strong>${matchedText}</strong>` : '**';
                                
                              })

                              chunkString = chunkString.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
                            setChunks((prevChunks:any) => prevChunks + chunkString);
                            setMssgLoop(true)
                            readChunk();
          
                        })
                        .catch((error:any) => {
                            // Log the error
                            console.error(error);
                        });
                };
                // Start reading the first chunk
                readChunk();
            })
            .catch(error => {
                // Log the error
            
                console.error(error);
            });
        
    }


    const createMarkup = (htmlString:any) => {
        return { __html: htmlString };
      };




    const handleKeyDown = (e:any) => {
        if(e.key === "Enter"){
            //sendStreamMessage(queryParams)
            setMssgLoop(false)
            let newParam = {...queryParams,language:selectedLanguage}
            const dat = JSON.stringify(newParam)
            fetchStream(dat)

        }
    }

    //if(e.key === "Enter") handleSendMessage()
    
    useEffect(() => {
        if(dummyQuery) {
            setChatQuery("")
        }

        if (sendMessageLoading) {
            bottomRef.current?.scrollIntoView({behavior: 'smooth'});
        }

        if(sendMessageSuccess) {
            endRef.current?.scrollIntoView({behavior: 'smooth'});
        }

        if(chunks || dummyQuery){
            endRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    }, [dummyQuery, sendMessageLoading, sendMessageSuccess,chunks,dummyQuery])

    useEffect(() => {
        inputRef.current?.scrollIntoView({behavior: "smooth"})
        endRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [convoId,chatMessages.length, projectId])

    useEffect(()=>{
        setMssgLoop(null)
    },[chatMessages.length])

  const fetchStreamOnClick = (data:any)=>{
    let newParam = {...data,language:selectedLanguage}
        const mainData = JSON.stringify(newParam)

        fetchStream(mainData)
  }


  const selectNewLanguage = (lang:any)=>{
    let languageStore = {language:lang,conversationUuid:convoId}
    setSelectedLanguage(lang)
    storeDesiredLanguage(languageStore)
    setShowLanguage(false)
  }

  async function copyTextToClipboard(jsonString:string) {
    try {
      if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(jsonString);
        setCopied(true)
      }
    } catch (err) {
      console.error(err);
    }
  }


  const clipped = (data:any)=>{

    copyTextToClipboard(data)

    if(copied){

      toast.success('Copied to clipboard.', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setCopied(false)
    }
  }

  const changeInput = (e:any)=>{
    setChatQuery(e)
    const textarea = textareaRef.current;
    const sectionarea = sectionWrapperRef.current;
    const scrollHeight = textarea.scrollHeight;
    
    if (scrollHeight > sectionarea.offsetHeight && scrollHeight>textarea.clientHeight && scrollHeight<=70) {
        sectionarea.style.height = scrollHeight + 'px';
    } 

    if(scrollHeight > sectionarea.offsetHeight && scrollHeight>textarea.clientHeight && scrollHeight>70){
        sectionarea.style.height = '70px';
        sectionarea.style.overflow = 'auto';
        sectionarea.style.maxHeight = '70px'
    }
    if(scrollHeight<sectionarea.offsetHeight){
        sectionarea.style.height = scrollHeight + 'px';
    }
    else if(scrollHeight === sectionarea.offsetHeight){
        textarea.style.height = 'fit-content'
        const newHeight = textarea.scrollHeight;
        sectionarea.style.height = newHeight + 'px';
        textarea.style.height = '100%'

    }
    else{
        textarea.style.height = 'fit-content'
        const newHeight = textarea.scrollHeight;
        sectionarea.style.height = newHeight + 'px';
        textarea.style.height = '100%'
    }
  }

  const handleTextAreaLoad = () => {
    const textarea = textareaRef.current;
    setInitialHeight(textarea.offsetHeight);
    
  };

const textAreaStyling = {
    border: 'none',
    width: '100%',
    resize: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    
    color: '#062149',
    fontSize: '0.8rem',
    fontWeight: '400',
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
}
  return (
    <ChatroomSection>
        <div className="inner-chatroom-wrap">
            <div className="chatbox-wrapper">
                <div className="chatbox-header">

                        <div className="left-side">
                            <h3 className="head">Chatroom</h3>
                            <div className='dropDownHolder'>
                                <section onClick={()=>setShowLanguage(!showLanguage)} className='selectSection'>
                                    <span className='imageHold' ><img src={chatResponseIcon}/></span>
                                    <span>{selectedLanguage?selectedLanguage:'English'}</span>
                                    <span className='imageHold' ><img src={dropdownIcon}/></span>
                                </section>

                                <section className={showLanguage?'langArray':'langArrayHide'}>
                                    {languages.map((lang,index)=><span onClick={()=>selectNewLanguage(lang)} 
                                    style={{textAlign:"center",padding:'7px 0px',cursor:'pointer'}} key={languages.indexOf(lang)}>{lang}</span>)}
                                </section>
                            </div>
                           
                        </div>
                    

                    {/* {
                        chatMessages?.length > 0 && 

                        <div className="right-side">
                            <Button 
                                text={"Regenerate response"} 
                                onClick={() => {return}}
                                btnType={"chatroom"}
                                icon={refresh}
                            />
                            <Button 
                                text={"Restart chat"}
                                onClick={() => {return}}
                                btnType={"chatroom"}
                                icon={refresh}
                            />
                        </div>
                    } */}
                </div>
                <div className="chatbox-body">
                    {
                        (!dummyQuery && chatMessages?.length === 0) && 
                        <div className="center-box-wrap">
                            <img src={chatHolder} alt="" />

                            <div className="text">
                                <h3>Start your conversation</h3>
                                <p>Ask your AI a question and watch the magic</p>
                            </div>
                        </div>
                    }

                    {
                        (dummyQuery || chatMessages?.length > 0) && 
                        <ChatMessageWrap>
                            {getMessagesLoading?<Spinner/>:
                                <>{chatMessages?.map((item:any, index:any) => {
                                    return (
                                        <div key={index} style={{display:"flex",flexDirection:"column",rowGap:'12px'}}>
                                            <MessageSentWrap>
                                                <div className="chat-text-wrap">
                                                    <p>
                                                        {item?.query}
                                                    </p>
                                                </div>
                                            </MessageSentWrap>

                                            {
                                                item?.response &&
                                                <MessageReceivedWrap>
                                                    <div className="chat-text-wrap">
                                                    <p style={{lineHeight: "1.5"}} dangerouslySetInnerHTML ={{ __html: item?.response.replace(/\n/g, '<br />') }}>
                                                            
                                                        </p>
                                                        <span onClick={()=>clipped(item?.response)}>copy!</span>
                                                    </div> 
                                                </MessageReceivedWrap>
                                            }

                                        </div>
                                    )
                                })
                            }

                            {(MssgLoop===true) &&
                                <>
                                    <MessageSentWrap>
                                        <div className="chat-text-wrap">
                                            <p>
                                                {dummyQuery}
                                            </p>
                                        </div>
                                    </MessageSentWrap>

                                    <MessageReceivedWrap>
                                        <div className="chat-text-wrap">
                                            <p dangerouslySetInnerHTML={createMarkup(chunks)}>
                                                
                                            </p>
                                         
                                        </div> 
                                    </MessageReceivedWrap>
                                </>
                            }

                            
                            {
                                (MssgLoop===false && dummyQuery) && 
                                <MessageSentWrap>
                                    <div className="chat-text-wrap">
                                        <p>
                                            {dummyQuery}
                                        </p>
                                    </div>
                                </MessageSentWrap>
                            }

                            {
                                (MssgLoop===false && dummyQuery) &&

                                <MessageReceivedWrap ref={bottomRef}>
                                    <div className="chat-text-wrap">
                                        <p className='typing'>
                                            Typing...
                                        </p>
                                    </div> 
                                </MessageReceivedWrap>
                            }

                            <div className="empty-box" ref={endRef}></div>

                            </>}
                        </ChatMessageWrap>

                       
                        }

                </div>

                {
                    convoId &&
                    <ChatQuestionBox ref={inputRef}>
                        <section ref={sectionWrapperRef} style={{height:'30px',width:'100%'}}>
                            <textarea ref={textareaRef} wrap='soft' placeholder='Ask your question...' value={chatQuery} onChange={(e:any) => changeInput(e.target.value)} onKeyDown={(e:any) => handleKeyDown(e)} />
                        </section>
                            
                        <div className="send-btn">
                            <img src={send} alt="" onClick={()=>fetchStreamOnClick(queryParams)} />
                        </div>
                    </ChatQuestionBox>
                }
            </div>

            <ChatroomFooter>
                <p>{`© Copyright ${currentYear} - Prolific Technologies`}</p>
            </ChatroomFooter>
        </div>
    </ChatroomSection>
  )
}

export default Chat
