import styled from "styled-components"

export const AddfileWrap = styled.div<{colorState?: string}>`
    background-color:${(props:any) => props?.colorState === 'white' ? "#F2F8FF" : "#25282B"} ;
    transition: background-color 0.5s ease;
    border: solid 1px #B0CBF3;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;

    .inner-file-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2em 0;

        img {

        }

        .head-text {
            color:${(props:any) => props?.colorState === 'white' ? "#1162DC" : "white"} ;
            transition: color 0.5s ease;
            margin: 0;
            font-weight: 700;
            font-size: 1rem;
            margin-top: 15px;
        }

        .direction-text {
            color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
            transition: color 0.5s ease;
            font-weight: 400;
            margin: 0;
            font-size: 0.6rem;
        }
    }
`