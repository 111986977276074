import React from 'react'
import { TabContentWrap } from '../usersettings.styled'
import { AvatarCircle, BlueBox } from '../../UsecaseSetup/step3/thirdstep.styles'

import avatar from "../../../assets/svg/ai-profile.svg"
import addCircle from "../../../assets/svg/add-circle-blue-bg.svg"
import { AvatarTabSubwrap } from './avatartab.styles'
import Input from '../../../components/input'

const AiAvatar = ({chosenProject, chatbotName, chatbotDescribe, setChatbotName, setChatbotDescribe}:any) => {
  return (
    <TabContentWrap>
        
        <h4>Edit your AI Avatar.</h4>

        {/* <AvatarTabSubwrap>
            <p className="header">Modify Avatar</p>
            <BlueBox>
                <div className="inner-blue-wrap">
                    <div className="add-circle-wrap">
                        <img src={addCircle} alt="" />
                    </div>

                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                    <AvatarCircle>
                        <img src={avatar} alt="" />
                    </AvatarCircle>
                </div>
            </BlueBox>
        </AvatarTabSubwrap> */}

        <AvatarTabSubwrap>
            <p className="header">Rename your AI</p>
            <div className="flex-wrap">
                
                <AvatarCircle textColor={"#f699cd"}>
                    <div className="circle">
                        <p>{chosenProject?.chatbotName.charAt(0)}</p>
                    </div>
                </AvatarCircle>

                <Input holder={"Julia"} inputValue={ chatbotName || chosenProject?.chatbotName} handleInputChange={setChatbotName} />
            </div>
        </AvatarTabSubwrap>

        <AvatarTabSubwrap>
            <p className="header">Description <span>{"(optional)"}</span> </p>

            <textarea rows={4} cols={20} placeholder='Select from a range of use cases that will infuse your bot with a distinctive purpose. Choose one that works best with your objectives.' value={chatbotDescribe || chosenProject?.chatbotDescription} onChange={(e:any) => setChatbotDescribe(e.target.value)}/> 
        </AvatarTabSubwrap>


    </TabContentWrap>
  )
}

export default AiAvatar