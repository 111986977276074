import React,{useEffect,useState} from 'react'
import { PromptWrap, SpanWrap, OverWrap, CancelWrap, DeleteWrap } from './deletePrompt.styles'
import Spinner from '../../spinnersvg/Spinner'
import { useDeleteProjectMutation } from '../../services/customerApi'
     

function DeleteConvo({setShowDelete,
                     selectedConvo,
                     handleDeleteConversation,
                     deleteProject,
                     deleteConversationSuccess,
                     deleteConversationLoading,
                     isLoading,
                     projectId,
                     isSuccess}:any) {


    
        const handleDeletePop = ()=>{
        
        setShowDelete(false)
}

const [showLoad,setShowLoad] = useState(false)

useEffect(()=>{
    if(isLoading||deleteConversationLoading){
        setShowLoad(true)
    }
    if(deleteConversationSuccess){
        deleteProject(projectId)
    }

   
},[deleteConversationLoading,isLoading,deleteConversationSuccess])


  return (

    <OverWrap >
    <PromptWrap>
        {showLoad?<Spinner></Spinner>:<><SpanWrap>Delete Conversation</SpanWrap>
        <span style={{fontSize:'13px'}}>Are you sure you want to delete <i>{selectedConvo?.conversationName}</i>?</span>
        <section style={{display:"flex",justifyContent:"space-around",width:"80%",margin:"15px auto"}}>
            <CancelWrap onClick={()=> handleDeletePop()} >Cancel</CancelWrap>
            <DeleteWrap onClick={()=>handleDeleteConversation(selectedConvo?.conversationUuid)}>Delete</DeleteWrap>    
        </section></>}
    </PromptWrap>

    </OverWrap>
  )
}

export default DeleteConvo