import React, {useCallback, useEffect, useState} from 'react'
import { ChatModalWrapper } from './indivdualChatCreation.styles'
import Button from '../../button'
import { EmptyBox, UploadSection, UploadedFilesField, WrapInputField,ErrorHolder } from './indivdualChatCreation.styles'
import AddFile from '../../addFile'
import Input from '../../InputNew'
import FileUpBox from '../../fileup'
import {useDropzone} from 'react-dropzone'
import analyseIcon from '../../../assets/png/analyseIcon.png'
import { useCreateProjectMutation } from '../../../services/useCaseSetupApi'
import Spinner from '../../../spinnersvg/Spinner'
import { useCreateChatMutation,useGetUserProjectQuery,useStoreLanguageMutation,useGetUseCaseQuery } from '../../../services/customerApi'
import Utils from '../../../library/Utils'
import { ErrorAbsolute } from '../../input/input.styles'
import navigateColoredIcon from '../../../assets/png/navigateBack.png'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import axios from 'axios';
import * as cheerio from 'cheerio';
import HandleConvoMigration from '../../extraFunctions/HandleConvoMigration'


const ChatCreationModal = (
                            {setStepShown, 
                            closeModal,
                            projectName, 
                            setOpenHeaderModal,
                            setProjectName,
                            setRecallChats,
                            linksUploaded,
                            setIsOpen, 
                            setLinksUploaded, 
                            filesUploaded, 
                            setFilesUploaded}:any) => {

    const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    const [filesRejected, setFilesRejected] = useState<any>([]);
    const [link, setLink] = useState("")
    const [isInputError, setIsInputError] = useState(false)
    const [projectId,setProjectId] = useState('')
    const [projectUseCase,setProjectUseCase] = useState('')
    const [showProjError,setProjError] = useState(false)
    const [createProject, 
          { data: createProjectData, 
            isLoading: createProjectLoading, 
            isSuccess: createProjectSuccess, 
            isError: isCreateProjectError, 
            error: createProjectError}] = useCreateProjectMutation();

    const [createChat, 
          { data: createChatData, 
            isLoading: createChatLoading, 
            isSuccess: createChatSuccess, 
            isError: isCreateChatError, 
            error: createChatError}] = useCreateChatMutation()

    const [storeDesiredLanguage, 
          { data: storeLangResponse, 
            isSuccess: storeLangResponseSuccess, 
            isLoading: storeLangResponseLoading, 
            isError: storeLangResponseError, 
            error: storeLangResponseErrorMessage}] = useStoreLanguageMutation()

    const {data: useCaseData, 
            isLoading: useCaseLoading, 
            isSuccess: useCaseSuccess, 
            refetch: getUseCaseRefetch, 
            isError: isUseCaseError} = useGetUseCaseQuery()

    const [showSpinner,setShowSpinner] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const [errorHold,setErrorHold] = useState<any>({data:{messsage:''}})

    const navigate = useNavigate()
    const onDrop = useCallback((acceptedFiles:any) => {
        setFilesUploaded([...filesUploaded, ...acceptedFiles]);
        setProjectName(acceptedFiles[0].name.slice(0,-4))
        setFilesRejected([]);
    }, [filesUploaded])


    const {
        getRootProps,
        getInputProps,
        open,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 
            'application/pdf': ['.pdf',],
            'text/html': ['.html', '.htm'],
            'text/csv' : ['.csv'],
            'text/plain' : ['.txt'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        },
        maxSize: 100000000,
        // filesizeBase: 1000,
        multiple: true,
        onDrop,
        // onDropRejected
    });

    const onRemoveFile = (file:any) => {
        let newFilesArray = [...filesUploaded];
        newFilesArray.splice(newFilesArray.indexOf(file), 1);
        setFilesUploaded(newFilesArray)
    }


    async function fetchPageTitle(url:any) {
        try {
            const { data } = await axios.get(url);
            const $ = cheerio.load(data);
            const title = $('title').text();
            return title;
        } catch (error) {
            console.error('Error fetching page:', error);
        }
    }

    useEffect(()=>{
        setFilesUploaded([])
        setLinksUploaded([])
    },[])

    const handleAddUrl = () => {
        const isValidURL = urlRegex.test(link);
        if (link) {
            if(isValidURL) {
                setLinksUploaded([...linksUploaded, link])
                fetchPageTitle(link)
                setLink("")
            }else {
                setIsInputError(true)

                setTimeout(() => {
                    setIsInputError(false)
                }, 4000)
            }
        }
    }

    const onRemoveUrl = (link:any) => {
        let newLinkArray = [...linksUploaded];
        newLinkArray.splice(newLinkArray.indexOf(link), 1);
        setLinksUploaded(newLinkArray)
    }

    const handleOnKeyDown = (e:any) => {
        if(link) {
            if(e.key === "Enter") handleAddUrl()
        }else {
            return
        }
    }

    const handleGotoConvo = (item:any) => {
        const params:any = {chatId: `${item?.conversationUuid}`, projectId: `${item?.projectUuid}`
                            , language:`${item?.language}` }

        navigate({
            pathname: "/individual-chatroom",
            search: `?${createSearchParams(params).toString()}`
        })
    }



    const handleCreateProject = () => {
        const randomNumber = Math.floor(Math.random() * 30000) + 1;
        if(Utils.isFieldEmpty(projectUseCase) || Utils.isFieldEmpty(projectName)){            toast.error('Something went wrong, kindly try again.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }

        
        else{
            if( !Utils.isFieldEmpty(filesUploaded) || !Utils.isFieldEmpty(linksUploaded)){

                if(!showSpinner){

                    let formData: any = new FormData()
        
                    formData.append("usecaseKey", projectUseCase )
                    formData.append("projectName", `${projectName}${randomNumber}` )
                    formData.append("password", '')
                    formData.append("chatbotName", projectName)
                    formData.append("chatbotDescription", '')
                    formData.append("companyName", '' )
                    formData.append("customerNum", '' )
                    filesUploaded.forEach((file:any) => {
                        formData.append('files', file);
                    })
                    linksUploaded.forEach((file:any) => {
                        formData.append('urls', file);
                    })
                    createProject(formData)
                    setShowSpinner(true)
                }
              
            }

            else{
                toast.info('Fill in necessary fields.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }

    }
    }


    const handleCreateChat = () => {
        const params:any = {
            conversationName: projectName,
            projectUuid: projectId,
        }

        createChat(params)
    }

/**
 *     useEffect(() => {
        if(createProjectSuccess){
            refetch()
        }
    }, [data,createProjectSuccess])
 */

    useEffect(()=>{
        if(useCaseSuccess && useCaseData){
            useCaseData.forEach((element:any) => {
                if(element.name === 'General'){
                    setProjectUseCase(element.key)
                }
            });

        }
    },[useCaseSuccess,useCaseData])

    useEffect(()=>{
        if(createProjectSuccess ){
            const params:any = {
                conversationName: projectName,
                projectUuid: createProjectData.uuid,
            }
    
            createChat(params)
            
        }
       
    },[createProjectSuccess])

    useEffect(()=>{
        if(createChatSuccess){
            let languageStore = {language:'English',conversationUuid:createChatData.uuid}
            storeDesiredLanguage(languageStore)

        }

        if(storeLangResponseSuccess){
            const item = {language:'English',
                          conversationUuid: createChatData.uuid,
                          projectUuid:createProjectData.uuid,
                        }
            setFilesUploaded([])
            setProjectName('')
            setShowSpinner(false)
            setIsOpen(false)
            setRecallChats(true)

            if(width<992){
                setOpenHeaderModal(false)
            }

            HandleConvoMigration(item,navigate)
        }

        if(isCreateChatError || storeLangResponseError || isCreateProjectError){
            setShowSpinner(false)
            if(isCreateProjectError && createProjectError){
                if('status' in createProjectError && createProjectError.status !== 400){
                    setProjError(true)
                }
                else{
                    setErrorHold(createProjectError)
                }
            }
            else if(isCreateChatError){
                setErrorHold(createChatError)
            }

            else{
                setErrorHold(storeLangResponseErrorMessage)
            }
        }
    },[createChatSuccess,width,storeLangResponseSuccess,
      isCreateChatError,storeLangResponseError,isCreateProjectError])



    useEffect(() => {
        // Function to update state
        const handleResize = () => {
          setWidth(window.innerWidth);
          
        };
    
        // Add event listener for resize events
        window.addEventListener('resize', handleResize);
    
    
        // Cleanup function to remove event listener
        return () => window.removeEventListener('resize', handleResize);
      }, []); 

    const onCancelProject = ()=>{
        setFilesUploaded([])
        setProjectName('')
        setErrorHold({data:{messsage:''}})
        setIsOpen(false)
        setRecallChats(true)
        setProjError(false)

            if(width<992){
                setOpenHeaderModal(false)
            }

    }

    const refresh = ()=>{
        setFilesUploaded([])
        setProjectName('')
        setErrorHold({data:{messsage:''}})
        setProjError(false)
    }

  return (
    <ChatModalWrapper colorState={backgroundMode.color}>
        <section className='backButton' 
            onClick=
                {()=>{setFilesUploaded([])
                    setProjectName('')
                    setOpenHeaderModal(false);
                    closeModal()}}>
            <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                <img src={navigateColoredIcon}/>
            </span>
            <span 
                style=
                    {{display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    color:'#1162DC',
                    fontSize:"14px",
                    fontWeight:"600"}}
                    >
                Back
            </span>
        </section>
        <div className="text-wrap">

            <h3 className="topic-header">
                Upload your files
            </h3>

        </div>

        <UploadSection>
            <div  {...getRootProps()} onClick={open}>
                <input  {...getInputProps()} />
                <AddFile />
            </div>

            <WrapInputField colorState={backgroundMode.color}>
                <label htmlFor="">Or upload from a URL</label>
                <div className={isInputError ? "url-input-wrap input-error" : "url-input-wrap"}>
                    <input type="text" className="url-input" placeholder='https://www.example.com' 
                        onChange=
                            {
                                (e:any) => setLink(e.target.value)
                            } 
                        onKeyDown=
                            {
                                (e:any) => handleOnKeyDown(e)
                            } 
                        value={link} />
                        {
                            link ?  
                            <button className="url-btn" 
                                onClick={handleAddUrl}
                            >
                                Add url
                            </button> : 
                            <div className={"empty-box"}></div>
                        }

                    {
                        isInputError && 
                        <ErrorAbsolute>Please type in a valid url</ErrorAbsolute>
                    }
                </div>

                <div className="flex-wrap">
                    {
                        linksUploaded?.map((item:any, id:any) => {
                            return (
                                <FileUpBox 
                                    key={id}
                                    type={"url"}
                                    onRemove={() => onRemoveUrl(item)}
                                    urlName={item}
                                />
                            )
                        })
                    }
                </div>
            </WrapInputField>


            {
                filesUploaded?.length > 0 ?
                <UploadedFilesField colorState={backgroundMode.color}>
                    <p >Uploaded files</p>

                    <div className="files-flex-wrap">
                        {
                            filesUploaded?.map((item:any, index:any) => {
                                return (
                                    <FileUpBox 
                                        key={index}
                                        onRemove={() => onRemoveFile(item)}
                                        fileName={item?.name}
                                    />
                                )
                            })
                        }
                    </div>
                </UploadedFilesField> :
                <EmptyBox />
            }

            <WrapInputField>
                <label htmlFor="">Give this Chat a name (all data uploaded here belongs to this Chat) </label>
                <Input holder="My Business Development project" 
                    handleInputChange={setProjectName} 
                    inputValue={projectName} />
            </WrapInputField>
        </UploadSection>


        <div style={{margin:'20px 0px',display:"none"}}>
            <Spinner/>
        </div>
        
        <ErrorHolder error={errorHold} showProjError={showProjError}>
            <p className='errorMsg'>{showProjError?'Document Analysis timed-out.':errorHold.data.message}</p>
            <div className='navButtons'>
                <span onClick={()=>onCancelProject()} className='cancel'>Cancel Project</span>
                <span onClick={()=>refresh()} className='restart'>Refresh</span>
            </div>
        </ErrorHolder>
        

        <div className="steps-footer-box">
           <section 
                onClick=
                    {
                        ()=>{handleCreateProject()}
                    } 
                style=
                    {{backgroundColor:(Utils.isFieldEmpty(projectName) || showSpinner)?'#6096E8':'#1162DC',
                    height:'40px',
                    display:"flex",
                    alignItems:"Center",
                    columnGap:"10px",
                    color:'white',
                    cursor:'pointer',
                    justifyContent:"Center",
                    width:"100%"}}
            >
                <span style=
                            {{display:"flex",
                            justifyContent:"center",
                            alignItems:"center"}}
                >
                    <img src={analyseIcon} 
                        style=
                            {{display:"flex",
                            justifyContent:"center",
                            alignItems:"center"}}
                    />
                </span>
                <span style={{display:"flex",
                              justifyContent:"center",
                              alignItems:"center",
                              }}>
                    {showSpinner?'Analysing...':'Analyse'}
                </span>
           </section>
        </div>
    </ChatModalWrapper>
  )
}

export default ChatCreationModal