import React, { useEffect, useState } from 'react'
import { CheckpointsSubwrap, CheckpointsWrap, MobileCheckpointWrap,MobileCheckpoint, MobileFooterWrap, RightSetupWrap, SetupWrap, WaitingWhiteBox, MobileCheckpointList, MobileSetupHeader } from './usecase.styles'
import Modal from 'react-modal';

import logo from "../../assets/svg/prolific-logo.svg"
import SubHeadBox from '../../components/subHeaderBox'
import FirstStep from './step1'
import SecondStep from './step2'
import ThirdStep from './step3'
import loader from "../../assets/svg/loader.svg"
import { AvatarCircle } from './step3/thirdstep.styles';
import { useCreateProjectMutation, useGetUseCasesQuery } from '../../services/useCaseSetupApi';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, RootState } from '../../store';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../../spinnersvg/Spinner';

const UsecaseSetup = () => {
    const navigate = useNavigate()
    const [steps, setSteps] = useState("first")
    const [casesReceived, setCasesReceived] = useState<any>([])
    const [chosenCase, setChosenCase] = useState<any>()
    const [filesUploaded, setFilesUploaded] = useState<any>([])
    const [linksUploaded, setLinksUploaded] = useState<any>([])
    const [knowledgeBaseName, setKnowledgeBaseName] = useState("")
    const [aiName, setAiName] = useState("")
    const [description, setDescription] = useState("")
    const [chosenIcon, setChosenIcon] = useState()
    const [companyName, setCompanyName] = useState("")
    const [customerNum, setCustomerNum] = useState("")

    const [createProject, {data: createProjectData, isLoading: createProjectLoading, isSuccess: createProjectSuccess, isError: isCreateProjectError, error: createProjectError}] = useCreateProjectMutation();
    
    const {data, isLoading, isSuccess, refetch, isError} = useGetUseCasesQuery()

    const {count} = useAppSelector<any>((store:RootState) => store?.auth?.auth)

    const customStyles = {
        content: {
        transition: '0.125s ease-in-out',
        transform: 'translate(-50%, -50%)',
        height: '33%',
        width: 'unset',
        top: '50%',
        left: '50%',
        padding: '0 0 0 0',
        borderRadius: '16px',
        display: 'flex',
        justifyContent: 'center',
        alignCenter: 'center',
        border: 'none',
        },
        overlay: {
        zIndex: 1000,
        position: 'fixed',
        backgroundColor: 'rgba(0,0,0,0.7)'
        }
    };

    // USEFFECT TO FETCH USECASES
    useEffect(() => {
        refetch()
    }, [data, isSuccess])

    useEffect(() => {
        if(isSuccess) {
            setCasesReceived(data)
        }
    }, [isSuccess, data, isLoading, isError])

    // USEEFFECT TO CREATE PROJECT
    useEffect(() => {
        if(createProjectSuccess) {
            navigate("/new-chat")
        }

        if(isCreateProjectError && 'status' in createProjectError!) {
            notifyError(createProjectError?.data?.message)
        }

        if(isCreateProjectError && createProjectError && 'status' in createProjectError && 
            typeof createProjectError.status === 'string' &&
            createProjectError?.status === 'FETCH_ERROR'){
                console.log(createProjectError)
                navigate("/new-chat")
        }
        
    }, [createProjectData, createProjectLoading, createProjectSuccess, createProjectError, isCreateProjectError])

    const handleCreateProject = () => {
        let formData: any = new FormData()

        formData.append("usecaseKey", chosenCase?.key )
        formData.append("projectName", knowledgeBaseName )
        formData.append("password", '')
        formData.append("chatbotName", aiName)
        formData.append("chatbotDescription", description)
        formData.append("companyName", companyName )
        formData.append("customerNum", customerNum )
        filesUploaded.forEach((file:any) => {
            formData.append('files', file);
        })
        linksUploaded.forEach((file:any) => {
            formData.append('urls', file);
        })

        createProject(formData)
    }

    const notifyError = (text:string) => toast.error (text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

  return (
    <SetupWrap>
        <ToastContainer />

        <Modal isOpen={createProjectLoading ? true : false} style={customStyles}>
            <WaitingWhiteBox>
                <AvatarCircle textColor={"#f699cd"}>
                    <div className="circle">
                        <p>{aiName.charAt(0)}</p>
                    </div>
                </AvatarCircle>

                <h3>
                    {aiName} is analyzing your data
                </h3>

                {/* <img src={loader} alt="" /> */}

                <Spinner></Spinner>
            </WaitingWhiteBox>
        </Modal>


        <MobileSetupHeader>
            <img src={logo} alt="" />
        </MobileSetupHeader>
        <div className="inner-setup-wrap">

            <div className="left-setup-wrap">
                <div className="header-wrap">
                    <img src={logo} onClick={() => navigate("/")}/>
                </div>

                <div className="checkpoints-wrap">
                    <SubHeadBox title={'How to use'} isBorder={true} />

                    <CheckpointsWrap>
                        <CheckpointsSubwrap active={steps === "first" ? true : false}>
                            <div className="circle-ball"></div>

                            <p className="checkpoint-text">Select use case</p>
                        </CheckpointsSubwrap>

                        <CheckpointsSubwrap active={steps === "second" ? true : false}>
                            <div className="circle-ball"></div>

                            <p className="checkpoint-text">Upload documents to query from or provide a website you want to read from</p>
                        </CheckpointsSubwrap>

                        <CheckpointsSubwrap active={steps === "third" ? true : false}>
                            <div className="circle-ball"></div>

                            <p className="checkpoint-text">Customize your bot</p>
                        </CheckpointsSubwrap>

                        <CheckpointsSubwrap className='start-convo'>
                            <div className="circle-ball"></div>

                            <p className="checkpoint-text">Start your conversation</p>
                        </CheckpointsSubwrap>
                    </CheckpointsWrap>
                </div>

                <div className="footer-wrap">
                    <p>© Copyright 2023 - Prolific Technologies</p>
                </div>
            </div>


            <RightSetupWrap>
                <MobileCheckpointWrap>
                    <SubHeadBox title={'How to use'} isBorder={true} />

                    <MobileCheckpoint>
                        <MobileCheckpointList active={steps === "first" ? true : false}>
                            Select
                        </MobileCheckpointList>

                        <MobileCheckpointList active={steps === "second" ? true : false}>
                            Upload
                        </MobileCheckpointList>

                        <MobileCheckpointList active={steps === "third" ? true : false}>
                            Customize
                        </MobileCheckpointList>

                        <MobileCheckpointList>
                            Start
                        </MobileCheckpointList>
                    </MobileCheckpoint>

                </MobileCheckpointWrap>

                <div className="inner-right-section">
                    {
                        steps === "first" &&
                        <FirstStep setStepShown={setSteps} useCaseList={casesReceived} chosenCase={chosenCase} setChosenCase={setChosenCase} count={count} customerNum={customerNum} setCustomerNum={setCustomerNum} companyName={companyName} setCompanyName={setCompanyName} />
                    }

                    {
                        steps === "second" &&
                        <SecondStep  setStepShown={setSteps} projectName={knowledgeBaseName} setProjectName={setKnowledgeBaseName} filesUploaded={filesUploaded} setFilesUploaded={setFilesUploaded} linksUploaded={linksUploaded} setLinksUploaded={setLinksUploaded} />
                    }

                    {
                        steps === "third" &&

                        <ThirdStep setStepShown={setSteps} description={description} setDescription={setDescription} aiName={aiName} setAiName={setAiName} chosenIcon={chosenIcon} setChosenIcon={setChosenIcon} handleCreateProject={handleCreateProject}  />
                    }


                </div>

                <MobileFooterWrap>
                    <p>© Copyright 2023 - Prolific Technologies</p>
                </MobileFooterWrap>
            </RightSetupWrap>
        </div>
    </SetupWrap>
  )
}

export default UsecaseSetup