import RectangleBlur from "../../assets/png/Rectangle.webp";

type PublicLayoutProps = {
  children: React.ReactNode;
};

const PublicLayout = ({ children }: PublicLayoutProps) => {
  return (
    <div
      style={{
        height: "100%",
        backgroundRepeat: " no-repeat",
        backgroundSize: "cover",
        position: "relative",
        background:'linear-gradient(to right, #37508A 0%, #37508A 10%, #784EAD 50%, #784EAD 75%, #252E3B 100%)',
        overflow:"auto",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
      }}
    >

      {children}
    </div>
  );
};

export default PublicLayout;
