import React from 'react'
import { SubHeadWrap } from './subheadbox.styles'

const SubHeadBox = ({title, isBorder}:any) => {
  return (
    <>
        <SubHeadWrap isBorder={isBorder}>
            {title}
        </SubHeadWrap>
    </>
  )
}

export default SubHeadBox