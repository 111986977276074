import React, { useEffect, useState } from 'react'
import { FreePlan, PaidPlan } from './infobar.styles'

import arrow from "../../../assets/svg/arrow-right-purple.svg"
import { useNavigate } from 'react-router-dom'
import { useGetCurrentPlanQuery } from '../../../services/customerApi'

const InfoBar = () => {
    const navigate = useNavigate()
    const [planInfo, setPlanInfo] = useState<any>([])


    const {data, isLoading, isSuccess, isError, refetch} = useGetCurrentPlanQuery()

    useEffect(() => {
        refetch()
    }, [data])

    useEffect(() => {
        if(isSuccess) {
            setPlanInfo(data)
        }
    }, [data, isSuccess, isLoading, isError])

  return (
    <>
        {
            planInfo?.length === 0 ? 
            <FreePlan>
                <div className="btn-plan-wrap">
                    Free Access
                </div>

                <p>
                You currently have free access to <span> 1 chatbot and 50 messages.</span>  
                </p>

                <div className="pay-plan-link" onClick={() => navigate("/pay-subscription-plan")}>
                    Tap here to pay for your plan

                    <img src={arrow} alt="" />
                </div>
            </FreePlan> :

            <PaidPlan>
            <div className="btn-plan-wrap">
                {planInfo?.name} plan
            </div>

            <p>
            You currently have access to <span> {planInfo?.totalChatbots} chatbot and {planInfo?.totalMessages} messages.</span>  
            </p>

            <div className="pay-plan-link" onClick={() => navigate("/pay-subscription-plan")}>
                Tap here to pay for your plan

                <img src={arrow} alt="" />
            </div>
            </PaidPlan>
        }

    </>
  )
}

export default InfoBar