import React, { useCallback, useEffect, useState } from 'react'
import { TabContentWrap } from '../usersettings.styled'
import { KnowledgeBaseWrap, Projectname } from './knowledgebase.styles'

import file from "../../../assets/svg/blue-file.svg"
import Button from '../../../components/button'
import add from "../../../assets/svg/add-circle-blue.svg"
import FileUpBox from '../../../components/fileup'
import Input from '../../../components/input'
import {useDropzone} from 'react-dropzone'


const KnowledgeBase = ({projectList, chosenProject, handleDeleteProjectUrl, handleDeleteProjectFile, filesSelected, setFilesSelected, urlSelected, setUrlSelected, handleUpdateUrl}: any) => {
  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
  const [filesRejected, setFilesRejected] = useState<any>([]);
  const [isInputError, setIsInputError] = useState(false)

  // USEEFFECT FOR PROJECT LIST
  useEffect(() => {

  }, [projectList])

  const onDrop = useCallback((acceptedFiles:any) => {
    console.log(acceptedFiles)
    setFilesSelected([...filesSelected, ...acceptedFiles]);
    setFilesRejected([]);
  }, [filesSelected])

  const {
      getRootProps,
      getInputProps,
      open,
      isDragActive,
      isDragAccept,
      isDragReject
  } = useDropzone({
      accept: { 
        'application/pdf': ['.pdf',],
        'text/html': ['.html', '.htm'],
        'text/csv' : ['.csv'],
        'text/plain' : ['.txt'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      },
      maxSize: 100000000,
      // filesizeBase: 1000,
      multiple: true,
      onDrop,
      // onDropRejected
  });

  const onRemoveFile = (file:any) => {
      let newFilesArray = [...filesSelected];
      newFilesArray.splice(newFilesArray.indexOf(file), 1);
      setFilesSelected(newFilesArray);
  }

  const handleUrlCheck = () => {
    const isValidURL = urlRegex.test(urlSelected);

    if(isValidURL) {
      handleUpdateUrl()
    }else {
      setIsInputError(true)

      setTimeout(() => {
          setIsInputError(false)
      }, 4000)
    }
  }

  return (
    <TabContentWrap>
        <h4>Edit the knowledge base of your projects.</h4>
        <KnowledgeBaseWrap>
          <div className="inner-knowledge-wrap">
            <div className="projects-wrap">
              <Projectname>
                <img src={file} alt="" />
                {chosenProject?.name}
              </Projectname>
            </div>

            <div className="uploaded-files-wrap">
              <p>Uploaded files</p>

              <div className="doc-wraps">
                {
                  chosenProject?.files?.map((item:any, index:any) => {
                      return (
                          <FileUpBox 
                              fileName={item}
                              key={index}
                              onRemove={() => handleDeleteProjectFile(item)}
                          />
                      )
                  })
                }
              </div>

              <div className="button-add-box">
                <div {...getRootProps()} onClick={open}>
                  <input {...getInputProps()} />
                  <Button text={"Add document"} btnType={"add"} icon={add} />
                </div>
              </div>
            </div>

            <div className="uploaded-files-wrap no-border">
              <p>Uploaded URLs</p>

              <div className="doc-wraps">
                {
                  chosenProject?.urls?.map((item:any, index:any) => {
                    return (
                      <FileUpBox 
                        key={index}
                        type={"url"} 
                        urlName={item}
                        onRemove={() => handleDeleteProjectUrl(item)}
                      />  
                    )
                  })
                }              
              </div>

              <div className="add-more-url-wrap">
                <div className="head-text">
                  Add more URLs
                </div>

                <div className="flex-wrap">
                  <Input holder={"https://www.example.com"} value={urlSelected} handleInputChange={setUrlSelected} inputerror={isInputError} />

                  <Button text={"Add URL"} btnType={"add"} icon={add}  onClick={handleUrlCheck}/>
                </div>
              </div>
            </div>
          </div>
        </KnowledgeBaseWrap>
    </TabContentWrap>
  )
}

export default KnowledgeBase