import React from 'react'
import { BackgrounWrap, CheckoutFormOverall } from './checkout.styles'
import Giftcard from '../../components/giftcard'
import Button from '../../components/button'
import Input from '../../components/input'

import logo from "../../assets/svg/logo-white.svg"
import cards from "../../assets/svg/cards.svg"
import paystack from "../../assets/svg/paystack.svg"
import shield from "../../assets/svg/shield-security.svg"
import { useNavigate } from 'react-router-dom'



const Checkout = () => {
    const navigate = useNavigate()

  return (
    <BackgrounWrap>
        <div className="inner-wrap-box">
            <div className="mobile-header">
                <img src={logo} alt=""  onClick={() => navigate("/")}/>
            </div>

            <div className="left-wrap">
                <div className="header-wrap">
                    <img src={logo} alt=""  onClick={() => navigate("/")}/>
                </div>

                <div className="center-align-box">
                    <h2>7 days free trial!</h2>

                    <p className='subhead-text'>You can always cancel your subscription.</p>

                    <Giftcard 
                        accessType={"GROWTH"}
                    />
                </div>

                <div className="footed-wrap">
                    <p>© Copyright 2023 - Prolific Technologies Limited || All Rights Reserved</p>
                </div>
            </div>

            <CheckoutFormOverall>
                <div className="form-wrap">
                    <div className="back-header">
                        <Button text={'Back'} btnType={'back-btn'} onClick={() => navigate("/")} />
                    </div>
                    <div className="form-header">
                        <h3>Enter payment details</h3>
                    </div>

                    <form>
                        <Input styleType={"inline-flex"} holder={'example@gmail.com'} type={'email'}/>

                        <div className="card-info-wrap">
                            <label htmlFor="">Card information</label>

                            <div className="card-input-wrap">
                                <input type="text" placeholder='1234 1234 1234 1234' />

                                <div className="card-types">
                                    <img src={cards} alt="" />
                                </div>
                            </div>

                            <div className="flex-card-wrap">
                                <Input type={'date'} holder={'MM / YY'} />
                                <Input type={'text'} holder={"CVC"}  />
                            </div>
                        </div>

                        <div className="sub-input">
                            <label htmlFor="">Name on card</label>
                            <Input type={'text'} holder={"Enter the name on your card"} />
                        </div>

                        <div className="sub-input">
                            <label htmlFor="">Country or region</label>
                            <Input type={'select'} />
                        </div>

                        <Button text={"Subscribe"} btnType={'checkout'} onClick={() => navigate("/auth")} />
                    </form>

                    <div className="subscription-info">
                    By confirming your subscription, you allow Prolific to charge your card for this payment and future payments in accordance with their terms. 
                    </div>

                    <div className="checkout-footer">
                        <img src={shield} />
                        <p className="pricing-footer-text">Payment secured by</p>
                        <img src={paystack} />
                    </div>
                </div>
            </CheckoutFormOverall>

            <div className="mobile-footer">
                <p>© Copyright 2023 - Prolific Technologies Limited || All Rights Reserved</p>
            </div>
        </div>
    </BackgrounWrap>
  )
}

export default Checkout