import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

declare global {
    interface Window { 
        _jsenv: any;
    }
}

const env = window._jsenv || process.env
const baseUrl = env.REACT_APP_API_BASE_URL;

export const useCaseSetupApi = createApi({
    reducerPath: 'useCaseSetupApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token?.access_token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}` );
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    endpoints: builder => ({
        createProject: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: '/project/create',
                    method: 'post',
                    body: value
                };
            },
        }),
        getUseCases: builder.query<any, void>({
            query: () => {
                return {
                    url: '/usecase',
                };
            }
        }),
    }),
});

export const {
    useCreateProjectMutation,
    useGetUseCasesQuery,
} = useCaseSetupApi;