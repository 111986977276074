import styled from "styled-components"

export const ProjectsOverall = styled.div `
    width: 100%;
    height: 450px;

    .header-text-wrap {
        padding-left: 40px;
        
        h3 {
            font-weight: 400;
            font-size: 0.8rem;
            color: #062149;
        }
    }

    .flex-column-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        row-gap: 13px;
        padding: 15px 40px 0px 40px;
    }

    .project-flex-wrap {
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr; 
        grid-template-rows: 50px;
        padding-right: 10px;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 13px;
        height: 250px;
        scroll-snap-type: y proximity;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing:border-box;
        padding:10px 10px 10px 10px;

        &::-webkit-scrollbar {
            width: 10px;
            background: #F3F8FF;
            border-radius: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: rgba(17, 98, 220, 1);
            border-radius: 10px;
        }
    }
`

export const ProjectOptions = styled.div<{active?: boolean}> `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-radius: 4px;
    border: ${(props:any) => props?.active ? "solid 1px #1162DC" : "1px solid #D1E4FF"} ;
    background-color: ${(props:any) => props?.active ? "#DDEBFF" : "#FFF"};
    cursor: pointer;
    height:55px;
    box-sizing:border-box;

    .left-side {
        display: flex;
        column-gap: 10px;

        img-wrap {
            img {
    
            }
        }
    
        .txt-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            h5 {
                margin: 0;
                color: #062149;
                font-weight: 500;
                font-size: 0.7rem;
            }
    
            p {
                margin: 0;
                color: #4C5666;
                font-weight: 400;
                font-size: 0.5rem;
            }
        }
    }

    .radio-wrap {

    }
`

export const ProjectNameWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid var(--neutral-300, #D4D4D4);
    background: var(--generic-white, #FFF);
    column-gap: 10px;
    padding: 5px;
    margin-top: 20px;

    &:has(> input:focus) {
        border: solid 1.5px #062149;
    }

    input {
        border: none;
        outline: none;
        width: 100%;
        font-size: 0.7rem;
    }
`