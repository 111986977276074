import React , {useState,useEffect} from 'react'
import { IntroChatHold } from './introChat.styles'
import Button from '../../../components/button'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux/es/hooks/useSelector'


function IntroChat({setIsOpen,setOpenHeaderModal}:any) {
  const navigate = useNavigate()
  const [width, setWidth] = useState(window.innerWidth);
  const backgroundMode = useSelector((state: RootState) => state.backgroundMode)


  useEffect(() => {
    // Function to update state
    const handleResize = () => {
      setWidth(window.innerWidth);
      
    };

    // Add event listener for resize events
    window.addEventListener('resize', handleResize);


    // Cleanup function to remove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); 


  return (
    <IntroChatHold colorState={backgroundMode.color}>
        <div className='createChatInfo'>
          <section className='introTitle'>
            Chat with your documents
          </section>

          <section className='introChat'>
            Upload a document here
          </section>

          <Button
            btnType={'new-chat'}
            text={'New Chat'}
            onClick={() => {width>992 ? setIsOpen(true):setOpenHeaderModal(true)}}
          />
        </div>
    </IntroChatHold>
  )
}

export default IntroChat