import styled from "styled-components"

export const SettingsWrapper = styled.div `
    background-color: #F3F8FF;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    margin-left:280px;

    @media only screen and (max-width: 992px) {
        background-color: #fff;
        margin-left:0px;
        
    }

    @media only screen and (max-width: 506px) {
        margin-left:0px;
    }
`

export const SettingsContent = styled.div<{colorState?: string}>`
    width: calc(100% );
    height: calc(100vh-80px);
    position: relative;
    background-color:${(props:any) => props?.colorState === 'white' ? "#F3F8FF" : "black"} ;
    transition: background-color 0.5s ease;
    display:flex;
    justify-content:space-around;
    align-items:top;
    margin-top:80px;
    padding-top:50px;

    .containerOne{
        height:45%;
        width:20%;
        padding:5px 10px;
        background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#1B1C1C"} ;
        box-sizing:border-box;
          border-radius:10px;

        .optionHold{
            
        }
    }

    .containerTwo{
        height:70%;
        width:70%;
    }

    `

    export const OptionsHold = styled.div<{colorState?: string,
                                           fieldState?: string,
                                           selectedState?: string
                                          }>
            `
                background-color:${(props:any) => props?.fieldState === props?.selectedState ? "#CBDAFF" : "transparent"} ;
                display:flex;
                margin-top:10px;
                justify-content:left;
                column-gap:10px;
                padding:5px;
                align-items:center;
                color:${(props:any) => props?.colorState === 'white' ? "#292D32" : "#DEDEDE"} ;
                font-weight:${(props:any) => props?.fieldState === props?.selectedState ? "700" : "400"} ;
                cursor:pointer;

            `

            export const OptionsHoldBg = styled.div<{colorState?: string,
                                                        fieldState?: string,
                                                        selectedState?: string
                                                    }>
                                        `
                                        background-color:${(props:any) => props?.fieldState === props?.selectedState ? "#292D32" : "transparent"} ;
                                        display:flex;
                                        margin-top:10px;
                                        justify-content:left;
                                        column-gap:10px;
                                        padding:5px;
                                        align-items:center;
                                        color:${(props:any) => props?.colorState === 'white' ? "#292D32" : "#DEDEDE"} ;
                                        font-weight:${(props:any) => props?.fieldState === props?.selectedState ? "700" : "400"} ;
                                        cursor:pointer;

                                        `

            export const SettingsFields = styled.div<{colorState?: string,
                fieldState?: string,
                selectedState?: string
               }>
                    `
                    height:80%;
                    width:70%;
                    display:flex;
                    flex-direction:column;
                    justify-content:space-between;
                    

                    .settingsTop{
                        width:100%;
                        height:25%;
                        background-color:${(props:any) => props?.colorState === 'white' ? "#FFFFFF" : "#1B1C1C"} ;
                        padding:15px;
                        box-sizing:border-box;
                        border-radius:10px;

                        .innerHold{
                            display:flex;
                            height:100%;
                            column-gap:15px;
                            align-items:center;
                            justify-content:space-between;

                            .LHS{
                                display:flex;
                                height:100%;
                                column-gap:15px;

                                .namesHolder{
                                    display:flex;
                                    flex-direction:column;
                                    height:100%;
                                    justify-content:space-around;

                                    .Name{
                                        color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
                                        font-weight:700;
                                        font-size:20px;
                                    }

                                    .Email{
                                        color:${(props:any) => props?.colorState === 'white' ? "#6A717D" : "white"};
                                        font-size:18px;
                                    }
                                }
                            }

                            .RHS{
                                display:flex;
                                justify-content:space-between;
                                column-gap:15px;
                                height:90px;
                                background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#1B1C1C"};

                                .titleHold{
                                    color:${(props:any) => props?.colorState === 'white' ? "#062149" : "#DEDEDE"};
                                    font-weight:700;
                                    font-size:20px;
                                    text-align:center;
                                }
                                
                                .valueHold{
                                    color:${(props:any) => props?.colorState === 'white' ? "#062149" : "#DEDEDE"}; 
                                    font-weight:400;
                                    font-size:14px;
                                    text-align:center;
                                }
                            }
                        }
                    }

                    .settingsBottom{
                    
                        width:100%;
                        height: 450px;
                        background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#1B1C1C"}; 
                        padding:15px;
                        box-sizing: border-box;
                        display:flex;
                        justify-content:space-around;
                        flex-direction:column;
                        margin-top:15px;
                        border-radius:10px;


                        .Edit{
                            color:${(props:any) => props?.colorState === 'white' ? "#407BFF" : "white"};
                            font-weight:600;
                            cursor:pointer;
                        }
                    }

                    `