import styled, {css} from "styled-components"



export const OverWrap = styled.div <{colorState?: string}>`
    position: absolute;
    z-index:100000;
    top: 0px;
    right: 0px;
    Webkit-backdrop-filter:blur(4px);
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    background-color:  ${(props:any) => props?.colorState === 'white' ?'#FFFFFF':'#343434'};
    
    
`


export const PromptWrap = styled.div <{colorState?: string}>`
padding:15px;
box-sizing:border-box;

.titleHolders{
    .top{
        color: ${(props:any) => props?.colorState === 'white' ?'#062149':'#FFFFFF'};
        font-weight:700;
        font-size:24px;

    }
    .bottom{
        color: ${(props:any) => props?.colorState === 'white' ?'#062149':'#FFFFFF'};
        font-weight:400;
        font-size:16px;
    }
}
`

export const CancelWrap = styled.span`
width:120px;
cursor:pointer;
height:45px;
display:flex;
align-items:center;
justify-content:center;
background-color: white;
border-radius:5px;
border:1px solid grey;
transition: background-color 1s;


&:hover {
    background-color: grey;
    color: white;

}
`


export const EditWrap = styled.span`
width:120px;
cursor:pointer;
height:45px;
display:flex;
align-items:center;
justify-content:center;
color:white;
background-color: #1162DC;
border-radius:5px;
transition: background-color 0.3s;


&:hover {
    background-color: white;
    color: #1162DC;
    border: 1px solid #1162DC;

}
`



export const SpanWrap = styled.span`
    font-weight: 700;
    font-size: 15px
`