import React,{useState} from 'react'
import { SettingsMobileContent } from './settingsmobile.styles'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import userProfileIcon from '../../../../assets/png/userProfileIcon.svg'
import navigateBackIcon from '../../../../assets/png/navigateBack.png'

import userProfileBlackBg from '../../../../assets/png/profileUpdateBlackBg.png'
import changePassBlackBgIcon from '../../../../assets/png/passwordIconBlackBg.png'
import changePassIcon from '../../../../assets/png/changePasswordIcon.png'
import billingIcon from '../../../../assets/png/billingIcon.png'
import billingBlackBgIcon from '../../../../assets/png/billingBlackBg.png'
import copyBlackBgIcon from '../../../../assets/png/copyBlackBg.png'
import copyIcon from '../../../../assets/png/copyWhiteBg.png'
import {RootState} from '../../../../store'
import EditUserMobileForm from './MobileForm/EditUserMobileForm'
import profileDelRed from '../../../../assets/svg/profile-delete-red.svg'


function SettingsMobile({userData,userDocCount}:any) {
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const [optionSelected,setOptionSelect] = useState('')

    const navList = [{imgLight:userProfileIcon,
                      imgBlack:userProfileBlackBg,
                      text:'User Profile',
                      click:'profile'
                    },
                    {imgLight:changePassIcon,
                        imgBlack:changePassBlackBgIcon,
                        text:'Change Password',
                        click:'password'
                      },
                      {imgLight:billingIcon,
                        imgBlack:billingBlackBgIcon,
                        text:'Billing',
                        click:'billing'
                      },
                      {imgLight:profileDelRed,
                        imgBlack:profileDelRed,
                        text:'Delete Profile',
                        click:'delete'
                      }]

    return (
        <SettingsMobileContent colorState={backgroundMode.color}>
            {optionSelected==='profile' &&  
            <span onClick={()=>setOptionSelect('')} style={{height:"30px",
                          width:"100%",
                          paddingLeft:"10px",
                          boxSizing:"border-box",
                          display:"flex",
                          alignItems:"center",
                          justifyContent:"start",
                          columnGap:"10px"}}>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <img src={navigateBackIcon}/>
                </span>
                <span style={{display:"flex",alignItems:"center",justifyContent:"center",color:"#1162DC"}}>
                    Back
                </span>
            </span>}

            {optionSelected==='profile'&&
            <p style={{paddingLeft:"10px",
                        fontWeight:"600",
                        color:backgroundMode.color==='white'?'black':'#FFFFFF'}}>User Profile</p>}

            <section style={{height:optionSelected==='profile'?"85%":"100%"}}>
            
            {optionSelected==='profile'?
            <EditUserMobileForm userData={userData}/>
            :<section style={{width:"100%",height:"100%",
                                padding:'15px',
                                boxSizing:"border-box"}}>
                    <p style={{color:backgroundMode.color==='white'?'#062149':'white',fontWeight:'600',textAlign:"center",fontSize:"18px"}}>My Profile</p>
                    <section style={{margin:"0px auto",height:"70px",width:"70px"}}>
                            <div style={{backgroundColor:"#d3d3d3",
                                        height:"100%",
                                        width:"100%",
                                        fontWeight:"700",
                                        color:"black",
                                        borderRadius:"50%",
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        fontSize:"20px"}}>
                                {userData.firstName[0].toUpperCase()}{userData.lastName[0].toUpperCase()}
                            </div>
                        </section>
                        
                        <div style={{display:'flex',flexDirection:'column',rowGap:"10px",justifyContent:"space-around"}}>
                            <span style={{color:backgroundMode.color==='white'?'#062149':'white',fontWeight:"700",fontSize:"20px",textAlign:"center"}}>{userData.firstName} {userData.lastName}</span>
                            <span style={{color:backgroundMode.color==='white'?'#6A717D':'white',fontSize:'18px',textAlign:"center"}}>{userData.email}</span>
                            <div style={{display:"flex",
                                         justifyContent:"space-between",
                                         columnGap:"10px",
                                         backgroundColor:backgroundMode.color==='white'?"white":'#1B1C1C',
                                         padding:"5px"}}>
                                <section>
                                    <p style={{color:backgroundMode.color==='white'?"#062149":'#DEDEDE',fontWeight:"700",fontSize:"20px",textAlign:"center"}}>{userDocCount?.documentCount}</p>
                                    <p style={{color:backgroundMode.color==='white'?"#062149":'#DEDEDE',fontWeight:"400",fontSize:"14px",textAlign:"center"}}>Documents Created</p>
                                </section>

                                <section>
                                    <p style={{color:backgroundMode.color==='white'?"#062149":'#DEDEDE',fontWeight:"700",fontSize:"20px",textAlign:"center"}}>{userDocCount?.urlCount}</p>
                                    <p style={{color:backgroundMode.color==='white'?"#062149":'#DEDEDE',fontWeight:"400",fontSize:"14px",textAlign:"center"}}>URLs Created</p>
                                </section>
                            </div>
                        </div>

                        <div style={{borderRadius:'10px 10px 0px 0px',
                                     backgroundColor:backgroundMode.color==='white'?"white":'#1B1C1C',
                                     marginTop:"20px"}}>
                            {navList.map((element:any,index:any)=>
                            <section onClick={()=>setOptionSelect(element.click)} key={index} style={{padding:"10px",cursor:"pointer",marginBottom:"15px",boxSizing:"border-box",display:"flex",justifyContent:"start",columnGap:"10px"}}>
                                <span style={{display:"flex",alignItems:'center',justifyContent:"center"}}>
                                    <img src={backgroundMode.color==='white'?element.imgLight:element.imgBlack}/>
                                </span>
                                {element.text==='Delete Profile'? <span style={{display:"flex",color:element.text==='Delete Profile'?'#DF0B0B':'',alignItems:'center',justifyContent:"center",fontSize:"16px",fontWeight:"400"}}>
                                    {element.text}
                                </span>
                                :<span style={{display:"flex",color:backgroundMode.color==='white'?"#062149":'#DEDEDE',alignItems:'center',justifyContent:"center",fontSize:"16px",fontWeight:"400"}}>
                                {element.text}
                            </span>}
                            </section>)}
                        </div>
                    
                </section>}
            </section>
        </SettingsMobileContent>
    )
    }

export default SettingsMobile