import React,{useEffect,useState} from 'react'
import { SettingsFields } from '../individualsettings.styles'
import FormInput from './ExtraComponents/TextInputs'
import { FormProvider, useForm,useFormContext } from "react-hook-form";
import { useEditUserMutation } from '../../../services/customerApi';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import Utils from '../../../library/Utils';
import { CancelButton } from './SettingsMobile/settingsmobile.styles';
import { UpdateButton } from './SettingsMobile/settingsmobile.styles';
import './settingsField.css'

function SettingsFieldBox({userData,userDataFetching,userDataRefetch,userDocCount}:any) {

  const { register,getValues,setValue, formState: { errors }, handleSubmit, reset,trigger } = useForm()
      const methods = useForm({
          mode: "onChange",
        });

      useEffect(()=>{
        if(userData){
            const newData = {...userData}
  
        newData.createdDate = Utils.formatLongDate(userData.createdDate)
  
        setValue('firstName',newData.firstName)
        setValue('joinedSince',newData.createdDate)
        setValue('referral',newData.referralCode)
        setValue('lastName',newData.lastName)
        setValue('email',newData.email)
        setValue('occupation', newData.occupation)
        setValue('location',newData.location)
        }
  
      },[userData])

    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const [disableFields,setDisableFields] = useState(true)


      const [editUser, {data: editUserData, 
                        isLoading: editUserLoading, 
                        isSuccess: editUserSuccess, 
                        isError: isEditUserError, 
                        error: editUserError}] = useEditUserMutation()
      
      const submitForm = ()=>{
        const dat = getValues()
        editUser(dat)
      }

      useEffect(()=>{
        if(!editUserLoading){
          setDisableFields(true)
        }

      },[editUserLoading])



      useEffect(()=>{
        if(editUserSuccess){
          userDataRefetch()
        }
      },[editUserSuccess])

  return (
    <SettingsFields colorState={backgroundMode.color}>
        <section className= 'settingsTop'>
            <section className='innerHold'>
             <section className='LHS'>
              <div style={{backgroundColor:"#d3d3d3",
                            height:"90px",
                            width:"90px",
                            fontWeight:"700",
                            color:"black",
                            borderRadius:"50%",
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            fontSize:"20px"}}>
                  {userData.firstName[0].toUpperCase()}{userData.lastName[0].toUpperCase()}
                </div>
                <div className='namesHolder'>
                    <span className='Name'>{userData.firstName} {userData.lastName}</span>
                    <span className='Email'>{userData.email}</span>
                </div>
             </section>

              <div className='RHS'>
                          <section>
                              <p className='titleHold'>{userDocCount?.documentCount}</p>
                              <p className='valueHold' >Documents Created</p>
                          </section>

                          <section>
                              <p className='titleHold'>{userDocCount?.urlCount}</p>
                              <p className='valueHold'>URLs Created</p>
                          </section>
                </div>


            </section>
        </section>

        <section className='settingsBottom'>
            <div style={{display:disableFields?"flex":'none',justifyContent:"space-between",marginBottom:"25px"}}>
                <span style={{color:backgroundMode.color==='white'?'#062149':'white',fontWeight:"600"}}>
                    User Profile
                </span>

                <span className='Edit' onClick={()=>setDisableFields(false)}>
                    {userDataFetching?'Editing' :'Edit Profile'}
                </span>
            </div>
         
            <section>
                <FormProvider {...methods}>

                    <div style={{display:"flex",justifyContent:"space-between",marginTop:"12px"}}>
                            <FormInput 
                            fields={{
                                label: "First Name",
                                register: "firstName",
                                validators: {
                                  required: "First name is required",
                                },
                              }}
                              styleType={"normal-flex"}
                              type={"text"}
                              holder={"Emmanuel Bob"}
                              errors={errors}
                              setValue={setValue}
                              register={register}
                              disableFields={disableFields}
                            />


                          <FormInput 
                            fields={{
                                label: "Last Name",
                                register: "lastName",
                                validators: {
                                  required: "Last name is required",
                                },
                              }}
                              styleType={"normal-flex"}
                              type={"text"}
                              holder={"Emmanuel Bob"}
                              errors={errors}
                              setValue={setValue}
                              register={register}
                              disableFields={disableFields}
                            />


                    </div>


                    <div style={{display:"flex",justifyContent:"space-between",marginTop:"12px"}}>
                            <FormInput 
                            fields={{
                                label: "Referral Link",
                                register: "referral",
                              }}
                              styleType={"normal-flex"}
                              type={"text"}
                              holder={"http://prolificref.12345=AHFKDNNC"}
                              errors={errors}
                              setValue={setValue}
                              register={register}
                              disable={true}
                              disableFields={disableFields}
                            />


                            <FormInput 
                            fields={{
                                label: "Occupation",
                                register: "occupation",
                              }}
                              type={"text"}
                              holder={"Occupation"}
                              
                              styleType={"normal-flex"}
                              errors={errors}
                              setValue={setValue}
                              register={register}
                              disableFields={disableFields}
                            />
                    </div>

                    <div style={{display:"flex",justifyContent:"space-between",marginTop:"12px"}}>
                            <FormInput 
                            fields={{
                                label: "Location",
                                register: "location",
                              }}
                              styleType={"normal-flex"}
                              type={"text"}
                              holder={"Lagos, Nigeria"}
                              errors={errors}
                              setValue={setValue}
                              register={register}
                              disableFields={disableFields}
                            />

                            <FormInput 
                              fields={{
                                  label: "Joined since",
                                  register: "joinedSince",
                                }}
                              type={"text"}
                              holder={"14th April 2024"}
                              
                              styleType={"normal-flex"}
                              errors={errors}
                              setValue={setValue}
                              register={register}
                              disable={true}
                              disableFields={disableFields}
                            />


                            
                    </div>


                </FormProvider>
            </section>
            <div style={{display:!disableFields?"flex":'none',justifyContent:"end",columnGap:"15px",marginTop:"20px"}}>
              <CancelButton 
                colorState={backgroundMode.color}
                onClick={()=>setDisableFields(true)}>
                  Cancel
              </CancelButton>
              <UpdateButton 
                onClick={()=>editUserLoading?'':submitForm()}
                colorState={backgroundMode.color}
                editUserLoading={editUserLoading}>
                {editUserLoading?'Updating...':'Update'}
              </UpdateButton>
            </div>
        </section>
    </SettingsFields>
  )
}

export default SettingsFieldBox