import styled from "styled-components"

export const AiAvatarSelection = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 15px;

    h2 {
        font-weight: 600;
        font-size: 0.8rem;
        color: #062149;
        margin: 0;
    }
`

export const BlueBox = styled.div `
    border-radius: 4px;
    border: 1px solid var(--primary-100, #B0CBF3);
    background-color: #F2F8FF;

    .inner-blue-wrap {
        display: flex;
        align-items: center;
        column-gap: 15px;
        row-gap: 20px;
        flex-wrap: wrap;
        padding: 1.5em;
    }
`

export const AvatarCircle = styled.div <{textColor?:string}> `
    border-radius: 50%;
    padding: 0.095em;
    background-color: #fff;
    border: solid 3px #D1E4FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    img {
        width: 30px;
        height: 30px;
    }

    .circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${(props:any) => props.textColor};

        p {
            font-weight: 600;
            font-size: 0.8rem;
            color: #fff;
            text-transform: uppercase;
            margin: 0;
        }
    }
`

export const NameSuggestionBox = styled.div `
    p {
        font-size: 0.7rem;
        font-weight: 500;
        color: #737373;
        margin-top: 0;
    }

    .suggestion-list {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
`

export const AiSuggestedName = styled.div `
    border-radius: 16px;
    background-color: var(--primary-50, #E1EEFF);
    display: flex;
    padding: 4px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #397CE2;
    font-weight: 500;
    font-size: 0.7rem;
    cursor: pointer;
`