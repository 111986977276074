import styled from "styled-components"

export const KnowledgeBaseWrap = styled.div `
    background-color: #FBFCFF;
    border-radius: 4px;
    width: 100%;

    .inner-knowledge-wrap {
        padding: 1rem;

        .projects-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 10px;
        }

        .uploaded-files-wrap {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            row-gap: 15px;
            padding: 20px 0 15px;
            border-bottom: solid 1px #E5E5E5;

            p {
                color: #062149;
                font-weight: 600;
                font-size: 0.8rem;
                margin: 0;
            }

            .doc-wraps {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 10px;
                row-gap: 7px;
                flex-wrap: wrap

            }

            .button-add-box {
                display: flex;
                justify-content: flex-start;
            }

            &.no-border {
                border: none;
            }

            .add-more-url-wrap {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                row-gap: 8px;

                .head-text {
                    font-weight: 600;
                    font-size: 0.6rem;
                    margin: 0;

                }

                .flex-wrap {
                    display: flex;
                    align-items: center;
                    column-gap: 10px;
                    justify-content: flex-start;

                    & > div {
                        flex: 0.5;
                        background-color: #fff;
                    }
                }
            }

        }
    }
`

export const Projectname = styled.div `
    border-radius: 4px;
    border: 1px solid var(--neutral-200, #E5E5E5);
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
    cursor: pointer;
    width: 140px;
    padding: 0.6em 1em;
    font-size: 0.8em;
    
`