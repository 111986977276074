import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../button";
import {
  HeaderList,
  HeaderSublist,
  Headernav,
  Headerwrap,
  MobileMenu,
  Overlay,
} from "./header.styles";

import cancel from "../../assets/svg/cancel-menu.svg";
import hamburger from "../../assets/svg/hamburger.svg";
import logo from "../../assets/svg/prolific-logo.svg";

const Header = ({ pricingRef, featureRef, handleScroll, contactRef }: any) => {
  const navigate = useNavigate();
  // const {keycloak, initialized} = useKeycloak()
  const [overlay, setOverlay] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const navList = [
    {
      name: "Home",
      link: "/",
      onClick: () => {
        return;
      },
    },
    {
      name: "Product",
      link: "#feature",
      onClick: () => {
        handleScroll(featureRef.current);
      },
    },
    {
      name: "Be an Ambassador",
      link: "/ambassador",
      onClick: () => {
        navigate("/ambassador");
      },
    },
    {
      name: "Pricing",
      link: "#pricing",
      onClick: () => {
        handleScroll(pricingRef.current);
      },
    },
    {
      name: "Contact",
      link: "/contact-us",
      onClick: () => {
        navigate("/contact-us");
      },
    },
  ];

  const handleMobileMenu = () => {
    setOverlay(!overlay);
    setMobileActive(!mobileActive);
  };

  const redirectToSignUp = () => {
    // keycloakContext.register({
    //   redirectUri: config.keycloak.registerRedirectUrl
    // });
    navigate("/choose-plan");
  };

  const login = () => {
    // keycloakContext.login({
    //   scope: config.keycloak.scope,
    //   redirectUri: config.keycloak.loginRedirectUrl
    // });
    navigate("/login");
  };

  return (
    <>
      <Overlay activeOverlay={overlay} onClick={handleMobileMenu} />
      <Headerwrap>
        <Headernav>
          <div className="logo-wrap">
            <img src={logo} alt="" onClick={() => navigate("/")} />
          </div>

          <HeaderList>
            {navList?.map((item, id) => {
              if(id==2 || id ==4){

                if (id == 2) {
                  return (
                    <span
                      onClick={() => navigate("/ambassador")}
                      style={{
                        fontSize: "16px",
                        color: "#407BFF",
                        cursor: "pointer",
                        lineHeight: "2.4rem",
                        fontWeight: "400",
                      }}
                    >
                      {item.name}
                    </span>
                  );
                }

                if(id == 4){
                  return (
                    <span
                      onClick={() => navigate("/contact-us")}
                      style={{
                        fontSize: "16px",
                        color: "#062149",
                        cursor: "pointer",
                        fontWeight: "500",
                      }}
                    >
                      {item.name}
                    </span>
                  );
                }
              }
               else {
                return (
                  <HeaderSublist key={id}>
                    {" "}
                    <a href={`${item.link}`} onClick={item.onClick}>
                      {item.name}
                    </a>{" "}
                  </HeaderSublist>
                );
              }
            })}
          </HeaderList>

          <div className="cta-section">
            {/* <Button 
              btnType={'link'}
              text={'Sign in'}
              onClick={login}
            /> */}

            <Button btnType={"cta"} text={"Sign in"} onClick={login} />
          </div>

          <div className="hamburger">
            <img src={hamburger} alt="" onClick={handleMobileMenu} />
          </div>
        </Headernav>
      </Headerwrap>

      {/* MOBILE MENU */}
      <MobileMenu mobileActive={mobileActive}>
        <div className="inner-mobile-wrap">
          <div className="close-menu-box">
            <img
              src={cancel}
              alt=""
              onClick={() => {
                handleMobileMenu();
              }}
            />
          </div>

          <ul className="mobile-list">
            {navList?.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={`${item.link}`}>{item.name}</Link>
                </li>
              );
            })}
          </ul>

          <div className="mobile-cta-wrap">
            {/* <Button 
              btnType={'link'}
              text={'Sign in'}
              onClick={login}
            /> */}

            <Button btnType={"cta"} text={"Sign in"} onClick={login} />
          </div>
        </div>
      </MobileMenu>
    </>
  );
};

export default Header;
