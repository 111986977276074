import styled from "styled-components"

export const IntroChatHold = styled.div<{colorState?: string}>`
             width:100%;
             margin-left:286px;
             margin-top:80px;
             background: ${(props:any) => props?.colorState === 'white' 
                        ? "linear-gradient(to bottom right, #DDE1FA 0%, #DDE1FA 25%, #F3F8FF 50%, #DDE1FA 75%, #DDE1FA 100%);" 
                        : ""};
             background-color:${(props:any) => props?.colorState === 'white' ? "#DDE1FA" : "black"};
             transition: all 0.5s ease;
             display:flex;
             align-items:center;
             justify-content:center;


             .createChatInfo{

                display:flex;
                justify-content:space-around;
                align-items:center;
                flex-direction:column;
                row-gap:20px;

                .introTitle{
                    font-size:36px;
                    font-weight:800;
                    background: linear-gradient(45deg, #407BFF, #C4A2FC);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent; /* For Webkit browsers like Chrome and Safari */
                    background-clip: text;
                    color: transparent; 
                }

                .introChat{
                    color:${(props:any) => props?.colorState === 'white'?'#062149':'#FFFFFF'};
                    font-size:18px;
                    font-weight:500;
                }
             }

             @media only screen and (max-width: 992px) {
                margin-left:0px;


                .createChatInfo{

    
                    .introTitle{
                        font-size:26px;
                      
                    }
    
                   
                 }
             
            }

            @media only screen and (max-width: 450px) {
                margin-left:0px;


                .createChatInfo{

    
                    .introTitle{
                        font-size:18px;
                      
                    }

                    .introChat{
                        font-size:16px;
                        font-weight:500;
                    }
    
                   
                 }
             
            }

`