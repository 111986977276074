import styled from "styled-components"

export const SidebarWrap = styled.div `
    position: fixed;
    left: 0;
    top: 0;
    width: 350px;
    height: 100vh;
    z-index: 10;
    padding: 80px 10px 10px 10px;
    background-color:#F3F8FF;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 5px;
      }

    .inner-sidebar-wrap {
        padding-top: 30px;
        padding-bottom: 80px;
        overflow:auto;
        width:100%;
    }

    @media only screen and (max-width: 1220px) {
        width: 350px;
    } 

    @media only screen and (max-width: 992px) {
        display: none;
    }
`

export const SidebarAccordion = styled.div `
    padding-bottom: 30px;

    .accordion-side-header {
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer;

        .left-side {
            font-weight: 600;
            font-size: 1rem;
            color: #062149;
        }
    }
`

export const SidebarAccordionList = styled.ul `
    padding-left: 15px;

    li {
        color: #062149;
        font-size: 0.9rem;
        font-weight: 500;
        list-style: none;
        padding: 0.9rem 0.8em; 
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            color: #062149;
            font-size: 0.9rem;
            margin: 0;
            font-weight: 500;
            width: 100%;
        }

        .img-wraps {
            display: none;
            align-items: center;
            column-gap: 20px;
            position:relative;
            margin-left:15px;

            img {
                cursor: pointer;
            }
        }

        &.active, &:hover {
            background-color: #fff;
            border-radius: 3px;
        }

        &.active {
            .img-wraps {
                display: flex;
            }
        }
    }
`

export const ProjectList = styled.ul `
    padding-left: 15px;

    li {
        color: #062149;
        font-size: 0.9rem;
        font-weight: 500;
        list-style: none;
        padding: 0.9rem 0.8em; 
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-text {
            display: flex;
            align-items: center;
            column-gap: 10px;

            img {

            }
        }

        p {
            color: #062149;
            font-size: 0.9rem;
            margin: 0;
            font-weight: 500;
        }

        .img-wraps {
            display: none;
            align-items: center;
            column-gap: 20px;
            margin-left: 15px

            img {
                cursor: pointer;
            }
        }

        &.active, &:hover {
            background-color: #fff;
            border-radius: 3px;
        }

        &.active {
            .img-wraps {
                display: flex;
            }
        }
    }

`


export const PromptWrap = styled.div`
    position: absolute;
    z-index:100000;
    top: -90px;
    right: -40px;
    
    height: 120px;
    width: 350px;
    display:flex;
    border-radius:13px;
    justify-content: space-around;
    flex-direction:column;
    align-items:center;
    background-color: white
`

export const SpanWrap = styled.span`
    font-weight: 700;
    font-size: 15px
`


export const SiteLinkList = styled.ul `
padding-left: 0px;

li {
    color: #062149;
    font-size: 0.9rem;
    font-weight: 700;
    list-style: none;
    padding: 0.9rem 0.8em; 
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 5px;

    &.active, &:hover {
        background-color: #fff;
        border-radius: 3px;
    }
}

`