import styled from "styled-components";

export const FormHolder = styled.div`
  display: flex;
  row-gap: 3px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  input {
    width: 50px;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin: 3px;
    outline: none;
    border: 1px solid #ccc;
  }

  @media only screen and (max-width: 480px) {
    input {
      width: 30px;
      padding: 5px;
      text-align: center;
      border-radius: 3px;
      margin: 1.5px;
    }
  }
`;
export const InnerRightAuthSubWrap = styled.div`
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-block: 15px;

  section {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    color: #062149;
  }

  .upperP {
    font-size: 15px;
    width: 75%;
    margin: 10px auto;
    text-align: center;
    color: #062149;
    font-weight: 400;
  }

  .lowerP {
    font-size: 12px;
    width: 50%;
    margin: 10px auto;
    text-align: center;
    color: #062149;
    font-weight: 400;
  }

  @media only screen and (max-width: 390px) {
    section {
      font-size: 24px;
      text-align: center;
      font-weight: 700;
      color: #062149;
    }

    .upperP {
      font-size: 13px;
      width: 75%;
      margin: 10px auto;
      text-align: center;
      color: #062149;
      font-weight: 400;
    }

    .lowerP {
      font-size: 11px;
      width: 50%;
      margin: 10px auto;
      text-align: center;
      color: #062149;
      font-weight: 400;
    }
  }
`;
