import styled from "styled-components"

export const SubHeadWrap = styled.div <{isBorder:boolean}> `
    background-color: #E1EEFF;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    border-radius: 1rem;
    color: #1162DC;
    font-size: 0.8rem;
    font-weight: 600;
    border: ${(props:any) => props.isBorder ? 'solid 1px #B0CBF3' : 'none'}
`