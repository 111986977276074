import React from 'react'
import { CardWrap } from './card.styles'

const Card = ({icon, title, content, cardType, onCardClicked, isCardActive}: any ) => {

  const handleCardClicked = () => {
    if(!cardType?.includes("useCase")) {
      return
    }

    onCardClicked()
  }

  return (
    <CardWrap cardType={cardType} onClick={handleCardClicked}>
        <div className="inner-card-wrapper">
            <img src={icon}/>

            <h4>{title}</h4>

            <p>{content}</p>
        </div>
    </CardWrap>
  )
}

export default Card