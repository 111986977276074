import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import Spinner from "../../spinnersvg/Spinner";
import { AuthWrap } from "../Auth/auth.styles";
import SignUpNavigator from "./SignUpDirection/SignUpNavigator";

import { Country } from "react-phone-number-input";
import { Link, useNavigate } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import { SignUpCard } from "../../components/authCard/authcard.styles";
import Button from "../../components/button";
import FeedbackBox from "../../components/feedbackBox";
import PublicLayout from "../../components/publicLayout";
import { useUserSignupMutation } from "../../services/onboardingApi";
import BusinessSignUp from "./BusinessSignUp";
import IndividualSignUp from "./IndividualSignUp";
import store,{ RootState, useAppSelector } from "../../store";
import { clearAuth } from "../../slices/auth";
import { useDispatch } from "react-redux";
import Utils from "../../library/Utils";
import { DateTime } from "luxon";


const env = window._jsenv || process.env
const imageBaseUrl = env.REACT_APP_GCP_BASE_URL;

const Signup = () => {

  const dispatch = useDispatch();
    const navigate = useNavigate();
    //set a re-direct to last visited
    const expires_in = useAppSelector<any>((store:RootState) => store?.auth?.auth?.token?.expires_in)

    useEffect(()=>{
      if(expires_in){
        let loggedInDate = localStorage.getItem('prolificLoggedInTime');
        let expiresDate = DateTime.fromISO(loggedInDate).plus({ seconds: expires_in });
        let currentDateTime = DateTime.now();
        if(Utils.isTokenExpired(expiresDate.toMillis(), currentDateTime.toMillis())) {
            dispatch(clearAuth())
            localStorage.removeItem('prolificLoggedInTime');
            localStorage.removeItem("prolificLastVisited");
        }
        else{
          const lastVisit = localStorage.getItem("prolificLastVisited")
          navigate(`${lastVisit}`)
        }
      }
    },[expires_in])



  const eyeSlash = `${imageBaseUrl}assets/svg/eye-slash.svg`
  const eye = `${imageBaseUrl}assets/svg/eye.svg`
  const mail = `${imageBaseUrl}assets/svg/mail-icon.svg`
  const logo = `${imageBaseUrl}assets/svg/logo-white.svg`

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const [navigationState, setNavigationState] = useState("INDIVIDUAL");
  const [progress, setProgress] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState<Country>("US");
  // const [selectedCountry, setSelectedCountry] = useState("");
  const [userDigit, setUserDigit] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  // const keycloakContext:any = React.useContext(KeycloakContext);
  const methods = useForm({
    mode: "onBlur",
  });
  const watchFields = methods.watch(["password"]);
  const [passwordShow, setPasswordShow] = useState("password");
  const [confirmPasswordShow, setCPasswordShow] = useState("password");
  // const dispatch = useDispatch();
  // const access_token = useAppSelector<any>((store:RootState) => store?.token.token)
  // const user = useAppSelector<any>((store:RootState) => store?.auth?.auth)

  const [signup, { data, isLoading, isSuccess, isError, error }] =
    useUserSignupMutation();

  // USEFFECT FOR SIGNUP
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate("/verify", { state: { data: data?.email } });
      }, 2000);
    }
    
    if (isError && "status" in error!) {
      if(Utils.isFieldEmpty(error?.data)){
        notifyError('Something went wrong. Kindly, try again.');
      }
      else{
        notifyError(error?.data?.message);
      }
    }
  }, [isSuccess, isError, error, data]);

  // const [createUser, {data, isLoading, isSuccess, isError, error}] = useCreateUserMutation();

  // const {data: getUserData, isLoading: getUserLoading, isSuccess: getUserSuccess, isError: getUserIsError, error: getUserError, refetch} = useGetUserQuery()

  // const signUpDetails: any = {
  //     firstName : null,
  //     lastName : null,
  //     email : keycloakContext.idTokenParsed.email,
  //     authUserId: keycloakContext.idTokenParsed.sub,
  // }

  // useEffect(() => {
  //     if(keycloakContext.token !== null && keycloakContext.token !== undefined) {
  //         dispatch(setToken(keycloakContext.token))

  //         if(access_token && !data) {
  //             createUser(signUpDetails)
  //         }

  //         if(isSuccess && !getUserData?.data) {
  //             refetch()
  //         }

  //         if(getUserSuccess && Utils?.isFieldEmpty(user)) {
  //             dispatch(setAuth(getUserData))
  //         }
  //     }
  // }, [keycloakContext, access_token, isSuccess, getUserSuccess, data])

  const onSubmit = (formData: any) => {
    let finalData = {
      ...formData,
      companyName: formData?.companyName ? formData?.companyName : "",
      userType: navigationState,
      country: selectedCountry,
      referrerCode: ''
    };

    if (userDigit === "") {
      toast.info("Kindly input your phone Number", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      signup(finalData);
    }
  };

  const notifyError = (text: string) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });


  const stepClickHandler = (e: any) => {
    const currentStep = Number(e.target.textContent) - 1;

    setActiveStep(currentStep);
  };

  return (
    <>
      <ToastContainer />
      <AuthWrap>
        <PublicLayout>
          <div className="inner-auth-wrap">
            <div className="auth-header">
              <Link to="/">
                <img src={logo} alt="brand logo" />
              </Link>
            </div>

            <div className="auth-flex-wrap">
              <div className="left-auth-subwrap">
                <div className="btn-wrap">
                  <Button
                    btnType={"backWhite"}
                    text={"Go home"}
                    onClick={() => navigate("/")}
                  />
                </div>

                <div className="auth-text-wrap">
                  <div className="large-text">
                    <h5>
                      Get started with <span> Prolific. </span>
                    </h5>

                    <p>
                      Prolific creates conversational AI chatbots with your data
                      to communicate naturally.
                    </p>
                  </div>
                </div>

                <div className="swiper-wrap">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    direction="horizontal"
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Our customer service has taken a giant leap with this chatbot. It streamlines processes, resolves issues promptly, and even assists in driving sales through personalized recommendations. A game-changer for our business!"
                        }
                        author={"Alex Brabra"}
                      />
                    </SwiperSlide>

                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Uploading my business’ data was easy and extremely fast, Prolific has helped in pleasantly engaging customers, and contributed to some sales. A practical asset for my business"
                        }
                        author={"Chris Dicaprio"}
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>

                <div className="auth-footer">
                  <p>{`© Copyright ${currentYear} - Prolific Technologies || All
                    Rights Reserved`}
                  </p>
                </div>
              </div>
              <div className="right-auth-subwrap">
                <SignUpCard navigationState={navigationState}>
                  <div className="inner-authcard-wrap">
                    <div className="stepper-container">
                      <Stepper
                        steps={[
                          { title: "", onClick: stepClickHandler },
                          { title: "", onClick: stepClickHandler },
                        ]}
                        size={23.27}
                        circleFontSize={12}
                        activeColor="#1162DC"
                        defaultColor="#E1EEFF"
                        completeColor="#1162DC"
                        activeStep={activeStep}
                        className="custom-stepper"
                        // defaultBarColor="#E1EEFF"
                        completeBarColor="#1162DC"
                      />
                    </div>

                    {activeStep === 0 && (
                      <SignUpNavigator
                        navigationState={navigationState}
                        setNavigationState={setNavigationState}
                        setProgress={setProgress}
                        setActiveStep={() => setActiveStep((prev) => prev + 1)}
                      />
                    )}

                    {activeStep === 1 && (
                      <>
                        {navigationState === "BUSINESS" ? (
                          <BusinessSignUp
                            methods={methods}
                            onSubmit={onSubmit}
                            setPasswordShow={setPasswordShow}
                            passwordShow={passwordShow}
                            confirmPasswordShow={confirmPasswordShow}
                            setCPasswordShow={setCPasswordShow}
                            eyeSlash={eyeSlash}
                            watchFields={watchFields}
                            eye={eye}
                            mail={mail}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                            userDigit={userDigit}
                            setUserDigit={setUserDigit}
                            isSuccess={isSuccess}
                            isLoading={isLoading}
                          />
                        ) : (
                          <IndividualSignUp
                            methods={methods}
                            onSubmit={onSubmit}
                            setPasswordShow={setPasswordShow}
                            passwordShow={passwordShow}
                            confirmPasswordShow={confirmPasswordShow}
                            setCPasswordShow={setCPasswordShow}
                            eyeSlash={eyeSlash}
                            watchFields={watchFields}
                            eye={eye}
                            mail={mail}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                            userDigit={userDigit}
                            setUserDigit={setUserDigit}
                            isSuccess={isSuccess}
                            isLoading={isLoading}
                            // country={country}
                            // setCountry={setCountry}
                          />
                        )}
                      </>
                    )}
                  </div>
                </SignUpCard>
              </div>
            </div>
          </div>
        </PublicLayout>
      </AuthWrap>
    </>
  );
};

export default Signup;
