import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  useResendOtpQuery,
  useUserVerifyMutation,
} from "../../services/onboardingApi";
import { AuthWrap } from "../Auth/auth.styles";
import { FormHolder, InnerRightAuthSubWrap } from "./verify.styles";

import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/svg/logo-white.svg";
import { Notification } from "../../components/Notification";
import Button from "../../components/button";
import FeedbackBox from "../../components/feedbackBox";
import PublicLayout from "../../components/publicLayout";
import Spinner from "../../spinnersvg/Spinner";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<any>(location?.state?.data);
  const methods = useForm({
    mode: "onBlur",
  });

  const [passwordShow, setPasswordShow] = useState("password");

  type CustomFormData = any[];

  // const keycloakContext:any = React.useContext(KeycloakContext);
  const dispatch = useDispatch();
  const [fetchAgain, setFetchAgain] = useState(false);
  // const access_token = useAppSelector<any>((store:RootState) => store?.token.token)
  // const user = useAppSelector<any>((store:RootState) => store?.auth?.auth)

  const [
    userVerify,
    {
      data: verifyData,
      isSuccess: verifySuccess,
      isLoading: verifyLoading,
      isError: verifyIsError,
      error: verifyError,
    },
  ] = useUserVerifyMutation();

  
  const { data, isSuccess, isLoading, isError, error, refetch }: any =
    useResendOtpQuery(email);

  useEffect(() => {
    if (fetchAgain) {
      refetch();

      if (isSuccess) {
        toast.success("OTP has been resent", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }
  }, [fetchAgain, isLoading, isSuccess]);
  /**
 * 
 * @param text 
    // USEFFECT FOR LOGIN 
    useEffect(() => {
        if(isSuccess) {
            dispatch(setAuth(data))
            localStorage.setItem('prolificLoggedInTime', DateTime.now());
            if(data?.count > 0) {
                navigate("/new-chat")
            }else {
                navigate("/usecase-setup")
            }
        }

        if(isError && 'status' in error!) {
            notifyError(error?.data?.message)
        }
    }, [isSuccess, isError, error, data])
 * @returns 
 */

  // const {data: getUserData, isLoading: getUserLoading, isSuccess: getUserSuccess, isError: getUserIsError, error: getUserError, refetch} = useGetUserQuery()

  // useEffect(() => {
  //     if(keycloakContext.token !== null && keycloakContext.token !== undefined) {
  //         dispatch(setToken(keycloakContext.token))

  //         if(access_token && !getUserData?.data) {
  //             refetch()
  //         }

  //         if(getUserSuccess && Utils?.isFieldEmpty(user)) {
  //             dispatch(setAuth(getUserData))
  //         }
  //     }
  // }, [keycloakContext, getUserSuccess, access_token, getUserData, user])

  useEffect(() => {
    if (verifySuccess && verifyData && verifyData.isVerified) {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
      // navigate("/login");
    }

    if (verifyError && verifyIsError) {
      toast.error("Please,input the right credentials.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }, [verifyData, verifyLoading, verifySuccess, verifyIsError, verifyError]);

  const [token, setToken] = useState(["", "", "", "", "", ""]);
  const inputRefs: React.RefObject<HTMLInputElement>[] = Array.from(
    { length: 6 },
    () => React.createRef()
  );

  const handleInputChange = (e: any, index: any) => {
    const { value } = e.target;
    const newToken = [...token];
    newToken[index] = value;

    // Move focus to the next input field if a digit is entered
    if (value.match(/^\d$/) && index < 5 && inputRefs[index + 1]?.current) {
      inputRefs[index + 1].current!.focus();
    }

    setToken(newToken);
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData("Text")
      .trim()
      .split("")
      .slice(0, 6);

    // Paste copied number into all input fields
    const newToken = [...token];
    for (let i = 0; i < 6; i++) {
      newToken[i] = pastedData[i] || "";
    }

    setToken(newToken);

    // Move focus to the last input field
    inputRefs[4]!.current!.focus();
  };

  const notifyError = (text: string) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const onSubmit = () => {
    if (token.length === 6) {
      const newToken = token.join("");
      const body = { email: email, otp: newToken };
      userVerify(body);
    } else {
      toast.warning("fill token", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  if (isLoading) {
    return <Spinner></Spinner>;
  }

  return (
    <>
      <ToastContainer />
      <AuthWrap>
        <PublicLayout>
         
          <div className="inner-auth-wrap">
            <div className="auth-header">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className="auth-flex-wrap">
              <div className="left-auth-subwrap">
                <div className="btn-wrap">
                  <Button
                    btnType={"backWhite"}
                    text={"Go home"}
                    onClick={() => navigate("/")}
                  />
                </div>

                <div className="auth-text-wrap">
                  <div className="large-text">
                    <h5>
                      Get started with <span> Prolific. </span>
                    </h5>

                    <p>
                      Prolific creates conversational AI chatbots with your data
                      to communicate naturally.
                    </p>
                  </div>
                </div>

                <div className="swiper-wrap">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    direction="horizontal"
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Our customer service has taken a giant leap with this chatbot. It streamlines processes, resolves issues promptly, and even assists in driving sales through personalized recommendations. A game-changer for our business!"
                        }
                        author={"Alex Brabra"}
                      />
                    </SwiperSlide>

                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Uploading my business’ data was easy and extremely fast, Prolific has helped in pleasantly engaging customers, and contributed to some sales. A practical asset for my business"
                        }
                        author={"Chris Dicaprio"}
                      />
                    </SwiperSlide>

                    {/* <SwiperSlide>
                                <FeedbackBox />
                            </SwiperSlide>

                            <SwiperSlide>
                                <FeedbackBox />
                            </SwiperSlide> */}
                  </Swiper>
                </div>

                <div className="auth-footer">
                  <p>
                    © Copyright 2023 - Prolific Technologies Limited || All
                    Rights Reserved
                  </p>
                </div>
              </div>

              <div className="right-auth-subwrap">
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    display: "flex",
                    borderRadius: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <InnerRightAuthSubWrap>
                    <section>Email Verification</section>
                    <p className="upperP">
                      A token has been sent to your mail. Copy it and paste here
                      for further verification
                    </p>
                    <p className="lowerP">
                      Token validity lasts for 10 minutes
                    </p>

                    {verifySuccess && verifyData && verifyData.isVerified && (
                      <Notification message="Token verified successfully." />
                    )}

                    {verifySuccess && verifyData && !verifyData.isVerified && (
                      <Notification success={false} message="Invalid token." />
                    )}

                    <FormProvider {...methods}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <FormHolder>
                          {token.map((value, index) => (
                            <input
                              key={index}
                              type="text"
                              maxLength={1}
                              value={value}
                              className="otp-input"
                              onChange={(e) => handleInputChange(e, index)}
                              onPaste={handlePaste}
                              ref={inputRefs[index]}
                            />
                          ))}
                        </FormHolder>
                        {verifyLoading
                        ?<button
                        style={{
                          padding: "10px 15px",
                          width: "120px",
                          backgroundColor: "#b0cbf3",
                          outline: "none",
                          border: "none",
                          borderRadius: "6px",
                          color: "white",
                        }}
                        type="submit"
                      >
                        Verifying...
                      </button>
                        : <button
                          onClick={() => onSubmit()}
                          style={{
                            padding: "10px 15px",
                            width: "120px",
                            cursor: "pointer",
                            backgroundColor: "#1162DC",
                            outline: "none",
                            border: "none",
                            borderRadius: "6px",
                            color: "white",
                          }}
                          type="submit"
                        >
                          Verify
                        </button>}
                      </div>
                    </FormProvider>

                    {isLoading?
                    
                    <p
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        textAlign: "right",
                        paddingRight: "15px",
                        boxSizing: "border-box",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      Resending OTP...?
                    </p>


                    :<p
                      onClick={() => setFetchAgain(true)}
                      style={{
                        width: "100%",
                        cursor: "pointer",
                        textAlign: "right",
                        paddingRight: "15px",
                        boxSizing: "border-box",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      Resend OTP?
                    </p>}
                  </InnerRightAuthSubWrap>
                </div>
              </div>
            </div>
          </div>
        </PublicLayout>
      </AuthWrap>
    </>
  );
};

export default VerifyEmail;
