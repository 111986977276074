import styled from "styled-components"

export const BackgrounWrap = styled.div `
    position: relative;
    min-height: 100vh;
    background: rgb(203,71,197);
    background: linear-gradient(326deg, rgba(203,71,197,1) 1%, rgba(56,131,237,1) 11%, rgba(15,45,42,1) 59%);
    width: 100%;

    .inner-wrap-box {
        min-height: 100vh;
        display: flex;
        padding: 0 6rem;
        justify-content: space-between;

        .mobile-header {
            display: none;
        }

        .mobile-footer {
            display: none;
        }

        .left-wrap {
            padding: 3rem 0 2rem;
            flex: 0.45;

            .header-wrap {
                img {
                    cursor: pointer;
                }
            }

            .center-align-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 2rem;
                row-gap: 1rem;

                h2 {
                    font-weight: 700;
                    margin: 0;
                    font-size: 2.5rem;
                    color: #fff;
                }

                .subhead-text {
                    margin: 0;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: 600;
                    color: #fff;
                }

            }

            .footed-wrap {
                margin-top: 2rem;
                p {
                    margin: 0;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: 400;
                    color: #fff;

                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .inner-wrap-box {
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            

            .mobile-header {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                padding: 30px 0;
            }

            .mobile-footer {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 30px 0;

                p {
                    margin: 0;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: 400;
                    color: #fff;
                    text-align: center;
                }
            }

            .left-wrap {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 569px) {
        .inner-wrap-box {
            padding: 0 2em;
        }
    }
`

export const CheckoutFormOverall = styled.div `
    background-color: #fff;
    min-height: 100%;
    flex: 0.45;

    .form-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 4rem;
        row-gap: 1.5em;

        .back-header {

        }

        .form-header {
            h3 {
                font-size: 2rem;
                margin: 0
            }
        }

        form {
            display: flex;
            flex-direction: column;
            row-gap: 1.2rem;

            label {
                margin-bottom: 10px;
                color: #737373;
            }

            .card-info-wrap {
                display: flex;
                flex-direction: column;

                .card-input-wrap {
                    border: solid 1px #D4D4D4;
                    border-radius: 4px;
                    display: flex;
                    padding: 0.8rem 1.2rem;
                    column-gap: 1.3em;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        border: none;
                        outline: none;
                        background-color: transparent;
                        width: 100%;
                        flex: 0.6;
                
                        &::placeholder {
                            color: #A3A3A3;
                            font-size: 0.8rem;
                        }
                    }

                    .card-types {
                        display: flex;
                        align-items: center;
                        column-gap: 10px;

                        img {
                            
                        }
                    }
                }

                .flex-card-wrap {
                    display: flex;
                    align-items: center;

                }
            }

            .sub-input {
                display: flex;
                flex-direction: column;
                row-gap: 0px;
            }
        }

        .subscription-info {
            margin-top: 20px;
            color: #737373;
            font-weight: 400;
            font-size: 0.75rem;
            text-align: center;
        }

        .checkout-footer {
            margin-top: 0px;
            display: flex;
            justify-content: center;
            column-gap: 10px;

            .pricing-footer-text {
                font-size: 0.8rem;
                font-weight: 500;
                color: #737373;
        
            }
        }
    }

    @media only screen and (max-width: 992px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;

        .form-wrap {
            padding: 2em 3em;
        }
    }

    @media only screen and (max-width: 768px) {
        width: 80%;
    }

    @media only screen and (max-width: 580px) {
        width: 100%;

        .form-wrap {
            padding: 2em 1.5em;
        }
    }

    

`