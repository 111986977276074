import { Route, Routes, useLocation } from "react-router-dom";
import SmoothScroll from "../components/SmoothScroll";
import AmbassadorPage from "../pages/Ambassador";
import Auth from "../pages/Auth";
import Chatroom from "../pages/Chatroom";
import Checkout from "../pages/Checkout";
import ChoosePlan from "../pages/ChoosePlan";
import ChooseProject from "../pages/ChooseProject";
import ForgotPassword from "../pages/ForgotPassword";
import Homepage from "../pages/Homepage";
import IndividualChat from "../pages/Individual-chat";
import IndividualChatRoom from "../pages/IndividualChatRoom";
import Login from "../pages/Login";
import PayPlan from "../pages/PayPlan";
import Signup from "../pages/Signup";
import UsecaseSetup from "../pages/UsecaseSetup";
import UserSettings from "../pages/UserSettings";
import VerifyEmail from "../pages/VerifyEmail";
import ConfirmPassword from "../pages/ConfirmPassword/ConfirmPassword";
import IndividualUserSettings from "../pages/IndividualUserSettings";
import ContactPage from "../pages/Contact Page/ContactPage";

function AppWrapper() {
  const location = useLocation();
  return (
    <SmoothScroll>
      <Routes>
        <Route path="/auth" element={<Auth />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/password-forgot" element={<ForgotPassword />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/usecase-setup" element={<UsecaseSetup />} />
        <Route path="new-chat" element={<ChooseProject />} />
        <Route path="choose-plan" element={<ChoosePlan />} />
        <Route path="pay-subscription-plan" element={<PayPlan />} />
        <Route path="/ambassador" element={<AmbassadorPage />} />
        <Route path="/user-settings" element={<UserSettings />} />
        <Route path="/chatroom" element={<Chatroom />} />

        <Route path="/individual-chat" element={<IndividualChat />} />
        <Route path="/individual-chatroom" element={<IndividualChatRoom />} />
        <Route path="/confirmPassword" element={<ConfirmPassword/>}/>
        <Route path="/individual-settings" element={<IndividualUserSettings/>} />
        <Route path="/contact-us" element={<ContactPage/>}/>
      </Routes>
    </SmoothScroll>
  );
}

export default AppWrapper;
