import styled from "styled-components"
import Utils from "../../../library/Utils"

export const UploadSection = styled.div `
    margin: 30px 0;
`

export const WrapInputField = styled.div <{colorState?: string}>`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    
    label {
        font-weight: 600;
        font-size: 0.8rem;
        color:${(props:any) => props?.colorState === 'white' ? "black" : "white"} ;


        span {
            font-weight: 300;
        }
    }

    .add-site-btn-wrap {
        display: flex;
        justify-content: flex-start;
        margin: 10px 0;

        .add-site-btn {
            display: flex;
            align-items: center;
            column-gap: 7px;
            cursor: pointer;

            img {

            }

            p {
                margin: 0;
                font-size: 0.7rem;
                color: #737373;
                font-weight: 500;
            }
        }
    }

    .flex-wrap {
        display: flex;
        align-items: center;
        column-gap: 15px;
        row-gap: 10px;
        flex-wrap: wrap;
        height:60px;
        overflow:auto;
    }

    .url-input-wrap {
        border: solid 1px #D4D4D4;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position-relative;
        padding: 0.4em;
        column-gap: 10px;
        color:${(props:any) => props?.colorState === 'white' ? "black" : "white"} ;
        border: ${(props:any) => props?.colorState === 'white' ? "1px solid #D4D4D4" : "1px solid #EFEFEF"} ;
        &:has(> input:focus) {
            border: ${(props:any) => props?.colorState === 'white' ? "1px solid #062149" : "1px solid #EFEFEF"} ; 
            color:${(props:any) => props?.colorState === 'white' ? "black" : "white"} ;

            &.input-error {
                border: solid 1px rgba(242, 114, 114, 1);
            }
        }

        &.input-error {
            border: solid 1px rgba(242, 114, 114, 1);
        }

        input.url-input {
            width: 100%;
            border: none;
            outline: none;
            background-color: transparent;
            font-size: 0.8rem;
            padding-left: 10px;
            color:${(props:any) => props?.colorState === 'white' ? "black" : "white"} ;


            &::placeholder {
                font-size: 0.8rem;
                color: #A3A3A3;
            }
        }

        button.url-btn {
            border: none;
            cursor: pointer;
            outline: none;
            color: #fff;
            font-weight: 500;
            font-size: 0.8rem;
            padding: 0.8em 1.5em;
            border-radius: 4px;
            background-color: #1162DC;
            white-space: nowrap;
        }

        .empty-box {
            padding: 1.1em 1.5em;
        }
    }
`

export const EmptyBox = styled.div `
    width: 100%;
    height: 30px;
`

export const UploadedFilesField = styled.div <{colorState?: string}>`
    height:70px;
    overflow:auto;

    p {
        font-size: 0.7rem;
        font-weight: 600;
        color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
    }

    .files-flex-wrap {
        display: flex;
        align-items: center;
        column-gap: 10px;
        row-gap: 6px;
        margin-top: 5px;
        flex-wrap: wrap;
        margin-bottom:5px;
    }
`

export const ErrorHolder = styled.div <{error?: any,showProjError:boolean}>`

    margin:20px 0px;
    display:${(props:any) => (!Utils.isFieldEmpty(props?.error.data.message) || props.showProjError) ?"block":"none"  } ;
    border:1px solid red;
    color:red;
    padding-bottom:20px;

    .errorMsg{
        color:red;
        text-align:center;
        font-size:16px;
    }

    .navButtons{
        margin-top:15px;
        display:flex;
        justify-content:space-around;

        .cancel{
            background-color:red;
            width:200px;
            padding:10px 0px;
            border-radius:7px;
            display:flex;
            justify-content:center;
            align-items:center;
            color:white;
            cursor:pointer;
            text-align:center;
        }

        .restart{
            background-color:#ADD8E6;
            width:200px;
            padding:10px 0px;
            border-radius:7px;
            display:flex;
            justify-content:center;
            align-items:center;
            color:white;
            cursor:pointer;
            text-align:center;
        }
    }



    p {
        font-size: 0.7rem;
        font-weight: 600;
        color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
    }

    .files-flex-wrap {
        display: flex;
        align-items: center;
        column-gap: 10px;
        row-gap: 6px;
        margin-top: 5px;
        flex-wrap: wrap;
    }
`


export const ChatModalWrapper = styled.div <{colorState?: string}>`
    

    .backButton{
        display:none;
        align-items:enter;
        justify-content:start;
        column-gap:10px;
        margin-bottom:15px;
        cursor:pointer;
    }
    .text-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        

        .steps-header {
            margin: 0;
            color: #808DA1;
            font-size: 0.8rem;
            font-weight: 400;
        }
    
        .topic-header {
            margin: 0;
            color: ${(props:any) => props?.colorState === 'white' ?'#062149':'#FFFFFF'};
            font-weight: 800;
            font-size: 1.8rem;

        }
    
        .instruction-content {
            margin: 0;
            font-weight: 400;
            font-size: 1rem;
            color: #062149;
        }
    }

    .customer-extra-info {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }


    .steps-footer-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        button {
            flex: 0.25;
        }
    }

    @media only screen and (max-width: 992px) {
        padding:30px 0px 30px 0px;
        box-sizing:border-box;
        width:85%;
        
        .backButton{
            display:flex;
            align-items:enter;
            justify-content:start;
            column-gap:10px;
            margin-bottom:15px;
        }

        .text-wrap {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
    
        
            .topic-header {
                display:flex;
                align-items:center;
                justify-content:center;
    
            }

    }


`