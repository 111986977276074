import styled, {css} from "styled-components"



export const OverWrap = styled.div`
    position: absolute;
    z-index:100000;
    top: 0px;
    right: 0px;
    Webkit-backdrop-filter:blur(4px);
    backdrop-filter: blur(4px);
    height: 100%;
    width: 100%;
    
`


export const PromptWrap = styled.div`
    position: absolute;
    z-index:100000;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 200px;
    width: 70%;
    padding:15px;
    display:flex;
    border-radius:13px;
    justify-content: space-around;
    flex-direction:column;
    align-items:center;
    background-color: rgba(250, 250, 250, 1);
    boxShadow:1px 1px 5px rgb(159, 159, 159)
`

export const CancelWrap = styled.span`
width:70px;
cursor:pointer;
height:35px;
display:flex;
align-items:center;
justify-content:center;
background-color: white;
border-radius:5px;
border:1px solid grey;
transition: background-color 1s;


&:hover {
    background-color: grey;
    color: white;

}
`


export const EditWrap = styled.span`
width:70px;
cursor:pointer;
height:35px;
display:flex;
align-items:center;
justify-content:center;
color:white;
background-color: rgb(3, 65, 3);
border-radius:5px;
transition: background-color 0.3s;


&:hover {
    background-color: rgb(2, 29, 2);
    color: white;

}
`



export const SpanWrap = styled.span`
    font-weight: 700;
    font-size: 15px
`