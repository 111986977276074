import React, { useEffect, useState } from 'react'
import { BluePageBg } from '../UserSettings/usersettings.styled'
import UserHeader from '../../components/userHeader'
import UserSidebar from '../../components/userSidebar'

import Chat from './Chat'
import { ChatroomFooter } from './chatroom.styles'
import { useGetMessagesQuery, useGetUserProjectQuery, useSendChatMessageMutation } from '../../services/customerApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useGetConversationListQuery } from '../../services/customerApi'
import Spinner from '../../spinnersvg/Spinner'

const Chatroom = () => {
    const navigate = useNavigate()
    const [projectList, setProjectList] = useState<any>([])
    const [fetchId, setFetchId] = useState()
    const [searchParams] = useSearchParams()
    const [chatMessages, setChatMessages] = useState<any>([])
    const convoId:any = searchParams.get('chatId')
    const projectId:any = searchParams.get('projectId')
    const storedLanguage:any = searchParams.get('language')
    const [chatQuery, setChatQuery] = useState("")
    const [dummyQuery, setDummyQuery] = useState("")
    const [resetChat,setResetChat] = useState(false)
    const [MssgLoop,setMssgLoop] = useState<any>(null)
    const [chunks, setChunks] = useState<any>('');
    const [selectedLanguage,setSelectedLanguage] = useState(typeof storedLanguage === "string"?storedLanguage:"English")
    localStorage.setItem("prolificLastVisited", `/chatroom`);


    // QUERY AND MUTATIONS FROM REDUX TOOLKIT
    const {data, isLoading, isSuccess, refetch, isError} = useGetUserProjectQuery()
    const {data:convoData, isLoading:isConvoLoading, isSuccess:isConvoSuccess, refetch:convoRefetch, isError:isConvoError} = useGetConversationListQuery()

    const {data: getMessagesData, isLoading: getMessagesLoading, isSuccess: getMessagesSuccess, refetch: getMessagesRefetch, isError: isGetMessagesError} = useGetMessagesQuery(convoId || fetchId, {refetchOnMountOrArgChange:true})

    const [sendMessage, {data: sendMessageData, isSuccess: sendMessageSuccess, isLoading: sendMessageLoading, isError: isSendMessageError, error: sendMessageError}] = useSendChatMessageMutation()

    // USEFFECT FOR GETTING PROJECT LIST
    useEffect(() => {
        refetch()
    }, [data])

    
    useEffect(() => {  
        getMessagesRefetch()
    }, [sendMessageData])

    useEffect(()=>{
        const newResponse =  chunks.replace(/\n/g, ' ')  
        const newObj = {query:dummyQuery,response:newResponse}
        setChatMessages((prev:any)=>[...prev,newObj])
    },[resetChat])


    useEffect(() => {
        if(isSuccess) {
            setProjectList(data?.projects)
        }
    }, [data, isLoading, isSuccess, isError])

    // USEFFECT FOR GETTING MESSAGES
    useEffect(() => {
        if(getMessagesSuccess) {
            setChatMessages(getMessagesData?.message)
            setDummyQuery("")
        }
    }, [getMessagesData, getMessagesLoading, getMessagesSuccess ])

    // USEEFFECT FOR SENDING MESSAGES
    useEffect(() => {
        if(sendMessageSuccess) {
            setChatQuery("")
        }

        if(isSendMessageError && 'status' in sendMessageError!) {
            notifyError(sendMessageError?.data?.message)
            setDummyQuery("")
        }

    }, [sendMessageData, sendMessageSuccess, sendMessageError, isSendMessageError])

    useEffect(()=>{
        if(storedLanguage !=='null'){
            setSelectedLanguage(storedLanguage)
        }
        else{
            setSelectedLanguage('English')
        }
        
    },[convoId,projectId])

    useEffect(()=>{
        convoRefetch()
        if(isConvoSuccess){
            convoData.conversations.forEach((element:any,index:any)=>{
                
                if(element.conversationUuid===convoId){
                    if(element.language !== 'English'){
                        setSelectedLanguage(element.language)
                    }

                    else{
                        setSelectedLanguage('English')
                    }
                }
            })
        }
    },[convoData,isConvoSuccess,isConvoLoading])


    const queryParams = {
        query: chatQuery,
        projectUuid: projectId,
        conversationUuid: convoId || fetchId,
        language: selectedLanguage?selectedLanguage.toLocaleLowerCase():'English'.toLocaleLowerCase()
    }

    const handleSendMessage = () => {
        const params:any = {
            query: chatQuery,
            projectUuid: projectId,
            conversationUuid: convoId || fetchId,
        }
        setDummyQuery(chatQuery)
        sendMessage(params)
    }

    const notifyError = (text:string) => toast.error (text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

  return (
    <BluePageBg>
        <ToastContainer />

        <UserHeader 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
            conversationId={convoId || fetchId}
        />
        <UserSidebar 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
            conversationId={convoId || fetchId}
        />

        {isConvoSuccess? <Chat 
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            chatQuery={chatQuery}
            setChatQuery={setChatQuery}
            handleSendMessage={handleSendMessage}
            getMessagesLoading={getMessagesLoading}
            sendMessageLoading={sendMessageLoading}
            dummyQuery={dummyQuery}
            setDummyQuery={setDummyQuery}
            chunks={chunks}
            setChunks={setChunks}
            convoId={convoId}
            projectId={projectId}
            sendMessageSuccess={sendMessageSuccess}
            resetChat={resetChat}
            setResetChat={setResetChat}
            queryParams={queryParams}
            selectedLanguage = {selectedLanguage}
            setSelectedLanguage = {setSelectedLanguage}
        />: <Spinner/>}
    </BluePageBg>
  )
}

export default Chatroom

