import React,{useState,useEffect}from 'react'
import { ContactsHold } from './contactpage.styles'
import { Link, useNavigate } from "react-router-dom";
import Button from '../../components/button';
import ContactCards from './ContactComponents/ContactCards/ContactCards';
import { title } from 'process';
import ContactForm from './ContactComponents/ContactForm/ContactForm';
import { useForm } from "react-hook-form";
import { useContactUsMutation } from '../../services/onboardingApi';
import Utils from '../../library/Utils';
import { ToastContainer, toast } from "react-toastify";
import { ContactMain } from './contactpage.styles';


const env = window._jsenv || process.env
const imageBaseUrl = env.REACT_APP_GCP_BASE_URL;


function ContactPage() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const [message,setMessage] = useState('')
    const methods = useForm({
        mode: "onBlur",
      });


      const [contactUs, { data:contactData, 
        isLoading:contactIsLoading, 
        isSuccess:contactIsSuccess, 
        isError:contactIsError, 
        error: contactError }] =
        
        useContactUsMutation();


    useEffect(()=>{
    },[contactIsSuccess,contactIsLoading])



    const onSubmit = (formData: any) => {
    let finalData = {
        ...formData,
        message:message
        };
        if(Utils.isFieldEmpty(message)){
            toast.info("Kindly Enter your Message.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
        }

        else{
            contactUs(finalData);
        }
    }
  
    const logo = `${imageBaseUrl}assets/svg/logo-white.svg`
    const navigate = useNavigate()


    const contactList = [{
        title:'OPENING HOURS',
        info:'09:00am - 05:00pm',
        titleTop:'EMAIL',
        infoTop:'sales@periculum.io'
    },
    {
        title:'CANADA',
        info:'4000, 421 - 7 Ave SW Calgary Alberta T2P 4K9',
        titleTop:'',
        infoTop:''
    },
    {
        title:'NIGERIA',
        info:'13A Bishop Oluwole Street, Victoria Island Lagos',
        titleTop:'',
        infoTop:''
    },
    {
        title:'KENYA',
        info:'Riverside Square, Riverside Drive, Riverside Nairobi Kenya',
        titleTop:'',
        infoTop:''
    }]
    return (
        <ContactsHold>
            <div className="auth-header">
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
            </div>

            <div className="btn-wrap">
                  <Button
                    btnType={"backWhite"}
                    text={"Go home"}
                    onClick={() => navigate("/")}
                  />
            </div>
            <ContactMain >

                <section className='contactLhs'>
                    {contactList.map((element:any,index:any)=>
                    <ContactCards title={element.title}
                                  info={element.info}
                                  titleTop={element.titleTop}
                                  infoTop={element.infoTop}
                                  index={index}/>)}
                </section>

                <section className='contactRhs'>
                    <ContactForm methods={methods} 
                                 onSubmit={onSubmit} 
                                 setMessage={setMessage}
                                 contactIsLoading={contactIsLoading}/>
                </section>
            </ContactMain>
            <div className="auth-footer">
                  <p>{`© Copyright ${currentYear} - Prolific Technologies Inc. || All Rights Reserved`}
                  </p>
            </div>

        </ContactsHold>
    )
    }

export default ContactPage

