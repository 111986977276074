import React, { useEffect, useState } from 'react'
import { AccessContentWrap, AccessListWrap, FreeAccesText, GiftcardBtn, GiftcardWrap } from './giftcard.styles'
import ToggleBar from '../togglebar'

const Giftcard = ({accessType, queryNum, chatbotNum, price, onClick, time, btnText}:any) => {
    const [access, setAccess] = useState("starter")

    // useEffect(() => {
    //     if(accessType === "growth") {
    //         setAccess("starter")
    //     }else if(accessType === "standard") {
    //         setAccess("growth")
    //     }else if (accessType === "enterprise") {
    //         setAccess ("standard")
    //     }else {
    //         return
    //     }
    // }, [accessType])

  return (
    <GiftcardWrap accessType={accessType}>
        <div className="inner-gift-wrap">
            <div className="top-card-section">
                    <div className="card-type">{accessType}</div>

                    <div className="card-price">${`${(Number(price))?.toLocaleString('en-US')}`}</div>

                    <div className="card-info">
                        <p>per account, billed {time}</p>  
                    </div>

                    {/* <div className="currency-control">
                        <div className="currency">NGN</div>
                        <ToggleBar />
                        <div className="currency">USD</div>
                    </div> */}
            </div>

            <div className="access-type-section">
                <div className="content-header">With this plan, get access to</div>

                {
                    accessType === "starter" ? 
                    <AccessContentWrap accessType={access}>
                        <div className="text-section">
                            <p>{queryNum?.toLocaleString()} queries</p>
                            <p>{chatbotNum} chatbots</p>
                            <p>Shareable links</p>
                            <p>API access</p>
                            <p>Unlimited website embeddings</p>
                        </div>
                    </AccessContentWrap> :
                    <AccessContentWrap accessType={access}>
                        <div className="access-type-box">
                            {access}
                        </div>

                        <div className="plus-wrap">
                            +
                        </div>

                        <div className="text-section">
                            <p>{queryNum?.toLocaleString()} queries</p>
                            <p>{chatbotNum} chatbots</p>
                        </div>
                    </AccessContentWrap>                    
                }
            </div>

            <GiftcardBtn accessType={accessType} onClick={onClick}>
                {btnText}
            </GiftcardBtn>
            <FreeAccesText>
                Enjoy free access to 1 chatbot and 50 messages.
            </FreeAccesText>
        </div>
    </GiftcardWrap>
  )
}

export default Giftcard