import { DateTime } from 'luxon';

const Utils = {
  formatDate: function (date) {
    if(!Utils.isFieldEmpty(date)) {
      let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

      if (month.length < 2) {
        month = '0' + month;
      }
        
      if (day.length < 2) {
        day = '0' + day;
      }

      return [year, month, day].join('-');
    } else {
      return null;
    }      
  },
  formatLongDate: function (date) {
    if(!Utils.isFieldEmpty(date)) {
      const longDate = DateTime.fromISO(date);
      const longDateReadable = longDate.toLocaleString(DateTime.DATE_FULL);
  
      return longDateReadable;
    } else {
      return "N/A"
    }    
  },
  formatLongDateMonth: function (date) {
    if (date !== undefined && date !== null) {
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      let stringArray = date.split('/');
      let month = monthNames[parseInt(stringArray[0]) - 1];
      let year = stringArray[1];

      return month + " " + year;
    } else {
      return "N/A"
    }
  },
  formatPercentage: function (number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'percent'
    });

    return formatter.format(number);
  },
  formatNumber: function(numFormat, number) {
    const formatter = new Intl.NumberFormat(numFormat);

    if(number === 'N/A' || number === 'NaN' || number === null) {
      return formatter.format(0);
    } else {
      return formatter.format(number);
    }
  },
  formatCurrency: function (numFormat, currSymbol, number) {
    const formatter = new Intl.NumberFormat(numFormat, {
      style: 'currency',
      currency: currSymbol,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (number === 'N/A' || isNaN(number) === true || Utils.isFieldEmpty(number)) {
      number = formatter.format(0);
    } else {
      number = formatter.format(number);
    }

    return number;
  },
  formatDateSlash: function(date) {
    let dateSplit = date.toISOString().split('T')[0];
    return dateSplit.replace(/-/g, "/");
  },
  formatTextColor: function(text, textColor, red, green, yellow, range) {
    let color = textColor;

    if (text === 'Yes' || text === 'Positive Cash Flow' || text === 'PROCESSED' || text.toUpperCase() === 'PASSED'
      || (range !== null && range !== undefined
        && (parseInt(text) >= range.thirdVal && parseInt(text) < range.maxVal)
        && range.type === 'credit')) {
      color = green;
    } else if (text === 'No' || text === 'Negative Cash Flow' || text.toUpperCase() === 'FAILED'
      || (range !== null && range !== undefined
        && (parseInt(text) >= range.minValue && parseInt(text) < range.secondVal)
        && range.type === 'credit')) {
      color = red;
    } else if (text === 'Equal Cash Flow' || text === 'UPLOADED' || text === 'PENDING'
      || (text === '0')
      || (range !== null && range !== undefined
        && (parseInt(text) >= range.secondVal && parseInt(text) < range.thirdVal)
        && range.type === 'credit')) {
      color = yellow;
    }

    return color;
  },
  capitalizeFirstLetter: function (text) {
    if(text !== null && text !== undefined) {
      let textArray = (text.toLowerCase()).split(' ');
    
      textArray.forEach((word, i) => {
        textArray[i] = word.charAt(0).toUpperCase() + word.slice(1);
      });
      return textArray.join(' ');
    } else {
      return 'N/A'
    }
    
  },
  checkNull: function (detail) {
    if(Utils.isFieldEmpty(detail)) {
      return 'N/A';
    } else {
      return detail;
    }
  },
  checkNullNumber: function (detail) {
    if(Utils.isFieldEmpty(detail)) {
      return 0;
    } else {
      return detail;
    }
  },
  isFieldEmpty: function (item) {
    if(item === "" || item === undefined || item === null 
      || (Array.isArray(item) && item.length === 0)
      || (typeof item === 'object' && Object.keys(item).length === 0)) {
      return true;
    }
    return false;
  },
  isTokenExpired: function (accessExpiry, currentDateTime){
    if(accessExpiry < currentDateTime) {
      return true;
    }
    return false;
  },
  handleOverflowTextTableCell: function(fields) {
    let stringLength = fields.cellValue.length;

    function showOverflow() {
      let ellipses = document.getElementById(fields.ellipsesID);
      let extraText = document.getElementById(fields.extraTextID);
      let showButton = document.getElementById(fields.buttonID);

      if (ellipses.style.display === "none") {
        ellipses.style.display = "inline";
        showButton.innerHTML = "Show more"; 
        extraText.style.display = "none";
      } else {
        ellipses.style.display = "none";
        showButton.innerHTML = "Show less"; 
        extraText.style.display = "inline";
      }
    }

    if(stringLength > fields.maxLength) {
      let firstPart = (fields.cellValue).substring(0, fields.maxLength);
      let secondPart = (fields.cellValue).substring(fields.maxLength, stringLength);

      return <>
        <p className="table-overflow-container">
          {firstPart}
          <span className='table-overflow-ellipses' id={fields.ellipsesID}>...</span>
          <span className='table-overflow-text-extra' id={fields.extraTextID}>{secondPart}</span>
          <button className='table-show-overflow-button' id={fields.buttonID} onClick={() => showOverflow()} style={{
            color: fields.buttonColor
          }}>Show more</button>
        </p>
      </>
    } else {
      return (
        <>{fields.cellValue}</>
      );
    }
  },
  getCurrentYear: function() {
    let date = new Date();

    return date.getFullYear();
  }
}

export default Utils;