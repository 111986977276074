import React, { useEffect, useState } from 'react'
import { PageBanner } from '../ChoosePlan/chooseplan.styles'
import Modal from 'react-modal';

import logo from "../../assets/svg/logo-white.svg"
import cancel from "../../assets/svg/cancel.svg"
import TabHeader from '../../components/tabHeader'
import { useGetPlanListQuery } from '../../services/customerApi'
import Giftcard from '../../components/giftcard'
import Button from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, RootState } from '../../store';
import Payment from '../../components/payment';

if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
}

const PayPlan = () => {
    const navigate = useNavigate()
    const [modalIsOpen, setIsOpen] = useState(false);
    const [chosenPlan, setChosenPlan] =  useState()
    const [planType, setPLanType] = useState("monthly")
    const [monthlyPlans, setMonthlyPlans] = useState<any>([])
    const [yearlyPlans, setYearlyPlans] = useState<any>([])
    let lastVisited:any = localStorage.getItem('prolificLastVisited');

    const auth = useAppSelector<any>((store:RootState) => store?.auth?.auth)

    const {data, isLoading, isSuccess, refetch} = useGetPlanListQuery()

    // USEEFFECT TO GET THE PLANS
    useEffect(() => {
        refetch()
    }, [data])

    useEffect(() => {
        if(isSuccess) {
            setMonthlyPlans(data?.monthly)
            setYearlyPlans(data?.yearly)
        }
    }, [data, isSuccess])

    const customStyles = {
        content: {
            transition: '0.125s ease-in-out',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "transparent",
            height: '60%',
            width: 'unset',
            top: '50%',
            left: '50%',
            padding: '0 0 0 0',
            // borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignCenter: 'center',
            border: 'none',
        },
        overlay: {
          zIndex: 1000,
          position: 'fixed',
          minHeight: '100vh',
          left: '0px',
          top: '0px',
        }
    };

    function afterOpenModal() { 

    }

    function openModal() {
        setIsOpen(true);
      }

    function closeModal() {
        setIsOpen(false);
    }  

    const handleModalCall = (item:any) => {
        setChosenPlan(item)
        openModal()
    }

  return (
    <PageBanner>
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles} contentLabel="" >
            <Payment close={closeModal} auth={auth} chosenPlan={chosenPlan} lastVisited={lastVisited} />
        </Modal>

        <div className="inner-section">
            <div className="header-nav">
                <img src={logo} onClick={() => navigate("/")}/>

                <Button 
                    btnType={'backWhite'}
                    text={'Cancel'}
                    icon={cancel}
                    onClick={() => navigate(lastVisited)}
                />
            </div>

            <div className="body-wrap">
                <div className="big-text-wrap">
                    Pay for your subscription plan

                    <TabHeader className={"choose-plan"} planType={planType} setPLanType={setPLanType} />
                </div>

                <div className="plans-wrap">
                    {
                        planType === "monthly" ? 
                        (
                            monthlyPlans?.map((item:any, index:any) => {
                                return (
                                    <Giftcard 
                                        key={index}
                                        accessType={item?.name}
                                        queryNum={item?.totalMessages}
                                        chatbotNum={item?.totalChatbots}
                                        price={item?.price}
                                        onClick={() => handleModalCall(item)}
                                        time={item?.time}
                                        btnText={"Subscribe"}
                                    />
                                )
                            })
                        ) :
                        (
                            yearlyPlans?.map((item:any, index:any) => {
                                return (
                                    <Giftcard 
                                        key={index}
                                        accessType={item?.name}
                                        queryNum={item?.totalMessages}
                                        chatbotNum={item?.totalChatbots}
                                        price={item?.price}
                                        onClick={() => handleModalCall(item)}
                                        time={item?.time}
                                        btnText={"Subscribe"}
                                    />
                                )
                            })
                        )
                    }
                </div>
            </div>
        </div>
    </PageBanner>
  )
}

export default PayPlan