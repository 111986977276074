import React from 'react'
import { StepsWrapper } from '../step1/firststep.styles'
import Button from '../../../components/button'
import { EmptyBox, UploadSection, UploadedFilesField, WrapInputField } from '../step2/secondstep.styles'
import AddFile from '../../../components/addFile'
import Input from '../../../components/input'
import { AiAvatarSelection, AiSuggestedName, AvatarCircle, BlueBox, NameSuggestionBox } from './thirdstep.styles'

import backBlue from "../../../assets/svg/back-blue.svg"
import avatar from "../../../assets/svg/ai-profile.svg"
import addCircle from "../../../assets/svg/add-circle-blue-bg.svg"
import { useNavigate } from 'react-router-dom'



const ThirdStep = ({setStepShown, description, setDescription, aiName, setAiName, setChosenIcon, chosenIcon, handleCreateProject}:any) => {
    const navigate = useNavigate()

    const nameSuggestions = [
        "Sara", "Pete" , "Gavie", "Lenie", "Kris"
    ]


  return (
    <StepsWrapper>
        <div className="text-wrap">
            <p className="steps-header">
                STEP 3
            </p>

            <h3 className="topic-header">
                Customize your AI
            </h3>

            <p className="instruction-content">
            Select from a range of use cases that will infuse your bot with a distinctive purpose. Choose one that works best with your objectives.
            </p>
        </div>

        <UploadSection>
            <WrapInputField>
                <label htmlFor="">Name your AI</label>
                <Input holder="Gavie is a name of my AI" inputValue={aiName} handleInputChange={setAiName} />

                <NameSuggestionBox>
                    <p>Suggestions</p>

                    <div className="suggestion-list">
                        {
                            nameSuggestions?.map((item:string, id:any) => {
                                return (
                                    <AiSuggestedName key={id} onClick={() => setAiName(item)}>
                                        {item}
                                    </AiSuggestedName>
                                )
                            })
                        }

                    </div>
                </NameSuggestionBox>
            </WrapInputField>

            {/* <AiAvatarSelection>
                <h2>Choose your AI icon</h2>

                <BlueBox>
                    <div className="inner-blue-wrap">
                        <div className="add-circle-wrap">
                            <img src={addCircle} alt="" />
                        </div>

                        <AvatarCircle textColor={"#f699cd"}>
                            <div className="circle">
                                <p>{aiName.charAt(0)}</p>
                            </div>
                        </AvatarCircle>

                    </div>
                </BlueBox>
            </AiAvatarSelection> */}



            <WrapInputField>
                <label htmlFor="">Description <span> (optional)</span> </label>
                <Input holder="Give your AI a description" handleInputChange={setDescription} inputValue={description}  />
            </WrapInputField>
        </UploadSection>



        <div className="steps-footer-box">
            <Button 
                text={'Back'}
                btnType={"cancel"}
                icon={backBlue}
                onClick={() => setStepShown("second")}
            />

            <Button 
                text={"Finalise"}
                btnType={aiName ? "cta" : "cta-inactive"}
                onClick={() => {
                    // navigate("/chatroom")
                    handleCreateProject()
                }}
            />
        </div>
    </StepsWrapper>
  )
}

export default ThirdStep