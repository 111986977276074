import React from 'react'
import { ErrorAbsolute, InputWrap } from './input.styles'

const Input = ({styleType, holder, type, img, handleInputChange, inputValue, inputerror}: any) => {
  return (
    <>
        <InputWrap styleType={styleType} error={inputerror}>

            <div className="inner-input-wrap">
                {
                    styleType === "inline-flex" && 
                    <div className="text-wrap">Email</div>
                }

                {
                    type === "select" ? 
                    <select>
                        <option value="">Nigeria</option>
                    </select>
                    :
                    <input type={type} placeholder={holder} onChange={(e) => handleInputChange(e.target.value) } value={inputValue}/>
                }

                {styleType === "normal-flex" && 
                    <img src={img} />
                }
            </div>

            {
                inputerror && 
                <ErrorAbsolute>
                    Please type in a valid url
                </ErrorAbsolute>
            }
        </InputWrap>

    </>
  )
}

export default Input