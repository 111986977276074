import React, { useEffect, useState } from 'react'
import { PageBanner } from './chooseplan.styles'

import logo from "../../assets/svg/logo-white.svg"
import cancel from "../../assets/svg/cancel.svg"
import TabHeader from '../../components/tabHeader'
import { useGetPlanListQuery } from '../../services/customerApi'
import Giftcard from '../../components/giftcard'
import Button from '../../components/button'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, RootState } from '../../store';

const ChoosePlan = () => {
    const navigate = useNavigate()
    const [planType, setPLanType] = useState("monthly")
    const [monthlyPlans, setMonthlyPlans] = useState<any>([])
    const [yearlyPlans, setYearlyPlans] = useState<any>([])

    const auth = useAppSelector<any>((store:RootState) => store?.auth?.auth)

    const {data, isLoading, isSuccess, refetch} = useGetPlanListQuery()

    // USEEFFECT TO GET THE PLANS
    useEffect(() => {
        refetch()
    }, [data])

    useEffect(() => {
        if(isSuccess) {
            setMonthlyPlans(data?.monthly)
            setYearlyPlans(data?.yearly)
        }
    }, [data, isSuccess])


  return (
    <PageBanner>
        <div className="inner-section">
            <div className="header-nav">
                <img src={logo} onClick={() => navigate("/")}/>

                <Button 
                    btnType={'backWhite'}
                    text={'Cancel'}
                    icon={cancel}
                    onClick={() => navigate("/")}
                />
            </div>

            <div className="body-wrap">
                <div className="big-text-wrap">
                    Choose your subscription plan

                    <TabHeader className={"choose-plan"} planType={planType} setPLanType={setPLanType} />
                </div>

                <div className="plans-wrap">
                    {
                        planType === "monthly" ? 
                        (
                            monthlyPlans?.map((item:any, index:any) => {
                                return (
                                    <Giftcard 
                                        key={index}
                                        accessType={item?.name}
                                        queryNum={item?.totalMessages}
                                        chatbotNum={item?.totalChatbots}
                                        price={item?.price}
                                        onClick={() => navigate("/signup")}
                                        time={item?.time}
                                        btnText={"Get Started"}
                                    />
                                )
                            })
                        ) :
                        (
                            yearlyPlans?.map((item:any, index:any) => {
                                return (
                                    <Giftcard 
                                        key={index}
                                        accessType={item?.name}
                                        queryNum={item?.totalMessages}
                                        chatbotNum={item?.totalChatbots}
                                        price={item?.price}
                                        onClick={() => navigate("/signup")}
                                        time={item?.time}
                                        btnText={"Get Started"}
                                    />
                                )
                            })
                        )
                    }
                </div>
            </div>
        </div>
    </PageBanner>
  )
}

export default ChoosePlan