import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import {
  default as Locale,
  default as en,
} from "react-phone-number-input/locale/en.json";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import "react-phone-number-input/style.css";
import { ErrorAbsolute, InputWrap } from "../input/input.styles";

function PhoneNumber({
  styleType,
  fields,
  setUserDigit,
  setSelectedCountry,
  selectedCountry,
  showLabel,
  label
}: any) {
  // const [country, setCountry] = useState<Country>("US");
  const [value, setValue] = useState("");
  const {
    formState: { errors },
  } = useFormContext();

  const onChangePhone = (number: any) => {
    setValue(value);
    setUserDigit(number);
  };
  const handleCountryCodeChange = (value: any) => {
    const a = getCountries();
    let countryName;
    if (value) {
      countryName = value;
    } else {
      countryName = "NG";
    }
    // const b = getName(countryName);
    // setSelectedCountry(getName(countryName));

    setSelectedCountry(value);
  };
  return (
    <div style={{width:"100%"}}>
      {showLabel && <section style={{color:'#062149',fontSize:"18px",fontWeight:"600",marginBottom:"10px"}}>{label}</section>}
      <InputWrap
        styleType={styleType}
        error={errors[fields.register] ? true : false}
      >
        <div className="inner-input-wrap">
          <CountrySelect
            labels={en}
            value={selectedCountry}
            onChange={setSelectedCountry}
            showShortName={true}
            showLabel={true}
            showDialCode={true}
          />
          <PhoneInput
            placeholder="Enter phone number"
            value={value}
            country={selectedCountry}
            name="phone"
            onChange={onChangePhone}
            defaultCountry="US"
            international={false}
            // onCountryChange={handleCountryCodeChange}
          />
        </div>
        {errors[fields.register] && (
          <ErrorAbsolute>
            {/* @ts-ignore */}
            {errors[fields?.register]?.message}
          </ErrorAbsolute>
        )}
      </InputWrap>
    </div>
  );
}

const sortComparer = (a: string, b: string) => {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

type CountrySelectProps = {
  value: string;
  showLabel?: boolean;
  showShortName?: boolean;
  labels: Locale;
  onChange: (e: string | undefined) => void;
  showDialCode?: boolean;
};

export const CountrySelect = ({
  value,
  onChange,
  labels,
  showShortName,
  showLabel,
  showDialCode,
  ...rest
}: CountrySelectProps) => {
  return (
    <select
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
    >
      <option value="">{labels["ZZ"]}</option>
      {getCountries()
        .sort(sortComparer)
        .map((country) => {
          const label = showShortName ? country : labels[country];
          const dialCode = getCountryCallingCode(country);
          return (
            <option key={country} value={country}>
              {showLabel && label} {showDialCode && <>({dialCode})</>}
            </option>
          );
        })}
    </select>
  );
};

export const CountrySelectInput = ({
  styleType,
  selectedCountry,
  country,
  setCountry,
  showLabel,
}: any) => {
  return (
    <div style={{width:"100%"}}>

      <InputWrap styleType={styleType} error={false}>
        <div className="inner-input-wrap">
          <CountrySelect
            labels={en}
            value={country}
            onChange={setCountry}
            showLabel={showLabel}
          />
        </div>
      </InputWrap>
    </div>

  );
};
export default PhoneNumber;
