import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../button";
import {
  HeaderList,
  HeaderSublist,
  Headernav,
  Headerwrap,
  MobileMenu,
  Overlay,
} from "./header.styles";
import cancel from "../../assets/svg/cancel-menu.svg";
import hamburger from "../../assets/svg/hamburger.svg";
import logo from "../../assets/svg/prolific-logo.svg";
const AmbassadorHead = ({ pricingRef, featureRef, contactRef, tag }: any) => {
  const navigate = useNavigate();
  const [overlay, setOverlay] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const navList = [
    {
      name: "Home",
      link: "/",
      onClick: () => {
        return;
      },
    },
    { name: "Product", link: "/#feature" },
    { name: "Be an Ambassador", link: "/ambassador" },
    { name: "Pricing", link: "/#pricing" },
    { name: "Contact", link: "/#contact" },
  ];
  const handleMobileMenu = () => {
    setOverlay(!overlay);
    setMobileActive(!mobileActive);
  };
  const redirectToSignUp = () => {
    // keycloakContext.register({
    //   redirectUri: config.keycloak.registerRedirectUrl
    // });
    navigate("/choose-plan");
  };
  const login = () => {
    // keycloakContext.login({
    //   scope: config.keycloak.scope,
    //   redirectUri: config.keycloak.loginRedirectUrl
    // });
    navigate("/login");
  };
  return (
    <>
      <Overlay activeOverlay={overlay} onClick={handleMobileMenu} />
      <Headerwrap>
        <Headernav>
          <div className="logo-wrap">
            <img src={logo} alt="" onClick={() => navigate("/")} />
          </div>
          <HeaderList tag={tag}>
            {navList?.map((item, id) => {
              if(id==2 || id ==4){

                if (id == 2) {
                  return (
                    <span
                      onClick={() => navigate("/ambassador")}
                      style={{
                        fontSize: "16px",
                        color: "#407BFF",
                        cursor: "pointer",
                        lineHeight: "2.4rem",
                        fontWeight: "400",
                      }}
                    >
                      {item.name}
                    </span>
                  );
                }

                if(id == 4){
                  return (
                    <span
                      onClick={() => navigate("/contact-us")}
                      style={{
                        fontSize: "16px",
                        color: "#062149",
                        cursor: "pointer",
                        fontWeight: "500",
                      }}
                    >
                      {item.name}
                    </span>
                  );
                }
              } else {
                return (
                  <HeaderSublist key={id} tag={tag}>
                    {" "}
                    <Link to={`${item.link}`}>{item.name}</Link>{" "}
                  </HeaderSublist>
                );
              }
            })}
          </HeaderList>
          <div className="cta-section">
            {/* <Button
              btnType={'link'}
              text={'Sign in'}
              onClick={login}
            /> */}
            <Button
              btnType={"cta"}
              text={"Sign in"}
              onClick={login}
              tag={tag}
            />
          </div>
          <div className="hamburger">
            <img src={hamburger} alt="" onClick={handleMobileMenu} />
          </div>
        </Headernav>
      </Headerwrap>
      {/* MOBILE MENU */}
      <MobileMenu mobileActive={mobileActive}>
        <div className="inner-mobile-wrap">
          <div className="close-menu-box">
            <img
              src={cancel}
              alt=""
              onClick={() => {
                handleMobileMenu();
              }}
            />
          </div>
          <ul className="mobile-list">
            {navList?.map((item, index) => {
              if(index == 4){
                return (
                  <p
                    onClick={() => navigate("/contact-us")}
                    style={{
                      marginTop: '20px',
                      fontSize: "1.1rem",
                      color: "#062149",
                      cursor: "pointer",
                      fontWeight: "600",
                    }}
                  >
                    {item.name}
                  </p>
                );
              }
              else{

                return (
                  <li key={index}>
                    <Link to={`${item.link}`}>{item.name}</Link>
                  </li>
                );
              }
            })}
          </ul>
          <div className="mobile-cta-wrap">
            {/* <Button
              btnType={'link'}
              text={'Sign in'}
              onClick={login}
            /> */}
            <Button btnType={"cta"} text={"Sign in"} onClick={login} />
          </div>
        </div>
      </MobileMenu>
    </>
  );
};
export default AmbassadorHead;