import styled from "styled-components"

export const ChatroomSection = styled.div `
    width: calc(100%);
    height: calc(100vh-40px);
    position: relative;
    

    .inner-chatroom-wrap {
        padding: 140px 5em 3em 5em;
        display: flex;
        flex-direction: column;
        box-sizing:border-box;
    
        .chatbox-wrapper {
            height:auto;
            .chatbox-header {
                display: flex;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                padding: 0 40px;
                justify-content: space-between;
                align-items: center;
                height: 80px;
                border: 1px solid var(--primary-50, #E1EEFF);
                background-color: #FCFDFF;
                box-shadow: 0px 1px 0px 0px rgba(17, 98, 220, 0.21);
             
                .left-side {
                    width:100%;
                    display:flex;
                    justify-content:space-between;
                    align-items:center;
                    h3.head {
                        font-size: 1.2rem;
                        color: #062149;
                        font-weight: 700;
                        margin: 0;
                    }

                    .dropDownHolder{
                        display:flex;
                        flex-direction:column;
                        row-gap:10px;
                        height:auto;
                        position:relative;
                        width:120px;

                        .selectSection{
                            display:flex;
                            align-items:center;
                            border-radius:5px;
                            box-shadow:1px 3px 2px 0px rgba(17, 98, 220, 0.21);
                            column-gap:4px;
                            padding:13px;
                            background-color:white;
                            justify-content:center;
                            cursor:pointer;

                            .imageHold{
                                display:flex;
                                align-items:center;
                                justify-content:center;
                            }
                        }

                        .langArray{
                            width:100%;
                            z-index:15;
                            background-color:white;
                            flex-direction:column;
                            height:200px;
                            overflow:auto;
                            margin-top:10px;
                            position:absolute;
                            top:40px;
                            border-radius:5px;
                            box-shadow:1px 3px 2px 0px rgba(17, 98, 220, 0.21);
                            display:flex;
                            border:0.1px solid black;
                            border-width: 0px 0px 0.1px 0px;
                        }
                        .langArrayHide{
                            display:none;
                        }
                    }
                }

                .right-side {
                    display: flex;
                    column-gap: 15px;
                    align-items: center;
                }
            }

            .chatbox-body {
                background-color: #fff;
                border: 1px solid var(--primary-50, #E1EEFF);
                height: 460px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
                padding: 20px 0;

                .center-box-wrap {
                    display: flex;
                    width: 50%;
                    align-items: center;
                    justify-content: center;
                    gap: 60px;

                    img {

                    }

                    .text {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        row-gap: 20px;

                        h3 {
                            font-size: 2.5rem;
                            margin: 0;
                            font-weight: 800;
                            color: #062149;
                        }

                        p {
                            font-size: 1.2rem;
                            font-weight: 400;
                            color: #062149;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1220px) {
        width: calc(100%);
    }

    @media only screen and (max-width: 1100px) {
        .inner-chatroom-wrap {
            padding: 140px 5em 3em 5em;
        }
    }

    @media only screen and (max-width: 992px) {
        width: 100%;
    }

    @media only screen and (max-width: 680px) {
        .inner-chatroom-wrap {
            .chatbox-wrapper {
                .chatbox-body {
                    .center-box-wrap {
                        flex-direction: column;
                        gap: 30px;

                        .text {
                            gap: 8px;
                            h3 {
                                text-align: center;
                            }

                            p {
                                text-align: center;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 506px) {
        .inner-chatroom-wrap {
            padding: 140px 0em 3em 20px;
        }
    }
`

export const ChatQuestionBox = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid var(--primary-50, #E1EEFF);
    background-color: var(--generic-white, #FFF);
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height:auto;
    
    overflow: auto;
    width:100%;
    box-sizing:border-box;

    textarea {
        border: none;
        width:99%;
        height:100%;
        resize:none;
        outline: none;
        background-color: transparent;
        lineHeight:100px;
        color: #062149;
        font-size: 0.8rem;
        font-weight: 400;
        white-space: pre-wrap;
        overflow: auto;
        overflow-x:hidden;
        box-sizing:border-box;

        &::-webkit-scrollbar {
            width: 15px;
            background: #F3F8FF;
            border-radius: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: rgba(17, 98, 220, 1);
            border-radius: 10px;
        }

        &::placeholder {
            color: #A3A3A3;
            font-size: 0.8rem;
            font-weight: 400;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }

    section::-webkit-scrollbar {
        width: 0px;
        background: #F3F8FF;
        border-radius: 10px;
    }

    section::-webkit-scrollbar-thumb {
        background: rgba(17, 98, 220, 1);
        border-radius: 10px;
    }


    .send-btn {
        display: flex;
        justify-content: center;
        img {
            cursor: pointer;
        }
    }
`

export const ChatroomFooter = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    p {
        margin: 0;
        text-align: center;
        color: #062149;
        font-size: 0.8rem;
    }
`