import styled from "styled-components"

export const UserHeaderWrap = styled.header<{colorState?: string}>`
    background-color: #fff;
    height: 80px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    background-color:${(props:any) => props?.colorState === 'white' ? "white" : "black"} ;
    transition: background-color 0.5s ease;

    .access-info-bar {
        position: fixed;
        top: 80px;
        right: 0;
        width: 100%;
        // border: solid 1px red;
        height: 40px;
        //display: flex;
        display:none;
        justify-content: flex-end;
    }

    .inner-user-header {
        display: flex;
        flex-direction : row;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 80px;
        background-color:${(props:any) => props?.colorState === 'white' ? "white" : "black"} ;
        transition: background-color 0.5s ease;


        .left-side {
            img {
                cursor: pointer;
            }
        }

        .right-side {
            display: flex;
            align-items: center;
            column-gap: 20px;
            padding: 15px;

            .toggle-wrap {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        background-color: #F3F8FF;

        

        .inner-user-header {
            padding: 0 60px;
            justify-content:start;
            height:100%;

            .right-side {
                button {
                    display: none;
                }
                
                .toggle-wrap {
                    display: block;
                }
            }

            .left-side {
                display:none;
               
            }
        }

        
    }

    @media only screen and (max-width: 768px) {
        .inner-user-header {
    
            padding: 0 40px;
        }
    }

    @media only screen and (max-width: 576px) {
        .inner-user-header {
         
            padding: 0 20px;

            .left-side {
                display:none;
                img {
                    width: 120px;
                }
            }
        }
    }
`

export const ShareWhiteBox = styled.div `
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 15px 20px;
    width: 100%;
    justify-content: center;

    .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: solid 1px #F5F5F5;
        padding-bottom: 10px;

        h3 {
            font-size: 1.1rem;
            font-weight: 700;
            color: #062149;
            margin: 0;
        }

        img {
            cursor: pointer;
        }
    }

    .form-wrap {
        border-bottom: solid 1px #F5F5F5;
        padding-bottom: 20px;
        p {
            color: #062149;
            font-size: 0.8rem;
            font-weight: 400;
            margin-bottom: 10px;
        }

        form {
            display: flex;
            align-items: center;
            column-gap: 10px;
        }
    }

    .flexd-wrap {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
`

export const UserMobileHeader = styled.div <{active?:boolean,colorState?: string}> `
    position: fixed;
    top: 0;
    left: ${(props:any) => props.active ? '0' : '-700px'};
    width: 40%;
    background-color: #fff;
    z-index: 500;
    height: 100vh;
    transition: all 0.5s ease;
    display: none;
    overflow:auto;

    .inner-user-mobile-wrap {
        width: 100%;
        height: 100vh;
        position:relative;
        

        .header-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            height:8%;
            top:0px;
            background-color:${(props:any) => props?.colorState === 'white' ? "#FBFDFF" : "#131415"} ;
            transition: background-color 0.5s ease;

            .logoHold{
                img {
                    cursor: pointer;
                }
            }

            h3.text {
                font-weight: 700;
                margin: 0;
                font-size: 1.4rem;
                color: #000;
            }

            img {
                cursor: pointer;
            }
        }

        .nav-wrap {
            width:calc(100%-30px);
            position:absolute;
            height:90%;
            bottom:0px;
            left:15px;
            right:15px;
        }

    }


    @media only screen and (max-width: 992px) {
        display: block;
    }

    @media only screen and (max-width: 768px) {
        width: 60%;
    } 

    @media only screen and (max-width: 576px) {
        width: 100%;
    }

    

`