import React,  {useState} from 'react'
import { ChatroomFooter, ChatroomSection } from '../../Chatroom/chatroom.styles'
import { ProjectNameWrap, ProjectOptions, ProjectsOverall } from '../chooseproject.styles'

import file from "../../../assets/svg/blue-file.svg"
import Button from '../../../components/button'

const NewChatSettings = ({projectList, projectChosen, setProjectChosen, projectTitle, setProjectTitle, handleCreateChat}:any) => {

    const handleProjectChosen = (item:any) => {
        setProjectChosen(item?.projectUuid)
    }


  return (
    <ChatroomSection>
        <div className="inner-chatroom-wrap">
            <div className="chatbox-wrapper">
                <div className="chatbox-header">
                    <div className="left-side">
                        <h3 className="head">Projects</h3>
                    </div>
                </div>

                <div className="chatbox-body">
                    <ProjectsOverall>
                        <div className="header-text-wrap">
                            <h3>Choose a project</h3>
                        </div>

                        <div className="flex-column-wrap">
                            <div className="project-flex-wrap">
                                {
                                    projectList?.map((item:any, index:any) => {
                                        return (
                                            <ProjectOptions active={projectChosen === item?.projectUuid ? true : false} key={index} onClick={() => handleProjectChosen(item)}>
                                                <div className="left-side">
                                                    <img src={file} alt="" />
            
                                                    <div className="txt-wrap">
                                                        <h5>{item?.name}</h5>
                                                        <p>{item?.chatbotDescription}</p>
                                                    </div>
                                                </div>
            
                                                <div className="radio-wrap"></div>
                                            </ProjectOptions>            
                                        )
                                    })
                                }
                            </div>

                            <ProjectNameWrap>
                                <input type="text" placeholder='Type in your project title' value={projectTitle} onChange={((e:any) => setProjectTitle(e.target.value))} />

                                <Button text={"Save"} btnType={ (projectChosen && projectTitle) ? "inline" : "inline-inactive"} onClick={handleCreateChat} />
                            </ProjectNameWrap>
                        </div>

                    </ProjectsOverall>
                </div>
            </div>

            <ChatroomFooter>
              <p>© Copyright 2023 - Prolific Technologies</p>
            </ChatroomFooter>
        </div>
    </ChatroomSection>
  )
}

export default NewChatSettings