import styled from "styled-components";

export const ContactFlier = styled.section `
    width:100%;
    padding:40px;
    box-sizing:border-box;
    border-radius:10px;
    background-color:white;
    height:auto;

`