import { useEffect, useState } from 'react'
import { BluePageBg } from './chatroom.styles'
import IndividualSideBar from '../../components/IndividualSideBar'
import IndividualUserHeader from '../../components/IndividualUserHeader'
import Modal from "react-modal";

import Chat from './Chat'
import { useGetMessagesQuery, 
         useGetUserProjectQuery, 
         useSendChatMessageMutation,
          } from '../../services/customerApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { useGetConversationListQuery,useStoreChatMutation } from '../../services/customerApi'
import Spinner from '../../spinnersvg/Spinner'
import ChatCreationModal from '../../components/modals/IndividualChatCreation/ChatCreationModal'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'

const IndividualChatRoom = () => {
    const navigate = useNavigate()
    const [projectList, setProjectList] = useState<any>([])
    const [fetchId, setFetchId] = useState()
    const [searchParams] = useSearchParams()
    const [chatMessages, setChatMessages] = useState<any>([])
    const convoId:any = searchParams.get('chatId')
    const projectId:any = searchParams.get('projectId')
    const storedLanguage:any = searchParams.get('language')
    const [chatQuery, setChatQuery] = useState("")
    const [dummyQuery, setDummyQuery] = useState("")
    const [resetChat,setResetChat] = useState(false)
    const [MssgLoop,setMssgLoop] = useState<any>(null)
    const [chunks, setChunks] = useState<any>('');
    const [selectedLanguage,setSelectedLanguage] = useState(typeof storedLanguage === "string"?storedLanguage:"English")
    localStorage.setItem("prolificLastVisited", `/individual-chatroom?chatId=${convoId}&projectId=${projectId}&language=${storedLanguage}`);

    const [modalIsOpen, setIsOpen] = useState(false);
    const [projectName, setProjectName] = useState("")
    const [linksUploaded, setLinksUploaded] = useState<any>([])
    const [filesUploaded, setFilesUploaded] = useState<any>([])
    const [recallChats,setRecallChats] = useState(false)
    const [openHeaderModal,setOpenHeaderModal] = useState(false)
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)

   


    // QUERY AND MUTATIONS FROM REDUX TOOLKIT
    const {data, isLoading, isSuccess, refetch, isError} = useGetUserProjectQuery()
    const {data:convoData, isLoading:isConvoLoading, isSuccess:isConvoSuccess, refetch:convoRefetch, isError:isConvoError} = useGetConversationListQuery()

    const {data: getMessagesData,isFetching:getMessageFetching, isLoading: getMessagesLoading, isSuccess: getMessagesSuccess, refetch: getMessagesRefetch, isError: isGetMessagesError} = useGetMessagesQuery(convoId || fetchId, {refetchOnMountOrArgChange:true})

    const [sendMessage, 
          {data: sendMessageData, 
           isSuccess: sendMessageSuccess, 
           isLoading: sendMessageLoading, 
           isError: isSendMessageError, 
           error: sendMessageError}] = useSendChatMessageMutation()

    const [storeChat, 
        {data: storedChatResponse, 
         isSuccess: storedChatSuccess, 
         isLoading: storedChatLoading, 
         isError: storedChatError, 
         error: storedChatErrorMessage}] = useStoreChatMutation()

    function afterOpenModal() { }

    function closeModal() {
        setIsOpen(false);
        //dispatch(resetDownload());
      }

    const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '700px',
          width: '50%',
          top: 'calc((100% - 700px) / 2)',
          bottom : 'calc((100% - 700px) / 2)',
          left: '25%',
          backgroundColor:backgroundMode.color==='white'?'#FFFFFF': '#1B1C1C',
          borderRadius:'15px',
          padding:'30px 40px 0px',
          boxSizing:'border-box'
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };


    // USEFFECT FOR GETTING PROJECT LIST
    useEffect(() => {
        refetch()
    }, [data])

    
 

    useEffect(()=>{
        if(resetChat){

            const newResponse =  chunks.replace(/\n/g, ' ')  
            const newObj = {query:dummyQuery,response:newResponse}
            storeChat({conversationUuid:convoId,query:dummyQuery,response:newResponse})
            setChatMessages((prev:any)=>[...prev,newObj])
        }
    },[resetChat])


    useEffect(() => {
        if(isSuccess) {
            setProjectList(data?.projects)
        }
    }, [data, isLoading, isSuccess, isError])

    // USEFFECT FOR GETTING MESSAGES
    useEffect(() => {
        if(getMessagesSuccess) {
            setChatMessages(getMessagesData?.message)
            setDummyQuery("")
        }
    }, [getMessagesData, getMessagesLoading, getMessagesSuccess ])

    // USEEFFECT FOR SENDING MESSAGES
    useEffect(() => {
        if(sendMessageSuccess) {
            setChatQuery("")
        }

        if(isSendMessageError && 'status' in sendMessageError!) {
            notifyError(sendMessageError?.data?.message)
            setDummyQuery("")
        }

    }, [sendMessageData, sendMessageSuccess, sendMessageError, isSendMessageError])

    useEffect(()=>{
        if(storedLanguage !=='null'){
            setSelectedLanguage(storedLanguage)
        }
        else{
            setSelectedLanguage('English')
        }
        
    },[convoId,projectId])

    useEffect(()=>{
        convoRefetch()
        if(isConvoSuccess){
            convoData.conversations.forEach((element:any,index:any)=>{
                
                if(element.conversationUuid===convoId){
                    if(element.language !== 'English'){
                        setSelectedLanguage(element.language)
                    }

                    else{
                        setSelectedLanguage('English')
                    }
                }
            })
        }
    },[convoData,isConvoSuccess,isConvoLoading])


    const queryParams = {
        query: chatQuery,
        projectUuid: projectId,
        conversationUuid: convoId || fetchId,
        language: selectedLanguage?selectedLanguage.toLocaleLowerCase():'English'.toLocaleLowerCase()
    }

    const handleSendMessage = () => {
        const params:any = {
            query: chatQuery,
            projectUuid: projectId,
            conversationUuid: convoId || fetchId,
        }
        setDummyQuery(chatQuery)
        sendMessage(params)
    }

    const notifyError = (text:string) => toast.error (text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

  return (
    <BluePageBg>
        <ToastContainer />

        <IndividualUserHeader 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
            conversationId={convoId || fetchId}
            recallChats={recallChats}
            setRecallChats={setRecallChats}
            setOpenHeaderModal={setOpenHeaderModal}
            projectId={projectId}
        />
        <IndividualSideBar 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
            conversationId={convoId || fetchId}
            setIsOpen={setIsOpen}
            recallChats={recallChats}
            setRecallChats={setRecallChats}
            setOpenHeaderModal={setOpenHeaderModal}
            projectId={projectId}
        />

        {isConvoSuccess? <Chat 
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            chatQuery={chatQuery}
            setChatQuery={setChatQuery}
            handleSendMessage={handleSendMessage}
            getMessagesLoading={getMessagesLoading}
            sendMessageLoading={sendMessageLoading}
            dummyQuery={dummyQuery}
            setDummyQuery={setDummyQuery}
            chunks={chunks}
            setChunks={setChunks}
            convoId={convoId}
            projectId={projectId}
            sendMessageSuccess={sendMessageSuccess}
            resetChat={resetChat}
            setResetChat={setResetChat}
            queryParams={queryParams}
            selectedLanguage = {selectedLanguage}
            setSelectedLanguage = {setSelectedLanguage}
            getMessageFetching={getMessageFetching}
        />: <Spinner/>}

        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
          contentLabel="Chat Creation" >
          <ChatCreationModal  projectName={projectName} setProjectName ={setProjectName} 
                              linksUploaded={linksUploaded} setLinksUploaded={setLinksUploaded} 
                              filesUploaded={filesUploaded} setFilesUploaded={setFilesUploaded}
                              closeModal={closeModal} setIsOpen={setIsOpen} setRecallChats={setRecallChats}
                              setOpenHeaderModal={setOpenHeaderModal}
                                />
        </Modal>
    </BluePageBg>
  )
}

export default IndividualChatRoom

