import { useNavigate } from "react-router";

function SignUpNavigator({
  navigationState,
  setNavigationState,
  setActiveStep,
}: any) {
  const navigate = useNavigate();
  return (
    <>
      <div className="form-head-wrap">
        <h3>Sign up for free.</h3>

        <span>How would you be using Prolific?</span>
        <p>Please select an option below.</p>
      </div>

      <section className="user-selector">
        <span
          onClick={() => setNavigationState("INDIVIDUAL")}
          className={
            navigationState === "INDIVIDUAL" ? "selected" : "unselected"
          }
        >
          As an Individual
        </span>
        <span
          onClick={() => setNavigationState("BUSINESS")}
          className={navigationState === "BUSINESS" ? "selected" : "unselected"}
        >
          As a Business
        </span>
      </section>

      <section onClick={() => setActiveStep()} className="naviagtion-option">
        Next
      </section>

      <div className="form-extras">
        <div className="login-redirect">
          Already have an account?{" "}
          <span onClick={() => navigate("/login")}> Log in </span>
        </div>

        {/* <div className="or-box">
                        <div className="horizontal-line"></div>
                        <p>OR</p>
                        <div className='horizontal-line'></div>
                    </div>

                    <div className="continue-with-google">
                        <img src={google} alt="" />

                        <p>Continue with Google</p>
                    </div> */}
      </div>
    </>
  );
}

export default SignUpNavigator;
