import React from 'react'


import pdf from "../../assets/svg/pdf.svg"
import close from "../../assets/svg/close-circle.svg"
import { FileUp } from './fileup.styles'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'


const FileUpBox = ({type, onRemove, fileName, urlName}:any) => {
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)


  return (
    <FileUp colorState={backgroundMode.color}>
        {
            type === "url" ?

            <div className="left-side link">
                <p>{urlName}</p>
            </div> :
            <div className="left-side">
                <img src={pdf} alt="" />

                <p className='doc-name'>{fileName}</p>
            </div>
        }

        <div className="cancel-wrap">
            <img src={close} alt="" onClick={onRemove} />
        </div>
    </FileUp>
  )
}

export default FileUpBox