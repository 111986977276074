import styled from "styled-components"


export const ContextHold= styled.div <{colorState?: string}>`
        background-color: #fff;
        border: 1px solid var(--primary-50, #E1EEFF);
        height: calc(90% - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
        transition: all 0.5s ease;       

       .contextFirstChild{
        height:100%;
        position:relative;
        top:0px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;      
        border-radius:15px;
        width:100%;
            .header{
               
                width:100%;
                display: flex;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                padding: 0 40px;
                justify-content: start;
                column-gap:7px;
                align-items: center;
                height: 80px;
                border: ${(props:any) => props?.colorState === 'white'
                        ? "1px solid var(--primary-50, #E1EEFF)" 
                        : "1px solid #E1E1E1"};
                background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
                transition: all 0.5s ease;
                box-shadow: 0px 1px 0px 0px rgba(17, 98, 220, 0.21);

                .imgHold{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                }

                .docHold{
                    color: ${(props:any) => props?.colorState === 'white'?
                            '#062149':'white'};
                    font-size:16px;
                    font-weight:600;
                }
            }

            .bottom{
                    padding:10px 0px 15px 0px;
                    box-sizing:border-box;
                    overflow:auto;
                    height:100%;
                    width:100%;
                    background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
                    border-bottom-right-radius: 15px;
                    border-bottom-left-radius: 15px;
                    .fileSect{
                        padding:15px;
                        box-sizing:border-box;
                        display:flex;
                        flex-direction:column;
                        row-gap:10px;

                        .fileHead{
                            color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                            font-weight:600;
                            font-size:12px;
                        }

                        .pdfSect{
                            display:flex;
                            justify-content:space-even;
                            flex-wrap:wrap;

                            .titleName{
                                color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                font-size:12px
                            }
                        }
                    }

                    .linkSect{
                            margin-top:20px;
                            padding:15px;

                            .linkHead{
                                color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                font-weight:600;
                                font-size:12px;
                            }

                            .linkSect{
                                display:flex;
                                justify-content:space-even;
                                flex-direction:column;
                                row-gap:5px;

                                 .urlTitle{
                                    font-weight:600;
                                    margin-bottom:5px;
                                    color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                }

                                .titleName{
                                    color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                    font-size:12px
                                }
                            }
                        }
            }

       }


       
`