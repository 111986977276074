import React, {useEffect,useState} from 'react'
import Spinner from '../../../spinnersvg/Spinner'
import { OverWrap,PromptWrap, CancelWrap, EditWrap, SpanWrap } from './chatName.styles'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import Input from '../../InputNew'


const UpdateChatNameModal = ({closeModal ,
                              selectedConvo,
                              handleEditConversation ,
                              updateConversationTitleSuccess, 
                              updateConversationTitleLoading, 
                              isLoading ,
                              isSuccess}:any) => {

    console.log('eer')
    const [newTitle,setNewTitle] = useState(selectedConvo?.conversationName)
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const cancelEditPop = ()=>{
          
        closeModal()
  }
  
  const [showLoad,setShowLoad] = useState(false)
  
  useEffect(()=>{
  if(updateConversationTitleLoading || isLoading){
      setShowLoad(true)
  }


  },[updateConversationTitleLoading,isLoading])

  useEffect(()=>{
    if(updateConversationTitleSuccess){
        setShowLoad(false)
        cancelEditPop()
    }
  },[updateConversationTitleSuccess])
  
  
  const EditTitle = (id:any,title:any)=>{
  const value = {Uuid:id,name:title}
  
  handleEditConversation(value)
  }
  
  
    

  return (
    <OverWrap colorState={backgroundMode.color} >
    <PromptWrap colorState={backgroundMode.color} >{showLoad?<Spinner></Spinner>:
    <>
       <section className='titleHolders'>
            <p className='top'>Update Chat Name</p>
            <p className='bottom'>Enter new name for this chat</p>
       </section>
        
        <Input holder="Chat Name" 
                    handleInputChange={setNewTitle} 
                    inputValue={newTitle} />
        <section style={{display:"flex",justifyContent:"end",width:"80%",margin:"15px auto",columnGap:'10px'}}>
            <CancelWrap onClick={()=>cancelEditPop()}>Cancel</CancelWrap>
            <EditWrap onClick={()=>EditTitle(selectedConvo?.conversationUuid,newTitle)}>Update</EditWrap>    
        </section>
        </>}
    </PromptWrap>

    </OverWrap>
  )
}

export default UpdateChatNameModal

/**
 *    <SidebarAccordion>
                <div className="accordion-side-header">
                    <div className="left-side">
                        Created Projects
                    </div>

                    {
                        activePlan?.totalChatbots > count &&
                        <Button btnType={"copy-project"} text={"New project"} icon={cross} onClick={() => navigate("/usecase-setup")}/>
                    }
                </div>

                <ProjectList>
                    {
                        projectList?.map((item:any, index:any) => {
                            return (
                                <li key={index} className={projectId === item?.key ? "active" : "" } >
                                    <div className="left-text" onClick={() => handleProjectChoice(item?.key)}>
                                        <img src={file} alt="" />
                                        <p>{item?.name}</p>
                                    </div>

                                    <div className="img-wraps">
                                        <img src={closeSquare} alt="" />
                                    </div>
                                </li>
                            )
                        })
                    }
                </ProjectList>
            </SidebarAccordion>
 */