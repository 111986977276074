import styled from "styled-components"


export const ContactsHold = styled.div `
    height:100vh;
    overflow:auto;
    background:linear-gradient(to right, #37508A 0%, #37508A 10%, #784EAD 50%, #784EAD 75%, #252E3B 100%);
    padding:2rem 4rem;

    @media only screen and (max-width: 750px) {
        padding:3rem 2rem;
        
    }
    

    .auth-header {
        display: flex;
        justify-content: flex-start;
        margin-bottom:10px;
      }

    .btn-wrap{
        margin:30px auto;
    }

    .auth-footer {
        margin:20px 0px;
        text-align:left;
        p {
          margin: 0;
          color: #fff;
          font-size: 1rem;
          font-weight: 300;
          color: #fff;
      
        }
      }
   }
                    
`


export const ContactMain = styled.div `
        height: 100vh;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction:row;

        @media only screen and (max-width: 1000px) {
            flex-direction:column;
            row-gap:50px;
            padding:40px 0px;
            height:auto;
            
        }

        .contactLhs{
            height:100%;
            width:35%;
            display:flex;
            flex-direction:column;
            justify-content:space-between;
            animation: grow 1s ease forwards;
            transform-origin: center;

            @media only screen and (max-width: 1000px) {
                width:100%;
                row-gap:15px;
                
            }
        }

        .contactRhs{
            width: 60%;
            height: 100%;
            background-color:white;
            border-radius:10px;
            animation: grow 1s ease forwards;
            transform-origin: center;

            @media only screen and (max-width: 1000px) {
                width:100%;
                height:1000px;
                margin-bottom:0px;
                box-sizing:border-box;
                
            }
        }

        @keyframes grow {
            from {
              opacity:0;
            }
            to {
              opacity:1;
            }
        }

    `

export const ContactFormStyle = styled.div `
        background-color:white;
        height:100%;
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        bordern-radius:10px 10px 0px 0px;
        border-radius:10px;
        padding:0px 25px;
        box-sizing:border-box;

        @media only screen and (max-width: 700px) {

            padding:0px 15px;
        }



        .form-header{
            text-align:center;
            height:140px;
            display:flex;
            justify-content:center;
            row-gap:5px;
            box-sizing:border-box;
            align-items:center;
            flex-direction:column;

            h2{
                color:#062149;
            }
            span{
                color:#6A717D;
                font-size:18px;

            }
        }
                    
`

export const ContactFormBody = styled.div `
        background-color:white;
        height:calc(100% - 140px);
        bordern-radius: 0px 0px 10px 10px;
        border-radius:10px;

        @media only screen and (max-width: 700px) {

            height:calc(100% - 140px);
        }
    
        form{
            height:100%;
            width:100%;
            display:flex;
            flex-direction:column;
            justify-content:space-between;
            box-sizing:border-box;
            padding:20px;

            @media only screen and (max-width: 700px) {
                row-gap:30px;
                
            }


            .form-row{
                display:flex;
                column-gap:10px;
                width:100%;

                @media only screen and (max-width: 1000px) {
                    flex-direction:column;
                    row-gap:30px;
                    
                }
            }
        }
        
                    
`

