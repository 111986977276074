import styled from "styled-components"

export const FeedbackOverall = styled.div `
    background-color: rgba(255, 255, 255, 0.20);
    border-radius: 8px;
    padding: 1.5em 1.6em;

    .inner-feedback-wrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 18px;

        img {
            width: 20px;
        }

        .feedback-content {
            color: #fff;
            font-weight: 600;
            font-style: italic;
        }

        .user-name-wrap {
            color: #fff;
            font-weight: 300;
            font-style: italic;
        }
    }
`