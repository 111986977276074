import styled from "styled-components"

export const PaymentWrap = styled.div `
    width: 20em;
    padding: 1em 5em;
    display: flex;
    flex-direction: column;
    gap: 1em 0;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .paystack-modal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    h3 {
        font-size: 1.6rem;
        color: #000;
        text-align: center;
    }

    button {
        outline: none;
        border: none;
        border-radius: 6px;
        padding: 0.8em 1.5em;
        cursor: pointer;
        background-color: #1162DC;
        color: #fff;
    }
`