import styled from "styled-components"

export const FreePlan = styled.div ` 
    height: 100%;
    background-color: #FFFAED;
    width: calc(100% - 380px);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    .btn-plan-wrap {
        border-radius: 4px;
        background: #7B61FF;
        padding: 0.6em;
        color: #fff;
        font-size: 0.6rem;
        cursor: unset;
        text-transform: capitalize;
        white-space: nowrap;
    }

    p {
        margin: 0;
        color: #000;
        font-size: 0.6rem;
        font-weight: 400;
        
        span {
            font-weight: 600;
        }
    }

    .pay-plan-link {
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: #7B61FF;
        font-size: 0.6rem;

        img {
            width: 13px;
        }
    }


    
    @media only screen and (max-width: 1220px) {
        width: calc(100% - 330px);

        .btn-plan-wrap, p, .pay-plan-link  {
            font-size: 0.7rem;
        }
    }

    @media only screen and (max-width: 992px) {
        width: 100%;
        
        .btn-plan-wrap, p, .pay-plan-link  {
            font-size: 0.7rem;
        }
    }

    @media only screen and (max-width: 500px) {
        .btn-plan-wrap, p {
            display: none;
        }
    }
`

export const PaidPlan = styled(FreePlan) `

`