import React, { useEffect, useState } from 'react'
import { BluePageBg, SubtabHeader, UserPageWhiteBg } from './usersettings.styled'
import UserHeader from '../../components/userHeader'
import UserSidebar from '../../components/userSidebar'
import Button from '../../components/button'
import KnowledgeBase from './knowledgebase'
import AiAvatar from './aiavatar'
import UsecaseSettings from './usecasesettings'
import { useDeleteProjectFileMutation, useDeleteProjectUrlMutation, useGetUserProjectQuery, useUpdateChatbotInfoMutation, useUpdatePdfMutation, useUpdateUrlMutation, useUpdateUsecaseMutation } from '../../services/customerApi'
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify'
import Spinner from '../../spinnersvg/Spinner'

if (process.env.NODE_ENV !== 'test') {
    Modal.setAppElement('#root');
}

const UserSettings = () => {
    const [tabno, setTabno] = useState("1")
    const [projectList, setProjectList] = useState<any>([])
    const [projectId, setProjectId] = useState()
    const [chosenProject, setChosenProject] = useState<any>()
    const [filesSelected, setFilesSelected] = useState<any>([]);
    const [chatbotName, setChatbotName] = useState("")
    const [chatbotDescribe, setChatbotDescribe] = useState("")
    const [urlSelected, setUrlSelected] = useState("")
    const [usecaseSelected, setUsecaseSelected] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [customerNum, setCustomerNum] = useState("")
    localStorage.setItem("prolificLastVisited", `/user-settings`);

    // QUERY AND MUTATIONS FROM REDUX TOOLKIT
    const {data, isLoading, isSuccess, refetch, isError} = useGetUserProjectQuery()

    const [deleteProjectUrl, {data: deleteUrlData, isLoading: deleteUrlLoading, isSuccess: deleteUrlSuccess, isError: isDeleteUrlError, error: deleteUrlError}] = useDeleteProjectUrlMutation();

    const [deleteProjectFile, {data: deleteFileData, isLoading: deleteFileLoading, isSuccess: deleteFileSuccess, isError: isDeleteFileError, error: deleteFileError}] = useDeleteProjectFileMutation();

    const [updatePdf, {data: updatePdfData,  isSuccess: updatePdfSuccess, isError: isUpdatePdfError, error: updatePdfError, isLoading: updatePdfLoading}] = useUpdatePdfMutation();

    const [updateChatbotInfo, {  isSuccess: updateChatbotSuccess, isError: isUpdateChatbotError, error: updateChatbotError, isLoading: updateChatbotLoading}] = useUpdateChatbotInfoMutation();

    const [updateUsecase, {  isSuccess: updateUsecaseSuccess, isError: isUpdateUsecaseError, error: updateUsecaseError, isLoading: updateUsecaseLoading}] = useUpdateUsecaseMutation();

    const [updateUrl, {data: updateUrlData,  isSuccess: updateUrlSuccess, isError: isUpdateUrlError, error: updateUrlError, isLoading: updateUrlLoading}] = useUpdateUrlMutation();


    const customStyles = {
        content: {
            transition: '0.125s ease-in-out',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "transparent",
            height: '60%',
            width: 'unset',
            top: '50%',
            left: '50%',
            padding: '0 0 0 0',
            // borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            alignCenter: 'center',
            border: 'none',
        },
        overlay: {
          zIndex: 1000,
          position: 'fixed',
          minHeight: '100vh',
          left: '0px',
          top: '0px',
        }
    };


    // USEEFECT FOR GETTING PROJECT
    useEffect(() => {
        refetch()
    }, [data, isSuccess, deleteUrlSuccess, deleteFileSuccess, updatePdfSuccess, updateChatbotSuccess, updateUrlSuccess, updateUsecaseSuccess])

    useEffect(() => {
        if(isSuccess) {
            setProjectList(data?.projects)
        }
    }, [data, isLoading, isSuccess, isError, deleteUrlSuccess, deleteFileSuccess, updatePdfSuccess, updateChatbotSuccess, updateUrlSuccess, updateUsecaseSuccess])

    // TO DETERMINE PROJECT TO SHOW
    const handleProjectChoice = (index:any) => {
        setProjectId(index)
    }

    // USEEFFECT TO FILTER  THE PROJECT CHOSEN
    useEffect(() => {
        var filtered: any = projectList;

        if(projectId) {
            filtered =  filtered?.filter((item:any) => item?.key === projectId )
        }

        setChosenProject(filtered?.[0])
    }, [projectId, projectList])

    // USEFFECT TO HANDLE SUCCESSFUL FILE UPDATE, URL UPDATE, CHATBOT INFO
    useEffect(() => {
        if(updatePdfSuccess) {
            setFilesSelected([])
        }

        if(updateUrlSuccess) {
            setUrlSelected("")
        }

        if (updateChatbotSuccess) {
            setChatbotDescribe("")
            setChatbotName("")
        }

        if (updateUsecaseSuccess) {
            setUsecaseSelected("")
            setCompanyName("")
            setCustomerNum("")
        }

    }, [updatePdfSuccess, updatePdfError, updateUrlSuccess, updateChatbotSuccess, updateUsecaseSuccess])

    // USEFFECT FOR DELETING FILE
    useEffect(() => {
        if(deleteFileSuccess) {
            notifySuccess("File succesfully deleted")
        }

        if(isDeleteFileError && 'status' in deleteFileError!) {
            notifyError(deleteFileError?.data?.message)
        }

    }, [deleteFileData, deleteFileSuccess, deleteFileLoading, deleteFileError, isDeleteFileError])

    // USEEFFECT FOR DELETING URL
    useEffect(() => {
        if(deleteUrlSuccess) {
            notifySuccess("URL successfully deleted ")
        }

        if(isDeleteUrlError && 'status' in deleteUrlError!) {
            notifyError(deleteUrlError?.data?.message)
        }

    }, [deleteUrlData, deleteUrlError, deleteUrlLoading, deleteUrlSuccess, isDeleteUrlError])

    // USEEFFECT FOR UPDATING PDF
    useEffect(() => {
        if(updatePdfSuccess) {
            notifySuccess("File Succesfully added")
        }

        if(isUpdatePdfError && 'status' in updatePdfError!) {
            notifyError(updatePdfError?.data?.message)
        }
    }, [updatePdfData, updatePdfSuccess, isUpdatePdfError, updatePdfError])

    // USEEFFECT FOR UPDATING URL
    useEffect(() => {
        if(updateUrlSuccess) {
            notifySuccess("URL Succesfully added")
        }

        if(isUpdateUrlError && 'status' in updateUrlError!) {
            notifyError(updateUrlError?.data?.message)
        }
    }, [updateUrlData, updateUrlSuccess, isUpdateUrlError, updateUrlError])

    // USEEFFECT FOR UPDATING USECASE
    useEffect(() => {
        if(updateUsecaseSuccess) {
            notifySuccess("Usecase Succesfully updated")
        }

        if(isUpdateUsecaseError && 'status' in updateUsecaseError!) {
            notifyError(updateUsecaseError?.data?.message)
        }
    }, [updateUsecaseSuccess, isUpdateUsecaseError, updateUsecaseError])

    // USEEFFECT FOR UPDATING CHATBOT INFO
    useEffect(() => {
        if(updateChatbotSuccess) {
            notifySuccess("Chatbot Information Succesfully updated")
        }

        if(isUpdateChatbotError && 'status' in updateChatbotError!) {
            notifyError(updateChatbotError?.data?.message)
        }
    }, [updateChatbotSuccess, isUpdateChatbotError, updateChatbotError])


    const handleDeleteProjectUrl = (item:any) => {
        const params:any = {
            url: item,
            projectUuid: chosenProject?.projectUuid
        }
        deleteProjectUrl(params)
    }

    const handleDeleteProjectFile = (item:any) => {
        const params:any = {
            url: item,
            projectUuid: chosenProject?.projectUuid
        } 

        deleteProjectFile(params)
    }

    // UPDATE PDF FOR A PARTUCLAR PROJECT
    const handleUpdatePdf = () => {
        let formData:any = new FormData()

        formData.append("projectUuid", chosenProject?.projectUuid)
        filesSelected.forEach((file:any) => {
            formData.append('files', file);
        })

        updatePdf(formData)
    }

    useEffect(() => {
        if(filesSelected?.length > 0) {
            handleUpdatePdf()
        }
    }, [filesSelected])

    const handleUpdateChatbotInfo = () => {
        const params:any = {
            chatbotName: chatbotName,
            projectUuid: chosenProject?.projectUuid,
            chatbotDescription: chatbotDescribe
        } 

        updateChatbotInfo(params)
    }

    const handleUpdateUrl = () => {
        const params:any = {
            url: urlSelected,
            projectUuid: chosenProject?.projectUuid,
        } 

        updateUrl(params)
    }

    const handleUpdateUsecase = () => {
        if(usecaseSelected?.includes("Customer Support") && customerNum && companyName) {
            const params:any = {
                usecaseName: usecaseSelected,
                projectUuid: chosenProject?.projectUuid,
                customerContact: customerNum,
                companyName: companyName,
            } 

            updateUsecase(params)
        }
        
        if(!usecaseSelected?.includes("Customer Support") && usecaseSelected) {
            const params:any = {
                usecaseName: usecaseSelected,
                projectUuid: chosenProject?.projectUuid,
            } 

            updateUsecase(params)
        }
    }

    const handleSaveUpdate = () => {
        if(tabno === "2") {
            handleUpdateChatbotInfo()
        }

        if(tabno === "3") {
            handleUpdateUsecase()
        }
    } 


    const notifyError = (text:string) => toast.error (text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const notifySuccess = (text:string) => toast.success (text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    

  return (
    <BluePageBg>
        <ToastContainer />

        <Modal isOpen={deleteFileLoading || deleteUrlLoading || updatePdfLoading || updateUrlLoading || updateUsecaseLoading || updateChatbotLoading} style={customStyles} contentLabel="">
            <Spinner></Spinner>
        </Modal>

        <UserHeader 
            projectList={projectList}
            handleProjectChoice={handleProjectChoice}
            projectId={projectId}
        />
        <UserSidebar 
            projectList={projectList}
            handleProjectChoice={handleProjectChoice}
            projectId={projectId}
        />

        <UserPageWhiteBg>
            <div className="inner-user-settings-wrap">
                <div className="back-wrapper">
                    {/* <Button text={"Back"} btnType={"backtransparent"} />  */}
                </div>

                <div className="settings-text-head">
                    <h4>settings</h4>
                </div>

                <div className="tab-section">
                    <SubtabHeader tabActive={tabno === "1" ? true : false} onClick={() => setTabno("1")}>
                        Knowledge base
                    </SubtabHeader>

                    <SubtabHeader tabActive={tabno === "2" ? true : false} onClick={() => setTabno("2")}>
                        AI Avatar
                    </SubtabHeader>

                    <SubtabHeader tabActive={tabno === "3" ? true : false} onClick={() => setTabno("3")}>
                        Use case
                    </SubtabHeader>
                </div>
                {
                    tabno === "1" &&
                    <KnowledgeBase projectList={projectList} chosenProject={chosenProject} 
                    handleDeleteProjectUrl={handleDeleteProjectUrl} 
                    handleDeleteProjectFile={handleDeleteProjectFile}
                    filesSelected={filesSelected}
                    setFilesSelected={setFilesSelected}
                    urlSelected={urlSelected}
                    setUrlSelected={setUrlSelected}
                    handleUpdateUrl={handleUpdateUrl}
                    />
                }

                {
                    tabno === "2" &&
                    <AiAvatar chosenProject={chosenProject} 
                        chatbotDescribe={chatbotDescribe}
                        setChatbotDescribe={setChatbotDescribe}
                        chatbotName={chatbotName}
                        setChatbotName={setChatbotName}
                    />
                }

                {
                    tabno === "3" &&
                    <UsecaseSettings chosenProject={chosenProject} 
                        usecaseSelected={usecaseSelected}
                        setUsecaseSelected={setUsecaseSelected}
                        companyName={companyName}
                        customerNum={setCustomerNum}
                        setCompanyName={setCompanyName}
                        setCustomerNum={setCustomerNum}
                    />
                }


                {
                    tabno !== "1" &&
                    <div className="footer-sec">
                        {/* <Button text={"Cancel"} btnType={"cancel"} /> */}

                        <Button text={"Save"}  onClick={handleSaveUpdate}/>
                    </div>
                }
            </div>
        </UserPageWhiteBg>
    </BluePageBg>
  )
}

export default UserSettings