import {createSlice} from '@reduxjs/toolkit';

interface IdentityState {
    convoId: string;
    projectId: string
  }

const initialState:IdentityState = {
    convoId: "" ,
    projectId: ""
};


const identityHoldSlice = createSlice({
  name: 'dataRecords',
  initialState,
  reducers: {
    updateConvoId: (state,action) => {
      state.convoId = action.payload},
    updateProjectId: (state,action) => {
        state.projectId =  action.payload
      }
  },
});

const {reducer, actions} = identityHoldSlice;
export const {
    updateConvoId,
    updateProjectId,
} = actions;
export default reducer;