import styled from "styled-components"

export const ButtonHold = styled.div <{bigSize?: boolean,colorState?: string}> `
    width:  ${(props:any) => props.bigSize ?'100%': '135px'};
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:${(props:any) => props?.colorState === 'white' ? "#E1EEFF" : "#313336"} ;
    transition: background-color 0.5s ease;
    cursor:pointer;
    box-sizing:border-box;
    column-gap:7px;
    border-radius:7px;


    span{
        display:flex;
        align-items:center;
        justify-content:center;
        font-size:14px;
        color: ${(props:any) => props?.colorState === 'white' ? '#1162DC':'white'};
        transition: color 0.5s ease;

        img: {
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }
`