import { FooterWrap } from "./footer.styles";

import logo from "../../assets/svg/logo-white.svg";
import perilogo from "../../assets/svg/periculum-footer-logo.svg";
import { useNavigate } from "react-router-dom";

const Footer = ({
  pricingRef,
  featureRef,
  handleScroll,
  contactRef,
  tag,
}: any) => {
  const navigate = useNavigate()
  const navList = [
    {
      name: "Home",
      link: "/",
      onClick: () => {
        return;
      },
    },
    {
      name: "Product",
      link: "#feature",
      onClick: () => {
        handleScroll(featureRef.current);
      },
    },
    {
      name: "Pricing",
      link: "#pricing",
      onClick: () => {
        handleScroll(pricingRef.current);
      },
    },
    {
      name: "Contact",
      onClick: () => {
        navigate("/contact-us");
      },
    },
  ];

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <FooterWrap tag={tag}>
      <div className="inner-footer-wrap">
        <div className="footer-top">
          <img src={logo} alt="" />

          <ul>
            {navList?.map((item, id) => {

              if(id == 3){
                return (
                  <span
                    onClick={() => navigate("/contact-us")}
                    style={{
                      fontSize: "16px",
                      color: "#d4d4d4",
                      cursor: "pointer",
                      fontWeight: "405",
                    }}
                  >
                    {item.name}
                  </span>
                );
              }

            else{
              return (
                <li key={id}>
                  <a href={`${item.link}`} onClick={item.onClick}>
                    {item.name}
                  </a>
                </li>
              );
            }
              
            })}
          </ul>
        </div>

        <div className="footer-bottom">
          <p>{`© Copyright ${currentYear} - Periculum Technologies Inc || All Rights Reserved`}</p>

          <div className="bottom-txt">
            Built by Periculum <img src={perilogo} />
          </div>
        </div>
      </div>
    </FooterWrap>
  );
};

export default Footer;
