import styled from "styled-components"

export const BluePageBg = styled.div `
    background-color: #F3F8FF;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    margin-left:350px;

    @media only screen and (max-width: 992px) {
        background-color: #fff;
        margin-left:0px;
        
    }

    @media only screen and (max-width: 506px) {
        margin-left:0px;
    }
`

export const UserPageWhiteBg = styled.div `
    width: 100%;
    background-color: #fff;
    min-height: 100%;
    position: relative;

    .inner-user-settings-wrap {
        padding: 120px 5em 3em 5em;
        display: flex;
        flex-direction: column;
        

        .back-wrapper {
            margin-bottom: 30px;
        }

        .settings-text-head {
            h4 {
                margin: 0;
                font-weight: 400;
                color: #808DA1;
                font-size: 0.8rem;
                text-transform: uppercase;
            }
        }

        .tab-section {
            display: flex;
            align-items: center;
            column-gap: 20px;
            padding: 20px 0px 10px;
            border-bottom: solid 1px #F5F5F5;
        }

        .footer-sec {
            display: flex;
            justify-content: flex-end;
            column-gap: 15px;
            align-items: center;
            margin-top: 40px;

            button {
                justify-content: center;
                padding: 0.6em 1em;
                width: 140px;

                img {
                    display: none;
                }
            }
        }
    }

    @media only screen and (max-width: 1220px) {
        width: 100%;
    } 

    @media only screen and (max-width: 992px) {
        width: 100%;
        padding-left: 70px;
    }

    @media only screen and (max-width: 760px) {
        .inner-user-settings-wrap {
            padding: 120px 0 3em;
        }
    }

    @media only screen and (max-width: 506px) {
        padding-left: 20px;

    }


`

export const SubtabHeader = styled.div <{tabActive?: boolean}> `
    color: ${(props:any) => props.tabActive ? "#1162DC" : "#808DA1"};
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
`

export const TabContentWrap = styled.div `
    margin-top: 30px;

    h4 {
        font-weight: 400;
        color: #062149;
        font-size: 1rem;
    }

    .swiper-button-prev, .swiper-button-next {
        color: #000 !important;
    }
`