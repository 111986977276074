import styled from "styled-components"


export const ContextHold= styled.div <{colorState?: string}>`
       width:400px;
       background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
       height:500px;
       border-top-right-radius: 15px;
       border-top-left-radius: 15px;
       postion:relative;
       border-top-right-radius: 15px;
       border-top-left-radius: 15px; 
       display:block;

       .contextFirstChild{
        height:100%;
        position:relative;
        top:0px;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;      
        border: ${(props:any) => props?.colorState === 'white'
                        ? "1px solid var(--primary-50, #E1EEFF)" 
                        : "1px solid #E1E1E1"};
            .header{
               
                width:100%;
                display: flex;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                padding: 0 40px;
                justify-content: start;
                column-gap:7px;
                align-items: center;
                height: 80px;
                border: ${(props:any) => props?.colorState === 'white'
                        ? "1px solid var(--primary-50, #E1EEFF)" 
                        : "1px solid #E1E1E1"};
                background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
                transition: all 0.5s ease;
                box-shadow: 0px 1px 0px 0px rgba(17, 98, 220, 0.21);

                .imgHold{
                    display:flex;
                    justify-content:center;
                    align-items:center;
                }

                .docHold{
                    color: ${(props:any) => props?.colorState === 'white'?
                            '#062149':'white'};
                    font-size:16px;
                    font-weight:600;
                }
            }

            .bottom{
                    padding:10px 0px 15px 0px;
                    box-sizing:border-box;
                    overflow:auto;
                    height:calc(100% - 100px);
                    background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
                    
                    .fileSect{
                        padding:15px;
                        box-sizing:border-box;
                        display:flex;
                        flex-direction:column;
                        row-gap:10px;

                        .fileHead{
                            color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                            font-weight:700;
                            font-size:13px;
                        }

                        .pdfSect{
                            display:flex;
                            justify-content:space-even;
                            flex-wrap:wrap;

                            .titleName{
                                color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                font-size:12px
                            }
                        }
                    }

                    .linkSect{
                            margin-top:20px;
                            padding:15px;

                            .linkHead{
                                color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                font-weight:700;
                                font-size:13px;
                            }

                            .linkSect{
                                display:flex;
                                justify-content:space-even;
                                flex-direction:column;
                                row-gap:5px;

                                .urlTitle{
                                    font-weight:600;
                                    margin-bottom:5px;
                                    color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                }

                                .titleName{
                                    color:${(props:any) => props?.colorState === 'white' ? "#1F2228" : "white"};
                                    font-size:12px
                                }
                            }
                        }
            }

       }


       @media only screen and (max-width: 1350px) {
        display: none;
    }
       
`