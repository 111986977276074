import React from 'react'
import { StepsWrapper, UsecasesFlexWrap } from './firststep.styles'
import Button from '../../../components/button'
import Card from '../../../components/card'

import support from "../../../assets/svg/support.svg"
import virtual from "../../../assets/svg/virtual.svg"
import assessment from "../../../assets/svg/assesment.svg"
import general from "../../../assets/svg/general.svg"
import cancel from "../../../assets/svg/cancel-round.svg"
import { useNavigate } from 'react-router-dom'
import Input from '../../../components/input'


const FirstStep = ({setStepShown, useCaseList, chosenCase, setChosenCase, count, companyName, setCompanyName, customerNum, setCustomerNum}:any) => {
    const navigate = useNavigate()


  return (
    <StepsWrapper>
        <div className="text-wrap">
            <p className="steps-header">
                STEP 1
            </p>

            <h3 className="topic-header">
                Use case
            </h3>

            <p className="instruction-content">
            Select from a range of use cases that will infuse your bot with a distinctive purpose. Choose one that works best with your objectives.
            </p>
        </div>

        <UsecasesFlexWrap>
            {useCaseList?.map((item:any, id:number) => {
                return (
                    <Card 
                        key={id}
                        icon={item?.icon}
                        title={item?.name}
                        content={item?.description}
                        keyId={item?.key}
                        cardType={chosenCase?.key === item?.key ? 'useCase-active' : 'useCase'}
                        onCardClicked={() => setChosenCase(item)}
                    />
                )
            })}
        </UsecasesFlexWrap>

        {
            chosenCase?.name?.includes("Customer Support") &&

            <div className="customer-extra-info">
                <Input holder={"Company name"} value={companyName} handleInputChange={setCompanyName} />
                <Input holder={"Customer contact"} value={customerNum} handleInputChange={setCustomerNum} />
            </div>
        }



        <div className="steps-footer-box">
            {
                count ? 
                <Button 
                    text={'Cancel'}
                    btnType={"cancel"}
                    icon={cancel}
                    onClick={() => {navigate("/user-settings")}}
                /> :
                <></>
            }

            <Button 
                text={"Next"}
                btnType={chosenCase ? "cta" : "cta-inactive"}
                onClick={() => setStepShown("second")}
            />
        </div>
    </StepsWrapper>
  )
}

export default FirstStep