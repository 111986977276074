import styled from "styled-components"

export const ChatMessageWrap = styled.div <{colorState?: string}>`
    height:450px;
    width: 94%;
    display: flex;
    border-radius: inherit;
    scroll-snap-type: y proximity;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    row-gap: 1em;
    padding: 5px 20px;
    &::-webkit-scrollbar {
        width: 10px;
        background: #F3F8FF;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background:${(props:any) => props?.colorState === 'white' ? "rgba(17, 98, 220, 1)" : "#313336"} ;
        transition: background 0.5s ease;
        border-radius: 10px;
    }

    @media only screen and (max-height: 780px) {
        height: 400px;
    }


    @media only screen and (max-height: 750px) {
        height: 390px;
    }

    @media only screen and (max-height: 720px) {
        height: 380px;
    }


    @media only screen and (max-height: 700px) {
        height: 300px;
    }


    .empty-box {
        padding: 2px;
    }
`

export const MessageSentWrap = styled.div <{colorState?: string}>`
    display: flex;
    justify-content: flex-end;
    column-gap: 0.5em;
    width: calc(50% - 2em);
    margin-left: 53%;

    .chat-text-wrap {
        border-radius: 4px;
        border: ${(props:any) => props?.colorState === 'white' 
                ? "0.5px solid var(--secondary-200, #DFCCFF)" 
                : "none"};
        background: ${(props:any) => props?.colorState !== 'white' 
                            ? "linear-gradient(to bottom right, #3A60BB 0%, #3A60BB 25%, #3A60BB 50%, #7973B9 75%, #7973B9 100%);" 
                            : "var(--secondary-50, #FAF6FF)"};
        transition: background 0.5s ease;
        padding: 13px;

        p {
            margin: 0;
            color:${(props:any) => props?.colorState === 'white' 
            ? "#062149" 
            : "white"};
            font-size: 0.8rem;
            font-weight: 500;
        }
    }


    @media only screen and (max-width: 650px) {
        width: calc(70% - 2em);
        margin-left: 38%;
    }

    @media only screen and (max-width: 340px) {
        width: calc(90% - 2em);
        margin-left: 21%;
    }
`

export const MessageReceivedWrap = styled(MessageSentWrap) <{colorState?: string}>`
    margin-left: 0;
    margin-right: 60%;
    justify-content: flex-start;
    width: 85%;

    @media only screen and (max-width: 850px) {
        width:90%;
        
    }
    

    .chat-text-wrap {
    

        border-radius: 4px;
        border: ${(props:any) => props?.colorState === 'white' 
                ? "0.5px solid var(--secondary-200, #DFCCFF)" 
                : "none"};
        background: ${(props:any) => props?.colorState !== 'white' 
                            ? "#333434" 
                            : "var(--primary-50, #E1EEFF)"};
        transition: background 0.5s ease;
        padding:10px 40px 40px 10px;
        position:relative;

        span{
            position:absolute;
            bottom:5px;
            right:10px;
            padding:5px;
            border: 1px solid grey;
            display:flex;
            align-items:center;
            justify-content:center;
            font-size:11px;
            border-radius:3px;
            cursor:pointer;
            color:grey;
        }

        span:hover{
            background-color:#B6D4F9;
            transition: background-color 0.5s;
            color:grey;
        }

        p.typing {
            font-style: italic;
            font-weight: 300;
        }
    }
`