

 /**
  * .inner-auth-wrap {
    display: block;
    //padding: 2em 6em;
    position: relative;
    height: 100%;
    z-index: 999999;
   

   }

  */

import styled from "styled-components";

export const AuthWrap = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  background:linear-gradient(to right, #37508A 0%, #37508A 10%, #784EAD 50%, #784EAD 75%, #252E3B 100%);


  .inner-auth-wrap {
    display: block;
    position: relative;
    height: 100%;
    z-index: 10;
    padding: 2em 3em;
    width:100%;

    @media screen and (max-width: 370px) {
      padding: 2em 0.5em;
  
    }

   }

  .forgot-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 100%;
    z-index:10;
  }

   
  @media screen and (max-width: 768px) {
    .forgot-wrapper {
     flex-direction: column;
      position: relative;
     }

  }
   .wrapper-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    @media screen and (max-width: 768px){
      width:100%;
      display:block !important;
    }



    .auth-footer {
        padding-bottom:100px;
        @media screen and (max-width: 768px){
          padding-bottom:0;
          display:none;
        }
        p {
          margin: 0;
          color: #fff;
          font-size: 1rem;
          font-weight: 300;
          color: #fff;
          white-space: nowrap;
        }
      }
   }


   .wrapper-right {
      width:70%;
      @media screen and (max-width: 768px){ 
        width:100%;
        margin-inline:auto;
        position:absolute;
        top:40%;
        left:50%;
        height:100%;
        transform: translate(-40%, -40%);
      }
    }
    
    .auth-header {
      display: flex;
      justify-content: flex-start;
      margin-bottom:10px;
    }
    .auth-header-bottom {
        margin-bottom: 40px;
    }

    .auth-flex-wrap {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      padding-right: 0em;
      width:100%;
      animation: grow 1s ease forwards;

        @media screen and (max-height: 750px){ 
          margin-top: 0px;
        }

        @media screen and (max-width: 450px){ 
          width: 90vw;
        }


        .left-auth-subwrap {
          flex: 0.4;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;



            .auth-text-wrap {
              margin-top: 10px;

              .large-text {
                  h5 {
                    font-weight: 800;
                    font-size: 3.5rem;
                    color: #fff;
                    margin: 0;

                    span {
                      background: -webkit-linear-gradient(45deg, #407bff, #c4a2fc);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                    }
                  }

                  p {
                    font-size: 1.2rem;
                    font-weight: 300;
                    color: #fff;
                    margin-top: 30px;
                  }
              }
            }

            .swiper-wrap {
              margin-top: 1.5em;

              .swiper {
                width: 400px !important;
                margin-left: 0px !important;
                margin-right: 0px !important;
              }

              // .swiper-slide {
              //     width: 380px !important;
              // }
            }

            .auth-footer {
              margin-top: 3rem;
              p {
                margin: 0;
                color: #fff;
                font-size: 1rem;
                font-weight: 300;
                color: #fff;
                white-space: nowrap;
              }
            }
        }

        .right-auth-subwrap {
          flex: 0.45;
          @media screen and (max-width: 768px){ 
            flex: 1;
          }
        }
    }

    @keyframes grow {
      from {
        opacity:0;
      }
      to {
        opacity:1;
      }
    }

    .auth-flex-wrap-forgot {
        display: block;
        width:100%;
        padding-right:0;
        margin-top:0;


        p {
            font-size: 1.2rem;
            font-weight: 300;
            color: #fff;
            margin-top: 30px;
          }
    }
  }

  @media only screen and (max-width: 1410px) {
    .inner-auth-wrap {
      .auth-flex-wrap {
        .right-auth-subwrap {
          flex: 0.5;
        }
      }
    }
  }

  @media only screen and (max-width: 1355px) {
    .inner-auth-wrap {
      .auth-flex-wrap {
        padding-right: 2em;
        .right-auth-subwrap {
          flex: 0.5;
        }
      }
    }
  }

  @media only screen and (max-width: 1140px) {
    .inner-auth-wrap {
      .auth-flex-wrap {
        padding-right: 0em;

        .right-auth-subwrap {
          flex: 0.55;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .inner-auth-wrap {
      margin:0px auto;
      width:90%;
      padding: 10px;
      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
    

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          height: 100%;
          flex:1;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    
   
    .inner-auth-wrap {
      width:100%;
      .auth-flex-wrap {
        justify-content: center;
        align-items: center;

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          flex: 1;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {

    .inner-auth-wrap {
      padding: 10px 10px;
      
      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          flex: 1;
        }
      }
    }
  }

  @media only screen and (max-width: 480px) {
    .inner-auth-wrap {
      padding: 5px 5px;
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      .auth-header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }

      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
        padding-top: 3em;

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          flex: 1;
          width: 85%;
        }
      }
    }
  }

  @media only screen and (max-width: 440px) {
    .inner-auth-wrap {
      min-height: 90vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:10px;

      .auth-header {
        display: flex;
        width: 100%;
        justify-content: flex-start;
      }

      .auth-flex-wrap {
        justify-content: center;
        align-items: center;
        padding-top: 3em;
        width: 100%;

        .left-auth-subwrap {
          display: none;
        }

        .right-auth-subwrap {
          width: 85%;
        }
      }
    }
  }
`;
