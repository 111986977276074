import styled from "styled-components"

export const TabHeadWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background-color: #F5F5F5;
    border-radius: 30px;
    position: relative;
    margin-top: 40px;

    &.choose-plan {
        background: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(2px);
    }
`

export const TableSubwrap = styled.div <{active?: boolean}> `
    font-size: 0.7rem;
    border-radius: inherit;
    padding: 10px 25px;
    background-color: ${(props:any) => props.active ? "#fff" : "transparent"};
    font-weight: 500;
    color: #062149;
    cursor: pointer;

    &.choose-plan {
        background-color: ${(props:any) => props.active ? "rgba(255, 255, 255, 0.40)" : "transparent"};
        color: #fff;
        border-radius: 16px;
        box-shadow: ${(props:any) => props.active ? "0px 0px 16px 0px rgba(163, 163, 163, 0.25)" : "unset"};
        backdrop-filter: ${(props:any) => props.active ? "blur(2px)" : "unset"};
    }
`

export const ImgWrap = styled.div `
    position: absolute;
    right: -10px;
    top: -32px;

    img {
        width: 95px;
    }
`