import React,{useState,useRef} from 'react'
import { ErrorAbsolute, InputWrap } from '../input/input.styles'
import { useFormContext } from "react-hook-form";

const HookInput = ({styleType, setEmail, 
                    forgotPass,holder, 
                    type, img, 
                    inputerror, inputValue, 
                    fields, setPasswordShow, 
                    passwordShow, inputtype,
                    showLabel,label}: any) => {
    const { register, formState: { errors } } = useFormContext();

    const handlePasswordVisibility = () => {
        if(inputtype !== "password") {
            return
        }

        if(passwordShow === "password") {
            setPasswordShow("text")
        }else {
            setPasswordShow("password")
        }
    }
  
  return (
    <div style={{width:"100%"}}>
        {showLabel && 
            <section style={{color:'#062149',fontSize:"18px",fontWeight:"600",marginBottom:"10px"}}>
                {label} &nbsp; {label==='Name of Organization' && <span style={{color:'#7D7D7D'}}>(Optional)</span>}
            </section>}
        <InputWrap styleType={styleType} inputtype={inputtype} error={errors[fields.register] ? true : false} >
            <div className="inner-input-wrap">
                    {
                        type === "select" ? 
                        <select>
                            <option value="">Nigeria</option>
                        </select>
                        : type === "email" && forgotPass?<input  type={type} placeholder={holder} {...register(fields.register, fields.validators)} onChange={(e:any)=>setEmail(e.target.value)} />
                        : 
                        <input type={passwordShow} placeholder={holder} {...register(fields.register, fields.validators)} />
                    }
    
                    {styleType === "normal-flex" && 
                        <img src={img} onClick={handlePasswordVisibility}/>
                    }
            </div>

            {
                errors[fields.register] && 
                <ErrorAbsolute>
                    {/* @ts-ignore */}
                    {errors[fields?.register]?.message}
                </ErrorAbsolute>
            }
        </InputWrap>
    </div>
  )
}

export default HookInput