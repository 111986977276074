import React,{useState} from 'react'
import { FormProvider } from 'react-hook-form'
import HookInput from '../../../../components/hookInput'
import PhoneNumber from '../../../../components/phoneNumber'
import { Country } from "react-phone-number-input";
import Button from '../../../../components/button';
import { ContactFormStyle } from '../../contactpage.styles';
import { ContactFormBody } from '../../contactpage.styles';


const env = window._jsenv || process.env
const imageBaseUrl = env.REACT_APP_GCP_BASE_URL;


function ContactForm({methods,onSubmit,setMessage,contactIsLoading}:any) {
    
  const [selectedCountry, setSelectedCountry] = useState<Country>("US");
  const [userDigit, setUserDigit] = useState("");
  const mail = `${imageBaseUrl}assets/svg/mail-icon.svg`


  return (
    <ContactFormStyle>
      <div className="form-header">
        <h2>Contact us</h2>

        <span>We would love to hear from you</span>
      </div>

      <ContactFormBody >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* FIRSTNAME AND LASTNAME */}
          <div className="form-row">
            <HookInput
              fields={{
                label: "First Name",
                register: "firstName",
                showLabel:true,
                validators: {
                  required: "First name is required",
                },
              }}
              styleType={"normal-flex"}
              type={"text"}
              holder={"First name"}
              showLabel={true}
              label = {"First Name"}
            />

            <HookInput
              fields={{
                label: "Last Name",
                register: "lastName",
                showLabel:true,
                validators: {
                  required: "Last name is required",
                },
              }}
              styleType={"normal-flex"}
              type={"text"}
              holder={"Last name"}
              showLabel={true}
              label = {"Last Name"}
            />
          </div>

          <div className="form-row">
          <HookInput
            fields={{
              label: "Work email",
              register: "email",
              showLabel:true,
              validators: {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$$/,
                  message: "Invalid email",
                },
              },
            }}
            type={"email"}
            holder={"Email"}
            img={mail}
            styleType={"normal-flex"}
            showLabel={true}
            label = {"Email Address"}
          />
            <PhoneNumber
              // country={country}
              // setCountry={selectedCountry}
              selectedCountry={selectedCountry}
              fields={{
                label: "Phone Number",
                register: "phone",
                showLabel:true,
                validators: { required: "Phone number is required" },
              }}
              setSelectedCountry={setSelectedCountry}
              styleType={"normal-flex"}
              setUserDigit={setUserDigit}
              showLabel={true}
              label = {"Phone Number"}
            />

            {/* <InputWrap styleType={""} inputtype={"unset"} error={false}>
              <div className="inner-input-wrap">
                <input
                  placeholder="COUNTRY"
                  autoComplete="off"
                  type="TEXT"
                  value={selectedCountry}
                />
              </div>
            </InputWrap> */}
          </div>

          <HookInput
            fields={{
              label: "Name of Organization",
              register: "organisation",
            }}
            type={"text"}
            holder={"Type Your Message here"}
            styleType={"normal-flex"}
            showLabel={true}
            label = {"Name of Organization"}
          />
        <section>
            <section style={{color:'#062149',fontSize:"18px",fontWeight:"600",marginBottom:"10px"}}>How can we help you?</section>
            <textarea onChange={(e)=>setMessage(e.target.value)} 
                      style={{height:"130px",
                      width:"100%",
                      padding:'10px',
                      boxSizing:"border-box"}} wrap='soft' placeholder='Ask your question...'/>
        </section>

          {!contactIsLoading?<Button text={"Submit"} type={"submit"} />:
          <div style={{width:"100%",height:'35px',
                       backgroundColor:"#6096E8",
                       display:"flex",
                       alignItems:"center",
                       justifyContent:"center",
                       color:'white'}}>
             Submitting...
          </div>}
        </form>
      </FormProvider>

      </ContactFormBody>
    </ContactFormStyle>
  )
}

export default ContactForm