import styled, {css} from "styled-components"

export const InputWrap = styled.div<{styleType: string, error: boolean, inputtype?: string}> `
    border: ${(props:any) => props.error ? "solid 2px rgba(242, 114, 114, 1)" : "solid 1px #D4D4D4"};
    border-radius: 4px;
    position: relative;
    flex:1;

    .inner-input-wrap {
        display: flex;
        padding: 0.8rem;
        column-gap: 1.3em;

        &:has(> input:focus) {
            border: ${(props:any) => props.error ? "solid 1px rgba(242, 114, 114, 1)" : "solid 1px #062149"};
        }

        .text-wrap {
            font-size: 0.8rem;
            font-weight: 500;
            color: #737373;
            white-space: nowrap;
            flex: 0.3;
            display: flex;
            align-items: center;
        }
    
        input {
            border: none;
            outline: none;
            background-color: transparent;
            width: 100%;
    
            &::placeholder {
                color: #A3A3A3;
                font-size: 0.8rem;
            }
        }

        img {
            cursor: ${(props:any) => props?.inputtype === "password" ? "pointer" : "unset"};
        }

        ${(props: any) =>
            props.styleType === "inline-flex" &&
            css`
                input {
                    flex: 0.6;
                }
            `
          };

        select {
            border: none;
            outline: none;
            background-color: transparent;
            width: 100%;
            cursor: pointer;
        }
    }


`

export const ErrorAbsolute = styled.div `
    color: rgba(242, 114, 114, 1);
    font-size: 0.7rem;
    position: absolute;
    bottom: -16px;
`