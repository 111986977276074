import React, { useEffect, useState } from 'react'
import { ProjectList, SidebarAccordion, SidebarAccordionList, SidebarWrap, SiteLinkList, PromptWrap, SpanWrap } from './usersidebar.styles'
import Button from '../button'
import { createSearchParams, useNavigate } from 'react-router-dom'

import cross from "../../assets/svg/blue-cross.svg"
import file from "../../assets/svg/blue-file.svg"
import trash from "../../assets/svg/trash.svg"
import message from "../../assets/svg/message-question.svg"
import settings from "../../assets/svg/setting-2.svg"
import logout from "../../assets/svg/logout.svg"
import closeSquare from "../../assets/svg/close-square.svg"
import closeCircle from "../../assets/svg/close-circle-2.svg"
import edit from "../../assets/svg/edit-2.svg"
import { useGetConversationListQuery, useGetCurrentPlanQuery, useDeleteConversationMutation, useUpdateConversationTitleMutation } from '../../services/customerApi'
import { useDispatch } from 'react-redux'
import { clearAuth } from '../../slices/auth'
import { RootState, useAppSelector } from '../../store'
import DeleteConvo from '../deletePrompt'
import EditConvoTitle from '../editPrompt'







const UserSidebar = ({projectList, handleProjectChoice, projectId, conversationId}:any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const path = window?.location?.pathname

    const [conversationList, setConversationList] = useState<any>([])
    const [activePlan, setActivePlan] = useState<any>([])
    const [triggerFetch,setTriggerFetch] = useState(false)
    const [showDelete,setShowDelete] = useState(false)
    const [selectedConvo,setSelectedConvo] = useState<any>('')
    const [showEditPop,setShowEditPop] = useState(false)

    const count = useAppSelector<any>((store:RootState) => store?.auth?.auth?.count)

    const {data, isLoading, isSuccess, isError, error, refetch} = useGetConversationListQuery()

    const {data: currentPlanData, refetch: refetchCurrentPlan, isSuccess: currentPlanSuccess} = useGetCurrentPlanQuery()
    const [deleteConversation, {data: deleteConversationData, isLoading: deleteConversationLoading, isSuccess: deleteConversationSuccess, isError: isdeleteConversationError, error: deleteConversationError}] = useDeleteConversationMutation() ;
    const [updateConversationTitle, {data: updateConversationTitleData, isLoading: updateConversationTitleLoading, isSuccess: updateConversationTitleSuccess, isError: isupdateConversationTitleError, error: updateConversationTitleError}] = useUpdateConversationTitleMutation()
    // USEEFFECT TO GET CONVERSATION LIST
    useEffect(() => {
        refetch()
        refetchCurrentPlan()
    }, [data, currentPlanData,triggerFetch])

    useEffect(() => {
        if(isSuccess) {
            setConversationList(data?.conversations)
        }

        if(currentPlanSuccess) {
            setActivePlan(currentPlanData)
        }
    }, [isSuccess, data, isLoading, isError, error, currentPlanSuccess])


    useEffect(()=>{
        if(deleteConversationSuccess || updateConversationTitleSuccess){
            
            setTriggerFetch(!triggerFetch)
        }
    },[deleteConversationSuccess,updateConversationTitleSuccess])


    useEffect(()=>{
        setShowDelete(false)
        setShowEditPop(false)
    },[data])

    const handleGotoConvo = (item:any) => {
        const params:any = {chatId: `${item?.conversationUuid}`, projectId: `${item?.projectUuid}`
                            , language:`${item?.language}` }

        navigate({
            pathname: "/chatroom",
            search: `?${createSearchParams(params).toString()}`
        })
    }

    const handleEditConversation = (val:any) => {
        const params = val

        updateConversationTitle(params)
    }


    const  handleDeleteConversation = (id:any) => {
        const params = id

        deleteConversation(params)
    }

    const handleLogout = () => {
        navigate("/login")
        localStorage.removeItem('prolificLoggedInTime');
        localStorage.removeItem("prolificLastVisited")
        dispatch(clearAuth())
    }

    const handleDeletePop = (item:any)=>{
            setSelectedConvo(item)
            setShowDelete(true)
    }


    
    const handleEditPop = (item:any)=>{
        setSelectedConvo(item)
        setShowEditPop(true)
}
    

  return (
    <SidebarWrap>
        {showDelete && <DeleteConvo setShowDelete={setShowDelete} selectedConvo={selectedConvo} handleDeleteConversation={handleDeleteConversation} deleteConversationSuccess={deleteConversationSuccess} deleteConversationLoading={deleteConversationLoading} isLoading={isLoading} isSuccess={isSuccess}/>}
        {showEditPop && <EditConvoTitle setShowEditPop ={setShowEditPop} selectedConvo={selectedConvo} handleEditConversation={handleEditConversation} updateConversationTitleSuccess={updateConversationTitleSuccess} updateConversationTitleLoading={updateConversationTitleLoading} isLoading={isLoading} isSuccess={isSuccess} />}
        <div className="inner-sidebar-wrap">
            <SidebarAccordion>
                <div className="accordion-side-header">
                    <div className="left-side">
                        Chat history
                    </div>
                </div>

                <SidebarAccordionList>
                    <li onClick={() => navigate("/new-chat")} className={path?.includes("new-chat") ? "active" : ""}>+ New chat</li>
                    {
                        conversationList?.map((item:any, index:any) => {
                            return (
                                <li key={index} className={item?.conversationUuid === conversationId ? "active" : ""}>
                                    <p onClick={() => handleGotoConvo(item)}>{item?.conversationName}</p>

                                    <div onClick={()=> handleEditPop(item)} className="img-wraps">
                                        
                                        <img src={edit} alt="" />
                                    </div>

                                    <div onClick={()=> handleDeletePop(item)} className="img-wraps">
                                        
                                        <img src={closeCircle} alt="" />
                                    </div>
                                </li>
                            )
                        })
                    }
                </SidebarAccordionList>
            </SidebarAccordion>

            <SidebarAccordion>
                <div className="accordion-side-header">
                    <div className="left-side">
                        Created Projects
                    </div>

                    {
                        activePlan?.totalChatbots > count &&
                        <Button btnType={"copy-project"} text={"New project"} icon={cross} onClick={() => navigate("/usecase-setup")}/>
                    }
                </div>

                <ProjectList>
                    {
                        projectList?.map((item:any, index:any) => {
                            return (
                                <li key={index} className={projectId === item?.key ? "active" : "" } >
                                    <div className="left-text" onClick={() => handleProjectChoice(item?.key)}>
                                        <img src={file} alt="" />
                                        <p>{item?.name}</p>
                                    </div>

                                    <div className="img-wraps">
                                        <img src={closeSquare} alt="" />
                                    </div>
                                </li>
                            )
                        })
                    }
                </ProjectList>
            </SidebarAccordion>

            <SiteLinkList>
                {/* <li> <img src={trash} alt="" /> Clear conversation</li> */}
                <li onClick={() => navigate("/user-settings")} className={path?.includes("user-settings") ? "active" : ""}> <img src={settings} alt="" /> Settings</li>
                {/* <li> <img src={message} alt="" /> Get help</li> */}
                <li onClick={handleLogout} > <img src={logout} alt="" /> Logout</li>
            </SiteLinkList>
        </div>
    </SidebarWrap>
  )
}

export default UserSidebar