import React, {useEffect, useState} from 'react'
import { PaymentWrap } from './payment.styles'
import { usePaystackPayment } from 'react-paystack';
import Spinner from '../../spinnersvg/Spinner';
import { useGetPlanPaidQuery } from '../../services/onboardingApi';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window { 
      _jsenv: any;
  }
}

const Payment = ({close, auth, chosenPlan, lastVisited}: any) => {
    let component = null
    const navigate = useNavigate()
    const [reference, setReference] = useState("")
    const key = String(chosenPlan?.key)

    const {data, isLoading, isSuccess, isError, error, refetch} = useGetPlanPaidQuery({reference, key})
  
  const env = window._jsenv || process.env

    const componentProps:any = {
        email: auth.email,
        // reference: (new Date()).getTime().toString(),
        amount: chosenPlan?.price * 100,
        currency: "USD",
        metadata: {
          name: auth.firstName
        },
        publicKey: env.REACT_APP_PAYSTACK_PUBLIC_KEY
    };

    const onSuccess = (reference:any) => {
        // Implementation for whatever you want to do with reference and after success call.
        setReference(reference?.reference)
    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      close()
    }

    const PaystackHook = () => {
      const initializePayment = usePaystackPayment(componentProps);
      return (
        <div className={"paystack-modal"}>
            <h3>You are about to pay for the <span>{chosenPlan?.time} {chosenPlan?.name} plan</span> </h3>

            <button onClick={() => {
                initializePayment(onSuccess, onClose)
            }}>Click to pay</button>
        </div>
      );
    };

    useEffect(() => {
      if(reference && key) {
        refetch()
      }
    }, [reference, chosenPlan])
  

      // USEFFECT FOR GETTING PLANPAIDREQUEST
      useEffect(() => {
        if(isSuccess) {
          navigate(lastVisited)
        }
      }, [data, isSuccess, isLoading, isError])

  return (
    <PaymentWrap>
      {
        isLoading ? 
        <Spinner></Spinner> : <PaystackHook />
      }
      {/* <Spinner></Spinner> */}
    </PaymentWrap>
  )
}

export default Payment