import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useForgotPasswordMutation } from "../../services/onboardingApi";
import { AuthWrap } from "../Auth/auth.styles";
import { ForgotSection, InnerRightAuthSubWrap } from "./forgot.styles";

import logo from "../../assets/svg/logo-white.svg";

import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import mail from "../../assets/svg/mail-icon.svg";
import Button from "../../components/button";
import FeedbackBox from "../../components/feedbackBox";
import HookInput from "../../components/hookInput";
import PublicLayout from "../../components/publicLayout";
import Spinner from "../../spinnersvg/Spinner";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState<any>();
  const methods = useForm({
    mode: "onBlur",
  });
  const [forgotPass, setForgotPass] = useState(true);

  // const keycloakContext:any = React.useContext(KeycloakContext);
  const dispatch = useDispatch();

  const [token, setToken] = useState(["", "", "", "", "", ""]);
  const inputRefs: React.RefObject<HTMLInputElement>[] = Array.from(
    { length: 6 },
    () => React.createRef()
  );

  const [
    forgotPassword,
    {
      data: forgotPasswordData,
      isSuccess: forgotPasswordSuccess,
      isLoading: forgotPasswordLoading,
      isError: forgotPasswordIsError,
      error: forgotPasswordError,
    },
  ] = useForgotPasswordMutation();

  useEffect(() => {
    if (forgotPasswordSuccess) {
      if (
        forgotPasswordData.message === "Email Sucessfully Sent to User Email"
      ) {
        toast.success("refer to your mail for completion", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    }

    if (forgotPasswordIsError || forgotPasswordError) {
      toast.error("check mail vaidity", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }, [
    forgotPasswordData,
    forgotPasswordError,
    forgotPasswordIsError,
    forgotPasswordLoading,
    forgotPasswordSuccess,
  ]);

  const onSubmit = () => {
    if (email) {
      const body = { email: email };
      forgotPassword(body);
    } else {
      toast.warning("fill all credentials", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <AuthWrap>
        <PublicLayout>
          <div className="inner-auth-wrap">
            <div className="auth-header">
              <Link to="/">
                <img src={logo} alt="brand logo" />
              </Link>
            </div>

            <div className="auth-flex-wrap">
              <div className="left-auth-subwrap">
                <div className="btn-wrap">
                  <Button
                    btnType={"backWhite"}
                    text={"Go home"}
                    onClick={() => navigate("/")}
                  />
                </div>

                <div className="auth-text-wrap">
                  <div className="large-text">
                    <h5>
                      Get started with <span> Prolific. </span>
                    </h5>

                    <p>
                      Prolific create conversational AI chatbots with your own
                      data to communicate naturally.
                    </p>
                  </div>
                </div>

                <div className="swiper-wrap">
                  <Swiper
                    spaceBetween={20}
                    slidesPerView={1}
                    autoplay={{
                      delay: 2500,
                      disableOnInteraction: false,
                    }}
                    direction="horizontal"
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Our customer service has taken a giant leap with this chatbot. It streamlines processes, resolves issues promptly, and even assists in driving sales through personalized recommendations. A game-changer for our business!"
                        }
                        author={"Alex Brabra"}
                      />
                    </SwiperSlide>

                    <SwiperSlide>
                      <FeedbackBox
                        text={
                          "Uploading my business’ data was easy and extremely fast, Prolific has helped in pleasantly engaging customers, and contributed to some sales. A practical asset for my business"
                        }
                        author={"Chris Dicaprio"}
                      />
                    </SwiperSlide>

                    {/* <SwiperSlide>
                                <FeedbackBox />
                            </SwiperSlide>

                            <SwiperSlide>
                                <FeedbackBox />
                            </SwiperSlide> */}
                  </Swiper>
                </div>

                <div className="auth-footer">
                  <p>
                    © Copyright 2023 - Prolific Technologies Limited || All
                    Rights Reserved
                  </p>
                </div>
              </div>

              <ForgotSection>
                <div className="contentHolder">
                  <InnerRightAuthSubWrap>
                    <section>Reset Your Password</section>

                    <FormProvider {...methods}>
                      <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <HookInput
                          fields={{
                            label: "Work email",
                            register: "email",
                            validators: {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$$/,
                                message: "Invalid email",
                              },
                            },
                          }}
                          type={"email"}
                          setEmail={setEmail}
                          forgotPass={forgotPass ? forgotPass : null}
                          holder={"Email"}
                          img={mail}
                          styleType={"normal-flex"}
                        />
                      </form>
                    </FormProvider>
                    {forgotPasswordLoading ?
                    
                    <button
                      style={{
                        padding: "10px 15px",
                        width: "120px",
                        cursor: "pointer",
                        backgroundColor: "#b0cbf3",
                        outline: "none",
                        border: "none",
                        borderRadius: "6px",
                        color: "white",
                      }}
                      
                    >
                      Submitting...
                    </button>
                    :<button
                      onClick={() => onSubmit()}
                      style={{
                        padding: "10px 15px",
                        width: "120px",
                        cursor: "pointer",
                        backgroundColor: "#1162DC",
                        outline: "none",
                        border: "none",
                        borderRadius: "6px",
                        color: "white",
                      }}
                      type="submit"
                    >
                      Submit
                    </button>}
                  </InnerRightAuthSubWrap>
                </div>
              </ForgotSection>
            </div>
          </div>
        </PublicLayout>
      </AuthWrap>
    </>
  );
};

export default ForgotPassword;
