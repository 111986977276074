import React,{useState,useEffect} from 'react'
import Spinner from '../../../spinnersvg/Spinner'
import { ContextHold } from './contextmini.styles'
import docIcon from '../../../assets/png/chatDocsIcon.png'
import { RootState } from '../../../store'
import { useSelector } from 'react-redux'
import fileIcon from '../../../assets/png/filesIcon.png'
import Utils from '../../../library/Utils'
import { useFetchChatFileMutation } from '../../../services/customerApi'
import backBlackIcon from '../../../assets/png/backBlackBg.png'
import backWhiteIcon from '../../../assets/png/backWhiteBg.png'
import pdfIcon from '../../../assets/png/pdfIcon.png'
import pptIcon from '../../../assets/png/pptxIcon.png'
import docFileIcon from '../../../assets/png/docIcon.png'



function ContextMiniPage({contextData,chatContext,projectUuid}:any) {
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)
    const [fileName,setFileName] = useState('')
    const [fileResponseHold,setFileResponseHold] = useState('')
    const [showPdf,setShowPdf] = useState(false)
    const [fileMimeType,setFileMiMeType] = useState('')

    const [fetchChatFile, 
          {data: fetchChatFileResponse, 
          isSuccess: fetchChatFileSuccess, 
          isLoading: fetchChatFileLoading, 
          isError: fetchChatFileError, 
          error: fetchChatFileErrorMessage}] = useFetchChatFileMutation()

    useEffect(()=>{
        if(fetchChatFileSuccess){
            setShowPdf(true)
            setFileResponseHold(fetchChatFileResponse.data.content)

            if(fetchChatFileResponse.data.fileExtension === '.txt'){
                setFileMiMeType('text/plain')
            }
            else if(fetchChatFileResponse.data.fileExtension === '.pdf'){
                setFileMiMeType('application/pdf')
            }
            else if(fetchChatFileResponse.data.fileExtension === '.doc'){
                setFileMiMeType('application/msword')
            }
            else if(fetchChatFileResponse.data.fileExtension === '.docx'){
                setFileMiMeType('application/vnd.openxmlformats-officedocument.wordprocessingml.document')
            }
            else if(fetchChatFileResponse.data.fileExtension === '.pptx'){
                setFileMiMeType('application/vnd.openxmlformats-officedocument.presentationml.presentation')
            }

        }
          },
          [fetchChatFileSuccess,fetchChatFileLoading,fetchChatFileError])
    
    
  return (
        <ContextHold colorState={backgroundMode.color}>
                {contextData==='loading' || fetchChatFileLoading?
                <Spinner/>:
                contextData==='success'?
                <>
                    {showPdf?
                    <div style={{width:'100%',height:'100%',margin:'0px auto'}}>

                        <section onClick={()=>setShowPdf(false)} style={{padding:'10px 10px 10px 30px',
                                                                        display:"flex",
                                                                        columnGap:"15px",
                                                                        alignItems:"center",
                                                                        cursor:'pointer'}}>
                            <span style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                <img src={backgroundMode.color==='white'?backWhiteIcon:backBlackIcon}/>
                            </span>
                            <span style={{display:"flex",
                                  justifyContent:"center",
                                  alignItems:"center",
                                  color:backgroundMode.color==='white'?'#1162DC':'white',
                                  fontWeight:'600'}}>
                                Back
                            </span>
                        </section>
                 
                        <div style={{display:"flex",
                            alignItems:"center",justifyContent:"Center",height:'85%'}}>
                            <embed
                                type={`${fileMimeType}`}
                                title="Context Viewer"
                                style={{width:'80vw',height:"100%",margin:'auto'}}
                                src={`data:${fileMimeType};base64,${fileResponseHold}`}
                            />
                        </div>
                    </div>
                    :<section className='contextFirstChild'> 

                    <div className='bottom'>
                        <section className='fileSect'>
                            <p className='fileHead'>Files</p>
                            <section className='pdfSect'>
                            {chatContext.sourceFiles.map((chatC:any)=>
                                <div style={{width:"33.33%",cursor:'pointer'}} onClick={()=>fetchChatFile({projectUuid:projectUuid,fileName:chatC})}>
                                    <section>
                                        <img src={
                                            chatC.split('.')[1].slice(0,3)==='pdf'?
                                            pdfIcon:
                                            chatC.split('.')[1].slice(0,3)==='doc'?
                                            docFileIcon:
                                            chatC.split('.')[1].slice(0,3)==='ppt'?
                                            pptIcon:
                                            fileIcon
                                            } alt=''/>
                                    </section>
                                    <p className='titleName'>{chatC}</p>
                                </div>)
                            }
                            </section>
                        
                        </section> 

                        <section className='linkSect'>
                            <p className='linkHead'>URLS</p>
                            <section className='linkSect'>
                            {chatContext.sourceLinks.map((chatC:any)=>
                                <div style={{display:'flex',flexDirection:'column',marginBottom:"10px"}}>
                                <span  className='urlTitle'>{chatC?.pageTitle}</span>
                                <a href={chatC.url} target='_blank' className='titleName' >{chatC.url}</a>
                            </div>)
                            }
                            </section>
                        
                        </section> 
                    </div>

                </section>}
                </>
                :
                <section style={{color:'red',fontWeight:'700',
                                 display:"flex",
                                 alignItems:"center",
                                 justifyContent:"center",
                                 marginTop:'35px',
                }}>
                    Something Went wrong!    
                </section>}
        </ContextHold>
  )
}

export default ContextMiniPage