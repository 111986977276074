import styled from "styled-components"

export const StepsWrapper = styled.div `
    .text-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        .steps-header {
            margin: 0;
            color: #808DA1;
            font-size: 0.8rem;
            font-weight: 400;
        }
    
        .topic-header {
            margin: 0;
            color: #062149;
            font-weight: 800;
            font-size: 1.8rem;

        }
    
        .instruction-content {
            margin: 0;
            font-weight: 400;
            font-size: 1rem;
            color: #062149;
        }
    }

    .customer-extra-info {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }


    .steps-footer-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        button {
            flex: 0.25;
        }
    }
`

export const UsecasesFlexWrap = styled.div `
    display: grid;
    grid-template-columns: auto auto auto ;
    align-content: center;
    grid-template-rows: 200px;
    gap: 40px 20px;
    margin: 30px 0px;

    @media only screen and (max-width: 992px) {
        grid-template-rows: unset;
    }

    @media only screen and (max-width: 528px) {
        grid-template-columns: auto auto ;
    }

    @media only screen and (max-width: 443px) {
        gap:40px 20px;
    }

    @media only screen and (max-width: 340px) {
        grid-template-columns: auto ;
    }
`