import React, { useEffect, useState } from 'react'
import { BluePageBg } from '../UserSettings/usersettings.styled'
import UserHeader from '../../components/userHeader'
import UserSidebar from '../../components/userSidebar'
import NewChatSettings from './newChat'
import { useCreateChatMutation, useGetUserProjectQuery } from '../../services/customerApi'
import Spinner from '../../spinnersvg/Spinner'
import { useNavigate} from 'react-router-dom'

const ChooseProject = () => {
    const navigate = useNavigate()
    const [projectChosen, setProjectChosen] = useState("")
    const [projectTitle, setProjectTitle] = useState("")
    const [projectList, setProjectList] = useState<any>([])
    localStorage.setItem('prolificLastVisited',window.location.pathname)


    // QUERY AND MUTATIONS FROM REDUX TOOLKIT
    const {data, isLoading, isSuccess, refetch, isError} = useGetUserProjectQuery()

    const [createChat, {data: createChatData, isLoading: createChatLoading, isSuccess: createChatSuccess, isError: isCreateChatError, error: createChatError}] = useCreateChatMutation()

    // USEFFECT FOR GETTING PROJECT LIST
    useEffect(() => {
        refetch()
    }, [data])

    useEffect(() => {
        if(isSuccess) {
            setProjectList(data?.projects)
        }
    }, [data, isLoading, isSuccess, isError])

    // USEEFFECT FOR CREATING NEW CHAT
    useEffect(() => {
        if(createChatSuccess) {
            navigate("/chatroom")
        }
    }, [createChatSuccess])

    const handleCreateChat = () => {
        const params:any = {
            conversationName: projectTitle,
            projectUuid: projectChosen,
        }

        createChat(params)
    }


  if (createChatLoading) {
    return (
        <Spinner></Spinner>
    )
  }  

  return (
    <BluePageBg>
        <UserHeader 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
        />
        <UserSidebar 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
        />

        <NewChatSettings 
            projectList={projectList}
            projectChosen={projectChosen}
            setProjectChosen={setProjectChosen}
            projectTitle={projectTitle}
            setProjectTitle={setProjectTitle}
            handleCreateChat={handleCreateChat}
        />
    </BluePageBg>
  )
}

export default ChooseProject