import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AmbassadorHead from "../../components/AmbassadorHead";
import Button from "../../components/button";
import Footer from "../../components/footer";
import { useFontHandler } from "../../hooks/useFontHandler";
import Utils from "../../library/Utils";
import {
  AboutProlific,
  AboutProlificLhs,
  AmbassadorLhs,
  AmbassadorRequirements,
  AmbassadorRequirementsLhs,
  Ambassadorwrap,
  BestFriend,
  FAQs,
  ReadySection,
  YourGain,
} from "./ambassador.styles";



const env = window._jsenv || process.env
const imageBaseUrl = env.REACT_APP_GCP_BASE_URL;


function AmbassadorPage() {
  const [ambsaddorImgCache,setAmbassadorImg] = useState<any>('')
  //image base Url

  const Gradient3 = `${imageBaseUrl}assets/png/Gradient3.webp`
  const RectangleBlur = `${imageBaseUrl}assets/png/Rectangle.webp`
  const addIcon = `${imageBaseUrl}assets/png/addIcon.png`
  const ambassadorImg = `${imageBaseUrl}assets/png/ambassadorLand.png`
  const AmbassadorReqIcon = `${imageBaseUrl}assets/png/ambassadorReq.webp`
  const cancelIcon = `${imageBaseUrl}assets/png/cancelIcon.png`
  const commissionIcon = `${imageBaseUrl}assets/png/commissionIcon.png`
  const communityIcon = `${imageBaseUrl}assets/png/communityIcon.png`
  const HeroBackgroundBlur = `${imageBaseUrl}assets/png/gradblur.png`
  const internshipIcon = `${imageBaseUrl}assets/png/internshipIcon.png`
  const productIcon = `${imageBaseUrl}assets/png/productIcon.png`
  const prolificChat = `${imageBaseUrl}assets/png/prolificChat.png`
  const recognitionIcon = `${imageBaseUrl}assets/png/recognitionIcon.png`
  const trendIcon = `${imageBaseUrl}assets/png/trendIcon.png`

  useEffect(()=>{
    if(!Utils.isFieldEmpty(localStorage.getItem('ambassadorImgCache'))){
      const imgCache = localStorage.getItem("ambassadorImgCache")
      setAmbassadorImg(imgCache)
    }
    else{
      localStorage.setItem("ambassadorImgCache",ambassadorImg)
    }

  },[ambassadorImg])


  useFontHandler();
  const featureRef: any = useRef(null);
  const pricingRef: any = useRef(null);
  const contactRef: any = useRef(null);
  const navigate = useNavigate();
  const [faqState, setFaqState] = useState(999);

  const navigateToExternalLink = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdeVK7lTzqMvWId6JfqhB1bCwN-YGwrYxClThfxI7z18qoEyQ/viewform",
      "_blank"
    );
  };

  const YourGainGrid = [
    {
      img: commissionIcon,
      text: "Commission per Sign Up",
    },
    {
      img: productIcon,
      text: "Product Swag",
    },
    {
      img: recognitionIcon,
      text: "Recognition and Awards",
    },

    {
      img: internshipIcon,
      text: "Internship opportunity for the Best Performer",
    },

    {
      img: trendIcon,
      text: "Develop Leadership, Creative and Managerial Skills",
    },
    {
      img: communityIcon,
      text: "Belong to a community of Campus Ambassadors and amplify your influence",
    },
  ];

  const aboutInfoBullets = [
    "Study better: Master complex subjects faster with Prolific's intuitive interface.",
    "Finish assignments faster: Get tasks done quicker and more efficiently, enhancing productivity.",
    "Translate seamlessly: Effortlessly translate responses into multiple languages. Prolific breaks down language barriers and promotes smooth cross-cultural communication.",
    "Save time: Save valuable time by streamlining data interactions and communication processes.",
  ];

  const RequirementsBullet = [
    "Have a high interest in Tech and AI.",
    "Be proactive and vocal",
    "Be able to influence within and outside your community",
    "Be an Undergraduate or post-graduate",
  ];

  const FaqQuestReply = [
    {
      ques: "Does it have access to my personal information?",
      reply: "No, Prolific does not have access to your personal information",
    },
    {
      ques: "During what hours is it available for assistance?",
      reply: "Prolific is available 24/7 ",
    },

    // {
    //   ques: "Can I escalate my query to a human agent if needed?",
    //   reply:
    //     "Please send an email (not your application) to marketing@periculum.io for inquiries.",
    // },
    {
      ques: "How long does the sign up process take?",
      reply: "Less than a minute",
    },
    {
      ques: "Is my data secure when interacting with it?",
      reply:
        "Our chatbot ensures the security of your data by prioritizing protection and confidentiality, aligned with Periculum's <a  style='color:#C4A2FC;cursor:pointer;font-weight:bold;' target='_blank' href='https://www.periculum.io/privacy-policy'>privacy policy</a>. Prolific operates in a secure environment, following industry best practices and robust security measures. We've implemented various technical and organizational safeguards to prevent unauthorized access, alteration, disclosure, or destruction of your data. We are NDPR(Nigeria Data Protection Regulation) Compliant which means our practices comply with relevant privacy laws and regulations most especially, the Nigeria Data Protection Act of 2023. ",
    },
    {
      ques: "How can I confirm if my language is on Prolific? ",
      reply: `
       
       <p>
       Prolific currently has 16 languages, to confirm if your language is on
       prolific?
       <a
       style='color:#C4A2FC;cursor:pointer;font-weight:bold;'
         target='_blank'
       href='/signup'
       >
         Click here
       </a>
     </p>`,
    },
    {
      ques: "How accurate and reliable are its responses?",
      reply:
        "If your information is accurate, your Prolific responses will be 100% accurate.",
    },
    {
      ques: "Where do I apply to be a Campus Ambassador?",
      reply: `<p>
        Click on this
        <a
          rel="noreferrer"
          target="_blank"
          style='color:#C4A2FC;cursor:pointer;font-weight:bold;'
          href="https://docs.google.com/forms/d/e/1FAIpQLSdeVK7lTzqMvWId6JfqhB1bCwN-YGwrYxClThfxI7z18qoEyQ/viewform"
        >
          link
        </a>
        to apply
      </p>`,
    },
    {
      ques: "Is the scheme for a specific course of study?",
      reply:
        "No. All students are eligible irrespective of their course or CGPA. However, preference will be placed on people interested in Tech and AI. Do well to highlight your strengths, ability to learn, adapt and be proactive in your application.",
    },
    {
      ques: "I am a freshman, can I still apply?",
      reply: "Yes, students across all levels are welcome to join the program",
    },
  ];

  const changeFaq = (index: any) => {
    if (index === faqState) {
      setFaqState(999);
    } else {
      setFaqState(index);
    }
  };

  return (
    <>
      <AmbassadorHead
        featureRef={featureRef}
        pricingRef={pricingRef}
        contactRef={contactRef}
        tag="ambassador"
      />

      <Ambassadorwrap>
        <AmbassadorLhs>
          <section className="header">
            <span
              style={{
                background: "-webkit-linear-gradient(#407BFF, #C4A2FC)",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
              }}
            >
              Prolific
            </span>{" "}
            is coming to your Campus
          </section>

          <section className="details">
            Prolific Campus Ambassador offers a unique opportunity to
            undergraduate and postgraduate students of tertiary institutions in
            Nigeria and across Africa.
          </section>

          <Button
            btnType={"cta"}
            text={"Apply Now"}
            tag="ambassador"
            onClick={() => navigateToExternalLink()}
          />
        </AmbassadorLhs>

        <div className="hero-banner-container">
          <figure className="hero-banner">
            <img
              src={Utils.isFieldEmpty(ambsaddorImgCache)?ambassadorImg:ambsaddorImgCache}
              alt="hero-banner"
              className="hero-banner-image"
            />
          </figure>
        </div>
      </Ambassadorwrap>

      <BestFriend
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          backgroundPosition: "center",
          background:"linear-gradient(to right, #37508A 0%, #37508A 10%, #784EAD 50%, #784EAD 75%, #252E3B 100%)",
        }}
      >

        <section className="header">Every Student’s Best Friend</section>
        <section className="details">
          Transform your bogus coursework into simple, intelligent
          conversations, and get answers with Prolific. Study for exams, get
          help with homework, effortlessly.
        </section>
      </BestFriend>

      <AboutProlific>
        <AboutProlificLhs>
          <section className="header">
            About{" "}
            <span
              style={{
                background: "-webkit-linear-gradient(#407BFF, #C4A2FC)",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
              }}
            >
              Prolific
            </span>
          </section>

          <section className="details">
            Prolific creates conversations with your data to communicate
            naturally. It helps you:
          </section>

          <section
            className="about-list"
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "15px",
            }}
          >
            {aboutInfoBullets.map((element: any, index: any) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}
              >
                <span style={{ flexShrink: 0 }}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM14.6549 18.6534L20.473 12.6534L19.127 11.3481L13.9554 16.6813L10.8464 13.7217L9.55361 15.0798L13.3354 18.6798L14.0082 19.3202L14.6549 18.6534Z"
                      fill="url(#paint0_linear_3499_71817)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3499_71817"
                        x1="0"
                        y1="0"
                        x2="33.7004"
                        y2="4.98527"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#407BFF" />
                        <stop offset="1" stop-color="#C4A2FC" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>

                <span className="bulletDetails">{element}</span>
              </div>
            ))}
          </section>
          <Button
            btnType={"cta"}
            text={"Read More"}
            onClick={() => navigate("/")}
            tag="ambassador"
          />
        </AboutProlificLhs>
        <div className="about-image">
          <img src={prolificChat} style={{ width: "100%" }} />
        </div>
      </AboutProlific>

      <YourGain
        style={{
          background:"linear-gradient(to right, #37508A 0%, #37508A 10%, #784EAD 50%, #784EAD 75%, #252E3B 100%)",
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          // backgroundPosition: "top right",
        }}
      >
        <section className="header">What’s in it for you!</section>
        <section className="details">
          In addition to being highly productive, our aim is for you to excel
          and flourish as a Student. Here are the benefits you will experience
          by taking on this role:
        </section>
        <div className="gridLay">
          {YourGainGrid.map((element, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "9999",
              }}
            >
              <span>
                <img src={element.img} />
              </span>
              <span className="bulletDetails">{element.text}</span>
            </div>
          ))}
        </div>
      </YourGain>

      <AmbassadorRequirements>
        <AmbassadorRequirementsLhs>
          <section className="header">
            <span
              style={{
                background: "-webkit-linear-gradient(#407BFF, #C4A2FC)",
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: "bold",
              }}
            >
              Requirements
            </span>{" "}
            to be an Ambassador
          </section>

          <section className="details">
            To be a Prolific Campus Ambassador, you must;
          </section>

          <section
            style={{ display: "flex", flexDirection: "column", rowGap: "15px" }}
          >
            {RequirementsBullet.map((element: any, index: any) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "15px",
                }}
              >
                <span style={{ flexShrink: 0 }}>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30ZM14.6549 18.6534L20.473 12.6534L19.127 11.3481L13.9554 16.6813L10.8464 13.7217L9.55361 15.0798L13.3354 18.6798L14.0082 19.3202L14.6549 18.6534Z"
                      fill="url(#paint0_linear_3499_71817)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_3499_71817"
                        x1="0"
                        y1="0"
                        x2="33.7004"
                        y2="4.98527"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#407BFF" />
                        <stop offset="1" stop-color="#C4A2FC" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="bulletDetails">{element}</span>
              </div>
            ))}
          </section>
          <Button
            btnType={"cta"}
            text={"Apply Now"}
            tag="ambassador"
            onClick={() => navigateToExternalLink()}
          />
        </AmbassadorRequirementsLhs>
        <div className="require-img-box">
          <img
            src={AmbassadorReqIcon}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            alt="AmbassadorReqIcon"
          />
        </div>
      </AmbassadorRequirements>

      <FAQs
        style={{
          //  backgroundImage: `url(${HeroBackground})`,
          background:"linear-gradient(to right, #37508A 0%, #37508A 10%, #784EAD 50%, #784EAD 75%, #252E3B 100%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >

        <div className="faq-wrapper">
          <section className="header">Frequently Asked Questions</section>
          {/* <section className="details">
            In addition to being highly productive, our aim is for you to excel
            and flourish as a Student. Here are the benefits you will experience
            by taking on this role:
          </section> */}
          <div className="FaqList">
            {FaqQuestReply.map((element, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  border: "1px solid white",
                  borderWidth: "0px 0px 1px 0px",
                }}
              >
                <section
                  className="question-wrapper"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <span className="question">{element.ques}</span>
                  <span
                    onClick={() => changeFaq(index)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={faqState === index ? cancelIcon : addIcon} />
                  </span>
                </section>
                <section
                  dangerouslySetInnerHTML={{ __html: element.reply as string }}
                  className={`reply-block ${faqState === index ?'isOpened':''}`}
                  
                />
              </div>
            ))}
          </div>
        </div>
      </FAQs>

      <ReadySection>
        <section className="header">
          Ready to be a{" "}
          <span
            style={{
              background: "-webkit-linear-gradient(#407BFF, #C4A2FC)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
              fontWeight: "bold",
            }}
          >
            Prolific
          </span>{" "}
          Campus Ambassador?
        </section>

        <Button
          btnType={"cta"}
          text={"Apply Now"}
          onClick={() => navigateToExternalLink()}
          tag="ambassador"
        />
      </ReadySection>

      <Footer
        featureRef={featureRef}
        pricingRef={pricingRef}
        contactRef={contactRef}
        tag="ambassador"
      />
    </>
  );
}

export default AmbassadorPage;
