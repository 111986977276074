import React,{useEffect} from 'react'
import { FormProvider, useForm } from "react-hook-form";
import FormInput from '../../ExtraComponents/TextInputs';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
import { CancelButton } from '../settingsmobile.styles';
import { UpdateButton } from '../settingsmobile.styles';
import Utils from '../../../../../library/Utils';

function EditUserMobileForm({userData}:any) {

    const methods = useForm({
        mode: "onBlur",
      });
    const { register,getValues,setValue, formState: { errors }, handleSubmit, reset,trigger } = useForm()
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)

    useEffect(()=>{
      if(userData){
          const newData = {...userData}

      newData.createdDate = Utils.formatLongDate(userData.createdDate)

      setValue('firstName',newData.firstName)
      setValue('joinedSince',newData.createdDate)
      setValue('referral',newData.referralCode)
      setValue('lastName',newData.lastName)
      setValue('occupation', newData.occupation)
      setValue('location',newData.location)
      }

    },[userData])


  return (
   <div style={{display:"flex",
                flexDirection:'column',
                rowGap:"20px",
                width:"100%",
                backgroundColor:backgroundMode.color==='white'?"white":'#1B1C1C',
                boxSizing:"border-box",
                height:"auto",
                
                padding:"10px",
                margin:"0px auto"}}>

                <FormProvider {...methods}>
                        <FormInput 
                        fields={{
                            label: "First Name",
                            register: "firstName",
                          }}
                          styleType={"normal-flex"}
                          type={"text"}
                          holder={"Emmanuel"}
                          errors={errors}
                          setValue={setValue}
                          register={register}
                        />


                        <FormInput 
                          fields={{
                              label: "Last Name",
                              register: "lastName",
                            }}
                          styleType={"normal-flex"}
                          type={"text"}
                          holder={"Bob"}
                          errors={errors}
                          setValue={setValue}
                          register={register}
                        />
                
                        <FormInput 
                          fields={{
                              label: "Referral Link",
                              register: "referral",
                            }}
                            styleType={"normal-flex"}
                            type={"text"}
                            holder={"http://prolificref.12345=AHFKDNNC"}
                            errors={errors}
                            setValue={setValue}
                            register={register}
                        />


                        <FormInput 
                          fields={{
                              label: "Occupation",
                              register: "occupation",
                            }}
                          type={"text"}
                          holder={"Occupation"}
                          
                          styleType={"normal-flex"}
                          errors={errors}
                          setValue={setValue}
                          register={register}
                        />

                        <FormInput 
                          fields={{
                              label: "Location",
                              register: "location",
                            }}
                          styleType={"normal-flex"}
                          type={"text"}
                          holder={"Lagos, Nigeria"}
                          errors={errors}
                          setValue={setValue}
                          register={register}
                        />


                      <div style={{display:"flex",justifyContent:"end",columnGap:"15px"}}>
                          <CancelButton colorState={backgroundMode.color}>Cancel</CancelButton>
                          <UpdateButton colorState={backgroundMode.color}>Update</UpdateButton>
                      </div>
            </FormProvider>
   </div>
    
  )
}

export default EditUserMobileForm