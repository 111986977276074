import styled from "styled-components"


export const BluePageBg = styled.div `
    background-color: #F3F8FF;
    position: relative;
    min-height: 100vh;
    display: flex;
    justify-content: flex-end;
    margin-left:280px;

    @media only screen and (max-width: 992px) {
        background-color: #fff;
        margin-left:0px;
        
    }

    @media only screen and (max-width: 506px) {
        margin-left:0px;
    }
`

export const ChatroomSection = styled.div<{colorState?: string}>`
    width: calc(100% );
    height: calc(100vh-40px);
    position: relative;
    background-color:${(props:any) => props?.colorState === 'white' ? "#F3F8FF" : "black"} ;
    transition: background-color 0.5s ease;


    .inner-chatroom-wrap {
        padding: 120px 1em 1em 1em;
        display: flex;
        height:95%;
        width:100%;
        display:flex;
        justify-content:space-around;
        box-sizing:border-box;
    
    
        .chatbox-wrapper {
            width:calc(100% - 450px) ;
            @media only screen and (max-width: 1350px) {
                width:90%;
            }
            .chatbox-header {
                display: flex;
                border-top-right-radius: 15px;
                border-top-left-radius: 15px;
                padding: 0px 40px;
                justify-content: space-between;
                align-items: center;
                height: 80px;
                border: ${(props:any) => props?.colorState === 'white'
                        ? "1px solid var(--primary-50, #E1EEFF)" 
                        : "1px solid #E1E1E1"};
                background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
                transition: all 0.5s ease;
                box-shadow: 0px 1px 0px 0px rgba(17, 98, 220, 0.21);
                

                @media only screen and (max-width: 1350px) {
                    height: 100px;
                    flex-direction:column;
                    padding: 10px 0px 0px 0px;
                }

                .header{
               
                    width:100%;
                    display: none;
                    padding: 10px 40px;
                    background-color:${(props:any) => props?.colorState === 'white' ? "#EFF4FF" : "#262626"} ;
                    transition: all 0.5s ease;
                    cursor:pointer;
                    margin-top:5px;

                    .headerFirst{
                        width:100%;
                        display:flex;
                        justify-content: end;
                        column-gap:7px;
                        align-items: center;
                        
                      
                    }

                    @media only screen and (max-width: 1350px) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
    
                    .imgHold{
                        display:flex;
                        justify-content:center;
                        align-items:center;
                    }
    
                    .docHold{
                        color: ${(props:any) => props?.colorState === 'white'?
                                '#062149':'white'};
                        font-size:16px;
                        font-weight:600;
                    }
                }
             
                .left-side {
                    width:100%;
                    display:flex;
                    justify-content:space-between;
                    padding: 0 40px;
                    align-items:center;

                    @media only screen and (max-width: 600px) {
                        padding: 0 10px;
                    }


                    h3.head {
                        font-size: 1.2rem;
                        color:${(props:any) => props?.colorState === 'white' ? "#062149" : "#FFFFFF"} ;
                        transition: all 0.5s ease;
                        font-weight: 700;
                        margin: 0;
                    }

                    .tooltipHold{
                        position:relative;
                        cursor:pointer;

                        .tooltiptext {
                            visibility: hidden;
                            opacity: 0;
                            width: 300px;
                            background-color: ${(props:any) => props?.colorState === 'white' ? "#808080" : "white"} ;
                            color: black;
                            color:${(props:any) => props?.colorState === 'white' ? "white" : "black"} ;
                            text-align: center;
                            border-radius: 8px;
                            padding: 15px;
                          
                            /* Position the tooltip */
                            position: absolute;
                            z-index: 1;
                            transition: opacity 0.5s, visibility 0s 0.5s;

                            .context{
                                text-align:left
                            }

                            .headerTool{
                                color:${(props:any) => props?.colorState === 'white' ? "white" : "#868787"} ;
                                font-size:12px;
                                margin-bottom:7px;
                                text-align:left
                            }
                          }
                    }

                    .tooltipHold:hover .tooltiptext {
                        visibility: visible;
                        opacity: 1;
                        transition: opacity 0.5s, visibility 0s;

                      }

                    .dropDownHolder{
                        display:flex;
                        flex-direction:column;
                        row-gap:10px;
                        height:auto;
                        position:relative;
                        width:120px;

                        .selectSection{
                            display:flex;
                            align-items:center;
                            border-radius:5px;
                            box-shadow:${(props:any) => props?.colorState === 'white' 
                                ? "1px 3px 2px 0px rgba(17, 98, 220, 0.21)" : "1px 3px 2px 0px #313336"} ;
                            column-gap:4px;
                            padding:13px;
                            background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#131415"} ;
                            color:${(props:any) => props?.colorState === 'white' ? "#062149" : "#FFFFFF"} ;
                            transition: all 0.5s ease;
                            justify-content:center;
                            cursor:pointer;

                            .imageHold{
                                display:flex;
                                align-items:center;
                                justify-content:center;
                            }
                        }

                        .langArray{
                            width:100%;
                            z-index:15;
                            background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#131415"} ;
                            transition: all 0.5s ease;
                            flex-direction:column;
                            height:200px;
                            overflow:auto;
                            margin-top:10px;
                            position:absolute;
                            top:40px;
                            border-radius:5px;
                            box-shadow:${(props:any) => props?.colorState === 'white' 
                                ? "1px 3px 2px 0px rgba(17, 98, 220, 0.21)" : "1px 3px 2px 0px #313336"} ;
                            display:flex;
                            border:0.1px solid black;
                            border-width: 0px 0px 0.1px 0px;

                            &::-webkit-scrollbar {
                                width: 10px;
                                background: #F3F8FF;
                                border-radius: 10px;
                            }
                        
                            &::-webkit-scrollbar-thumb {
                                background:${(props:any) => props?.colorState === 'white' ? "rgba(17, 98, 220, 1)" : "#313336"} ;
                                transition: background 0.5s ease;
                                border-radius: 10px;
                            }

                            .langText{
                                text-align:left;
                                padding:7px 5px;
                                cursor:pointer;
                                color:${(props:any) => props?.colorState === 'white' ? "#062149" : "#FFFFFF"} ;
                                transition: all 0.5s ease;
                            }
                        }
                        .langArrayHide{
                            display:none;
                        }
                    }
                }

                .right-side {
                    display: flex;
                    column-gap: 15px;
                    align-items: center;
                }
            }

            .mainchatboxbody {
                background-color: #fff;
                border: 1px solid var(--primary-50, #E1EEFF);
                height: calc(90% - 100px);
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
                padding: 5px 0;
                background-color:${(props:any) => props?.colorState === 'white' ? "#FCFDFF" : "#1B1C1C"} ;
                transition: all 0.5s ease;       
                box-sizing:border-box;

                

                .center-box-wrap {
                    display: flex;
                    width: 50%;
                    align-items: center;
                    justify-content: center;
                    gap: 60px;

                    img {

                    }

                    .text {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        row-gap: 20px;

                        h3 {
                            font-size: 2.5rem;
                            margin: 0;
                            font-weight: 800;
                            color: ${(props:any) => props?.colorState==='white'? '#062149':"transparent"};
                            background: ${(props:any) => props?.colorState !== 'white' 
                            ? "linear-gradient(to right, #407BFF 0%, #407BFF 25%, #C4A2FC 50%, #C4A2FC 75%, #C4A2FC 100%);" 
                            : ""};
                            -webkit-background-clip: text; /* Clips the background to the text (WebKit browsers) */
                            background-clip: text;
                            transition: background 0.5s ease;
                        }

                        p {
                            font-size: 1.2rem;
                            font-weight: 400;
                            color: ${(props:any) => props?.colorState==='white'? '#062149':"white"};
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1220px) {
        width: calc(100% );
    }

    @media only screen and (max-width: 1100px) {
        .inner-chatroom-wrap {
            140px 1em 1em 1em;
        }
    }

    @media only screen and (max-width: 992px) {
        width: 100%;
    }

    @media only screen and (max-width: 680px) {
        .inner-chatroom-wrap {
            .chatbox-wrapper {
                .mainchatboxbody {
                    .center-box-wrap {
                        flex-direction: column;
                        gap: 30px;

                        .text {
                            gap: 8px;
                            h3 {
                                text-align: center;
                            }

                            p {
                                text-align: center;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 506px) {
        .inner-chatroom-wrap {
            padding: 140px 1em 1em 1em;
        }
    }
`

export const ChatQuestionBox = styled.div <{colorState?: string}>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid var(--primary-50, #E1EEFF);
    background-color:${(props:any) => props?.colorState === 'white' ? "var(--generic-white, #FFF)" : "#1C1C1C"} ;
    transition: background-color 0.5s ease;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height:auto;
    
    overflow: auto;
    width:100%;
    box-sizing:border-box;

    textarea {
        border: none;
        width:99%;
        height:100%;
        resize:none;
        outline: none;
        background-color: transparent;
        lineHeight:100px;
        color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
        transition: color 0.5s ease;
        font-size: 0.8rem;
        font-weight: 400;
        white-space: pre-wrap;
        overflow: auto;
        overflow-x:hidden;
        box-sizing:border-box;

        &::-webkit-scrollbar {
            width: 15px;
            background: #F3F8FF;
            border-radius: 10px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: rgba(17, 98, 220, 1);
            border-radius: 10px;
        }

        &::placeholder {
            color: #A3A3A3;
            font-size: 0.8rem;
            font-weight: 400;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }

    section::-webkit-scrollbar {
        width: 0px;
        background: #F3F8FF;
        border-radius: 10px;
    }

    section::-webkit-scrollbar-thumb {
        background: rgba(17, 98, 220, 1);
        border-radius: 10px;
    }


    .send-btn {
        display: flex;
        justify-content: center;
        img {
            cursor: pointer;
        }
    }
`

export const ChatroomFooter = styled.div <{colorState?: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;

    p {
        margin: 0;
        text-align: center;
        color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
        transition: color 0.5s ease;
        font-size: 0.8rem;
    }
`