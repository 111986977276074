import React from 'react'
import { ImgWrap, TabHeadWrap, TableSubwrap } from './tabheader.styles'

import info from "../../assets/svg/free-info.svg"

const TabHeader = ({className, planType, setPLanType}: any) => {
  return (
    <>
        <TabHeadWrap className={className}>
            <TableSubwrap className={className} active={planType === "monthly" ? true : false} onClick={() => setPLanType("monthly")}>
                Monthly
            </TableSubwrap>

            <TableSubwrap className={className} active={planType === "yearly" ? true : false} onClick={() => {setPLanType("yearly")}}>
                Yearly
            </TableSubwrap>

            <ImgWrap>
              <img src={info} alt="" />
            </ImgWrap>
        </TabHeadWrap>
    </>
  )
}

export default TabHeader