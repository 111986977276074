import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import { ForgotPassWrap } from "./confirmpass.styles";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useSearchParams} from "react-router-dom";
import eyeSlash from "../../assets/svg/eye-slash.svg";
import eye from "../../assets/svg/eye.svg";
import logo from "../../assets/svg/logo-white.svg";
import { ForgotPass } from "./confirmpass.styles";
import Button from "../../components/button";
import HookInput from "../../components/hookInput";
import { useConfirmResetCodeQuery,useUpdatePassWordMutation } from "../../services/onboardingApi";
import Spinner from "../../spinnersvg/Spinner";
import { useParams,useLocation } from "react-router-dom";

import PublicLayout from "../../components/publicLayout";

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const methods = useForm({
    mode: "onBlur",
  });

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
 
  const [passwordShow, setPasswordShow] = useState("password");
  const [cpasswordShow, setCPasswordShow] = useState("password");
  const [showConfirmPassPage,setConfirmPassPage] = useState(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetCode = queryParams.get('resetCode');

  // const keycloakContext:any = React.useContext(KeycloakContext);
  const dispatch = useDispatch();
  // const access_token = useAppSelector<any>((store:RootState) => store?.token.token)
  // const user = useAppSelector<any>((store:RootState) => store?.auth?.auth)

  const [updatePassWord, { data:updatePassData, 
                           isSuccess:updatePassSuccess, 
                           isLoading:updatePassLoading, 
                           isError:updatePassIsError, 
                           error:updatePassErrorMessage }] =
  useUpdatePassWordMutation();


  const {data: resetCodeQueryData, 
         isLoading: resetCodeQueryLoading, 
         isSuccess: resetCodeQuerySuccess, 
         refetch: resetCodeQueryRefetch, 
         isError: resetCodeQueryError} = useConfirmResetCodeQuery(resetCode)
  

 // USEFFECT FOR LOGIN
  useEffect(() => {
    if (updatePassSuccess||resetCodeQuerySuccess) {
      setConfirmPassPage(true)
    }

    if(resetCodeQueryLoading){
        setConfirmPassPage(false)
    }

    if (updatePassIsError || resetCodeQueryError) {
        //navigate('/login')
        //navigate()
        if(updatePassErrorMessage && 'data' in updatePassErrorMessage){
          notifyError(updatePassErrorMessage.data.message);
        }

        if(resetCodeQueryError){
          notifyError('resetCode unvalidated.');
          navigate('/login')
        }
        
    }
  }, [resetCodeQueryLoading,resetCodeQuerySuccess
     ,updatePassSuccess,updatePassLoading, 
      updatePassIsError,resetCodeQueryError]);


  // const {data: getUserData, isLoading: getUserLoading, isSuccess: getUserSuccess, isError: getUserIsError, error: getUserError, refetch} = useGetUserQuery()

  // useEffect(() => {
  //     if(keycloakContext.token !== null && keycloakContext.token !== undefined) {
  //         dispatch(setToken(keycloakContext.token))

  //         if(access_token && !getUserData?.data) {
  //             refetch()
  //         }

  //         if(getUserSuccess && Utils?.isFieldEmpty(user)) {
  //             dispatch(setAuth(getUserData))
  //         }
  //     }
  // }, [keycloakContext, getUserSuccess, access_token, getUserData, user])

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const onSubmit = (formData: any) => {
    const obj = {...formData}
    obj['resetCode'] = resetCode
    updatePassWord(obj);
  };

 /**
  *  if (updatePassLoading) {
    return <Spinner></Spinner>;
  }
  */

  return (
    <>
        {showConfirmPassPage?
        <>
            <ToastContainer />
      <ForgotPassWrap
      // style={{
      //   backgroundImage: `url(${St})`,
      //   backgroundRepeat: " no-repeat",
      //   backgroundSize: "cover",
      //   backgroundColor: "rgb(203, 71, 197)",
      //   backgroundPosition: "center",
      //   position: "relative",
      // }}
      >
        <PublicLayout>
          <div className="inner-auth-wrap">
            <div className="auth-header">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className="auth-flex-wrap">
              <div className="left-auth-subwrap">
                <div className="btn-wrap">
                  <Button
                    btnType={"backWhite"}
                    text={"Back to Login"}
                    onClick={() => navigate("/login")}
                  />
                </div>

                <div className="auth-text-wrap">
                  <div className="large-text">
                    {updatePassSuccess?
                    <h5>
                        Your new <span>Password</span> 
                        &nbsp;<span>has</span> <span>been</span> <span>set</span>    
                    </h5>:
                    <h5>
                        Set New <span> Password.</span>
                     </h5>
                    }

                    {updatePassSuccess?'':
                    <p>
                    Enter your new password.
                    </p>}
                  </div>
                </div>

                <div className="auth-footer">
                  <p>{`© Copyright ${currentYear} - Prolific Technologies || All
                    Rights Reserved`}
                  </p>
                </div>
              </div>

              <div className="right-auth-subwrap">
                <ForgotPass>
                  <div className="inner-authcard-wrap">
                    <div className="form-head-wrap">
                      {updatePassSuccess?
                       <h3>New Password Set!</h3>
                       :<h3>Enter New Password</h3>}
                    </div>

                   {updatePassSuccess?'': <FormProvider {...methods}>
                      <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <HookInput
                          fields={{
                            label: "Password",
                            register: "password",
                            validators: {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters",
                              },
                            },
                          }}
                          type={passwordShow}
                          holder={"Password"}
                          img={passwordShow === "password" ? eyeSlash : eye}
                          styleType={"normal-flex"}
                          passwordShow={passwordShow}
                          setPasswordShow={setPasswordShow}
                          inputtype={"password"}
                        />

                        <HookInput
                          fields={{
                            label: "Confirm Password",
                            register: "cPassword",
                            validators: {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters",
                              },
                            },
                          }}
                          type={cpasswordShow}
                          holder={"Confirm Password"}
                          img={cpasswordShow === "password" ? eyeSlash : eye}
                          styleType={"normal-flex"}
                          passwordShow={cpasswordShow}
                          setPasswordShow={setCPasswordShow}
                          inputtype={"password"}
                        />
                        {updatePassLoading?
                        <Button text={"Setting Password..."} btnType={"log-inactive"} />
                        :<Button text={"Set New Password"} type="submit" />}
                        
                      </form>
                    </FormProvider>}

                    {updatePassSuccess && 
                    <Button text={"Return to Login"} onClick={()=>navigate('/login')} />}

                  </div>
                </ForgotPass>
              </div>
            </div>
          </div>
        </PublicLayout>
        {/* <figure
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            padding: 0,
            margin: 0,
            zIndex: 1,
          }}
        >
          <img
            src={St1}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="si"
          />
        </figure> */}
      </ForgotPassWrap>
        </>:
        <Spinner/>}
    </>
  );
};

export default ConfirmPassword;


