import styled from "styled-components";

export const SignUpCard = styled.div<{ navigationState?: string }>`
  background-color: #fff;
  min-height: 100%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  width: 100%;


  .inner-authcard-wrap {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 1em 4em;
    width: 100%;
    

    .stepper-container {
      margin-bottom: 1rem;

      div {
        cursor: pointer;
      }

      a {
        cursor: pointer;
      }

      @media only screen and (max-height: 750px) {
        display:none;
      }

    }

 

    .user-selector {
      width: 80%;
      display: flex;
      margin: 0px auto;
      height: 50px;

      .unselected {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border: 0.1px solid rgba(17, 98, 220, 1);
        font-size: 400;
        color: rgba(14, 1, 29, 1);
        cursor: pointer;
      }

      .selected {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        border: 0.1px solid rgba(17, 98, 220, 1);
        font-size: 400;
        color: rgba(14, 1, 29, 1);
        background-color: rgba(225, 238, 255, 1);
        cursor: pointer;
      }
    }

    .naviagtion-option {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      color: rgba(255, 255, 255, 1);
      font-size: 17px;
      background-color: rgba(17, 98, 220, 1);
      margin: 0px auto;
      height: 50px;
      margin-top: 20px;
      border-radius: 8px;
      cursor: pointer;
    }
    .form-head-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 20px;
      gap: 20px;

      h3 {
        color: #062149;
        font-weight: 700;
        font-size: 23.27px;
        line-height: 29.92px;
        text-align: center;
        margin: 0;

        @media only screen and (max-width: 768px) {
          font-size: 1.5rem;
        }
      }

      p {
        color: #062149;
        font-size: 13.3px;
        line-height: 19.95px;
        font-weight: 400;
        text-align: center;
        margin: 0;
        // width: 80%;
      }

      span {
        color: #062149;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        margin: 0;
        width: 100%;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      row-gap: 15px;

      .form-row {
        display: flex;
        column-gap: 15px;
        flex:1;
      }
    }

    .form-extras {
      margin-top: 30px;

      .login-redirect {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        color: #062149;
        column-gap: 6px;

        span {
          color: #1162dc;
          font-weight: 600;
          cursor: pointer;
        }
      }

      .or-box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
        padding: 0 50px;
        column-gap: 20px;

        .horizontal-line {
          border: solid 1px #d4d4d4;
          width: 100%;
        }

        p {
          font-size: 1rem;
          font-weight: 500;
          margin: 0;
          color: #737373;
        }
      }

      .continue-with-google {
        cursor: pointer;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #d4d4d4;
        border-radius: 4px;
        padding: 1em;
        column-gap: 10px;

        img {
        }

        p {
          margin: 0;
          font-size: 1rem;
          color: #000;
          font-weight: 600;
        }
      }
    }
  }


  @media only screen and (max-width: 992px) {
    .inner-authcard-wrap {
      padding: 3em 4em;
    }
  }

  @media only screen and (max-width: 768px) {
    .inner-authcard-wrap {
      padding: 3em 1.5em;
    }
  }
`;

export const LoginCard = styled(SignUpCard)`
  .login-subtext {
    width: 100%;
  }
`;
