import React, { useEffect, useState } from 'react'
import { BluePageBg } from '../UserSettings/usersettings.styled'
import { ContentPage } from './chooseproject.styles'
import UserHeader from '../../components/userHeader'
import IndividualUserHeader from '../../components/IndividualUserHeader'
import UserSidebar from '../../components/userSidebar'
import IndividualSideBar from '../../components/IndividualSideBar'
import NewChatSettings from './newChat'
import { useCreateChatMutation, useGetUserProjectQuery,useGetUserDataQuery } from '../../services/customerApi'
import Spinner from '../../spinnersvg/Spinner'
import { useNavigate} from 'react-router-dom'
import IntroChat from './Steps/IntroChat'
import Modal from "react-modal";
import { Overlay } from '../../components/header/header.styles'
import ChatCreationModal from '../../components/modals/IndividualChatCreation/ChatCreationModal'
import { RootState } from '../../store'
import { useSelector } from 'react-redux'
import { useGetUserQuery } from '../../services/onboardingApi'


const IndividualChat = () => {
    const navigate = useNavigate()
    const [projectChosen, setProjectChosen] = useState("")
    const [projectTitle, setProjectTitle] = useState("")
    const [projectList, setProjectList] = useState<any>([])
    const [recallChats,setRecallChats] = useState(false)

    const [projectName, setProjectName] = useState("")
    const [linksUploaded, setLinksUploaded] = useState<any>([])
    const [filesUploaded, setFilesUploaded] = useState<any>([])
    const [modalIsOpen, setIsOpen] = useState(false);
    const [openHeaderModal,setOpenHeaderModal] = useState(false)
    const backgroundMode = useSelector((state: RootState) => state.backgroundMode)


    function afterOpenModal() { }

    function closeModal() {
        setIsOpen(false);
        //dispatch(resetDownload());
      }

    const customStyles = {
        content: {
          transition: '0.125s ease-in-out',
          transform: 'scale(1)',
          height: '700px',
          width: '50%',
          top: 'calc((100% - 700px) / 2)',
          bottom : 'calc((100% - 700px) / 2)',
          left: '25%',
          backgroundColor:backgroundMode.color==='white'?'#FFFFFF': '#1B1C1C',
          borderRadius:'15px',
          padding:'30px 40px 0px',
          boxSizing:'border-box',
         
        },
        overlay: {
          zIndex: 1000,
          position: 'absolute'
        }
      };

     
    localStorage.setItem('prolificLastVisited',window.location.pathname)


    // QUERY AND MUTATIONS FROM REDUX TOOLKIT
    const {data, isLoading, isSuccess, refetch, isError} = useGetUserProjectQuery()

    const {data:userData, 
          isLoading:userDataLoading, 
          isSuccess:userDataSuccess, 
          refetch:userDataRefetch, 
          isError:userDataisError} = useGetUserDataQuery()

    const [createChat, {data: createChatData, 
                        isLoading: createChatLoading, 
                        isSuccess: createChatSuccess, 
                        isError: isCreateChatError, 
                        error: createChatError}] = useCreateChatMutation()

    useEffect(()=>{
        userDataRefetch()
    },[])


    // USEFFECT FOR GETTING PROJECT LIST
    useEffect(() => {
        refetch()
    }, [data])

    useEffect(() => {
        if(isSuccess) {
            setProjectList(data?.projects)
        }
    }, [data, isLoading, isSuccess, isError])

    // USEEFFECT FOR CREATING NEW CHAT
    useEffect(() => {
        if(createChatSuccess) {
            navigate("/chatroom")
        }
    }, [createChatSuccess])

    const handleCreateChat = () => {
        const params:any = {
            conversationName: projectTitle,
            projectUuid: projectChosen,
        }

        createChat(params)
    }


  if (createChatLoading) {
    return (
        <Spinner></Spinner>
    )
  }  

  return (
    <ContentPage>
        <IndividualUserHeader 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
            recallChats={recallChats}
            setRecallChats={setRecallChats}
            setOpenHeaderModal={setOpenHeaderModal}
            openHeaderModal={openHeaderModal}
        />
        <IndividualSideBar 
            projectList={projectList}
            handleProjectChoice={() => navigate("/user-settings")}
            setIsOpen={setIsOpen}
            recallChats={recallChats}
            setRecallChats={setRecallChats}
        />

        <IntroChat setIsOpen={setIsOpen} setOpenHeaderModal={setOpenHeaderModal}/>

        <Overlay activeOverlay={modalIsOpen} onClick={() => closeModal()} />
        <Modal isOpen={modalIsOpen} onAfterOpen={afterOpenModal} onRequestClose={closeModal} style={customStyles}
          contentLabel="Chat Creation" >
          <ChatCreationModal  projectName={projectName} setProjectName ={setProjectName} 
                              linksUploaded={linksUploaded} setLinksUploaded={setLinksUploaded} 
                              filesUploaded={filesUploaded} setFilesUploaded={setFilesUploaded}
                              closeModal={closeModal} setIsOpen={setIsOpen} setRecallChats={setRecallChats}
                              setOpenHeaderModal={setOpenHeaderModal}
                                />
        </Modal>
    </ContentPage>
  )
}

export default IndividualChat


/**
 * <NewChatSettings 
            projectList={projectList}
            projectChosen={projectChosen}
            setProjectChosen={setProjectChosen}
            projectTitle={projectTitle}
            setProjectTitle={setProjectTitle}
            handleCreateChat={handleCreateChat}
        />
 */