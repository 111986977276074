import styled from "styled-components"

export const SidebarWrap = styled.div<{colorState?: string}>`
    position: fixed;
    left: 0;
    top: 0;
    width: 286px;
    height: 100vh;
    z-index: 10;
    padding: 80px 20px 0px 20px;
    background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#131415"} ;
    transition: background-color 0.5s ease;
    box-sizing:border-box;

    &::-webkit-scrollbar {
        width: 5px;
      }

    .inner-sidebar-wrap {
        padding-top: 30px;
        padding-bottom: 0px;
        position:relative;
        height:100%;
    }

    @media only screen and (max-width: 1220px) {
        width: 286px;
    } 

    @media only screen and (max-width: 992px) {
        display: none;
    }
`

export const SidebarAccordion = styled.div <{colorState?: string}>`
    padding-bottom: 30px;
    .topSection{
        position:absolute;
        top:0px;
        height:25%;
        width:100%;
        display:flex;
        flex-direction:column;
        justify-content:space-around;
        box-sizing:border-box;

        .accordion-side-header {
            display: flex;
            align-items: center;
            column-gap: 10px;
            cursor: pointer;
    
            .left-side {
                font-weight: 600;
                font-size: 24px;
                color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
                transition: color 0.5s ease;
            }
        }

        .folderChatHold{
            display: flex;
            align-items: center;
            justify-content:space-between;
            width:100%;
            column-gap: 10px;
        };

        .chatPartition{
            background-color:${(props:any) => props?.colorState === 'white' ? "#E1EEFF" : "#313336"} ;
            transition: color 0.5s ease;
            height:1.5px;
            margin: 0px 0px 10px 0px;
        }


        .creationHold{
            margin-top:25px;
            display:flex;
            flex-direction:column;
            row-gap:10px;
            justify-content:center;
            align-items: start;
        }
    }

`

export const SidebarAccordionList = styled.div<{colorState?: string}>`

    position:absolute;
    top:25%;
    width:100%;
    height:45%;
    box-sizing:border-box;
    
    
   .holder{
    margin-top:0px;
    overflow:auto;
    height:100%;
    scroll-snap-type: y proximity;
    color:red;

    &::-webkit-scrollbar {
        width: 10px;
        background: #F3F8FF;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background:${(props:any) => props?.colorState === 'white' ? "rgba(17, 98, 220, 1)" : "#313336"} ;
        transition: background 0.5s ease;
        border-radius: 10px;
    }
    
    .chatPartition{
        background-color:#C4D9F8;
        height:1.5px;
        margin: 0px 0px 10px 0px;
    }

    li {
        color: #062149;
        font-size: 0.9rem;
        font-weight: 500;
        list-style: none;
        padding: 0.9rem 0.8em; 
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow:hidden;
        
       

        p {
            color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
            font-size: 0.9rem;
            margin: 0;
            font-weight: 500;
            width: 100%;
            transition: color 0.5s ease;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow:hidden;
        }

        .img-wraps {
            display: none;
            align-items: center;
            column-gap: 20px;
            position:relative;
            margin-left:15px;

            img {
                cursor: pointer;
            }
        }

        &.active, &:hover {
            border-radius: 3px;
            background-color:${(props:any) => props?.colorState === 'white' ? "#C4D9F8" : "#313336"} ;
            transition: background-color 0.3s ease;
            border-radius:5px;
        }

        &.active {
            .img-wraps {
                display: flex;
            }
        }
    }
   } 

    
`

export const ProjectList = styled.ul `
    padding-left: 15px;

    li {
        color: #062149;
        font-size: 0.9rem;
        font-weight: 500;
        list-style: none;
        padding: 0.9rem 0.8em; 
        cursor: pointer;
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-text {
            display: flex;
            align-items: center;
            column-gap: 10px;

            img {

            }
        }

        p {
            color: #062149;
            font-size: 0.9rem;
            margin: 0;
            font-weight: 500;
        }

        .img-wraps {
            display: none;
            align-items: center;
            column-gap: 20px;
            margin-left: 15px

            img {
                cursor: pointer;
            }
        }

        &.active, &:hover {
            background-color: #fff;
            border-radius: 3px;
        }

        &.active {
            .img-wraps {
                display: flex;
            }
        }
    }

`


export const PromptWrap = styled.div`
    position: absolute;
    z-index:100000;
    top: -90px;
    right: -40px;
    
    height: 120px;
    width: 350px;
    display:flex;
    border-radius:13px;
    justify-content: space-around;
    flex-direction:column;
    align-items:center;
    background-color: white
`

export const SpanWrap = styled.span`
    font-weight: 700;
    font-size: 15px
`


export const SiteLinkList = styled.ul<{colorState?: string}>`
padding-left: 0px;
position:absolute;
bottom:0px;
background-color:${(props:any) => props?.colorState === 'white' ? "white" : "#131415"} ;
transition: background-color 0.5s ease;
width:100%;
height:30%;
box-sizing:border-box;
display:flex;
justify-content:space-around;
flex-direction:Column;
box-sizing:border-box;

.lightHold{
    height:40px;
    width:50%;
    background-color:${(props:any) => props?.colorState === 'white' ? "#407BFF" : "#313336"} ;
    transition: background-color 0.5s ease;
    border-radius:15px;
    display:flex;
    justify-content:space-around;
    align-items:center;
    padding:10px 0px;
}

li {
    color: #062149;
    font-size: 14px;
    font-weight: 600;
    list-style: none;
    padding: 0.9rem 0.8em; 
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
    color:${(props:any) => props?.colorState === 'white' ? "#062149" : "white"} ;
    transition: color 0.5s ease;

    &.active, &:hover {
        background-color:${(props:any) => props?.colorState === 'white' ? "#fff" : "#313336"} ;
        transition: background-color 0.5s ease;
        border-radius: 3px;
    }
}

`