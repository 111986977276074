import styled from "styled-components"

export const SetupWrap = styled.div `
    min-height: 100vh;
    position: relative;
    background-color: #F3F8FF;

    .inner-setup-wrap {
        display: flex;
        padding: 0 6rem;
        justify-content: space-between;

        .left-setup-wrap {
            flex: 0.25;
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .header-wrap {
                padding-top: 3rem;
                img {

                }
            }

            .checkpoints-wrap {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                row-gap: 30px;
            }

            .footer-wrap {
                padding-bottom: 2rem;
                p {
                    margin: 0;
                    font-size: 0.8rem;
                    font-weight: 400;
                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        background-color: #fff;
        
        .inner-setup-wrap {
            flex-direction: column;

            .left-setup-wrap {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 687px) {
        .inner-setup-wrap {
            padding: 0 3em;
        }
    }

    @media only screen and (max-width: 576px) {
        background-color: #fff;

        .inner-setup-wrap {
            padding: 0;
        }
    }
`

export const MobileSetupHeader = styled.header `
    height: 60px;
    width: 100%;
    position: relative;
    background-color: #F3F8FF;
    display: none;
    justify-content: flex-start;
    align-items: center;

    img {
        cursor: pointer;
        margin-left: 20px;
    }

    @media only screen and (max-width: 992px) {
        display: flex;
    }

    @media only screen and (max-width: 576px) {
        img {
            width: 120px;
        }
    }
`

export const CheckpointsWrap = styled.div `
`

export const CheckpointsSubwrap = styled.div <{active?:boolean}> `
    position: relative;
    border-left: solid 3px #B0CBF3;
    padding-left: 1.5em;
    padding-bottom: 30px;

    .circle-ball {
        width: ${(props:any) => props.active ? "7px" : "15px"};
        height: ${(props:any) => props.active ? "7px" : "15px"};
        background-color: #fff;
        left: -9.5px;
        top: 0px;
        position: absolute;
        border-radius: 50%;
        border: ${(props:any) => props.active ? "solid 6px #1162DC" : "solid 1px #1162DC"};
    }

    .checkpoint-text {
        margin: 0;
        color: #062149;
        font-size: 0.9rem;
        font-weight: ${(props:any) => props.active ? "600" : "300"};
    }

    &.start-convo {
        border-left: none;

        .circle-ball {
            left: -7px;
        }
    }
`

export const RightSetupWrap = styled.div `
    background-color: #fff;
    flex: 0.65;
    min-height: 100vh;

    .inner-right-section {
        padding: 6rem 4rem;
    }

    @media only screen and (max-width: 1150px) {
        .inner-right-section {
            padding: 6em 3rem;
        }
    }

    @media only screen and (max-width: 992px) {
        flex: 1;
    }

    @media only screen and (max-width: 550px) {
        .inner-right-section {
            padding: 4em 2em;
        }
    }
`

export const WaitingWhiteBox = styled.div`
    background-color: #fff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    row-gap: 15px;

    h3 {
        font-weight: 700;
        color: #1162DC;
        margin: 0;
        font-size: 1.2rem;
    }

    img {

    }
`

export const MobileFooterWrap = styled.div `
    margin-top: 40px;
    margin-bottom: 30px;
    display: none;
    justify-content: center;
    align-items: center;

    p {
        margin: 0;
        font-size: 0.9rem;
        font-weight: 400;
    }

    @media only screen and (max-width: 992px) {
        display: flex;
    }
`

export const MobileCheckpointWrap = styled.div `
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 2px;
    margin-top: 15px;

    @media only screen and (max-width: 992px) {
        display: flex;
    }
`

export const MobileCheckpoint = styled.ul `
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    padding: 0;
    column-gap: 40px;

    &::before {
        z-index: 0;
        height: 1px;
        background-color: #B0CBF3;
        content: " ";
        position: absolute;
        top: 0.6375rem;
        left: 15px;
        right: 15px;
    }
`

export const MobileCheckpointList = styled.li <{active?: boolean}> `
    font-size: .875rem;
    font-weight: ${(props:any) => props.active ? '700' : '400'};
    position: relative;
    color: #062149;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &::before {
        content: " ";
        display: inline-block;
        width: ${(props:any) => props.active ? '0.5rem' : '1.075rem'};
        height: ${(props: any) => props.active ? '0.5rem' : '1.075rem'};
        margin: 0 auto .65rem auto;
        border-radius: 100%;
        border: ${(props:any) => props.active ? 'solid 5px #1162DC' : 'solid 1px #1162DC'} ;
        background-color: #fff;
        z-index: 2;
        // position: absolute;
    }

`