import styled from "styled-components"

export const AccordionCard = styled.div <{active?: boolean;}> `
    border-bottom: solid 1px #F5F5F5;

    .accordion-header{
        display: flex;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        padding: 30px 25px;
        background-color: #fff;

        .left-side {
            display: flex;
            align-items: center;
            column-gap: 5px;

            p {
                margin: 0;
                font-weight: 700;
                font-size: 1rem
            }
        }

        img {
            width: 22px;
        }
    }
`

export const AccordionContent = styled.div <{active?: boolean;}>`
    background-color: #fff;
    padding: ${(props:any) => props.active ? "30px 25px" : " 0"};
    max-height: ${(props:any) => props.active ? 'auto' : 0};
    transition: max-height 0.3s ease;
    overflow: hidden;
    border-bottom: solid 1px #F5F5F5;

    .context-text {
        font-size: 1.1rem;
        color: #062149;
        font-weight: 500;
    }
`