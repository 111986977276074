import styled from "styled-components"

export const ModifyAvatarOverall = styled.div `
    p.head-text {
        font-size: 0.8rem;
        color: #062149;
        margin: 0;
        font-weight: 800;
    }

    .usecase-wrapper {
        display: grid;
        grid-template-columns: auto auto auto;
        align-content: center;
        grid-template-rows: 200px;
        gap: 20px;
        column-gap: 30px;
        margin: 10px 0 40px;
    }

    .customer-extra-info {
        display: flex;
        align-items: center;
        column-gap: 15px;
        
    }

    @media only screen and (max-width: 1270px) {
        .usecase-wrapper {
            grid-template-rows: unset;
            column-gap: 20px;
        }
    }

    @media only screen and (max-width: 1020px) {
        .usecase-wrapper {
            column-gap: 15px;
        }
    }

    @media only screen and (max-width: 650px) {
        .usecase-wrapper {
            grid-template-columns: auto auto;
        }
    }

    @media only screen and (max-width: 354px) {
        .usecase-wrapper {
            grid-template-columns: auto ;
        }
    }
`